import Vue from "vue";
import VueResource from "vue-resource";

// API Components
// e.g., 'import API from "./api-call"'
import Drivers from "./drivers"
import Features from "./features"
import JobAssignments from "./job-assignments"
import Jobs from "./jobs"
import Organizations from "./organizations"
import QuoteRequests from "./quote-requests"
import Shifts from "./shifts"
import Users from "./users"
import Vehicles from "./vehicles"

Vue.use(VueResource);

// Use the current protocol/host/port for the API root
const API_ROOT =
  location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");

Vue.http.options.root = API_ROOT;
Vue.http.options.crossOrigin = true;
Vue.http.options.xhr = { withCredentials: true };

Vue.http.interceptors.push((request, next) => {
  const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  request.headers.set("X-CSRF-Token", csrfToken);
  request.headers.set("Accept", "application/json");

  next();
});

Vue.http.interceptors.push((request, next) => {
  next(response => {
    if (response.status === 401) {
      document.location.href = "/sign-in";
    }
    return response;
  });
});

export {
  Drivers,
  Features,
  JobAssignments,
  Jobs,
  Organizations,
  QuoteRequests,
  Shifts,
  Users,
  Vehicles
}

import * as API from '../../../api';

export default {
  createJobAssignment ({ commit }, payload) {
    API.JobAssignments.save({ job_assignment: payload }).then(response => {
      commit('appendSentAssignment', response.body)
    })
  },
  updateJobAssignment ({ commit }, payload) {
    API.JobAssignments.update({ id: payload.id }, { job_assignment: payload.job_assignment }).then(response => {
      commit('updateReceivedAssignment', response.body)
    })
  },
  getSentJobAssignments ({ commit }) {
    API.JobAssignments.get().then(response => {
      commit('setSentAssignments', response.body)
    })
  },
  getReceivedJobAssignments ({ commit }) {
    API.JobAssignments.get().then(response => {
      commit('setReceivedAssignments', response.body)
    })
  },
  CABLE_addReceivedJobAssignment ({ commit }, assignment) {
    commit('appendReceivedAssignment', assignment)
  },
  CABLE_updateSentJobAssignment ({ commit }, assignment) {
    commit('updateSentAssignment', assignment)
  }
}

<template>
  <div class="box-detail job-detail">
    <h2 class="job-detail__title">Incident Details</h2>
    <div class="job-detail__details container-fluid padding-left-no">
      <div class="row">
        <div class="job-detail__location col-md-6">
          <h3 class="job-detail__subtitle">Location</h3>
          <p class="job-detail__description mb-0 pb-0">{{ location }}</p>
          <p v-if="zone" class="job-detail__description">Zone: {{ zoneFormatted }}</p>
        </div>
        <div class="job-detail__police-mark col-md-6">
          <h3 class="job-detail__subtitle" v-if="motorist">Motorist Info</h3>
          <p class="job-detail__description" v-if="motorist">{{ motorist.firstName }} {{ motorist.lastName }}
          <br v-if="motorist.phoneNumber">{{ formattedPhone(motorist.phoneNumber) }}</p>
          <h3 class="job-detail__subtitle" v-if="details">{{ isMotorist ? 'Motorist Remarks' : 'Police Remark' }}</h3>
          <p class="job-detail__description" v-if="details">{{ details }}</p>
        </div>
      </div>
      <div class="row">
        <div v-if="organization.orgType === 'dispatcher'" class="col-md-6">
          <h3 class="job-detail__subtitle">{{department.name}}</h3>
          <p class="job-detail__description">Incident {{incidentNumber}} <span>Requested by {{officer}}</span></p>
        </div>
        <div v-else class="col-md-6">
          <h3 class="job-detail__subtitle">Source</h3>
          <p class="job-detail__description">{{ isMotorist ? 'Motorist' : department.name }}</p>
          <p class="job-detail__description" v-if="incidentNumber">Incident No. {{ incidentNumber }}</p>
        </div>
        <div v-if="isMotorist" class="col-md-6">
          <h3 class="job-detail__subtitle">Requested Service</h3>
          <p class="job-detail__description" v-if="incidentType">
            {{ incidentType }}
            <span v-if="quote">
              {{ formatPrice(quote.price) }}
            </span><br>
            <span v-if="destination && incidentType === 'Basic tow'">
              {{ destination }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncidentDetails',
  props: [
    'incidentNumber', 
    'incidentType',
    'isMotorist',
    'motorist',
    'department', 
    'destination', 
    'details', 
    'location', 
    'officer', 
    'organization',
    'quote',
    'zone'
  ],
  computed: {
    zoneFormatted () {
      if (this.zone) {
        return this.zone.name + " - " + this.zone.code
      }
    }
  },
  methods: {
    formatPrice(value) {
      if (!value) return null;

      return `$${value / 100}`;
    },
  }
}
</script>
<template>
  <div class="motorist-info">
    <div class="motorist-bar">
      <img class="curbside-icon" src="../../../../../images/logo_curbsidesos_dark.png" alt="curbside">
    </div>
    <MotoristBanner :data="jobDetail"/>
    <MotoristOnboarding :job_assignment_id="jobDetail.assignments[0].id" :motorist_data="jobDetail.assignments[0].motoristData" :job_detail="jobDetail"/>
  </div>
</template>

<script>
import MotoristBanner from './motorist-banner';
import MotoristOnboarding from './motorist-onboarding/motorist-onboarding'
import humps from 'humps';

export default {
  name: 'MotoristInfoJob',
  props: ['info_job', 'currentOrganization'],
  components: {
    MotoristBanner,
    MotoristOnboarding
  },
  data: () => ({
    jobDetail: '',
  }),
  created () {
    this.jobDetail = humps.camelizeKeys(this.info_job);
    if (this.currentOrganization === undefined) {
      let navbar = document.getElementById('vue-navbar');
      navbar.remove();
    }
  }
}
</script>
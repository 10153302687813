<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import HTTPClient from '../api/jwt';

  export default {
    created() {
      HTTPClient.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken;
      this.setAccessToken(this.accessToken);
    },

    methods: {
      ...mapMutations('users', [
        'setAccessToken'
      ]),
    },

    props: ['accessToken']
  }
</script>

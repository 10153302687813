<template>
  <div>
    <div class="col-md-10 margin-auto">
    <div class="row">
        <div class="form-group col-md-6">
          <input type="hidden" name="driver[first]" v-model="drivers.firstName">
          <md-field :class="getValidationClass('drivers', 'firstName')">
            <label class="form__label">First name</label>
            <md-input v-model="drivers.firstName" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.drivers.firstName.required">The First Name is required</span>
          </md-field>
        </div>
        <div class="form-group col-md-6"> 
          <input type="hidden" name="driver[last]" v-model="drivers.LastName">
          <md-field :class="getValidationClass('drivers', 'LastName')">
            <label class="form__label">Last name</label>
            <md-input v-model="drivers.LastName" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.drivers.LastName.required">The Last Name is required</span>
          </md-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <input type="hidden" name="driver[phone]" v-model="drivers.phoneNumber">
          <md-field :class="getValidationClass('drivers', 'phoneNumber')">
            <label class="form__label">Mobile phone number</label>
            <md-input v-model="drivers.phoneFormat" class="form-control" v-on:keyup="parseNumber()"></md-input>
            <span class="md-error" v-if="!$v.drivers.phoneNumber.required">The Mobile Phone Number is required</span>
          </md-field>
          <span
            class="validate-number"
            v-if="!drivers.validate_number"
            >The Mobile Number must be at least 10 characters long</span>
        </div>

        <div class="form-group col-md-6">
          <input type="hidden" name="driver[email]" v-model="drivers.email">
          <md-field :class="getValidationClass('drivers', 'email')">
            <label class="form__label">Email (optional)</label>
            <md-input v-model="drivers.email" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.drivers.email.email">Invalid email</span>
          </md-field>
        </div>
      </div>

      <div v-if="type_form === 'edit'">
        <div class="row col-md-12 col-lg-12 padding-left-no">
          <div v-if="drivers.invitationAccepted">
            <div class="col-md-12">
              <input type="hidden" name="driver[active]" v-model="drivers.active">
              <md-switch v-model="drivers.active" :class="[drivers.active === 'true' ? 'md-checked' : 'md', 'driver-switch']">{{drivers.active ? 'Active operator' : 'De-activate operator'}}</md-switch>
            </div>
            <div class="driver-license col-md-12">
              <h2 class="driver-license__title">Operator's License</h2>
              <p class="driver-license__description">Operator license information is only editable by the driver.</p>
              <div class="driver-license__licenses row">
                <div class="col-md-6">
                  <div class="license" v-if="licenseDriverFront" @click="drivers.showLicenseFront = true">
                    <div class="license__head">
                      <img :src="licenseDriverFront" alt="license front">
                    </div>
                    <div class="license__body">
                      <p>Front</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="license space-left" v-if="licenseDriverBack" @click="drivers.showLicenseBack = true">
                    <div class="license__head">
                      <img :src="licenseDriverBack" alt="license back">
                    </div>
                    <div class="license__body">
                      <p>Back</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 padding-left-no">
                <div class="form-group">
                  <md-field class="license-expiration-field">
                    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><mask id="nfu34tb" fill="#fff"><path d="m16 13h-3c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1zm0-10v1h-8v-1c0-.55-.45-1-1-1s-1 .45-1 1v1h-1c-1.11 0-1.99.9-1.99 2l-.01 14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2h-1v-1c0-.55-.45-1-1-1s-1 .45-1 1zm2 17h-12c-.55 0-1-.45-1-1v-10h14v10c0 .55-.45 1-1 1z" fill="#fff" fill-rule="evenodd"/></mask><g fill-opacity=".54" fill-rule="evenodd" mask="url(#nfu34tb)"><path d="m0 0h24v24h-24z"/></g></svg>
                    <p>{{ expiration_date }}</p>
                  </md-field>
                </div>
              </div>
            </div>
          </div>

          <div class="form__actions col-md-12">
            <div class="form__button-delete">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <defs>
                    <path id="l" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"/>
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <mask id="m" fill="#fff">
                        <use xlink:href="#l"/>
                    </mask>
                    <g fill="#f06837"  mask="url(#m)">
                        <path d="M0 0h24v24H0z"/>
                    </g>
                </g>
              </svg>
              <button class="form__button-link" @click.prevent="drivers.activeDialog = true">Delete Operator</button>
            </div>
            <div v-if="!drivers.invitationAccepted" :class="[this.drivers.sendingInvite ? 'disabled-button ' : '','form__button-resend-invitation']">
              <i class="material-icons">
              autorenew
              </i>
              <button :class="[this.drivers.sendingInvite ? 'disabled-button ' : '','form__button-link']" @click="resendInvite(data.organization_id, data.id, $event)">Resend Invite</button>
              <md-progress-spinner :class="[this.drivers.sendingInvite ? 'spinner-show ' : '', 'spinner']" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>
        </div>
      </div>

      <md-dialog-confirm
        :md-active.sync="drivers.activeDialog"
        md-content="Are you sure you want to delete this operator ?"
        md-confirm-text="Delete"
        md-cancel-text="Cancel"
        @md-cancel="onCancel"
        @md-confirm="deleteDriver(data.id,$event)"
      />
    </div>


    <div class="footer-form">
      <div class="col-md-10 margin-auto">
        <div class="footer-form__content">
          <md-button :disabled='!isCompleteForm' :class="[isCompleteForm ? 'active-button' : 'disabled-button','btn btn-primary footer-form__submit']" @click="validateUser">{{type_form === 'edit' ? 'update' : 'Invite Operator'}}</md-button>
          <md-button :href="path" class="md-primary footer-form__submit footer-form__submit-cancel">Cancel</md-button>
          <md-progress-spinner :class="[this.drivers.update ? 'spinner-show ' : '', 'spinner spinner--orange']" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </div>
      </div>
    </div>

    <md-snackbar
      :class="[drivers.error ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-driver']"
      :md-position="drivers.position"
      :md-duration="drivers.isInfinity ? Infinity : drivers.duration"
      :md-active.sync="drivers.alert"
      md-persistent
    >
      <span class="snackbar-notify__text">
        <i v-if="!drivers.error" class="material-icons">check</i>
        {{ drivers.alertMsg }}
      </span>
    </md-snackbar>

    <md-dialog class="dialog-driver-licenses-form" :md-active.sync="drivers.showLicenseFront">
       <img class="dialog-license-image" :src="licenseDriverFront" alt="license front">
    </md-dialog>
    <md-dialog class="dialog-driver-licenses-form" :md-active.sync="drivers.showLicenseBack">
       <img class="dialog-license-image" :src="licenseDriverBack" alt="license back">
    </md-dialog>


  </div>
</template>


<script>
import Vue from 'vue'
import {
  required,
  email,
  minLength
} from 'vuelidate/lib/validators'
import { formatPhoneNumber, caretPhone } from '../../../mixins/formatNumber'
import humps from 'humps'

export default {
  name: 'FormDriver',
  props: ['data', 'path', 'type_form', 'driver_license_front', 'driver_license_back', 'current_organization', 'expiration_date'],
  data: () => ({
    drivers: {
      firstName: null,
      LastName: null,
      phoneNumber: null,
      phoneFormat: null,
      invitationAccepted: false,
      email: null,
      sendingInvite: false,
      inviteSend: false,
      isInfinity: false,
      duration: 4000,
      position: 'left',
      active: null,
      showLicenseFront: false,
      showLicenseBack: false,
      activeDialog: false,
      validate_number: true,
      button_active: true,
      update: false,
      alert: false,
      alertMsg: '',
      error: false
    },
    licenseDriverFront: '',
    licenseDriverBack: ''
  }),
  validations: {
    drivers: {
      firstName: {
        required
      },
      LastName: {
        required
      },
      phoneNumber: {
        required,
        minLength: minLength(10),
      },
      email: {
        email
      }
    }
  },
  computed: {
    isCompleteForm () {
      return this.drivers.firstName && this.drivers.LastName && this.drivers.phoneNumber
    }
  },
  methods: {
   getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    validateUser () {
      this.drivers.button_active = false;
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.update = true
        if (this.type_form === 'edit' ) {
          this.editDriver();
        } else {
          this.addDriver();
        }
        this.drivers.button_active = true;
      }
    },
    addDriver () {
      let driver = {
        first: this.drivers.firstName,
        last: this.drivers.LastName,
        phone: this.drivers.phoneNumber,
        email: this.drivers.email,
        organization_id: this.current_organization,
        active: this.drivers.active
      }
      this.drivers.update = true
      Vue.http.post(`/drivers/`, driver ,[{responseType: 'json'}])
        .then(response => {
          if(response.ok) {
            this.drivers.alert = true
            this.drivers.alertMsg = 'Operator created successfully'
            this.drivers.update = false
            setTimeout(() => {
              window.location.href = '/drivers';
            },1000)
          }
        }, err => {
           if(!err.ok) {
             if(err.body.driver.length) {
               this.drivers.error = true;
               this.drivers.alert = true;
               this.drivers.button_active = true;
               this.drivers.alertMsg = `${err.body.driver}`
               this.drivers.update = false
             }
           }
        });
    },
    editDriver () {
      let driver = {
        first: this.drivers.firstName,
        last: this.drivers.LastName,
        phone: this.drivers.phoneNumber,
        email: this.drivers.email,
        organization_id: this.current_organization,
        active: this.drivers.active
      }
      this.drivers.update = true
      Vue.http.put(`/drivers/${this.data.id}`, driver ,[{responseType: 'json'}])
        .then(response => {
          if(response.ok) {
            this.drivers.error = false
            this.drivers.alert = true
            this.drivers.alertMsg = 'Operator updated successfully'
            this.drivers.update = false
            setTimeout(() => {
              window.location.href = '/drivers';
            },1000)
          }
        }, err => {
          const { body } = err
          if (body.driver) {
            this.drivers.error = true
            this.drivers.alert = true
            this.drivers.alertMsg = body.driver
            this.drivers.update = false
            this.drivers.button_active = true;
          }
        });
    },
    onCancel () {
      this.drivers.activeDialog = false
    },
    deleteDriver (id,event) {
      Vue.http.delete(`/drivers/${id}`,[],[{responseType: 'json'}])
      .then(response => {
        if(response.ok) {
          window.location.href = '/drivers';
        }
      }, err => {
        this.drivers.error = true;
        this.drivers.alert = true;
        this.drivers.button_active = true;
        this.drivers.alertMsg = `${err.body.error.message}`
      });
    },
    resendInvite (organizationId,driverId,e) {
      e.preventDefault();
      this.drivers.sendingInvite = true
      this.drivers.alert = true
      Vue.http.post(`/organizations/${organizationId}/drivers/${driverId}/send_email`,[],[{responseType: 'json'}])
        .then(response => {
          if(response.ok) {
            this.drivers.sendingInvite = false
            this.drivers.inviteSend = true
            this.drivers.alertMsg = "We've resent the link to the app store"
          }
        }, err => {
          //console.log(err);
        });
    },
    parseNumber () {
      let val = caretPhone(this.drivers.phoneFormat,this.drivers.phoneNumber)
      this.drivers.phoneNumber = val.phoneNumber
      this.drivers.phoneFormat = val.phoneFormat
      this.drivers.validate_number = this.$v.drivers.phoneNumber.minLength 
    }
  },
  created () {
    let data = humps.camelizeKeys(this.data)
    this.drivers.firstName = data.first
    this.drivers.LastName = data.last
    this.drivers.email = data.email
    this.drivers.phoneNumber = data.phone
    this.drivers.phoneFormat = formatPhoneNumber(data.phone)
    this.drivers.active = data.active
    this.drivers.invitationAccepted = data.invitationAccepted
    this.licenseDriverFront = this.driver_license_front
    this.licenseDriverBack = this.driver_license_back
  }
}
</script>

<style lang="scss" scoped>
  .validate-number {
    color: red;
  }

  .spinner-show {
    margin-top: 0 !important;
  }
</style>

<template>
  <div class="motorist-info">
    <div class="motorist-bar">
      <img class="curbside-icon" src="../../../../../images/logo_curbsidesos_dark.png" alt="curbside">
    </div>
    <MotoristBanner :data="jobDetail"/>
    <IncidentDetails :data="jobDetail"/>
    <MotoristTowDetail :data="jobDetail"/>
  </div>
</template>

<script>
import MotoristBanner from './motorist-banner';
import IncidentDetails from './incident-details';
import MotoristTowDetail from './motorist-tow-detail';
import humps from 'humps';

export default {
  name: 'MotoristInfoJob',
  props: ['info_job', 'currentOrganization'],
  components: {
    MotoristBanner,
    IncidentDetails,
    MotoristTowDetail
  },
  data: () => ({
    jobDetail: '',
  }),
  created () {
    this.jobDetail = humps.camelizeKeys(this.info_job);
    if (this.currentOrganization === undefined) {
      let navbar = document.getElementById('vue-navbar');
      navbar.remove();
    }
  }
}
</script>
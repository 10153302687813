<template>
  <md-card class="card card-notification">
    <p class="title-card">New jobs</p>
    <div class="card-notification__item">
      <div class="row">
        <div :class="[admin ? 'col-md-6' : 'col-md-8']">
        <div>
            <p class="card-notification__title">New job requests</p>
        </div>
        </div>
        <div :class="[admin ? 'col-md-6' : 'col-md-4']">
          <div class="card-notification__options">
            <md-checkbox v-model="JobRequestOption.email">Email</md-checkbox>
            <md-checkbox v-model="JobRequestOption.sms">SMS</md-checkbox>
            <md-checkbox v-model="JobRequestOption.robocall">Automated calls</md-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="card-notification__item">
      <div class="row">
        <div :class="[admin ? 'col-md-6' : 'col-md-8']">
          <div>
            <p class="card-notification__title">Quoted job was assigned to operator</p>
          </div>
        </div>
        <div :class="[admin ? 'col-md-6' : 'col-md-4']">
          <div class="card-notification__options">
            <md-checkbox v-model="JobWasAssignedToDriver.email">Email</md-checkbox>
            <md-checkbox v-model="JobWasAssignedToDriver.sms">SMS</md-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="card-notification__item">
      <div class="row">
        <div :class="[admin ? 'col-md-6' : 'col-md-8']">
          <p class="card-notification__title">Impounds, preference tows, or other direct requests</p>
          <p class="card-notification__desc">At least one method should be checked.</p>
        </div>
        <div :class="[admin ? 'col-md-6' : 'col-md-4']">
          <div class="card-notification__options">
            <md-checkbox v-model="preferences.email">Email</md-checkbox>
            <md-checkbox v-model="preferences.sms">SMS</md-checkbox>
            <md-checkbox v-model="preferences.robocall">Automated calls</md-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="card-notification__item">
      <div class="row">
        <div :class="[admin ? 'col-md-6' : 'col-md-8']">
          <p class="card-notification__title">Quoted job was declined</p>
        </div>
        <div :class="[admin ? 'col-md-6' : 'col-md-4']">
          <div class="card-notification__options">
            <md-checkbox v-model="quoteWasDeclined.email">Email</md-checkbox>
          </div>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="showDialog" class="notification-dialog">
      <md-dialog-title class="notification-dialog__title">At least one method should be checked</md-dialog-title>
      <md-dialog-content>
        <p class="notification-dialog__description">In order to not miss any tow opportunity that was specifically assigned to you, you must keep at least one method on.</p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onConfirm">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
  </md-card>
</template>

<script>
export default {
  name: 'NewJobs',
  props: ['notifications'],
  data: () => ({
    showDialog: false,
    optionsList: ['JobRequestOption','JobWasAssignedToDriver','preferences','quoteWasDeclined'],
    JobRequestOption: {
      email: true,
      sms: true,
      robocall: false
    },
    JobWasAssignedToDriver: {
      email: true,
      sms: false
    },
    preferences: {
      email: true,
      sms: true,
      robocall: false
    },
    quoteWasDeclined: {
     email: false
    },
    checkboxRow: 'jobRequestOption',
    robocall: {
      from: '',
      to: '',
      phoneNumber: ''
    },
    settings: [],
    admin: false
  }),
  watch: {
    notifications: function(settingVal,oldVal) {
      for (let i=0; i < this.optionsList.length; i++) {
        for (let k=0; k < settingVal.length ; k++) {
          if (settingVal[k].name === this.optionsList[i]) {
            this[this.optionsList[i]][settingVal[k].option] = settingVal[k].value
          }
        }
      }
    }
  },
  methods: {
    onConfirm () {
      //if (this.checkboxRow == 'jobRequestOption') this.JobRequestOption.email = true
      //if (this.checkboxRow == 'JobWasAssignedToDriver') this.JobWasAssignedToDriver.email = true
      if (this.checkboxRow == 'preferences') this.preferences.email = true
      this.showDialog = true
    },
    setSettingArray (optionList) {
      let data = [];
      for ( let i=0; i < optionList.length; i++ ) {
        let checkboxList = Object.values(this[optionList[i]]),
            checkboxKeys = Object.keys(this[optionList[i]]),
            numberOptions = checkboxList.length;

          for ( let k=0; k < checkboxList.length; k++ ) {
            data.push({
              name: optionList[i],
              option: checkboxKeys[k],
              value: checkboxList[k]
            })
          }
      }
      return data
    }
  },
  updated () {
    /* if ( !this.JobRequestOption.email && !this.JobRequestOption.sms ) {
      this.checkboxRow = 'jobRequestOption'
      this.showDialog = true
    } else if ( !this.JobWasAssignedToDriver.email && !this.JobWasAssignedToDriver.sms ) {
      this.checkboxRow = 'JobWasAssignedToDriver'
      this.showDialog = true
    } */
    if ( !this.preferences.email && !this.preferences.sms && !this.preferences.robocall ) {
      this.checkboxRow = 'preferences'
      this.showDialog = true
    } else {
      this.showDialog = false
    }

    this.$emit('show:settings', { data:this.setSettingArray(this.optionsList), key: 'newJobs' })
  }
}
</script>
import { gmapApi } from "vue2-google-maps";
import humps from 'humps';

const CITY = 'locality';
const STATE = 'administrative_area_level_1';
const COUNTY = 'administrative_area_level_2';
const COUNTRY = 'country';
const NEIGHBORHOOD = 'neighborhood';
const POSTAL_CODE = 'postal_code';

export default {
  geocoder: null,
  autocomplete: null,

  computed: {
    google: gmapApi
  },

  methods: {
    getGoogleApiRefs() {
      this.geocoder = new this.google.maps.Geocoder;
      this.autocomplete = new this.google.maps.places.AutocompleteService();
    },

    /**
     * Init the refs of google apis.
     */
    initGeocoder() {
      return new Promise((resolve, reject) => {
        if (this.google) {
          this.getGoogleApiRefs();

          resolve();
        } else {
          setTimeout(() => {
            this.getGoogleApiRefs();

            resolve();
          }, 1000);
        }
      });
    },

    /**
     * Get predictions of places from the google api.
     *
     * @param {*} request
     */
    getPredictions(request) {
      return new Promise(
        (resolve, reject) => {
          this.autocomplete.getPlacePredictions(request, function (results, status) {
            if (status !== "OK") {
              resolve([]);
            } else {
              resolve(results);
            }
          })
        }
      )
    },

    getAddressComponent(geocoderResult, type) {
      const addressComponents = geocoderResult.addressComponents;

      if (!addressComponents || !addressComponents.length) return ;

      return addressComponents.find(
        addressComponent => addressComponent.types?.includes(type)
      );
    },

    mapGeocodedAddressToLocation(geocodedResult) {
      if (!geocodedResult) return ;

      return {
        latitude: geocodedResult.geometry.location.lat(),
        longitude: geocodedResult.geometry.location.lng(),
        placeId: geocodedResult.placeId,
        address: geocodedResult.formattedAddress,
        city: this.getAddressComponent(geocodedResult, CITY)?.longName,
        state: this.getAddressComponent(geocodedResult, STATE)?.shortName,
        county: this.getAddressComponent(geocodedResult, COUNTY)?.shortName,
        country: this.getAddressComponent(geocodedResult, COUNTRY)?.longName,
        neighborhood: this.getAddressComponent(geocodedResult, NEIGHBORHOOD)?.longName,
        postalCode: this.getAddressComponent(geocodedResult, POSTAL_CODE)?.longName
      };
    },

    /**
     * Geocode coordinates to human-readable address or vice versa.
     *
     * @param {*} request
     */
    geocode(request) {
      return new Promise(
        (resolve, reject) => {
          this.geocoder.geocode(request, (results, status) => {
            if (status !== "OK") {
              reject(status);
            } else {
              const _results = humps.camelizeKeys(results);
              const firstResult = this.mapGeocodedAddressToLocation(_results[0]);

              if (!firstResult) reject(status);

              resolve(firstResult);
            }
          })
        }
      )
    }
  }
}

<template>
  <md-card class="card card-notification">
    <p class="title-card">Automated calls Schedule</p>
    <p class="faded-1">Setup when you would like to receive robocalls.</p>
    <div>
      <!-- Robocall Settings: Time Windows -->
      <div class="robocall-events">
        <p class="mb-0">
          <md-checkbox v-model="notificationsWeekdays.robocall" class="mb-0"><p>Weekdays</p></md-checkbox>
          <div class="faded-1">You will receive calls between 6:00 AM and 6:00 PM, Monday through Friday</div>
        </p>
        <p class="mb-0">
          <md-checkbox v-model="notificationsWeeknights.robocall" class="mb-0"><p>Weeknights</p></md-checkbox>
          <div class="faded-1">You will receive calls between 6:00 PM and 6:00 AM, Monday night through Friday morning</div>
        </p>
        <p class="mb-0">
          <md-checkbox v-model="notificationsWeekends.robocall" class="mb-0"><p>Weekends</p></md-checkbox>
          <div class="faded-1">You will receive calls between 6:00 PM Friday and 6:00 AM Monday</div>
        </p>
      </div>
    </div>
  </md-card>
</template>

<script>
export default {
  name: 'RobocallSettings',
  props: ['notifications'],

  data: () => ({
    optionsList: ['notificationsWeekdays', 'notificationsWeeknights', 'notificationsWeekends'],
    notificationsWeekdays: {
      robocall: false
    },
    notificationsWeeknights: {
      robocall: false
    },
    notificationsWeekends: {
      robocall: false
    },
  }),
  watch: {
    notifications: function(settingVal, oldVal) {
      for (let i=0; i < this.optionsList.length; i++) {
        for (let k=0; k < settingVal.length ; k++) {
          if (settingVal[k].name === this.optionsList[i]) {
            this[this.optionsList[i]][settingVal[k].option] = settingVal[k].value
          }
        }
      }
    }
  },
  methods: {
    setSettingArray (optionList) {
      let data = [];
      for ( let i=0; i < optionList.length; i++ ) {
        let checkboxList = Object.values(this[optionList[i]])
        let checkboxKeys = Object.keys(this[optionList[i]])
        let numberOptions = checkboxList.length;

          for ( let k=0; k < checkboxList.length; k++ ) {
            data.push({
              name: optionList[i],
              option: checkboxKeys[k],
              value: checkboxList[k]
            })
          }
      }
      return data
    }
  },

  updated () {
    this.$emit('show:settings', { data: this.setSettingArray(this.optionsList), key: 'robocalls' })
  }
}
</script>
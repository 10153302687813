<template>
  <div class="assignment-info">
    <!-- Old Details Page -->
    <div class="row" v-if="(features && features.new_tower_details === false)">
      <div class="col-md-6 pb-3">
        <h5>Incident Information</h5>
        <div class="location-details">
          <h6>{{ request.job.address }}</h6>
          <p class="faded-1 mb-0 pb-0">Zone: {{ zoneFormatted }}</p>
          <p class="faded-1 pb-3">{{ request.job.intersection }}</p>
        </div>
        <div class="job-details" v-if="request.job.details">
          <h6>{{ remarkTitle }}</h6>
          <p class="faded-1 pb-3">{{ request.job.details }}</p>
        </div>
        <div class="incident-details">
          <h6 v-if="request.job.requesting_org">{{ request.job.requesting_org }}</h6>
          <h6 v-else>Incident</h6>
          <p class="faded-1" v-if="request.job.incident_number">
            Incident No. {{ request.job.incident_number }} <br>
            <span v-if="request.job.officer">Requested by {{ request.job.officer }}</span>
          </p>
          <p class="faded-1" v-if="request.job.incident_type">
            {{ request.job.incident_type }}
          </p>
        </div>
      </div>

      <div class="col-md-6">
        <div class="vehicle-details">
          <h5 class="d-inline">Vehicle Information</h5>
          <div v-for="(assignment, index) in assignments" :key="assignment.id">
            <div class="pt-3">
              <h6>Vehicle {{ index+1 }} of {{ assignments.length }}</h6>
            </div>
            <VehicleDetail class="mt-1" :vehicle="assignment.incident_vehicle" :key="assignment.incident_vehicle.plate"/>
            <div class="assignment-button">
              <md-button v-if="showCompleteButton(assignment) && !isMotoristJob"
                class="md-flat md-primary"
                @click="completeModalShow(assignment)"
                :disabled="isDisabled(assignment)">
                <md-icon class="btn-icon">done</md-icon>
                Mark as Complete
              </md-button>
              <md-button v-else-if="(assignment.status === 'completed' || isComplete(assignment)) && !isMotoristJob"
                class="md-flat md-primary"
                :disabled="true">
                Assignment Complete
              </md-button>
            </div>
          </div>
        </div>
      </div>

      <md-dialog :md-active.sync="complete_confirm_modal">
        <md-dialog-title>
          <h5>Mark as Complete</h5>
        </md-dialog-title>

        <md-dialog-content>
          <p>{{ modalMessage(selected_assignment) }}</p>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button class="btn btn-secondary" @click="completeAssignment(selected_assignment)">
            Continue
          </md-button>
          <md-button class="btn btn-secondary" @click="completeModalCancel">
            Cancel
          </md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
    <!-- End Old Details Page -->

    <!-- New Details Page -->
    <div class="row" v-if="(features && features.new_tower_details === true)">
      <div class="col-md-12 pb-3">
        <h5>Incident details</h5>
      </div>
    </div>

    <div class="row" v-if="(features && features.new_tower_details === true)">
      <div class="col-md-6 pb-3">
        <div class="location-details">
          <h6 class="assignment-info__title">Location</h6>
          <p class="faded-1">
            {{ request.job.address }}
          </p>
          <p class="faded-1">
            {{ request.job.intersection }}
          </p>
        </div>

        <div class="incident-details">
          <h6 class="assignment-info__title">
            {{ !isMotoristJob ? 'Department' : 'Incident Type' }}
          </h6>
          <p class="faded-1" v-if="assignments.length > 0 && assignments[0].requesting_department">
            {{ assignments[0].requesting_department.name }}
          </p>
          <p class="faded-1" v-if="request.job.officer">
            Requested by {{ request.job.officer }}
          </p>
          <p class="faded-1" v-if="request.job.incident_number">
            Incident No. {{ request.job.incident_number }}
          </p>
          <p class="faded-1" v-if="request.job.incident_type">
            {{ request.job.incident_type }}
          </p>
        </div>
      </div>

      <!-- Additional Remarks -->
      <div class="col-md-6 pb-3">
        <div class="job-details" v-if="isMotoristJob && showMotoristInfo">
          <h6 class="assignment-info__title">
            Motorist Info
          </h6>
          <p class="faded-1 mb-0">
            {{ this.request.motorist.first_name }} {{ this.request.motorist.last_name }}
          </p>
          <p class="faded-1">
            {{ formattedMotoristPhone }}
          </p>
        </div>

        <div class="job-details" v-if="request.job.details">
          <h6 class="assignment-info__title">
            {{ remarkTitle }}
          </h6>
          <p class="faded-1">
            {{ request.job.details }}
          </p>
        </div>

        <!-- 
          Requested service
          ---------------------------------------------------
          Only show that info if it's a motorist job. 
        -->
        <div class="job-details" v-if="isMotoristJob">
          <h6 class="assignment-info__title">
            Requested service
          </h6>
          <p class="faded-1 mb-0" v-if="request.job.incident_type">
            {{ request.job.incident_type }} 
            <span v-if="request.winning_quote">
              {{ formatPrice(request.winning_quote.price) }}
            </span>
          </p>
          <p class="faded-1" v-if="destination && request.job.incident_type === 'Basic tow'">
            {{ destination }}
          </p>
        </div>
      </div>

      <md-dialog :md-active.sync="complete_confirm_modal">
        <md-dialog-title>
          <h5>Mark as Complete</h5>
        </md-dialog-title>

        <md-dialog-content>
          <p>{{ modalMessage(selected_assignment) }}</p>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button class="btn btn-secondary" @click="completeAssignment(selected_assignment)">
            Continue
          </md-button>
          <md-button class="btn btn-secondary" @click="completeModalCancel">
            Cancel
          </md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
    <!-- End New Details Page -->
  </div>
</template>

<script>

// API
import { mapState, mapActions } from "vuex"

// Components
import VehicleDetail from "../vehicles/vehicle-detail"

export default {
  props: ['request', 'assignments', 'destination'],

  components: {
    VehicleDetail
  },

  data: () => {
    return {
      complete_confirm_modal: false,
      selected_assignment: {},
      completed_assignments: [],
      loading_assignments: []
    }
  },

  computed: {
    ...mapState('features', {
      features: state => state.features
    }),

    ...mapState('organizations', {
      current: state => state.current
    }),

    isMotoristJob () {
      return (this.request.motorist_id != null )
    },

    /**
     * Only show motorist info if this provider won the job and an operator is assigned
     */
    showMotoristInfo () {
      // Provider won the job
      const quoteSelected = this.request.winning_quote && this.request.winning_quote.organization_id == this.current.id

      // Provider assigned operator
      const assignment = this.request.job.assignments[0]
      const operatorAssigned = assignment && assignment.driver

      return (quoteSelected && operatorAssigned)
    },
    
    remarkTitle () {
      return this.isMotoristJob ? 'Motorist Remarks' : 'Police Remark';
    },

    zoneFormatted () {
      if (this.request.job.zone) {
        return this.request.job.zone.name + " - " + this.request.job.zone.code
      }
    },

    formattedMotoristPhone () {
      const motorist_phone = this.request.motorist.phone_number

      const code = motorist_phone.substring(0, 3)
      const first = motorist_phone.substring(3, 6)
      const last = motorist_phone.substring(6)

      return `(${code}) ${first}–${last}`
    },
  },

  methods: {
    ...mapActions('quote_requests', [
      'updateReceivedQuoteRequest'
    ]),

    completeModalShow (assignment) {
      this.selected_assignment = assignment
      this.complete_confirm_modal = true
    },

    completeModalCancel () {
      this.selected_assignment = {}
      this.complete_confirm_modal = false
    },

    completeAssignment (assignment) {
      this.complete_confirm_modal = false
      this.loading_assignments.push(assignment.id)
      this.$http.put(`/job_assignments/${assignment.id}/complete`)
        .then(response => {
          if (response.status == 200) {
            this.updateReceivedQuoteRequest({ id: this.request.id })
            const i = this.loading_assignments.indexOf(assignment.id)
            this.loading_assignments.splice(i, 1)
            this.completed_assignments.push(assignment.id)
            this.$emit('complete')
          } else {}
        })
    },

    formatPrice(value) {
      if (!value) return null;

      return `$${value / 100}`;
    },

    isComplete (assignment) {
      const index = this.completed_assignments.indexOf(assignment.id)
      return (index != -1)
    },

    isDisabled (assignment) {
      const index = this.loading_assignments.indexOf(assignment.id)
      return (index != -1)
    },

    modalMessage (assignment) {
      const driver = assignment.driver
      const requesting_org = assignment.requesting_org

      switch (assignment.status) {
        case 'notified':
          return `${driver.first} ${driver.last} has not acknowledged this assignment in the Curbside SOS app. Using the mobile app allows ${requesting_org.name} to monitor the incident and determine when roads are cleared. Continuing will inform dispatch the job is completed, and the job details will no longer be available to the operator. Are you sure you want to mark the job as complete?`
        case 'started':
          return `${driver.first} ${driver.last} has not arrived at the incident location. Continuing will inform dispatch the job is completed, and the job details will no longer be available to the operator. Are you sure you want to mark the job as complete?`
        case 'arrived':
          return `${driver.first} ${driver.last} has not left the incident location. Continuing will inform dispatch the job is completed, and the job details will no longer be available to the operator. Are you sure you want to mark the job as complete?`
        case 'departed':
        default:
          return `Are you sure you want to mark this job as complete?`
      }
    },

    showCompleteButton (assignment) {
      if (this.isComplete(assignment)) {
        return false
      } else {
        switch (assignment.status) {
          case 'notified':
          case 'started':
          case 'arrived':
          case 'departed':
            return true
          default:
            return false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .assignment-info__title {
    margin-bottom: 4px;
  }
</style>

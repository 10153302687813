import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	selected_quote_request: ''
}

export default {
  namespaced: true,
  state,
  actions,
  getters, 
  mutations
};

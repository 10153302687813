<template>
  <div class="map-icon">
    <img v-bind:src="iconPath" />
  </div>
</template>

<script>
import svgs from '../../../../images/map/'

export default {
  props: ['type'],

  computed: {
    iconPath () {
      switch (this.type) {
        case 'driver':
          return svgs.DefaultDriver
          break
        case 'new':
        case 'open':
        case 'pending':
        case 'direct':
          return svgs.PendingPin
          break
        case 'in_progress':
          return svgs.InProgressPin
          break
        case 'unassigned':
        case 'unresolved':
        case 'outbid':
          return svgs.UnassignedPin
          break
        case 'completed':
        default:
          return svgs.DefaultPin
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="avatar">
    <img v-bind:src="avatarPath" />
  </div>
</template>

<script>
import avatars from '../../../../images/avatar/'

export default {
  props: ['type'],

  computed: {
    avatarPath () {
      switch (this.type) {
        case 'driver':
          return avatars.DefaultDriver
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>

import * as API from '../../../api';

export default {
  async getCurrentOrganization ({ commit }) {
    let response = await API.Organizations.current()
    commit('setCurrentOrganization', response.body)
  },
  async getDispatchers ({commit}, payload) {
    let response = await API.Organizations.dispatchers()
    commit('setDispatchers', response.body)
  },
  async getPoliceDepartments ({commit}, payload) {
    let response = await API.Organizations.police_departments()
    commit('setPoliceDepartments', response.body)
  },
  async getTowers ({commit}, payload) {
    let response = await API.Organizations.towers()
    commit('setTowers', response.body)
  },
  async getOperationHours({commit}, organizationId) {
    const response = await API.Organizations.operation_hours({ id: organizationId })
    commit('setOperationHours', response.body.data)
  }
}

<template>
  <div class="pt-5 pt-md-0">

    <md-dialog-title class="row mb-0">
      <div class="col-2 nowrap">
        <h5>Call Wrecker</h5>
      </div>
      <div class="col-10 text-right">
        <h6>Incident Location</h6>
        <p>{{ request.job.address }}</p>
      </div>
    </md-dialog-title>


		<md-dialog-content class="pb-0 md-dialog-content md-theme-default">
      <div class="choose-wrecker" v-if="view === 'choose-wrecker'">
        <div class="row" style="min-height: 300px;">
          <div class="col-sm-6 px-0 overflow-auto" style="max-height: 300px;">
              <md-list class="py-0">
                <md-list-item v-for="wrecker in sortedWreckers" :key="wrecker.id"
                              class="border-bottom">
                  <div class="md-list-item-text py-3">
                    <span>{{ wrecker.name }}</span>
                    <span>{{ wrecker.distance }} miles</span>
                    <span>{{ wrecker.phone }}</span>
                  </div>
                  <div class="md-list-action">
                    <md-radio v-model="selected_tower" :value="wrecker"></md-radio>
                  </div>
                </md-list-item>
              </md-list>
          </div>
          <div class="col-sm-6 px-0">
            <GmapMap
              :center="point"
              :zoom="14"
              :options="options"
              class="border-left w-100 h-100">
              <custom-marker :marker="point" alignment="top">
                <MapIcon :type="'unassigned'" />
              </custom-marker>
            </GmapMap>
          </div>
        </div>
      </div>
      <div class="choose-vehicles" v-if="view === 'choose-vehicle'">
        <div class="row" style="min-height: 300px;">
          <div class="col-md-12 px-0">
            <md-list class="py-0">
              <md-list-item v-for="assignment in unassigned" :key="assignment.id"
                            class="border-bottom py-3">
                <div class="md-list-item-text">
                  <span>{{ assignment.incident_vehicle.make }} {{ assignment.incident_vehicle.model }}</span>
                </div>
                <div class="md-list-action">
                  <md-checkbox v-model="selected_assignments" :value="assignment.id"></md-checkbox>
                </div>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
      <div class="row" v-if="view === 'loading'">
        <div class="col-md-12 py-5 text-center">
          <md-progress-spinner class="loader" :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
          <p class="mt-4">Assigning to wrecker...</p>
        </div>
      </div>
    </md-dialog-content>


    <div class="footerBar px-3 py-2">
      <md-button v-if="view === 'choose-wrecker'" class="btn btn-primary" :disabled="selected_tower === null" @click="chooseWrecker">
        Choose Vehicles
      </md-button>
      <md-button v-if="view === 'choose-vehicle' || view === 'loading'" :disabled="selected_assignments.length == 0 || disable_actions" class="btn btn-primary" @click="sendAssignment">
        Send Assignment
      </md-button>
      <md-button class="btn btn-secondary" :disabled="disable_actions" @click="close">
        Cancel
      </md-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Organizations } from '../../api'

// Components
import MapIcon from '../maps/map-icon'
export default {
  props: ['request'],

  components: {
    MapIcon
  },

  data: () => {
    return {
      selected_tower: null,
      selected_assignments: [],
      view: 'choose-wrecker',
      disable_actions: false,
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }
    }
  },

  computed: {
    ...mapState('organizations', {
      wreckers: state => state.towers
    }),
    sortedWreckers () {
      const filteredWreckers = this.wreckers.filter(wrecker => {
        return wrecker.contact_phone !== null
      })
      const mappedWreckers = filteredWreckers.map(wrecker =>
        ({
          id: wrecker.id,
          name: wrecker.name,
          distance: this.distance(wrecker),
          phone: this.phone(wrecker.contact_phone)
        })
      )
      return mappedWreckers.sort((a, b) => (a.distance - b.distance))
    },
    point (point) {
      return {
        lat: this.request.job.latitude,
        lng: this.request.job.longitude
      }
    },
    unassigned () {
      const assignments = this.request.job.assignments
      return assignments.filter(x => x.status === 'unassigned')
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },
    chooseWrecker () {
      this.view = 'choose-vehicle'
    },
    distance (wrecker) {
      return this.distanceCrowFlies(wrecker.latitude,
                                     wrecker.longitude,
                                     this.request.job.latitude,
                                     this.request.job.longitude)
    },
    async sendAssignment () {
      this.view = 'loading'
      this.disable_actions = true
      let response = this.submitForm()
      setTimeout(() => {
        this.$emit('close')
      },2500)
    },
    async submitForm () {
      let payload = { quote_request_id: this.request.id, job_assignment_ids: this.selected_assignments }
      let response = await Organizations.manual_assignment({ id: this.selected_tower.id }, payload)
      return response
    },
    phone (number) {
      return this.formattedPhone(number)
    }
  }
}
</script>

<style scoped lang='scss'>
</style>

<template>
  <span class="timeleft caption">
    {{ timeleft }}
  </span>
</template>

<script>
export default {
  props: ["date"],

  data () {
    return {
      end_time: new Date(this.date),
      seconds_left: 0,
      timeleft: "",
      interval: 250,
      positive: true,
      expired: false,
      intervalObject: null
    }
  },

  watch: {
    seconds_left: function (a, b) {
      if (a !== b && a > 0) {
        setInterval(this.throttledGetAge, this.interval)
      }
    },
  },

  methods: {
    getAge () {
      if (this.seconds_left > 0) {
        this.seconds_left = this.secondsLeft()
        this.timeleft = this.formattedTimeLeft()
      } else if (!this.expired) {
        this.seconds_left = 0
        this.positive = false
        this.expired = true
        this.$emit('update:expired', this.expired)
      }
    },
    formattedTimeLeft() {
      const ss = this.formatSeconds()
      const m = this.formatMinutes()
      return m + ':' + ss
    },
    secondsLeft () {
      return Math.floor((this.end_time - new Date) / 1000)
    },
    formatSeconds () {
      const secs = this.seconds_left % 60
      const str = (secs < 10) ? '0' + secs.toString() : secs.toString()
      return str
    },
    formatMinutes () {
      const mins = Math.floor(this.seconds_left / 60)
      return mins.toString()
    }
  },

  created () {
    this.throttledGetAge = this.$throttle(this.getAge, 250)
    this.seconds_left = this.secondsLeft()
  }

}
</script>

<style scoped lang="">
</style>

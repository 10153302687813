<template>
  <md-step id="fifth" md-label="Bank Account" :md-editable="false" :md-done.sync="done">
    <p>Please provide your bank information to receive payment.</p>

    <!-- Form -->
    <form class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <!-- Row: Account Owner -->
        <div class="row">
          <!-- Account owner -->
          <div class="col-md-6">
            <md-field :class="getValidationClass('bank_account', 'account_name')">
              <label class="form__label">Account owner</label>
              <md-input v-model="bank_account.account_name" class="form-control"></md-input>
              <span class="md-error">This field is required</span>
              <span v-if="$v.bank_account.account_name.$invalid" class="invalid display-none"></span>
            </md-field>
          </div>
        </div>

        <div class="row">
          <!-- Routing Number -->
          <div class="col-md-6">
            <md-field :class="getValidationClass('bank_account', 'routing_number')">
              <label class="form__label">Routing number</label>
              <md-input v-model="bank_account.routing_number" class="form-control"></md-input>
              <span class="md-error">This field is required</span>
              <span v-if="$v.bank_account.routing_number.$invalid" class="invalid display-none"></span>
            </md-field>
          </div>
          <!-- Account Number -->
          <div class="col-md-6">
            <md-field :class="getValidationClass('bank_account', 'account_number')">
              <label class="form__label">Account number</label>
              <md-input v-model="bank_account.account_number" class="form-control"></md-input>
              <span class="md-error">This field is required</span>
              <span v-if="$v.bank_account.account_number.$invalid" class="invalid display-none"></span>
            </md-field>
          </div>
        </div>
      </div>
    </form>

    <!-- Submit Buttons -->
    <div>
      <md-button
        :disabled="!isCompleteForm"
        class="md-raised md-primary"
        @click="next"
      >
        Save & continue
      </md-button>
      <md-button
        v-if="enableFinishLater"
        :disabled="!isCompleteForm"
        class="md-raised md-primary"
        @click="finishLater"
      >
        Save & finish later
      </md-button>
    </div>

    <!-- Loading Spinner, Snackbar Alerts -->
    <ProgressDialog v-if="loading" />
    <md-snackbar
      :class="[snackbarAlert.error ? 'snackbar-notify--error' : 'snackbar-notify--success', 'snackbar-notify snackbar-notify-driver snackbar-notify--success']"
      :md-position="snackbarAlert.position"
      :md-duration="snackbarAlert.isInfinity ? Infinity : snackbarAlert.duration"
      :md-active.sync="snackbarAlert.alert"
      md-persistent
    >
      <span class="snackbar-notify__text">
        <i class="material-icons">check</i>
        {{ snackbarAlert.alertMsg }}
      </span>
    </md-snackbar>
  </md-step>
</template>

<script>
import ProgressDialog from './progress-dialog'
import Vue from 'vue'
import Stripe from '@/shared/mixins/stripe';
import { required, minLength, maxLength } from "vuelidate/lib/validators"

import { caretPhone, formatPhoneNumber } from '../../mixins/formatNumber'

import humps from 'humps'

export default {
  name: 'BankAccount',
  mixins: [Stripe],
  props: ['company_data', 'stored_bank_account', 'onboardingStep', 'enableFinishLater'],
  components: {},
  data () {
    return {
      bank_account: {
        account_name: this.stored_bank_account ? this.stored_bank_account.account_holder_name : '',
        routing_number: this.stored_bank_account ? this.stored_bank_account.routing_number : '',
        account_number: '',
      },
      data_save: null,
      validateNumber: false,
      openBox: false,
      done: false,
      loading: false,
      isCompleteForm: true,
      snackbarAlert: {
        alert: false,
        error: false,
        alertMsg: '',
        position: 'left',
        isInfinity: false,
        duration: 4000,
        update: false
      }
    }
  },
  components: {
    ProgressDialog
  },
  validations: {
    bank_account: {
      account_name: {
        required
      },
      routing_number: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      account_number: {
        required,
        minLength: minLength(4),
        maxLength: minLength(17),
      }
    }
  },
  computed: {
    submitActive () {
      return this.bank_account.account_name.length > 0 && this.bank_account.routing_number.length == 9 && this.bank_account.account_number.length > 3
    }
  },
  methods: {
    /**
     * Validate, submit the form and redirect to the next step.
     */
    next() {
      // if (!this.isValidForm()) return ;

      this.isCompleteForm = false
      this.submit().then((success) => {
        if (success) {
          this.$emit('next', 'sixth');
        }
      });
    },

    /**
     * Validate, submit the form and let finish it more later.
     */
    finishLater() {
      if (!this.isValidForm()) return ;

      this.submit().then((success) => {
        if (success) {
          this.$emit('next', 'thank-you');
        }
      });
    },

    /**
     * Check if the form is correctly filled up.
     */
    isValidForm() {
      this.$v.$touch();
      let invalidFields = [...document.querySelectorAll('.invalid')]
     
      if (invalidFields.length) {
        let element = invalidFields[0].parentElement,
            input = document.querySelector('input');

        input.scrollIntoView();
      }

      return !this.$v.$invalid;
    },
    
    /**
     * Load stripe and get the client secret of the payment intent.
     */
    initialize() {
      this.loadstripe()
        .then((stripe) => {
          // Loading Stripe
        });
    },
    getValidationClass(form, fieldName) {
      const field = this.$v[form][fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    submit () {
      // Initialize
      let bankAccountParams = {
        country: 'US',
        currency: 'usd',
        account_holder_name: this.bank_account.account_name,
        routing_number: this.bank_account.routing_number,
        account_number: this.bank_account.account_number,
      }
      this.loading = true; // trigger loading spinner

      // 1. Stripe call using those parameters to create a Stripe bank account token
      return this.stripe.createToken('bank_account', bankAccountParams).then(response => {
        if(response.token) {
          // 2a. If that succeeds, pass bank account token to new Curbside API backend endpoint
          let params = {
            bank_account_token: response.token.id
          }

          return this.$http.post(`/organizations/${this.company_data.id}/bank_account`, params)
          .then(response => {
            if(response.status === 200) {
              // Success
              this.done = true;
              this.alertTitle = "Success!";
              this.alertMsg = "Bank Information added successfully";
              this.alert = true;
              this.loading = false;
              this.error = false;
              this.isCompleteForm = false;

              return true;
            } else {
              // Error
              this.alertTitle = "Error";
              this.alertMsg = "Something went wrong, please try again";
              this.alert = true;
              this.loading = false;
              this.error = true;
              this.isCompleteForm = true;

              return false;
            }
          })
          .catch(error => {
            // Error
            console.log('error: ', error)
            this.alertTitle = "Error";
            this.alertMsg = "Something went wrong, please try again";
            this.alert = true;
            this.loading = false;
            this.error = true;
            this.isCompleteForm = true;

            return false;
          })
        } else {
          this.loading = false;
          // 2a. If that fails, let user know why
          let error = response.error
          let error_messages = {
            'parameter_invalid_empty': 'One or more required values were not provided.',
            'routing_number_invalid': 'The bank routing number provided is invalid.',
            'account_number_invalid': 'The bank account number provided is invalid.'
          }
          let error_message = 'Something went Wrong' // default error message
          if (error && error_messages[error.code]) {
            error_message = error_messages[error.code]
          }
          // end gathering error messages
          // Error
          this.alertTitle = "Error";
          this.alertMsg = "Something went wrong, please try again";
          this.alert = true;
          this.loading = false;
          this.error = true;
          this.isCompleteForm = true;

          return false;
        }
      })
    }
  },
  created () {
    // Update whether this registration step has already been completed
    if(this.onboardingStep)
     {
      if(this.onboardingStep.step === 'sixth') {
        this.done = true;
      }
    }
    // Load Stripe
    this.loadstripe()
        .then((stripe) => {
          // console.log('stripe: ', stripe);
        });
  }
}
</script>

<style lang="scss" scoped>
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
</style>

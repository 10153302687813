<template>
  <md-card class="card account-owner card-wrecker-detail card-box-profile card-box offset-card section js-content-box" id="bussiness-info">
    <GmapMap
      :center="{lat:10, lng:10}"
      :zoom="14"
      style="display: none;">
    </GmapMap>
    <h2 class="title-card">Business Info</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <transition name="slide">
      <div class="card-box__content" v-if="openBox">
        <div class="card-box__content-form js-box-content-form">
          <div class="row">
            <div v-if="!edit_info" class="form-group col-md-6">
              <div class="field">
                <label class="form__label">Company name</label>
                <div v-if="company.company_name" class="form__field">{{company.company_name}}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass(this,'company','company_name')">
                  <label class="form__label">Company name</label>
                  <md-input v-model="company.company_name" class="form-control" v-on:keyup="detectChange()"></md-input>
                  <span class="md-error" v-if="!$v.company.company_name.required">Company name is required</span>
                </md-field>
              </div>
            </div>

            <div v-if="!edit_info" class="form-group col-md-6">
            </div>
            <div v-else class="col-md-6 col-sm-12">
            </div>
          </div>
          <div class="row">
            <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
              <div class="field margin-bottom-no">
                <label class="form__label">Company phone number</label>
                <div v-if="company.phone_format" class="form__field">{{ company.phone_format }}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass(this,'company','phone_number')">
                  <label class="form__label">Company phone number</label>
                  <md-input v-model="company.phone_format" class="form-control" v-on:keyup="caretPhone(); detectChange()"></md-input>
                  <span class="md-error" v-if="!$v.company.phone_number.required">
                    Company phone number is required
                  </span>
                  <span class="md-error" v-if="!$v.company.phone_number.minLength">
                    The Mobile Number must be at least 10 characters long
                  </span>
                </md-field>
              </div>
            </div>

            <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
              <div class="field margin-bottom-no">
                <label class="form__label">Company address</label>
                <div v-if="company.company_address" class="form__field">{{company.company_address.includes('undefined') ? 'None' : company.company_address}}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <div :class="getValidationClass(this, 'company', 'company_address')">
                  <LocationAutocomplete
                    v-model="company.company_address"
                    label="Enter a location..."
                    helperText=""
                    :currentLocation="false"
                    @location="getSelection"
                  />
                  <span class="md-error-autocomplete" v-if="!$v.company.company_address.required">
                    Company address is required
                  </span>
                </div>
              </div>
            </div>
          </div>
          <transition name="slide">
            <div v-if="edit_info" class="row">
              <div class="card-box__content-actions">
                <md-button :class="[deactivedButton ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
                <md-button class="md-accent card-box__button-cancel" @click="cancel(!up_to_date ? 'database' : 'browserData')">Cancel</md-button>
                <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </md-card>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import LocationAutocomplete from "@motorist/components/global/location-autocomplete";
import { required, minLength } from "vuelidate/lib/validators";
import { caretPhone } from '../../../mixins/formatNumber'
import { resetData } from '../../../mixins/resetData'
import { getValidationClass } from '../../../mixins/getValidationClass'
import humps from 'humps'

const googleTimezoneApi = "https://maps.googleapis.com/maps/api/timezone/json";

export default {
  name: 'BussinessInfo',
  props: ['company_data'],
  components: {
    LocationAutocomplete
  },
  data: () => ({
    company: {
      company_name: '',
      phone_number: '',
      company_address: '',
      selection: {},
      address: '',
      state: '',
      zip: '',
      city: '',
      latitude: '',
      longitude: '',
      location_attributes: '',
      phone_format: '',
      location: '',
      spTimeZone: ""
    },
    data_save: null,
    edit_info: false,
    openBox: false,
    update: false,
    deactivedButton: true,
    up_to_date: false
  }),
  validations: {
    company: {
      company_name: {
        required
      },
      phone_number: {
        required,
        minLength: minLength(10)
      },
      company_address: {
        required
      }
    }
  },
  methods: {
    getValidationClass,
    editInfo () {
      if ( !this.up_to_date ) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    caretPhone () {
      let phone = caretPhone(this.company.phone_format,this.company.phone_number)
      this.company.phone_format = phone.phoneFormat
      this.company.phone_number = phone.phoneNumber
    },
    getSelection (location) {
      if (!location) return;

      axios.get(
        `${googleTimezoneApi}?location=${location.latitude}%2C${location.longitude}&timestamp=1331161200&key=${CSOS_RT_CONF.GOOGLE_MAPS_API_KEY}`
      ).then(response => {
        this.company.spTimeZone = response.data?.timeZoneId
      }).catch(() => {
        console.log('An internal error has ocurred!')
      });

      this.deactivedButton = false
      this.company.location = location,
      this.company.company_address = location.address.split(',')[0],
      this.company.city = location.city ? location.city : location.neighborhood,
      this.company.state = location.state,
      this.company.zip = location.postalCode,
      this.company.latitude = location.latitude,
      this.company.longitude = location.longitude
      this.company.location_attributes = location
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit () {
      this.update = true;
      this.deactivedButton = true;
      let company = {
        name: this.company.company_name,
        contact_phone: this.company.phone_number,
        address: this.company.company_address,
        state: this.company.location_attributes.state,
        city: this.company.location_attributes.city,
        zip: this.company.location_attributes.postalCode,
        latitude: this.company.location_attributes.latitude,
        longitude: this.company.location_attributes.longitude,
        location_attributes: this.company.location_attributes,
      }
      if (this.company.spTimeZone){
        company.sp_time_zone = this.company.spTimeZone;
      }

      Vue.http.put(`/organizations/${this.company_data.id}`, company ,[{responseType: 'json'}])
        .then(() => {
          this.data_save = humps.camelizeKeys(this.company)
          setTimeout(() => {
            this.update = false;
            this.deactivedButton = false;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
            this.up_to_date = true
            this.edit_info = false
          },1000)
        }, err => {
          setTimeout(() => {
            this.update = false;
            this.deactivedButton = false;
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.error : 'Something went Wrong', error: true})
            this.up_to_date = false
          },1000)
      });
    },
    cancel (data) {
      this.edit_info = !this.edit_info;
      this.deactivedButton = true
      if ( data === 'database' ) {
        this.company = resetData(this.company,this.company_data)
        this.company.location_attributes = this.company_data.location
      } 
      if ( data === 'browserData') {
        this.company = resetData(this.company,humps.decamelizeKeys(this.data_save,{split: /(?=[A-Z0-9])/ }))
      }
    },
    detectChange() {
      this.deactivedButton = false
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    this.company = resetData(this.company,this.company_data)
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  } 
}
</script>

<style lang="scss" scoped>
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }

  .md-error-autocomplete {
    height: 20px;
    position: absolute;
    bottom: -12px;
    font-size: 12px;
    transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: #ff1744;
    opacity: 1;
    transform: translateZ(0);
    display: block !important;
    left: 0;
  }
</style>
<template>
  <div class="assign-driver-form">
    <div class="container-padded">
      <div class="row">
        <div class="col-md-1">
          <BackButton @back="$emit('back')" />
        </div>

        <div class="col-md-10 pt-4">
          <div class="driver-selection">
            <h4>Assign {{ operatorString }}</h4>
            <span class="assign-driver-form__title">
              {{ jobAddressTitle }}
            </span>

            <div class="row pt-3">
              <div class="col-md-6">
                <h6>Available Operators</h6>
                <p>Select {{ unassignedCount }} {{ operatorString }}.</p>
                
                <div v-if="loading">
                  <p class="caption">Locating operators...</p>
                  <md-progress-bar md-mode="indeterminate"></md-progress-bar>
                </div>

                <div v-else class="driver-select-list">
                  <SelectDriver 
                    :drivers="drivers_with_etas"
                    :selected_drivers="selected_drivers" 
                    :selectable_drivers_count="unassignedCount" 
                    :support_multiple_options="true"
                    :disable_inactives="false"
                    @select-driver="selectDriver" 
                  >
                    <div class="note mb-2">
                      <p class="caption">
                        Note: Operators without ETAs could not be located or routed to the incident destination. 
                        Make sure all operators are using the Curbside SOS mobile app and have location services set to Always Allow.
                      </p>

                      <md-button class="btn btn-secondary center" @click="refreshDriverLocations" @disabled="!drivers">
                        Refresh Driver Locations
                      </md-button>
                    </div>
                  </SelectDriver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div v-if="drivers_with_etas && drivers_with_etas.length > 0" class="footerBar position-fixed py-3">
      <fieldset class="row">
        <div class="col-md-10 offset-md-1">
          <md-button class="btn btn-primary" :disabled="disabledSubmitButton" @click="submitDriverAssignments">
            Notify {{ operatorString }}
          </md-button>
          <md-button class="btn btn-secondary" @click="$emit('back')">
            Cancel
          </md-button>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Drivers } from '../../../api';

// Mixins
import availableDrivers from '../../../mixins/available-drivers';
import jobServices from '../../../mixins/jobServices';

// Components
import BackButton from '../../utilities/back-button';
import SelectDriver from '../../drivers/select-driver';

export default {
  props: ['request'],

  components: {
    BackButton,
    SelectDriver
  },

  mixins: [availableDrivers, jobServices],

  computed: {
    ...mapState('drivers', {
      drivers: state => state.all
    }),

    unassignedCount () {
      const assignments = this.request.job.assignments
      return assignments.filter(x => x.status === 'assigned').length
    },

    operatorString () {
      if (this.unassignedCount.length > 1) {
        return "operators"
      } else {
        return "operator"
      }
    },

    disabledSubmitButton () {
      return this.selected_drivers.length < this.unassignedCount;
    },

    /**
     * It is necessary for the jobServices mixin.
     */
    job () {
      return this.request.job;
    }
  },

  data() {
    return {
      drivers_with_etas: null,
      selected_drivers: [],
      loading: true
    }
  },

  methods: {
    /**
     * Sort the available drivers. This method is invoked by the availableDrivers mixin.
     * Firstly We sort the drivers alfabetically and then by the closest ETA.
     */
    sortDrivers (drivers) {
      const sortedDrivers = drivers.sort((nextDriver, prevDriver) => (
        nextDriver.attributes.last.localeCompare(prevDriver.attributes.last)
      )).sort((nextDriver, prevDriver) => {
        const nextEta = nextDriver.attributes.duration_in_seconds != null ? nextDriver.attributes.duration_in_seconds : Infinity;
        const prevEta = prevDriver.attributes.duration_in_seconds != null ? prevDriver.attributes.duration_in_seconds : Infinity;

        return nextEta - prevEta;
      });

      return sortedDrivers;
    },

    refreshDriverLocations () {
      this.drivers_with_etas = null;
      this.selected_drivers = [];
      this.loading = true;

      return this.getAvailableDrivers(this.request.job.assignments[0].id)
        .catch(() => {
          this.drivers_with_etas = [];
        }).finally(() => {
          this.loading = false;
        });
    },

    selectDriver (driverIds) {
      this.selected_drivers = driverIds;
    },

    async submitDriverAssignments () {
      let payload = { driver_ids: this.selected_drivers, quote_request_id: this.request.id }
      let response = await Drivers.assign(payload)
      this.delay(2000).then(
        this.$emit('back')
      )
    }
  },

  created () {
    this.refreshDriverLocations();
  },
}
</script>

<style lang="scss">
  .assign-driver-form {
    &__title {
      color: rgba(11, 57, 81, 0.65);
      font-size: 16px;
    }
  }
</style>

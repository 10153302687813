<template>
  <div class="cancel-quote-request-modal">
    <md-dialog-title class="cancel-quote-request-modal__title">
      Cancel order
    </md-dialog-title>

		<md-dialog-content class="dialog-content">
      <p class="cancel-quote-request-modal__description">
        <span>The order has been sent to all assigned service providers. Those responding will be notified the job is no longer available.</span>
        <span> Service Providers assigned by rotation will be placed back at the top of the list.</span>
      </p>

      <fieldset v-if="activeJobAssignments.length > 1">
        <legend class="cancel-quote-request-modal__fieldset-legend">Choose vehicle to cancel order</legend>
        <md-list>
          <!-- All vehicles option -->
          <md-list-item :key="1" class="cancel-quote-request-modal__vehicle-item">
            <div class="md-list-item-text">
              <span class="cancel-quote-request-modal__all-vehicles-item">
                All vehicles
              </span>
            </div>
            <div class="md-list-action">
              <md-checkbox 
                v-model="allVehiclesState" 
                :indeterminate="areVehiclesPartiallySelected"
              >
              </md-checkbox>
            </div>
          </md-list-item>

          <!-- Vehicle options -->
          <md-list-item 
            v-for="assignment in activeJobAssignments" 
            :key="assignment.incident_vehicle.id" 
            class="cancel-quote-request-modal__vehicle-item py-3"
          >
            <div class="md-list-item-text">
              <VehicleDetail :vehicle="assignment.incident_vehicle"></VehicleDetail>
            </div>
            <div class="md-list-action">
              <md-checkbox 
                v-if="jobAssigmentCanBeCanceled(assignment.status)" 
                v-model="selectedVehicles" 
                :value="assignment.id"
              >
              </md-checkbox>

              <div v-else class="cancel-quote-request-modal__unable-to-cancel-wrapper">
                <span class="cancel-quote-request-modal__unable-to-cancel">
                  Unable to cancel
                </span>
                <span class="cancel-quote-request-modal__wrecker-on-site">
                  Job Completed
                </span>
              </div>
            </div>
          </md-list-item>
        </md-list>
      </fieldset>

      <fieldset class="cancel-quote-request-modal__note-fieldset">
        <legend class="cancel-quote-request-modal__fieldset-legend">
          Add a note
        </legend>
        <small class="cancel-quote-request-modal__description">
          This note will be saved in job history details.
        </small>

        <md-field>
          <label>Add an optional note explaining the reason the job is canceled.</label>
          <md-textarea v-model="note"></md-textarea>
          <span class="md-helper-text">150 character limit</span>
        </md-field>

        <md-checkbox v-if="isThereAtLeastOneAssignmentAssigned(cancelableJobAssignments)" v-model="shareNoteWithWrecker">
          Share note with wrecker
        </md-checkbox>
      </fieldset>
    </md-dialog-content>

    <md-dialog-actions class="dialog-actions-custom">
      <md-button 
        class="md-raised md-primary" 
        :disabled="!isValidForm || isFormSubmitting"
        @click="submitForm"
      >
        Cancel order
      </md-button>
      <md-button class="md-primary" @click="$emit('close')">
        Nevermind
      </md-button>

      <md-progress-spinner
        v-if="isFormSubmitting"
        md-mode="indeterminate"
        :md-diameter="30"
        :md-stroke="3"
      ></md-progress-spinner>
    </md-dialog-actions>
  </div>
</template>

<script>
  import Vue from 'vue';
  import jobServices from '../../mixins/jobServices';

  // Components
  import VehicleDetail from '../vehicles/vehicle-detail';

  export default {
    name: 'CancelQuoteRequestModal',
    components: {
      VehicleDetail
    },
    mixins: [jobServices],

    computed: {
      /**
       * Validate user has selected at least 1 vehicle.
       */
      isValidForm() {
        return this.selectedVehicles.length > 0;
      },

      /**
       * Returns only the job assignments not canceled.
       */
      activeJobAssignments() {
        const ignoredStatuses = ['canceled', 'reassigned'];
        
        return this.request.job.assignments.filter((jobAssigment) => {
          return ignoredStatuses.indexOf(jobAssigment.status) === -1;
        });
      },

      /**
       * Returns only the job assignments that can be canceled.
       */
      cancelableJobAssignments() {
        return this.filterCancelableJobAssignments(this.request.job);
      },

      /**
       * Keeps the all vehicles checkbox state.
       */
      allVehiclesState: {
        get() {
          return this.selectedVehicles.length > 0;
        },
        set(checked) {
          if (this.areVehiclesPartiallySelected) {
            this.selectAllVehicles(true);
          } else {
            this.selectAllVehicles(checked);
          }
        }
      },

      /**
       * Check at least one vehicles has been selected.
       */
      areVehiclesPartiallySelected() {
        return (this.selectedVehicles.length > 0 && this.selectedVehicles.length < this.cancelableJobAssignments.length);
      },

      /**
       * It is necessary for the jobServices mixin.
       */
      job () {
        return this.request.job;
      }
    },

    data() {
      return {
        isFormSubmitting: false,
        selectedVehicles: [],
        note: '',
        shareNoteWithWrecker: true
      }
    },

    /**
     * Select all vehicles if there is only one vehicle.
     */
    created() {
      if (this.cancelableJobAssignments.length === 1) {
        this.selectAllVehicles(true);
      }
    },

    methods: {
      /**
       * Select or unselect all vehicles in one go. We added the assignment id no the vehicle id.
       */
      selectAllVehicles(checked) {
        let assignmentIds = [];

        if (checked) {
          assignmentIds = this.cancelableJobAssignments.map((assignment) => {
            return assignment.id;
          });
        }

        this.selectedVehicles = assignmentIds;
      },

      /**
       * Iterates all selected vehicles and perform a request to cancel its assigment.
       * If all vehicles has been canceled then send a request to cancel the job.
       * 
       * @param {array} jobAssigmentIds - The assigment ids of the selected vehicles.
       * @param {string} note
       * @param {boolean} shareNoteWithWrecker
       */
      cancelJobAssignments() {
        const jobId = this.request.job.id;
        const note = this.note.trim();
        const share = this.shareNoteWithWrecker;

        this.isFormSubmitting = true;

        Vue.http.put(
          `/jobs/${jobId}/cancel_assignments`, 
          {
            note, 
            share,
            assignment_ids: this.selectedVehicles
          }, 
          [{ responseType: 'json' }]
        ).then(response => {
          if (!response.ok) {
            return ;
          }

          this.$emit('finish', `Vehicle(s) canceled successfully.`);
        }, error => {
          this.$emit('finish', error ? error.body.message : 'Something went Wrong!', true);
        }).finally(() => {
          this.$emit('close');
          this.isFormSubmitting = false;
        });
      },

      /**
       * Perform a http request to cancel the selected vehicles.
       */
      submitForm() {
        this.cancelJobAssignments();
      }
    },

    props: ['request']
  }
</script>

<style lang="scss">
  .cancel-quote-request-modal {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #f7f9fa;

    &__title {
      margin-bottom: 10px;
      font-size: 28px;
      font-weight: bold;
    }

    &__description {
      font-size: 14px;
      color: rgba(15, 50, 70, 0.65);
    }

    &__fieldset-legend {
      color: #0f3246;
      font-size: 20px;
      font-weight: bold;
    }

    &__all-vehicles-item {
      font-weight: bold;
    }

    &__vehicle-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &:last-child {
        border-bottom: none;
      }

      .md-checkbox.md-theme-default.md-indeterminate .md-checkbox-container:after {
        border-color: white;
      }
    }

    &__note-fieldset {
      margin-top: 24px;
    }

    &__unable-to-cancel-wrapper {
      margin-right: 14px;
      font-size: 14px;
      text-align: center;
    }

    &__unable-to-cancel {
      display: block;
      color: #ef585a;
    }

    &__wrecker-on-site {
      display: block;
      margin-top: 4px;
      color: rgba(15, 50, 70, 0.65);
    }
  }

  .dialog-actions-custom {
    padding: 20px 24px;
    min-height: initial;
    justify-content: flex-start;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
</style>

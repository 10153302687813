<template>
  <md-card class="card account-owner card-wrecker-detail card-box-profile card-box offset-card section js-content-box" id="bank-account">
    <h2 class="title-card">Bank Account</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <transition name="slide">
      <div class="card-box__content" v-if="openBox">
        <div class="card-box__content-form js-box-content-form">
          <div class="row">
            <!-- Account owner -->
            <div v-if="!edit_info" class="form-group col-md-6">
              <div class="field">
                <label class="form__label">Account owner</label>
                <div v-if="bank_account.account_name" class="form__field">{{bank_account.account_name}}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass(this,'bank_account','account_name')">
                  <label class="form__label">Account owner</label>
                  <md-input v-model="bank_account.account_name" class="form-control" v-on:keyup="detectChange()"></md-input>
                  <span class="md-error" v-if="!$v.bank_account.account_name.required">Account owner is required</span>
                </md-field>
              </div>
            </div>
            <div v-if="!edit_info" class="form-group col-md-6">
            </div>
            <div v-else class="col-md-6 col-sm-12">
            </div>
          </div>
          <div class="row">
            <!-- Routing Number -->
            <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
              <div class="field margin-bottom-no">
                <label class="form__label">Routing number</label>
                <div v-if="bank_account.routing_number" class="form__field">{{ bank_account.routing_number }}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass(this,'bank_account','routing_number')">
                  <label class="form__label">Routing number</label>
                  <md-input
                    v-model="bank_account.routing_number"
                    class="form-control"
                    maxlength="9"
                    inputmode="numeric"
                    pattern="\d*"
                    @keydown="allowOnlyNumbers"
                    v-on:keyup="detectChange()"
                  />
                  <span class="md-error">Routing number is required</span>
                </md-field>
              </div>
            </div>
            <!-- Account Number -->
            <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
              <div class="field margin-bottom-no">
                <label class="form__label">Account number</label>
                <div v-if="bank_account.account_number" class="form__field">
                  {{ bank_account.account_number }}
                </div>
                <div v-else-if="stored_bank_account" class="form__field">
                  {{ "********" + stored_bank_account.last4 }}
                </div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass(this,'bank_account','account_number')">
                  <label class="form__label">Account number</label>
                  <md-input
                    v-model="bank_account.account_number"
                    class="form-control"
                    maxlength="17"
                    inputmode="numeric"
                    pattern="\d*"
                    @keydown="allowOnlyNumbers"
                    v-on:keyup="detectChange"
                  />
                  <span class="md-error">Account number is required</span>
                </md-field>
              </div>
            </div>
          </div>
          <transition name="slide">
            <div v-if="edit_info" class="row">
              <div class="card-box__content-actions">
                <md-button :class="[!submitActive ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="submit">Save</md-button>
                <md-button class="md-accent card-box__button-cancel" @click="cancel(!up_to_date ? 'database' : 'browserData')">Cancel</md-button>
                <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </md-card>
</template>

<script>
import Vue from 'vue'
import Stripe from '@/shared/mixins/stripe';
import { required, minLength, maxLength } from "vuelidate/lib/validators"
import { resetData } from '../../../mixins/resetData'
import { getValidationClass } from '../../../mixins/getValidationClass'

import humps from 'humps'

export default {
  name: 'BankAccount',
  mixins: [Stripe],
  props: ['company_data', 'stored_bank_account'],
  components: {},
  data () {
    return {
      bank_account: {
        account_name: this.stored_bank_account ? this.stored_bank_account.account_holder_name : '',
        routing_number: this.stored_bank_account ? this.stored_bank_account.routing_number : '',
        account_number: '',
      },
      data_save: null,
      validateNumber: false,
      edit_info: false,
      openBox: false,
      update: false,
      deactivedButton: true,
      up_to_date: false
    }
  },
  validations: {
    bank_account: {
      account_name: {
        required
      },
      routing_number: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      account_number: {
        required,
        minLength: minLength(4),
        maxLength: minLength(17),
      }
    }
  },
  computed: {
    submitActive () {
      return this.bank_account.account_name.length > 0 && this.bank_account.routing_number.length == 9 && this.bank_account.account_number.length > 3
    }
  },
  methods: {
    /**
     * Load stripe and get the client secret of the payment intent.
     */
    initialize() {
      this.loadstripe()
        .then((stripe) => {
          // Loading Stripe
        });
    },
    getValidationClass,
    editInfo () {
      if ( !this.up_to_date ) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    submit () {
      this.update = true;
      this.deactivedButton = true;

      // Initialize
      let bankAccountParams = {
        country: 'US',
        currency: 'usd',
        account_holder_name: this.bank_account.account_name,
        routing_number: this.bank_account.routing_number,
        account_number: this.bank_account.account_number,
      }

      // 1. Stripe call using those parameters to create a Stripe bank account token
      this.stripe.createToken('bank_account', bankAccountParams).then(response => {
        if(response.token) {
          // 2a. If that succeeds, pass bank account token to new Curbside API backend endpoint
          let params = {
            bank_account_token: response.token.id
          }
          Vue.http.post(`/organizations/${this.company_data.id}/bank_account`, params)
          .then(response => {
            setTimeout(() => {
              this.update = false;
              this.deactivedButton = true;
              this.$emit('show:snackbar', {update: true, alertMsg: 'Your information was successfully submitted.', error: false})
              this.up_to_date = true
              this.edit_info = false
            }, 1000)
          })
          .catch(error => {
            this.update = false;
            this.deactivedButton = false;
            this.$emit('show:snackbar', {update: true, alertMsg: error.body.msg, error: true})
          })
        } else {
          // 2a. If that fails, let user know
          let error = response.error
          let error_messages = {
            'parameter_invalid_empty': 'One or more required values were not provided.',
            'routing_number_invalid': 'The bank routing number provided is invalid.',
            'account_number_invalid': 'The bank account number provided is invalid.'
          }
          let error_message = 'Something went Wrong' // default error message
          if (error && error_messages[error.code]) {
            error_message = error_messages[error.code]
          }

          setTimeout(() => {
            this.update = false;
            this.deactivedButton = true;
            this.$emit('show:snackbar', {update: true, alertMsg: error_message, error: true})
            this.up_to_date = false
          }, 1000)
        }
      })
    },
    cancel (data) {
      this.edit_info = !this.edit_info;
      this.deactivedButton = true
      if ( data === 'database' ) {
        this.bank_account = resetData(this.bank_account,this.bank_account_data)
      }
      if ( data === 'browserData') {
        this.bank_account = resetData(this.bank_account,humps.decamelizeKeys(this.data_save,{split: /(?=[A-Z0-9])/ }))
      }
    },
    allowOnlyNumbers(event) {
      const keyCode = event.keyCode;

      if ((keyCode > 47 && keyCode < 58) || keyCode === 8) return;

      event.preventDefault();

      return false;
    },
    detectChange() {
      this.deactivedButton = false
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    this.company = resetData(this.company,this.company_data)
    this.loadstripe()
        .then((stripe) => {
          // console.log('stripe: ', stripe);
        });
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>

<style lang="scss" scoped>
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
</style>


import basicTowImage from 'images/motorist/icon-tow-icon-b.svg';
import flatTireImage from 'images/motorist/icon-flat-tire-icon.svg';
import lockoutImage from 'images/motorist/icon-lockout-icon.svg';
import wontStartImage from 'images/motorist/icon-battery-icon.svg';
import outOfGasImage from 'images/motorist/icon-gas-icon.svg';
import winchOutImage from 'images/motorist/icon-winch-out-icon.svg';

export default {
  name: 'Motorist flow',
  version: '1.0.0',
  baseUrl: '/api/v1',
  httpTimeout: 30000,
  vuexPersistedStorageKey: 'curbside',
  defaultPosition: {
    latitude: 42.9604505,
    longitude: -86.0513259,
  },
  requestFormSteps: {
    'Basic tow': ['tow-destination', 'select-car', 'phone-number', 'confirm-location', 'details'],
    'default': ['select-car', 'phone-number', 'confirm-location', 'details']
  },
  requestFormStepLabels: {
    'tow-destination': "Where do you want your vehicle towed?",
    'select-car': "What type of car do you have?",
    'phone-number': "What’s your phone number?",
    'confirm-location': "Confirm your location",
    'details': "Describe your situation"
  },
  services: [
    {
      id: 'basic_tow',
      label: 'Basic tow',
      key: 'hookUp',
      path: basicTowImage,
      description: 'Do you need a ride? If yes, how many passengers? Do you have your keys with you?',
      secondDescription: 'Transporting a nonfunctioning vehicle. Also includes cost of getting vehicle on tow truck for transport.'
    },
    {
      id: 'flat_tire',
      label: 'Flat tire',
      key: 'tireChange',
      path: flatTireImage,
      description: 'Specify what side the flat tire is and whether you have a spare.',
      secondDescription: 'Helping to replace a flat tire.',
    },
    {
      id: 'locked_out',
      label: 'Lockout',
      key: 'lockOut',
      path: lockoutImage,
      description: 'What type of key does your car have? (e.g. Remote key-less entry or physical key)',
      secondDescription: 'Accessing a vehicle that is locked and does not have keys accessible.',
    },
    {
      id: 'wont_start',
      label: 'Won’t start',
      key: 'deadBattery',
      path: wontStartImage,
      description: 'Were you actively driving when your car stopped or were you parked for sometime?',
      secondDescription: 'Helping to restart a car battery that is out of power.',
    },
    {
      id: 'out_gas',
      label: 'Out of gas',
      key: 'outOfGas',
      path: outOfGasImage,
      description: 'Does your car take regular gas or diesel?',
      secondDescription: 'Filling up a motorist gas tank when they’ve run out.',
    },
    {
      id: 'winch_out',
      label: 'Winch Out',
      key: 'winchOut',
      path: winchOutImage,
      description: 'Are you stuck on top of an object? How far away are you from the main road and solid ground? Is there damage to your car?',
      secondDescription: 'Pulling a vehicle from an area that is not easily accessible.',
    },
  ],
  additionalCharges: {
    mileageFee: {
      type: 'mileage_fee',
      description: '$10.00/ mile',
      name: 'Mileage Fee',
      value: 1000,
    },
  },
  checkout: {
    // CSOS-2097 Use "window." to prevent wepback from tyring to interpolate this value in to the generated JS.
    returnUrl: `${window.CSOS_RT_CONF.SITE_URL}/sos/checkout/:id/thank_you`,
  },
  systemMessages: {
    INTERNAL_ERROR: 'An internal error occurred. Please try again in a few seconds.',
    NETWORK_ERROR: 'No internet connection. Check your connection and try again.',
    UNAUTHORIZED: 'Not authorized to perform this request.',
    NETWORK_CONNECTED: 'Internet connection detected again!',
  },
};

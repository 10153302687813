<template>
  <div class="drivers-table" v-if="ready">
    <DataTable
      :data="list"
      title="Wreckers"
      :columns="['Company name', 'Company phone number', '# of drivers', '# of vehicles', 'Status']"
      button-text="invite wrecker"
      :button-path="'/wreckers/add'"
      type-table="wreckers"
      edit-button-path="/wreckers"
    ></DataTable>

    <TableResponsive
      :data="list"
      title="Wreckers"
      :columns="['Company name', 'Company phone number', '# of drivers', '# of vehicles', 'Status']"
      button-text="Invite wreckers"
      :button-path="'/wreckers/add'"
      type-table="wreckers"
      edit-button-path="/wreckers"
    ></TableResponsive>
  </div>
</template>

<script>
import DataTable from "../data-table";
import TableResponsive from "./table-responsive-wrecker";

const formatPhoneNumber = str => {
  const cleaned = ("" + str).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
  return null;
};
const renderAttrQuantity = (obj, key) => {
  if (obj[key]) {
    const length = obj[key].length;
    return length > 0 ? length : "";
  }
  return "";
};
export default {
  props: ["wreckers"],

  components: {
    DataTable,
    TableResponsive
  },

  data: () => ({
    list: [{ "Company name": 1 }],
    ready: false
  }),

  mounted() {
    const mappedWreckers = this.wreckers.map((wrecker, idx) => ({
      id: wrecker.id,
      "Company name": wrecker.name,
      "Company phone number": formatPhoneNumber(wrecker.contact_phone),
      "# of drivers": renderAttrQuantity(wrecker, "drivers"),
      "# of vehicles": renderAttrQuantity(wrecker, "vehicles"),
      resend: (wrecker.owner || {}).profile_completed,
      active: wrecker.active
    }));
    this.list = mappedWreckers.reverse();
    this.ready = true;
    console.log(this.list);
  }
};
</script>

<style scoped lang='scss'>
</style>

<template>
  <div class="dispatch-dashboard">
    <transition>

      <!-- Dispatch Dashboard View -->
      <div class="d-md-flex flex-row-reverse" v-if="view === 'dispatch-view'">
        <div class="column column--right">
          <DispatchMap />
        </div>

        <div class="column column--left">
          <div v-if="loading">
            <div class="col-md-12 cellSuccess text-center">
              <md-progress-spinner class="mx-auto" :md-diameter="120" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <div v-else-if="towers.length == 0">
            <md-empty-state
              md-icon="time_to_leave"
              md-label="You don't have any Wreckers"
              md-description="To order tow jobs, please add wreckers to Curbside. We’ll email them an invite, where they’ll be able to register and enter all required information.">
              <md-button autofocus class="btn btn-primary" :href="'/wreckers/add'">+ Invite Wreckers</md-button>
            </md-empty-state>
          </div>

          <div v-else-if="activeSentRequests.length == 0">
            <md-empty-state
              md-icon="time_to_leave"
              md-label="No active tows"
              md-description="Use the button below to order a new tow.">
              <md-button autofocus class="btn btn-primary" @click="view='create-quote-request-view'">+ New Tow Order</md-button>
            </md-empty-state>
          </div>

          <div class="feed" v-else-if="activeSentRequests.length">
            <div class="create-job-assignment-button px-4 py-3">
              <md-button autofocus class="btn btn-secondary compact" @click="view='create-quote-request-view'">
                <md-icon>add</md-icon>
                New Tow Order
              </md-button>

            </div>
            <SentQuoteRequestList @select-request="viewRequest($event)" />
          </div>
        </div>
      </div>

      <!-- Sent Job Detail View -->
      <div v-else-if="view === 'job-info-view'">
        <SentJobInfo :request="selected_request" @back="cancelDetailView" />
      </div>

      <!-- Create Quote Request Form -->
      <div class="viewRequestForm container-padded" v-if="view === 'create-quote-request-view'">
        <div class="row">
          <div class="col-md-1">
            <div class="return-button">
              <md-button class="md-icon-button" @click="view='dispatch-view'">
                <md-icon>arrow_back</md-icon>
              </md-button>
            </div>
          </div>
          <div class="col-md-10">
            <div class="viewHeader pt-4">
              <h3>Order new tow</h3>
              <p class="caption">A tow company will be automatically assigned.</p>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-10 offset-md-1">
            <CreateQuoteRequestForm @viewState="formSubmitted" @cancelOrder="view='dispatch-view'"/>
          </div>
        </div>
      </div>
      <div class="container-padded text-center" v-if="view === 'create-quote-success-view'">
        <div class="row">
          <div class="col-md-12 cellSuccess">
            <md-progress-spinner class="loader" :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            <p class="mt-4">Processing...</p>
          </div>
        </div>
      </div>
    </transition>
    <md-snackbar
      class="snack-success"
      :md-active.sync="alert"
      :md-position="'center'"
      :md-duration="4000">
      <span>
        <i class="md-icon md-theme-dark">check</i>
        Your account was successfully created.
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// Sockets
import dispatchConstructor from '../socket/channels/dispatch'

// Components
import CreateQuoteRequestForm from './forms/create-quote-request/form'
import DispatchMap from './maps/dispatch-map'
import SentQuoteRequestList from './quote-requests/sent-quote-request-list'
import SentQuoteRequestDetail from './quote-requests/sent-quote-request-detail'
import SentJobInfo from './sent-job-info/sent-job-info'

export default {
  data () {
    return {
      loading: true,
      alert: false,
      view: 'dispatch-view',
      selected_request: null
    }
  },

  components: {
    CreateQuoteRequestForm,
    DispatchMap,
    SentQuoteRequestList,
    SentQuoteRequestDetail,
    SentJobInfo
  },

  methods: {
    async subscribe () {
      await this.$cable.create({ channelConstructor: dispatchConstructor }, { dispatcher: this.current })
    },
    viewRequest(request) {
      this.view = 'job-info-view'
      this.selected_request = request
    },
    cancelDetailView() {
      this.view = 'dispatch-view'
      this.selected_request = null
    },
    formSubmitted: function(success) {
      this.view = success;
      setTimeout(() => {
        this.view = 'dispatch-view'
      },2500)
    }
  },

  computed: {
    ...mapState('organizations', {
      current: state => state.current,
      towers: state => state.towers
    }),
    ...mapState('views', {
      selected_quote_request: state => state.selected_quote_request
    }),
    ...mapGetters('quote_requests', [
      'activeSentRequests'
    ]),
  },

  updated: function () {
    if(this.view === "create-quote-request-view") {
      // put focus on button: ref='btnAddVehicle'

      // setTimeout(this.$refs.btnAddVehicle.$el.focus(), 50)
    }
  },

  async created () {
    await Promise.all([
      this.$store.dispatch('users/getCurrentUser'),
      this.$store.dispatch('organizations/getCurrentOrganization'),
      this.$store.dispatch('organizations/getPoliceDepartments'),
      this.$store.dispatch('organizations/getTowers'),
      this.$store.dispatch('quote_requests/getSentQuoteRequests'),
      this.$store.dispatch('features/getFeatures'),
    ])
    await this.subscribe()
    this.loading = false
  },
  mounted () {
    if(localStorage.getItem('user-login-account-form') !== null) {
      this.alert = true;
      localStorage.removeItem('user-login-account-form')
    }
  }
}
</script>

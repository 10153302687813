<template>
  <div>
    <md-card class="md-card drivers-card section card-box" id="drivers-card">
      <h2 class="title-card title-card--table section">Drivers</h2>
      <md-table
          v-model="driverList"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-vehicles"
        >
          <md-table-empty-state
            md-label="No records found"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" class="table-row item-hover" v-if="item.active" @click="openDialog(item)">
            <md-table-cell md-label="First name" md-sort-by="first"><p class="text-item-table">{{ item.first }}</p></md-table-cell>
            <md-table-cell md-label="Last name" md-sort-by="last"><p class="text-item-table">{{ item.last }}</p></md-table-cell>
          </md-table-row>
        </md-table>
    </md-card>
    <md-dialog :md-active.sync="showDialog" class="dialog-form-wizard dialog-organization">
      <md-dialog-title class="title">Driver’s details</md-dialog-title>
      <md-dialog-content class="dialog-form-wizard__content dialog-driver-organization">
        <div class="col-md-12">
          <form class="md-layout" enctype="multipart/form-data" accept-charset="UTF-8" id="formVehicle" name="formVehicle">
            <div class="md-layout-item md-small-size-100">
              <div class="row">
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">First name</label>
                    <div class="form__field">{{driver.first_name}}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Last name</label>
                    <div class="form__field">{{driver.last_name}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 padding-left-no">
                <p class="dialog-organization__license">Driver's License</p>
                <div class="driver-license__licenses row">
                  <div class="col-md-5">
                    <div class="license">
                      <a class="license-link" :href="driver.license_front" target="_blank">
                        <div class="license__head">
                          <img :src="driver.license_front" alt="license front">
                        </div>
                        <div class="license__body">
                          <p>Front</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="license license-back">
                      <a class="license-link" :href="driver.license_back" target="_blank">
                        <div class="license__head">
                          <img :src="driver.license_back" alt="license back">
                        </div>
                        <div class="license__body">
                          <p>Back</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="dialog-form-wizard__footer">
        <div class="footer-form__content">
          <md-button class="btn btn-primary footer-form__submit" @click="closeDialog">close</md-button>
        </div>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import licenseFront from '../../../../../images/icon_license_front.png'
import licenseBack from '../../../../../images/icon_license_back.png'

export default {
  name: 'Drivers',
  props: ['drivers'],
   data: () => ({
    showDialog: false,
    driverList: [],
    driver: {
      first_name: '',
      last_name: '',
      license_front: '',
      license_back: '',
    }
  }),
  methods: {
    openDialog (item) {
      this.showDialog = true
      this.driver.first_name = item.first;
      this.driver.last_name = item.last;
      this.driver.license_front = item.license_front_url ? item.license_front_url.url : licenseFront
       this.driver.license_back = item.license_back_url ? item.license_back_url.url : licenseBack
    },
    closeDialog () {
      this.showDialog = false
    }
  },
  mounted () {
   this.driverList = this.drivers.filter(driver => driver.invitation_accepted)
  }
}
</script>

<style lang="scss" scoped>
.license-link {
  color: #0f3246 !important;
  &:hover {
    text-decoration: none !important;
  }
}
</style>
<template>
  <span class="timeago caption">
    {{ timeago }}
  </span>
</template>

<script>
export default {
  props: ["date"],

  data () {
    return {
      timeago: "",
      interval: 60 * 1000,
    }
  },

  watch: {
    timeago: function (a, b) {
      if (a != b) {
        setInterval(this.getAge, this.interval)
      }
    },
  },

  created () {
    this.getAge()
  },

  methods: {
    getAge () {
      this.timeago = this.setTimeago()
    },
    getDiff () {
      return new Date - new Date(this.date)
    },
    setTimeago () {
      const diff = this.getDiff()

      const hours = Math.floor(diff / (60 * 60 * 1000))
      const minutes = Math.floor((diff - (hours * 60 * 60 * 1000)) / (60 * 1000))

      if (hours === 0 && minutes === 0) {
        return 'Requested just now'
      } else if (hours === 0) {
        return `Requested ${minutes} min ago`
      } else {
        return `Requested ${Math.abs(hours)} hr ${minutes} min ago`
      }
    }
  }

}
</script>

<style scoped lang="">
</style>

<template>
  <div class="request-details mt-5">
    <div class="section-title">
      <h5>Additional remarks</h5>
      <p class="caption">Situational details to pass onto the wrecker. (Optional)</p>
    </div>
    <md-field class="heightSm">
      <md-textarea 
        v-model="request.comments"
        ref="commentsVehicles" 
        tabindex="2" 
        placeholder="e.g. Flooding at Lake Ave, consider alt route"
      ></md-textarea>
    </md-field>
    <div v-if="features.emergency">
      <md-checkbox v-model="request.is_emergency">
        <p class="mb-1">This is an emergency</p>
        <p class="caption faded-1 mb-0">We’ll let wreckers know this is an emergency.</p>
      </md-checkbox>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: ['request'],
    computed: {
      ...mapState('features', {
        features: state => state.features
      }),
    }
  }
</script>

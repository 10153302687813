<template>
  <div v-if="renderNav" :class="[current_organization.org_type === 'dispatcher' ? 'navbar-app--blue' : 'navbar-app--white','page-container md-layout-column navbar-app']">
    <md-toolbar class="md-primary position-fixed">
      <md-button class="md-icon-button" @click="showNavigation = true">
        <md-icon>menu</md-icon>
      </md-button>
      <a href="/passthrough" class="navbar-brand"></a>
    </md-toolbar>

    <md-drawer :md-active.sync="showNavigation" md-swipeable class="navbar-app__drawer">
      <h4 class="mt-3 ml-3">{{ current_organization.name }}</h4>

      <!-- Tower Sidebar -->
      <ul v-if="current_organization.org_type === 'tower'" class="navbar-app__list">

        <!-- Job-related sections -->
        <li class="navbar-app__item">
          <a :class="[link_active === 'base' ? 'active-link' : '', 'navbar-app__link']" :href="links.dispatch.url">
            <img src="../../../images/pin.svg" alt="dispatch-img">
            <span class="profile-dispatch">{{ links.dispatch.name }}</span>
          </a>
        </li>

        <li class="navbar-app__item">
          <a :class="[link_active === 'history' ? 'active-link' : '', 'navbar-app__link']" :href="links.history.url">
            <img src="../../../images/history.svg" alt="history-img">
            <span>{{ links.history.name }}</span>
          </a>
        </li>

        <!-- Company-related sections -->
        <li class="navbar-app__item navbar-app__item-line-top">
          <a :class="[link_active === 'drivers' ? 'active-link' : '', 'navbar-app__link']" :href="links.drivers.url">
            <img src="../../../images/assignment.svg" alt="driver-img">
            <span>{{ links.drivers.name }}</span>
          </a>
        </li>

        <li class="navbar-app__item">
          <a :class="[link_active === 'users' ? 'active-link' : '', 'navbar-app__link']" :href="links.users.url">
            <img src="../../../images/users.svg" alt="users-img">
            <span>{{ links.users.name }}</span>
          </a>
        </li>

        <li class="navbar-app__item">
          <a :class="[link_active === 'vehicles' ? 'active-link' : '', 'navbar-app__link']" :href="links.vehicles.url">
            <img src="../../../images/truck.svg" alt="wreckers-img">
            <span>{{ links.vehicles.name }}</span>
          </a>
        </li>

        <li class="navbar-app__item">
          <a :class="[link_active === 'organizations' ? 'active-link' : '', 'navbar-app__link']" :href="links.settings.url">
            <img src="../../../images/settings.svg" alt="settings-img">
            <span>Settings</span>
          </a>
        </li>

        <!-- Your user account-related sections -->
        <li class="navbar-app__item navbar-app__item-line-top">
          <a class="navbar-app__link" :href="links.profile.url">
            <img src="../../../images/profile.svg" alt="profile-img">
            <span class="profile-text">{{ links.profile.name }}</span>
          </a>
        </li>

         <li class="navbar-app__item">
          <a class="navbar-app__link" :href="links.notification_settings.url">
            <img src="../../../images/icon-notification.svg" alt="profile-img">
            <span class="profile-text">{{ links.notification_settings.name }}</span>
          </a>
        </li>

        <!-- Curbside-related sections -->
        <li class="navbar-app__item navbar-app__item-line-top">
          <a class="navbar-app__link" href="https://www.curbsidesos.com/support" target="_blank" rel="noopener noreferrer">
            <img src="../../../images/help.svg" alt="help-img">
            <span>Help center</span>
          </a>
        </li>

        <li class="navbar-app__item">
          <a class="navbar-app__link" @click="openFeedbackPopup">
            <img src="../../../images/feedback.svg" alt="users-img">
            <span>{{ links.feedback.name }}</span>
          </a>
        </li>

        <li class="navbar-app__item navbar-app__item-line-bottom">
          <a class="navbar-app__link" :href="links.logout.url">
            <img src="../../../images/logout.svg" alt="logout-img">
            <span>{{ links.logout.name }}</span>
          </a>
        </li>
      </ul>

      <!-- Dispatcher Sidebar -->
      <ul v-if="current_organization.org_type === 'dispatcher'" class="navbar-app__list">
        <li class="navbar-app__item">
          <a :class="[link_active === 'job_assignments' ? 'active-link' : '', 'navbar-app__link']" :href="links.dispatch.url">
            <img src="../../../images/pin.svg" alt="dispatch-img">
            <span class="profile-dispatch">{{ links.dispatch.name }}</span>
          </a>
        </li>

        <li class="navbar-app__item">
          <a :class="[link_active === 'organizations' ? 'active-link' : '', 'navbar-app__link']" :href="links.wreckers.url">
            <img src="../../../images/truck.svg" alt="wreckers-img">
            <span>{{ links.wreckers.name }}</span>
          </a>
        </li>

        <li class="navbar-app__item">
          <a :class="[link_active === 'jobs' ? 'active-link' : '', 'navbar-app__link']" :href="links.history.url">
            <img src="../../../images/history.svg" alt="history-img">
            <span>{{ links.history.name }}</span>
          </a>
        </li>
       
        <li class="navbar-app__item navbar-app__item-line-top">
          <a class="navbar-app__link" :href="links.profile.url">
            <img src="../../../images/profile.svg" alt="profile-img">
            <span class="profile-text">{{ links.profile.name }}</span>
          </a>
        </li>

        <!-- Comment out until implemented
        <li class="navbar-app__item">
          <a class="navbar-app__link" :href="links.settings.url">
            <img src="../../../images/settings.svg" alt="settings-img">
            <span>{{ links.settings.name }}</span>
          </a>
        </li> -->

        <li class="navbar-app__item navbar-app__item-line-bottom">
          <a class="navbar-app__link" :href="links.users.url">
            <img src="../../../images/users.svg" alt="users-img">
            <span>{{ links.users.name }}</span>
          </a>
        </li>

        <!-- Comment out until implemented -->
        <li class="navbar-app__item">
          <a class="navbar-app__link" @click="openFeedbackPopup">
            <img src="../../../images/feedback.svg" alt="users-img">
            <span>{{ links.feedback.name }}</span>
          </a>
        </li>
       
        <li class="navbar-app__item">
          <a class="navbar-app__link" @click="logout">
            <img src="../../../images/logout.svg" alt="logout-img">
            <span>{{ links.logout.name }}</span>
          </a>
        </li>
      </ul>

      <!-- Superorg Sidebar -->
      <ul v-if="current_organization.org_type === 'super'" class="navbar-app__list">
        <li class="navbar-app__item">
          <a class="navbar-app__link" @click="logout">
            <img src="../../../images/logout.svg" alt="logout-super">
            <span>{{ links.logout.name }}</span>
          </a>
        </li>
      </ul>

    </md-drawer>
    <md-dialog :md-active.sync="showFeedbackPopup" class="feedback-popup">
      <FeedbackForm :currentPage="link_active" :currentOrganization="current_organization" @close:feedbackPopup="closeFeedbackPopup" @show:snackbar="showAlert"></FeedbackForm>
    </md-dialog>
    <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
      <span class="snackbar-notify__text">
        <div class="content-icon">
          <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
        </div>
        {{ this.alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import FeedbackForm from './feedback/feedback-form';

export default {
  name: 'menuApp',
  props: ['links', 'current_organization', 'link_active'],
  components: {
    FeedbackForm
  },
  data: () => ({
    showNavigation: false,
    showSidepanel: false,
    drawerFixed: true,
    showFeedbackPopup: false,
    errorSnack: false,
    position: 'center',
    isInfinity: false,
    duration: 4000,
    alertMsg: '',
    update: false,
    counter: 0,
    sessionCount: '',
    renderNav: true
  }),
  methods: {
    openFeedbackPopup () {
      this.showFeedbackPopup = true;
      this.showNavigation = false;
    },
    closeFeedbackPopup (item) {
      this.showFeedbackPopup = item;
    },
    showAlert (opts) {
      this.update = opts.update
      this.alertMsg = opts.alertMsg
      this.errorSnack = opts.error
    },
    logout () {
      localStorage.removeItem('sessionCount');
      localStorage.removeItem('counter');
      window.location.href = this.links.logout.url
    }
  },
  mounted () {
    if(localStorage.getItem('counter') !== null) {
      this.counter = localStorage.getItem('counter');
    } 

    setTimeout(() => {
      this.hide = true
    },1000)
    
    setInterval(() => {
      ++this.counter;
       let hour = Math.floor(this.counter /3600),
          minute = Math.floor((this.counter - hour*3600)/60),
          seconds = this.counter - (hour*3600 + minute*60);
       if(hour < 10) hour = "0"+hour;
       if(minute < 10) minute = "0"+minute;
       if(seconds < 10) seconds = "0"+seconds;
       
       this.sessionCount = `${hour} : ${minute} : ${seconds}`;
       localStorage.setItem('sessionCount', this.sessionCount);
       localStorage.setItem('counter', this.counter);
    }, 1000);

     if (window.location.pathname === '/register') {
       this.renderNav = false
     }
  }
}
</script>

<template>
  <md-card class="card account-owner card-box-profile card-wrecker-detail card-box offset-card fees js-content-box-fees" id="fees">
    <h2 class="title-card">Fees</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
       <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">      
    </div>
    <div class="card-box__content" v-if="openBox">
      <p>Please provide your rate for the following services, excluding mileage. We require a $40 minimum for all services offered. If you do not offer the service, leave the value as $0.</p>
      <div class="card-box__content-form js-box-content-form">
        <div class="row">
          <!-- hook_up -->
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Hook-up</label>
              <div class="form__field">${{fees.hook_up}}</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass('fees','hook_up')">
                <label class="form__label">
                  Hook-up
                </label>
                <div class="form-wrapper-field">
                  <md-icon class="form-wrapper-field__icon">attach_money</md-icon>
                  <md-input v-model="fees.hook_up" class="form-control" type="number"></md-input>
                </div>
                <span class="md-error">Minimum $40 or $0 if not offered</span>
                <span v-if="$v.fees.hook_up.$invalid" class="invalid display-none"></span>
              </md-field>
            </div>
          </div>

          <!-- tire_change -->
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Flat Tire</label>
              <div class="form__field">${{fees.tire_change}}</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass('fees','tire_change')">
                <label class="form__label">
                  Flat Tire
                </label>
                <div class="form-wrapper-field">
                  <md-icon class="form-wrapper-field__icon">attach_money</md-icon>
                  <md-input v-model="fees.tire_change" class="form-control" type="number"></md-input>
                </div>
                <span class="md-error">Minimum $40 or $0 if not offered</span>
                <span v-if="$v.fees.tire_change.$invalid" class="invalid display-none"></span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- lock_out -->
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Lockout</label>
              <div class="form__field">${{fees.lock_out}}</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass('fees','lock_out')">
                <label class="form__label">
                  lockout
                </label>
                <div class="form-wrapper-field">
                  <md-icon class="form-wrapper-field__icon">attach_money</md-icon>
                  <md-input v-model="fees.lock_out" class="form-control" type="number"></md-input>
                </div>
                <span class="md-error">Minimum $40 or $0 if not offered</span>
                <span v-if="$v.fees.lock_out.$invalid" class="invalid display-none"></span>
              </md-field>
            </div>
          </div>
          <!-- dead_battery -->
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Jump Start</label>
              <div class="form__field">${{fees.dead_battery}}</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass('fees','dead_battery')">
                <label class="form__label">
                  Jump Start
                </label>
                <div class="form-wrapper-field">
                  <md-icon class="form-wrapper-field__icon">attach_money</md-icon>
                  <md-input v-model="fees.dead_battery" class="form-control" type="number"></md-input>
                </div>
                <span class="md-error">Minimum $40 or $0 if not offered</span>
                <span v-if="$v.fees.dead_battery.$invalid" class="invalid display-none"></span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- out_of_gas -->
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Fuel Delivery</label>
              <div class="form__field">${{fees.out_of_gas}}</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass('fees','out_of_gas')">
                <label class="form__label">
                  Fuel Delivery
                </label>
                <div class="form-wrapper-field">
                  <md-icon class="form-wrapper-field__icon">attach_money</md-icon>
                  <md-input v-model="fees.out_of_gas" class="form-control" type="number"></md-input>
                </div>
                <span class="md-error">Minimum $40 or $0 if not offered</span>
                <span v-if="$v.fees.out_of_gas.$invalid" class="invalid display-none"></span>
              </md-field>
            </div>
          </div>
          <!-- winch_out -->
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Winch Out</label>
              <div class="form__field">${{fees.winch_out}}</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass('fees','winch_out')">
                <label class="form__label">
                  Winch Out
                </label>
                <div class="form-wrapper-field">
                  <md-icon class="form-wrapper-field__icon">attach_money</md-icon>
                  <md-input v-model="fees.winch_out" class="form-control" type="number"></md-input>
                </div>
                <span class="md-error">Minimum $40 or $0 if not offered</span>
                <span v-if="$v.fees.winch_out.$invalid" class="invalid display-none"></span>
              </md-field>
            </div>
          </div>
        </div>
        <!-- Mileage Fees -->
        <p class="card-box-profile__subtitle">Please list your mileage fee.</p>
        <div class="row">
          <!-- mileage_other_location -->
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Mileage (other location)</label>
              <div class="form__field">${{fees.mileage_other_location}}</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass('fees','mileage_other_location')">
                <label class="form__label">
                  Mileage (other location)
                </label>
                <div class="form-wrapper-field">
                  <md-icon class="form-wrapper-field__icon">attach_money</md-icon>
                  <md-input v-model="fees.mileage_other_location" class="form-control" type="number"></md-input>
                </div>
                <span class="md-error">Minimum $40 or $0 if not offered</span>
                <span v-if="$v.fees.mileage_other_location.$invalid" class="invalid display-none"></span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="row"></div>
        <div class="row"></div>
        <transition name="fade">
          <div v-if="edit_info" class="row">
            <div class="card-box__content-actions">
              <md-button :class="[deactived_button ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
              <md-button class="md-accent card-box__button-cancel" @click="cancel(!up_to_date ? 'database' : 'browserData')">Cancel</md-button>
              <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </md-card>
</template>

<script>
import Vue from 'vue';
import { required, minValue } from "vuelidate/lib/validators";
import { resetData } from '../../../mixins/resetData';
import humps from 'humps'

const MINIMUM_FEE = 40;

export default {
  name: 'Fees',
  props: ['detail', 'id'],
  data: () => ({
    fees: {
      hook_up: '',
      mileage_other_location: '',
      out_of_gas: '',
      lock_out: '',
      tire_change: '',
      dead_battery: '',
      winch_out: '',
      tower_locations: []
    },
    save_data: null,
    edit_info: false,
    openBox: false,
    deactived_button: false,
    update: false,
    up_to_date: false
  }),
  validations () {
    return {
      fees: {
        hook_up: {
          required,
          minValue: minValue(this.fees['hook_up'] > 0 ? MINIMUM_FEE : 0)
        },
        mileage_other_location: {
          required,
          minValue: minValue(0)
        },
        out_of_gas: {
          required,
          minValue: minValue(this.fees['out_of_gas'] > 0 ? MINIMUM_FEE : 0)
        },
        lock_out: {
          required,
          minValue: minValue(this.fees['lock_out'] > 0 ? MINIMUM_FEE : 0)
        },
        tire_change: {
          required,
          minValue: minValue(this.fees['tire_change'] > 0 ? MINIMUM_FEE : 0)
        },
        dead_battery: {
          required,
          minValue: minValue(this.fees['dead_battery'] > 0 ? MINIMUM_FEE : 0)
        },
        winch_out: {
          required,
          minValue: minValue(this.fees['winch_out'] > 0 ? MINIMUM_FEE : 0)
        }
      }
    }
  },
  methods: {
    convertValue (number) {
      return number / 100;
    },
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    validate () {
      this.$v.$touch();
      let invalidFields = [...document.querySelectorAll('.invalid')]
     
      if(invalidFields.length) {
        let element = invalidFields[0].parentElement,
          input = document.querySelector('input');

          input.scrollIntoView();
      }
      if (!this.$v.$error) {
        this.submit();
      }
    },
    editInfo () {
      if ( !this.up_to_date ) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }

      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    submit () {
      this.update = true;
      this.deactived_button = true;
      Vue.http.put(`/tower_details/${this.id}`, {tower_detail: this.fees} ,[{responseType: 'json'}])
        .then(response => {
          setTimeout(() => {
            this.update = false;
            this.deactived_button = false;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
            this.up_to_date = true
            this.edit_info = false
            this.save_data = humps.camelizeKeys(this.fees)
          },1000)
        }, err => {
          setTimeout(() => {
            this.update = false;
            this.deactived_button = false;
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.error : 'Something went Wrong', error: true})
            this.up_to_date = false
          },1000)
      });
    },
    cancel ( data ) {
      this.edit_info = !this.edit_info
      if ( data === 'database' ) {
        resetData(this.fees,this.detail, newData => {
          for ( let value in newData ) {
            if ( !(value === 'tower_locations') ) {
              this.fees[value] = this.convertValue(newData[value])
            }
          }
        })
      }

      if ( data === 'browserData') {
        this.fees = resetData(this.fees,humps.decamelizeKeys(this.save_data,{split: /(?=[A-Z0-9])/ }))
      }
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    resetData(this.fees,this.detail, newData => {
      for ( let value in newData ) {
        if ( !(value === 'tower_locations') ) {
          this.fees[value] = this.convertValue(newData[value])
        }
      }
    })
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>
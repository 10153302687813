import Vue from 'vue';
import { set } from '../../helpers';

export default {
  setQuotes: set('quotes'),
  appendSentQuoteRequest (state, quote_request) {
    let index = state.sent.findIndex((request) => request.id === quote_request.id)
    if (index === -1) {
      state.sent.push(quote_request)
    } else {
      Vue.set(state.sent, index, quote_request)
    }
  },
  appendReceivedQuoteRequest (state, quote_request) {
    let index = state.received.findIndex((request) => request.id === quote_request.id)
    if (index === -1) {
      state.received.push(quote_request)
    } else {
      Vue.set(state.received, index, quote_request)
    }
  },
  setSentQuoteRequests: set('sent'),
  setReceivedQuoteRequests: set('received'),
  updateSentQuoteRequest (state, quote_request) {
    const quote_request_index = state.sent.findIndex((item) => item.id === quote_request["id"] || item.id === quote_request.id)
    Vue.set(state.sent, quote_request_index, quote_request)
  },
  updateReceivedQuoteRequest (state, quote_request) {
    const quote_request_index = state.received.findIndex((item) => item.id === quote_request.id)
    if (quote_request_index >= 0) {
      Vue.set(state.received, quote_request_index, quote_request)
    } else {
      state.received.push(quote_request)
    }
  },
  addETAs (state, quote_request, eta) {
    const quote_request_index = state.sent.findIndex((item) => item.id === quote_request.id)
    var qr = state.sent[quote_request_index]
  },
  updateReceivedQuoteRequestAssignment (state, { quote_request_id, assignment }) {
    const request_index = state.received.findIndex((item) => item.id === quote_request_id)
    var quote_request = state.received[request_index]
    const assignment_index = quote_request.job.assignments.findIndex((item) => item.id === assignment.id)
    Vue.set(state.received[request_index].job.assignments, assignment_index, assignment)
  },
  removeSentQuoteRequest (state, id) {
    const request_index = state.sent.findIndex((item) => item.id === id)
    state.sent.splice(request_index, 1)
  },
  removeReceivedQuoteRequest (state, id) {
    const request_index = state.received.findIndex((item) => item.id === id)
    state.received.splice(request_index, 1)
  }
}
<template>
  <div class="vehicles-table">
    <DataTable
      :data="vehicles"
      title="Vehicles"
      :columns="['make', 'model', 'nickname', 'color']"
      button-text="new vehicle"
      :button-path="'/vehicles/new'"
      type-table="vehicles"
      edit-button-path="/vehicles"
    >
    </DataTable>

    <TableResponsive
      :data="vehicles"
      title="Vehicles"
      :columns="['make', 'model', 'nickname', 'color']"
      button-text="new vehicle"
      :button-path="'/vehicles/new'"
      type-table="vehicles"
      edit-button-path="/vehicles"
    >
  </TableResponsive>
  </div>
</template>

<script>
import DataTable from '../data-table'
import TableResponsive from '../table-responsive'

export default {
  props: ['vehicles'],

  components: {
    DataTable,
    TableResponsive
  },

  data: () => {
    return {}
  },
}
</script>

<style scoped lang='scss'>
</style>

<template>
  <div class="job-detail">
    <div class="job-info">
      <!-- Address, TimeAgo -->
      <div class="d-flex justify-content-between">
        <div>
          <h5 class="mb-0" v-if="jobAddressTitle">
            <md-icon style="color: #0F3246;">place</md-icon>{{ jobAddressTitle }}
          </h5>
          <!-- Incident Details -->
          <div class="pt-3">
            <!-- Incident Details: Request Source (911 or Motorist) -->
            <span v-if="isMotoristJob">
              <span style="border-radius: .5em; background-color: #BAF7D0; color: #14532D; padding: .5em;">
                <md-icon style="color: #14532D;">emoji_people</md-icon> <span style="font-weight: bold;">Motorist</span>
              </span>
            </span>
            <span v-else>
              <span v-if="!job.emergency" style="border-radius: .5em; background-color: #BEDBFE; color: #1F3A8A; padding: .5em;"><md-icon style="color: #1F3A8A;">local_police</md-icon> <span style="font-weight: bold;">911</span></span>
            <span v-if="job.emergency" style="border-radius: .5em; background-color: #febebe; color: #8a1f1f; padding: .5em;"><md-icon style="color: #8a1f1f;">local_police</md-icon> <span style="font-weight: bold;">911 Emergency</span></span>
            </span>

            <!-- Incident Type -->
            <span style="color: #1C4ED8; font-weight: bold;">{{ getServicesName(job.incident_type) }}</span>

            <!-- Requested At -->
            <span class="caption"><md-icon>schedule</md-icon> {{ createTimeString }}</span>
          </div>
        </div>
        <div class="text-right">
          <span style="font-weight: bold; color: rgb(240, 104, 55)"><Timeago :date="job.created_at" /></span>
          <!-- Job Status -->
          <div style="margin-top: 1.5em">
            <StatusBadge :status="status" :hide_badge="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import jobServices from '../../mixins/jobServices';

// Components
import StatusBadge from '../utilities/status-badge'
import Timeago from '../utilities/timeago'

import { getServicesName } from '../../mixins/servicesList';

export default {
  props: ['request', 'job', 'status'],

  components: {
    StatusBadge,
    Timeago
  },

  mixins: [jobServices],

  computed: {
    requestingDepartment () {
      return this.job.assignments[0].requesting_department.name
    },

    isMotoristJob () {
      return (this.request.motorist_id != null )
    },

    createTimeString () {
      const event = new Date(this.job.created_at);
      return event.toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    }
  },
  methods: {
    getServicesName
  }
}
</script>

<style scoped lang="scss">
  .emergency {
    color: #ef585a !important;
  }
</style>

import * as DefaultDriver from './driver_pin_default.svg'
import * as DefaultPin from './incident_pin_default.svg'
import * as DefaultPinFocused from './incident_pin_hasFocus.svg'
import * as InProgressPin from './incident_pin_inProgress.svg'
import * as InProgressPinFocused from './incident_pin_inProgress_hasFocus.svg'
import * as PendingPin from './incident_pin_pending.svg'
import * as PendingPinFocused from './incident_pin_pending_hasFocus.svg'
import * as UnassignedPin from './incident_pin_unassigned.svg'
import * as UnassignedPinFocused from './incident_pin_unassigned_hasFocus.svg'

export default {
  DefaultDriver,
  DefaultPin,
  DefaultPinFocused,
  InProgressPin,
  InProgressPinFocused,
  PendingPin,
  PendingPinFocused,
  UnassignedPin,
  UnassignedPinFocused
}

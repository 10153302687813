<template>
  <div
    class="sidebar-filters"
    :class="{
      'sidebar-filters--visible': visible,
    }"
    :base-z-index="1000"
    aria-close-label="Close sidebar filters"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="sidebar-filters__heading-wrapper">
      <span class="sidebar-filters__heading">
        Filters
      </span>
      <button class="sidebar-filters__close-btn" @click="hideSidebar">
        <md-icon>close</md-icon>
      </button>
    </div>

    <form
      class="sidebar-filters__form"
      @submit.prevent="onApply"
    >
      <fieldset class="sidebar-filters__fieldset">
        <!--
          This special Vue component render components dynamically.
          More info here: https://vuejs.org/api/built-in-special-elements.html#component
        -->
        <component
          :is="getDynamicComponent(field.type)"
          v-for="field in fieldsModel"
          :key="field.name"
          v-bind="field"
          @change="onFieldChange"
        />
      </fieldset>

      <!-- Buttons group: Apply and reset -->
      <div class="flex justify-evenly gap-4 sm:justify-center">
        <md-button
          class="md-raised md-primary"
          type="submit"
        >
          Apply
        </md-button>
        <md-button
          class="md-raised md-accent"
          @click="onClearFilters"
        >
          Clear
        </md-button>
      </div>
    </form>
  </div>
</template>

<script>
// import { useVuelidate } from '@vuelidate/core'
import cloneDeep from 'lodash.clonedeep';

// Import components
import DateRange from './fields/date-range.vue';
import Default from './fields/default.vue';
import DropdownOptions from './fields/dropdown-options.vue';
import InputText from './fields/input-text.vue';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },

  /* setup() {
    return { v$: useVuelidate() }
  }, */

  computed: {
    /**
     * Computed fields prop to add it more internal details.
     */
    fieldsModel() {
      const fieldsClone = cloneDeep(this.fields);

      return fieldsClone.map((field) => {
        // Some keys are not required, but we need to add them internal to keep consistency
        // in the algorithms.
        if (!field.type) {
          field.type = 'default';
        }

        if (field.type === 'default' && !field.matchMode) {
          field.matchMode = 'contains';
        }

        return field;
      });
    },
  },

  methods: {
    /**
     * Handle the submit event in the filters form.
     */
    async onApply() {
      // const isFormValid = await this.v$.$validate()
      const isFormValid = true;

      if (!isFormValid) return;

      this.hideSidebar();
      this.$emit('apply', this.getFilters());
    },

    /**
     * Handle the clear filters event.
     */
    onClearFilters() {
      this.clearFilters();
      this.hideSidebar();
    },

    /**
     * Update the fields on new changes.
     *
     * @param {*} fieldName
     * @param {*} value
     * @param {*} matchMode
     */
    onFieldChange(fieldName, value, matchMode = null) {
      const fields = cloneDeep(this.fields);
      const field = fields.find(({ name }) => name === fieldName);

      if (!field) return;
      if ((!field.type || field.type === 'default') && matchMode) {
        field.matchMode = matchMode;
      }

      field.value = value;
      this.$emit('update:fields', fields);
    },

    /**
     * Return the field's event handler.
     *
     * @param {*} type
     */
    getChangeHandlerEvent(type) {
      const fields = {
        date: this.onFieldChange,
        default: this.onFieldChange,
        options: this.onFieldChange,
        text: this.onFieldChange,
      };

      return fields[type];
    },

    /**
     * Return the field's component constructor.
     */
    getDynamicComponent(type) {
      const fields = {
        date: DateRange,
        default: Default,
        options: DropdownOptions,
        text: InputText,
      };

      return fields[type];
    },

    /**
     * Return an object with the filters and their values.
     */
    getFilters() {
      return this.fieldsModel.reduce((filters, field) => {
        const { name, value, matchMode, type } = field;
        filters[name] = { value, type };

        if (matchMode) filters[name].matchMode = matchMode;

        return filters;
      }, {});
    },

    /**
     * Clear/reset all filter fields.
     */
    clearFilters() {
      const fields = cloneDeep(this.fields);

      fields.forEach((field) => {
        field.value = null;

        if (!field.type || field.type === 'default') {
          field.matchMode = 'contains';
        }
      });

      this.$emit('update:fields', fields);
      this.$emit('clear');
    },

    /**
     * Hide the component
     */
    hideSidebar() {
      this.$emit('update:visible', false);
    },

    /**
     * Show the component.
     */
    showSidebar() {
      this.$emit('update:visible', true);
    },
  },
};
</script>

<style>
.sidebar-filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.25rem;
  position: fixed;
  top: 50px;
  right: -100%;
  height: calc(100% - 50px);
  z-index: 1;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: right 300ms ease-in-out;
}

@media screen and (min-width: 769px) {
  .sidebar-filters {
    max-width: 410px;
  }
}

@media screen and (min-width: 960px) {
  .sidebar-filters {
    max-width: 410px;
    height: calc(100% - 65px);
    top: 65px;
  }
}

.sidebar-filters--visible {
  right: 0;
}

.sidebar-filters__heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.sidebar-filters__heading {
  font-size: 1.75rem;
  line-height: 2.375rem;
  color: #0f3246;
  display: flex;
  align-items: center;
}

.sidebar-filters__close-btn {
  padding: 4px;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 50%;
}

.sidebar-filters__close-btn:hover {
  background-color: #f0f0f0;
}

.sidebar-filters__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 0;
  overflow: hidden;
}

.sidebar-filters__fieldset {
  overflow-y: auto;
}

.sidebar-filters.p-sidebar .p-sidebar-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1.875rem;
  padding-top: 0;
}
</style>

import axios from 'axios'

export class Here {

  constructor (params) {
    this.autocomplete_url = 'https://autocomplete.search.hereapi.com/v1/autocomplete'
    this.geocode_url = 'https://lookup.search.hereapi.com/v1/lookup'
    this.reverse_geocode_url = 'https://revgeocode.search.hereapi.com/v1/revgeocode'
    this.api_key = CSOS_RT_CONF.HERE_API_KEY
    this.axios = axios
    this.params = params
  }

  // Here.com Autocomplete Suggestions API
  // https://www.here.com/docs/bundle/geocoding-and-search-api-migration-guide/page/migration-geocoder/topics-api/autocomplete.html
  async autocomplete(query) {
    try {
      return await this.axios.get(this.autocomplete_url,
          {params: this.autocompleteParams(query)});
    } catch (error) {
      console.error('Error in autocomplete:', error);
      return {data: {items: []}}; // return empty array in case of any error
    }
  }

  autocompleteParams (query) {
    return {
      q: query,
      apiKey: this.api_key,
      at: `${this.params.latitude},${this.params.longitude}`,
      in: `countryCode:USA`
    }
  }

  // Here.com Geocoding API
  geocode (id) {
    return this.axios.get(this.geocode_url, { params: this.geocodeParams(id) })
  }

  geocodeParams (id) {
    return {
      id: id,
      apiKey: this.api_key
    }
  }

  reverseGeocode (latitude, longitude) {
    const params = this.reverseGeocodeParams(latitude, longitude)
    return this.axios.get(this.reverse_geocode_url, { params })
  }

  reverseGeocodeParams (latitude, longitude) {
    return {
      at: `${latitude},${longitude},50`,
      types: 'address',
      maxResults: 1,
      apiKey: this.api_key
    }
  }
}

<template>
  <div class="notifications">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 margin-auto">
          <p class="notifications__title">Notifications settings</p>
          <p class="notifications__description">You can control what messages you receive and how. Note that there are certain notifications that can’t be completely turned off.</p>
          <robocall-settings @show:settings="getSettings" :notifications="settings"></robocall-settings>
          <new-jobs @show:settings="getSettings" :notifications="settings"></new-jobs>
          <jobs-in-progress @show:settings="getSettings" :notifications="settings"></jobs-in-progress>
          <documents @show:settings="getSettings" :notifications="settings"></documents>
        </div>
      </div>
    </div>
    <!-- <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
      <span class="snackbar-notify__text">
        <div class="content-icon">
          <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
        </div>
        {{ this.alertMsg }}
      </span>
    </md-snackbar> -->
  </div>
</template>

<script>

import Vue from 'vue';

// Components
import RobocallSettings from './robocall-settings';
import NewJobs from './new-jobs';
import JobsInProgress from './jobs-in-progress';
import Documents from './documents';

export default {
  name: 'NotificationSettings',
  data: () => ({
    notification_settings: [],
    load: false,
    robocalls: [],
    newJobs: [],
    jobInProgress: [],
    documents: [],
    settings: [],
    errorSnack: false,
    alertMsg: '',
    position: 'left',
    isInfinity: false,
    duration: 4000,
    update: false,
    isCompleteForm: false,
    arraySettings: [],
    updateData: false
  }),
  components: {
    RobocallSettings,
    NewJobs,
    JobsInProgress,
    Documents
  },
  watch: {
    notification_settings: function (val) {
      this.settings = val
    }
  },
  methods: {
    getSettings (opts) {
      if ( opts.key === 'robocalls') {
        this.robocalls = opts.data;
      } else if ( opts.key === 'newJobs') {
        this.newJobs = opts.data;
      } else if (opts.key === 'jobInProgress') {
        this.jobInProgress = opts.data;
      } else if (opts.key === 'documents') {
        this.documents = opts.data;
      }
      this.saveChanges()
    },
    saveChanges () {
      this.notification_settings = [...this.robocalls, ...this.newJobs, ...this.jobInProgress, ...this.documents]
      this.load = true
      Vue.http.post(`/notification_settings`, { notification_settings: this.notification_settings}, [{responseType: 'json'}])
          .then(response => {
            this.load = false
            this.update = true
            this.alertMsg = 'Your changes were successfully saved.'
          }, err => {
            this.errorSnack = true
            this.load = false
            this.alertMsg = 'Something went wrong'
      });
    },
    validateButton () {
      let settingsApp = JSON.stringify([...this.robocalls, ...this.newJobs, ...this.jobInProgress, ...this.documents]),
          settingsBack = JSON.stringify(this.notification_settings)
      settingsApp === settingsBack ? this.isCompleteForm = false : this.isCompleteForm = true
    }
  },
  created () {
    Vue.http.get(`/notification_settings/my_settings`,[{responseType: 'json'}])
      .then(response => {
        console.log(response)
        this.notification_settings = response.body.notification_settings
      }, err => {
        console.log(err)
    });
  }
}
</script>
<template>
  <div>
    <div class="col-md-10 margin-auto content-table-mobile">
      <div v-if="searchFilter.length" class="search">
        <div class="search__content">
          <div class="search__content-title">
            <h1 class="search__title">{{title}}</h1>
            <button class="search__button-search" @click="openSearch"></button>
          </div>
          <div class="search__button-add">
            <a :href="buttonPath" class="header-table__add">{{ buttonText }}</a>
          </div>
        </div>
        <transition name="fade">
          <input
            placeholder="Search"
            v-model="search"
            @input="searchOnTable"
            :class="[showInput ? 'show-search ' : 'hide-search', 'header-table__search']"
          />
        </transition>
      </div>
      <div v-if="searched.length" v-for="item in searched" md-card class="box">
        <ul class="box-list card" @click="detailWrecker(item.id)">
          <li class="box-list__item">
            <p class="box-list__title">Company Name</p>
            <p class="box-list__value">{{ item["Company name"] }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Company Phone Number</p>
            <p class="box-list__value">{{ item["Company Phone Number"] }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title"># Of Drivers</p>
            <p class="box-list__value">{{item["# of drivers"]}}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Status</p>
            <p
              class="box-list__value"
              v-if="item['resend']"
            >{{item['active'] ? 'Active' : 'Inactive' }}</p>
          </li>
          <li>
            <div v-if="!item['resend']">
              <md-button
                v-if="!item.invitation_accepted "
                class="table-list-drivers__button"
                @click="resend(item)"
              >Resend Invite</md-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <md-snackbar
        :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success' ,'snackbar-notify snackbar-notify-driver']"
        :md-position="position"
        :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="alert"
        md-persistent
      >
        <span class="snackbar-notify__text">
          <i v-if="!error" class="material-icons">check</i>
          {{alertMsg}}
        </span>
      </md-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
const toLower = text => {
  return text.toString().toLowerCase();
};

const filterByValue = (items, term) => {
  if (term) {
    return items.filter(item =>
      Object.keys(item).some(
        key => item[key] && toLower(item[key]).includes(toLower(term))
      )
    );
  }

  return items;
};

export default {
  name: "TableResponsiveDriver",
  props: [
    "data",
    "title",
    "columns",
    "button-text",
    "button-path",
    "edit-button-path",
    "type-table"
  ],
  data: () => ({
    alert: false,
    error: false,
    search: null,
    searched: [],
    searchFilter: [],
    showInput: false,
    alertMsg: '',
    position: 'left',
    isInfinity: false,
    duration: 4000
  }),
  methods: {
    searchOnTable() {
      this.searched = filterByValue(this.data, this.search);
    },
    openSearch() {
      this.showInput = !this.showInput;
    },
    resend(item) {
      http: Vue.http.post(`/organizations/${item.id}/resend_invitation`).then(
        response => {
          if (response.ok) {
            this.error = false
            this.alert = true
            this.alertMsg = `We've sent an invite to the wrecker`;
          }
        },
        err => {
          this.error = true;
          this.alert = true;
          if(err.status === 422) {
            this.alertMsg = err.body.msg
          } else {
            this.alertMsg = "Something went wrong, please try again";
          }
        }
      );
    },
    detailWrecker (id) {
      window.location.href = `/wreckers/${id}` 
    },
    done() {
      location.reload(true);
    }
  },
  created() {
    this.searched = this.data.reverse();
    this.searchFilter = this.data.reverse();
    console.log(this.searched)
  }
};
</script>

<style lang="scss" scoped>
/* .md-field {
    max-width: 300px;
  } */

.md-table-head-label {
  text-transform: capitalize;
}
</style>

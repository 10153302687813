<template>
  <div>
    <div class="col-md-10 margin-auto content-table-mobile">
      <div v-if="searchFilter.length" class="search">
        <div class="search__content">
          <div class="search__content-title">
            <h1 class="search__title">{{title}}</h1>
            <button class="search__button-search" @click="openSearch"></button>
          </div>
          <div class="search__button-add">
            <a :href="buttonPath" class="header-table__add">{{ buttonText }}</a>
          </div>
        </div>
        <transition name="fade">
          <input
            placeholder="Search"
            v-model="search"
            @input="searchOnTable"
            :class="[showInput ? 'show-search ' : 'hide-search', 'header-table__search']"
          />
        </transition>
      </div>
      <div v-if="searched.length" v-for="item in searched" md-card class="box">
        <ul :class="[item['Status'] === 'De-active' ? 'disabled' : '', 'box-list card']">
          <a :href="editButtonPath+'/'+item['id']+'/edit'" class="box-list__button-edit">
            <i class="material-icons">edit</i>
          </a>
          <li class="box-list__item">
            <p class="box-list__title">First Name</p>
            <p class="box-list__value">{{ item['First name'] }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Last Name</p>
            <p class="box-list__value">{{ item['Last name'] }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Phone number</p>
            <p class="box-list__value">{{ item['Phone number'] }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Status</p>
            <p class="box-list__value">{{ item['Status'] }}</p>
          </li>
          <li v-if="!item['resend']" class="mt-10">
            <md-button
              v-if="!item.invitation_accepted "
              class="table-list-drivers__button"
              @click="resend(item, 'users')"
            >Resend Invite</md-button>
          </li>
        </ul>
      </div>
    </div>
     <md-snackbar
        :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success' ,'snackbar-notify snackbar-notify-driver']"
        :md-position="position"
        :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="alert"
        md-persistent
      >
        <span class="snackbar-notify__text">
          <i v-if="!error" class="material-icons">check</i>
          {{alertMsg}} 
        </span>
      </md-snackbar>
  </div>
</template>

<script>
import Vue from "vue";

const toLower = text => {
  return text.toString().toLowerCase();
};

const filterByValue = (items, term) => {
  if (term) {
    return items.filter(item =>
      Object.keys(item).some(
        key => item[key] && toLower(item[key]).includes(toLower(term))
      )
    );
  }

  return items;
};

export default {
  name: "TableResponsiveDriver",
  props: [
    "data",
    "title",
    "columns",
    "button-text",
    "button-path",
    "edit-button-path",
    "type-table"
  ],
  data: () => ({
    search: null,
    searched: [],
    searchFilter: [],
    position: 'left',
    isInfinity: false,
    duration: 4000,
    alertMsg: '',
    alert: false,
    inviteSend: false,
    error: false,
    showInput: false
  }),
  methods: {
    searchOnTable() {
      this.searched = filterByValue(this.data, this.search);
    },
    openSearch() {
      this.showInput = !this.showInput;
    },
    resend(item, target) {
      http: Vue.http.post(`/${target}/${item.id}/resend_invitation`).then(
        response => {
          if (response.ok) {
            this.error = false
            this.alert = true
            this.alertMsg = `We've resent an invite to the user.`;
          }
        },
        err => {
          this.error = true;
          this.alert = true;
          if(err.status === 422) {
            this.alertMsg = err.body.msg
          } else {
            this.alertMsg = "Something went wrong, please try again";
          }
        }
      );
    }
  },
  created() {
    this.searched = this.data;
    this.searchFilter = this.data;
  }
};
</script>

<style lang="scss" scoped>
/* .md-field {
    max-width: 300px;
  } */

.md-table-head-label {
  text-transform: capitalize;
}
</style>

export default function channelConstructor (...options) {
  return new DriversChannel(...options)
}

export class DriversChannel {

  constructor ({ store }, { driver }) {
    this.channel_name_base = "drivers_channel_"
    this.driver_id = driver.id
    this.store = store
  }

  // Called on connection
  connected () {
    return () => {}
  }

  // Called on disconnection
  disconnected () {
    return () => {}
  }

  // Called when data is received
  received () {
    // DriversChannel broadcasts updated drivers to the dispatch dashboard
    // Push new driver to Vuex store, let Vue do the rest
    return ({ data }) => {
      this.store.dispatch('drivers/CABLE_updateDriver', data)
    }
  }

  key () {
    return this.channel_name_base + this.driver_id
  }

  channel () {
    return {
      channel: "DriversChannel",
      id: this.driver_id
    }
  }

  mixins () {
    return {
      connected: this.connected(),
      disconnected: this.disconnected(),
      received: this.received()
    }
  }
}

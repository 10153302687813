<template>
  <div class="received-quote-request-list">
    <ReceivedQuoteRequestDetail v-for="request in activeReceivedRequests"
      :request="request"
      :key="request.id"
      @select-request="$emit('select-request', $event)"
    >
    </ReceivedQuoteRequestDetail>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ReceivedQuoteRequestDetail from "./received-quote-request-detail"

export default {
  components: {
    ReceivedQuoteRequestDetail
  },

  computed: {
    ...mapGetters('quote_requests', [
      'activeReceivedRequests'
    ])
  },

  mounted () {
    console.log('this.activeReceivedRequests: ', this.activeReceivedRequests);
  },
}
</script>

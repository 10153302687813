import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/job_assignments{/id}"
const customMethods = {
  availableDrivers: { method: "GET", url: `/api/v1/job_assignments{/id}/driver_locations` },
  sentAssignments: { method: "GET", url: `${path}/` },
  receivedAssignments: { method: "GET", url: `${path}/` },
  cancel: { method: "PUT", url: `${path}/cancel` },
  complete: { method: "PUT", url: `${path}/complete` },
  decline: { method: "PUT", url: `${path}/decline` },
  reassign: { method: "PUT", url: `${path}/reassign` }
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

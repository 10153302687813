<template>
  <div class="vehicle-details">
    <div class="section-title">
      <h5>Vehicle information</h5>
    </div>
    <div class="incident-vehicle-counter">
      <div class="row">
        <div class="col-md-12">
          <p class="caption" v-if="vehicles.length == 0">No vehicle information added to incident</p>
          <input type="hidden" v-model.number="vehicles.length" />
        </div>
      </div>
    </div>
    <div class="vehicles-list" v-if="vehicles.length > 0">
      <div class="incident-vehicle row border-bottom mb-2 pb-2" v-for="(vehicle, index) in vehicles" :key="index">
        <div class="col-9">
          <VehicleDetail :vehicle="vehicle"></VehicleDetail>
        </div>
        <div class="col-3 text-left">
          <md-button class="md-icon-button btnIcon" @click="editVehicle(index)">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button class="md-icon-button btnIcon" @click="deleteVehicle(index)">
            <md-icon>delete</md-icon>
          </md-button>
        </div>
      </div>
    </div>
    <md-button class="btn btn-secondary compact btnAddVehicle"
               @click="launchAddVehicle()"
               ref="btnAddVehicle"
               tabindex="1">
      <md-icon>add</md-icon>
      Add vehicle
    </md-button>
    <div class="add-vehicle" id="subformAddVehicle">
      <md-dialog :md-active.sync="adding">
        <md-dialog-title><h5>Add vehicle</h5></md-dialog-title>
        <md-dialog-content>
          <h6>Vehicle information</h6>

          <div class="row">
            <div class="col-md-6">
              <!-- Vehicle Make: Autocomplete -->
              <md-autocomplete
                v-model="form_vehicle.make"
                :md-options="make_options"
                @md-changed="getMakeAutocompleteOptions"
                :md-open-on-focus="false"
                tabindex="20"
                ref="vehicleMake"
                md-dense
                v-if="features && features.make_model_autocomplete == true">
                <label>Make</label>
              </md-autocomplete>
              <!-- Vehicle Make: Static -->
              <md-field v-if="features && features.make_model_autocomplete == false">
                <label>Make</label>
                <md-input v-model="form_vehicle.make" tabindex="20" ref="vehicleMake"></md-input>
              </md-field>

              <!-- Vehicle Plate -->
              <md-field>
                <label>Plate (optional)</label>
                <md-input v-model="form_vehicle.plate" tabindex="22"></md-input>
              </md-field>
            </div>

            <div class="col-md-6">
              <!-- Vehicle Model: Autocomplete -->
              <md-autocomplete
                v-model="form_vehicle.model"
                :md-options="model_options"
                @md-changed="getModelAutocompleteOptions"
                :md-open-on-focus="false"
                tabindex="21"
                md-dense
                v-if="features && features.make_model_autocomplete == true">
                <label>Model</label>
              </md-autocomplete>
              <!-- Vehicle Model: Static -->
              <md-field v-if="features && features.make_model_autocomplete == false">
                <label>Model</label>
                <md-input v-model="form_vehicle.model" tabindex="21"></md-input>
              </md-field>

              <!-- Vehicle Color -->
              <md-autocomplete v-model="form_vehicle.color" :md-options="color_opts" tabindex="23" :md-open-on-focus="false" md-dense>
                <label>Color (optional)</label>
              </md-autocomplete>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <md-field>
                <label>Insurance (optional)</label>
                <md-input v-model="form_vehicle.insurance" tabindex="24"></md-input>
              </md-field>
            </div>

            <div class="col-md-6 compactChex">
              <md-checkbox v-model="form_vehicle.drivable">Rollover</md-checkbox>
              <md-checkbox v-model="form_vehicle.blocking">Vehicle is blocking</md-checkbox>
            </div>
          </div>

          <br>
          <br>
          <h6>Assign Service Provider</h6>
          <p class="impound-preferred-note" v-if="this.features && !this.features.open_bid">
            <span v-if="features && features.rotation_list == false">An impound wrecker or a preferred wrecker is required.</span>
            <span v-if="features && features.rotation_list == true">Provider selected via rotation list will be chosen upon order submission.</span>
          </p>
          <!-- Assign by Rotation List -->
          <div class="compactChex" v-if="features && features.rotation_list == true">
            <md-checkbox
              v-model="form_vehicle.assign_by_rotation"
              :disabled="rotationAssignDisabled">
              Assign by rotation
              <span class="impound-towers-not-available" v-if="!this.selected_zone">
                (Please select a Zone)
              </span>
              <span class="impound-towers-not-available" v-if="this.selected_zone && this.zoneTowers.length == 0">
                (Selected Zone must have at least 1 active tower)
              </span>
            </md-checkbox>
          </div>

          <!-- Assign to Preferred Tower -->
          <div class="compactChex">
            <md-checkbox v-model="form_vehicle.preferred" :disabled="form_vehicle.assign_by_rotation == true || form_vehicle.impound == true" @change="unsetPreferredTower">
              Has towing company preference
            </md-checkbox>
            <div v-if="form_vehicle.preferred == true && form_vehicle.impound == false">
              <md-field>
                <label>Select towing company...</label>
                <md-select v-model="form_vehicle.preferred_tower_id" md-dense>
                  <!-- zone selected: zone towers first, non-zone towers second -->
                  <md-optgroup v-if="selected_zone" label="Zone Towers">
                    <md-option v-for="tower in zoneTowers" :value="tower.id" :key="tower.id">
                      {{ tower.name }}
                    </md-option>
                  </md-optgroup>
                  <md-optgroup v-if="selected_zone" label="Other Towers">
                    <md-option v-for="tower in otherTowers" :value="tower.id" :key="tower.id">
                      {{ tower.name }}
                    </md-option>
                  </md-optgroup>
                  <!-- no zone selected: show all towers -->
                  <md-optgroup v-if="!selected_zone" label="All Towers">
                    <md-option v-for="tower in towers" :value="tower.id" :key="tower.id">
                      {{ tower.name }}
                    </md-option>
                  </md-optgroup>
                </md-select>
              </md-field>
            </div>
          </div>

          <!-- Assign to Impound Tower -->
          <div class="compactChex">
            <md-checkbox class="preferred-impound-checkbox" v-model="form_vehicle.impound" :disabled="form_vehicle.assign_by_rotation == true || form_vehicle.preferred == true || impoundTowers.length === 0" @change="unsetPreferredTower">
              Send to impound
              <span class="impound-towers-not-available" v-if="impoundTowers.length === 0">
                (Impound towers not available)
              </span>
            </md-checkbox>

            <div v-if="form_vehicle.impound == true && form_vehicle.preferred == false">
              <md-field>
                <label>Select impound towing company...</label>
                <md-select v-model="form_vehicle.impound_tower_id" md-dense>
                  <md-option v-for="tower in impoundTowers" :value="tower.id" :key="tower.id">
                    {{ tower.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <br>

          <h6>Service Requirements</h6>

          <!-- Release requires authorization -->
          <div class="compactChex">
            <md-checkbox v-model="form_vehicle.release">Release requires authorization</md-checkbox>
          </div>

          <!-- Requires flat bed -->
          <div class="compactChex">
            <md-checkbox v-model="form_vehicle.flatbed">Requires flat bed</md-checkbox>
          </div>

          <!-- Has tow destination -->
          <div class="compactChex">
            <md-checkbox v-model="form_vehicle.destination" @change="destinationToggle()">Has specific tow destination</md-checkbox>
            <div v-if="form_vehicle.destination == true">
              <LocationAutocomplete v-bind:selection.sync="form_vehicle.destination_address" @update:selection="setDestination"></LocationAutocomplete>
            </div>
          </div>

          <br>
          <h6>Additional Remark (optional)</h6>
          <md-field class="heightSm">
            <label>e.g. May need winch out</label>
            <md-textarea v-model="form_vehicle.comment" tabindex="25"></md-textarea>
          </md-field>
        </md-dialog-content>
        <div class="footerBar px-3 py-2">
          <md-button class="btn btn-primary" v-if="!editing" @click="addVehicle" tabindex="26" :disabled="!isValidForm">Add</md-button>
          <md-button class="btn btn-primary" v-if="editing" @click="updateVehicle" tabindex="26" :disabled="!isValidForm">Update</md-button>
          <md-button class="btn btn-secondary" @click="resetForm" tabindex="27">Cancel</md-button>
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import errorClass from '../../../../../javascripts/error_class'

// Components
import LocationAutocomplete from '../map-forms/location-autocomplete'
import VehicleDetail from '../../vehicles/vehicle-detail'
export default {
  props: ['vehicles', 'selected_zone'],

  inject: ['$v'],

  components: {
    LocationAutocomplete,
    VehicleDetail
  },

  data () {
    return {
      vehicle_array: this.vehicles,
      number: null,
      adding: false,
      editing: false,
      update_index: null,
      color_opts: ['Black', 'Red', 'Green', 'Blue', 'Gray', 'Silver', 'Gold'],
      // CSOS-2062 Use server side runtime config
      make_base_URL: CSOS_RT_CONF.TELEMETRY_URL + '/vehicles/make?',
      model_base_URL: CSOS_RT_CONF.TELEMETRY_URL + '/vehicles/model?',
      make_results: null,
      model_results: null,
      make_options: [],
      model_options: [],
      form_vehicle: {
        // incident vehicle traits
        make: null,
        model: null,
        plate: null,
        color: null,
        insurance: null,
        blocking: false,
        drivable: false,
        // service provider assignment
        impound: false,
        impound_tower_id: null,
        release: false,
        preferred: false,
        preferred_tower_id: null,
        assign_by_rotation: false,
        // service completion requirements
        release: false,
        flatbed: false,
        destination: false,
        destination_address: null,
        requested_destination: null,
        comment: null
      },
      isValidVehicle: true
    }
  },

  computed: {
    ...mapState('organizations', {
      towers: state => state.towers
    }),

    ...mapState('organizations', {
      current_organization: state => state.current
    }),

    ...mapState('features', {
      features: state => state.features
    }),

    ...mapGetters('organizations', [
      'impoundTowers'
    ]),

    zoneTowers () {
      if (this.selected_zone == null) return []
      const zone = this.current_organization.zones.find(zone => zone.id == this.selected_zone);
      return zone.towers.filter(tower => tower.active);
    },

    otherTowers () {
      if (this.selected_zone == null) return []
      const zone_tower_ids = this.zoneTowers.map(zone_tower => zone_tower.id);
      return this.towers.filter(tower => !zone_tower_ids.includes(tower.id));
    },

    rotationAssignDisabled () {
      // conditions
      const directSelected = this.form_vehicle.preferred == true || this.form_vehicle.impound == true
      const zoneNotSelected = this.selected_zone == null
      const zoneEmpty = this.zoneTowers.length == 0

      return directSelected || zoneNotSelected || zoneEmpty
    },

    makeParams () {
      return {
        query: this.form_vehicle.make,
        // CSOS-2062 Use server side runtime config
        token: CSOS_RT_CONF.TELEMETRY_TOKEN
      }
    },
    modelParams () {
      let params = {
        query: this.form_vehicle.model,
        // CSOS-2062 Use server side runtime config
        token: CSOS_RT_CONF.TELEMETRY_TOKEN
      }

      // Model results can be focused to the specific Vehicle Make
      if (this.form_vehicle.make && this.form_vehicle.make.length > 0) {
        params.make = this.form_vehicle.make.toUpperCase()
      }

      return params
    },
    makeURL () {
      return this.make_base_URL + new URLSearchParams(this.makeParams).toString()
    },
    modelURL () {
      return this.model_base_URL + new URLSearchParams(this.modelParams).toString()
    },

    /**
     * Validate the fields of the vehicles form.
     */
    isValidForm() {
      const form = this.form_vehicle;

      // Laying this out deliberately for my own sanity
      // There's probably a simpler way to do this, but here's a
      // maximalist approach to building this out:

      // if open_bid is on
      var open_bid = this.features.open_bid;
      var rotation_list = this.features.rotation_list;

      // if both make and model have values
      var make_and_model = form.make && form.model;

      // Service Provider Assignment: If service provider assigned by rotation list
      var assign_by_rotation = form.assign_by_rotation;

      // Service Provider Assignment: If a direct type is checked, is a selection made
      var selected_preferred = form.preferred ? form.preferred_tower_id !== null : true;
      var selected_impound = form.impound ? form.impound_tower_id !== null : true;

      // Service Provider Assignment: A selection is made and checked
      var preferred = form.preferred && form.preferred_tower_id;
      var impound = form.impound && form.impound_tower_id;

      // Form validation changes based on what options are available for Service Provider assignment
      if (open_bid) {
        // Require Make & Model and Service Provider selection if associated assignment method option is checked
        return make_and_model && selected_preferred && selected_impound
      } else if (rotation_list) {
        return make_and_model && (preferred || impound || assign_by_rotation)
      } else {
        // Else require a Preferred or Impound choice
        return make_and_model && (preferred || impound)
      }
    }
  },

  methods: {
    launchAddVehicle(){
      this.adding=true
      setTimeout(this.focusVehicleMake, 150)
      setTimeout(this.fixTabindex, 150)
    },
    focusVehicleMake () {
      // Focus placement methodology changes depending on whether element is autocomplete or regular input
      if (this.features && this.features.make_model_autocomplete) {
        this.$refs.vehicleMake.$el.querySelector('input').focus();
      } else {
        this.$refs.vehicleMake.$el.focus()
      }
    },
    focusAddBtn(){
      setTimeout(this.$refs.btnAddVehicle.$el.focus(), 50)
    },
    resetForm () {
      this.adding = false
      this.editing = false
      this.isValidVehicle = true
      this.form_vehicle = {
        make: null,
        model: null,
        plate: null,
        color: null,
        insurance: null,
        impound: false,
        impound_tower_id: null,
        release: false,
        blocking: false,
        drivable: false,
        preferred: false,
        preferred_tower_id: null,
        flatbed: false,
        destination: false,
        destination_address: null,
        requested_destination: null,
        comment: null,
        assign_by_rotation: false
      }

      this.focusAddBtn();
    },
    addVehicle () {
      if(this.form_vehicle.impound == true && !this.form_vehicle.impound_tower_id ||
         this.form_vehicle.preferred == true && !this.form_vehicle.preferred_tower_id ||
         this.form_vehicle.destination == true && !this.form_vehicle.requested_destination) {
            this.isValidVehicle = false
      }
      else {
        this.vehicle_array.push(this.form_vehicle)
        this.$emit('update:vehicles', this.vehicle_array)
        this.resetForm()
        this.focusAddBtn()
      }
    },
    editVehicle (index) {
      this.form_vehicle = this.vehicles[index]
      this.index = index
      this.editing = true
      this.adding = true
    },
    updateVehicle () {
      this.vehicle_array[this.update_index] = this.form_vehicle
      this.$emit('update:vehicles', this.vehicle_array)
      this.resetForm()
      this.focusAddBtn()
    },
    deleteVehicle (index) {
      this.vehicle_array.splice(index, 1)
      this.$emit('update:vehicles', this.vehicle_array)
      this.focusAddBtn()
    },
    setDestination () {
      const safeState = (this.form_vehicle.destination_address.address.stateCode != undefined) ? this.form_vehicle.destination_address.address.stateCode : this.form_vehicle.destination_address.address.state
      this.form_vehicle.requested_destination = {
        line_1: this.form_vehicle.destination_address.address_primary,
        line_2: this.form_vehicle.destination_address.address_secondary,
        district: this.form_vehicle.destination_address.address.district,
        city: this.form_vehicle.destination_address.address.city,
        state: safeState,
        zip: this.form_vehicle.destination_address.address.postalCode,
        country: this.form_vehicle.destination_address.address.Country,
        location_type: this.form_vehicle.destination_address.address.resultType,
        latitude: this.form_vehicle.destination_address.position.lat,
        longitude: this.form_vehicle.destination_address.position.lng,
      }
    },
    destinationToggle() {
      if(this.form_vehicle.destination == false){
        this.form_vehicle.requested_destination = null
      }
    },
    unsetPreferredTower() {
      if (!this.form_vehicle.preferred) {
        this.form_vehicle.preferred_tower_id = null
      } else if (!this.form_vehicle.impound) {
        this.form_vehicle.impound_tower_id = null
      }
    },
    fixTabindex() {
      let els = document.querySelectorAll('div[tabindex]')
      let i = 0
      for (i = 0; i < els.length; i++) {
        els[i].removeAttribute('tabindex')
      }
    },
    getMakeAutocompleteOptions () {
      this.getMakeSuggestions()
    },
    getMakeSuggestions () {
      fetch(this.makeURL)
        .then(response => response.json())
        .then(data => {
          this.make_results = data
          this.make_options = data.map(function(datum) {
            return datum['name']
          })
        })
    },
    getModelAutocompleteOptions () {
      this.getModelSuggestions()
    },
    getModelSuggestions () {
      fetch(this.modelURL)
        .then(response => response.json())
        .then(data => {
          this.model_results = data
          this.model_options = data.map(function(datum) {
            return datum['name']
          })
        })
    }
  },

  created () {
    this.getValidationClass = errorClass.getValidationClass;
  }
}
</script>

<style scoped lang="scss">
  .impound-towers-not-available {
    color: #F06837;
    opacity: 0.6;
  }

  .preferred-impound-checkbox {
    .md-checkbox-label {
      max-height: 30px;
      height: auto;
    }
  }

  .impound-preferred-note {
    color: #F06837;
    font-size: 14px;
  }

  .rotation-validation {
    font-size: .8em;
    margin-bottom: 1em;
    margin-top: 0em;
    font-weight: bold;
    color: #e91e63;
  }
</style>

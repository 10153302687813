<template>
  <md-card class="card account-owner card-wrecker-detail card-box-profile card-box offset-card section password-card">
    <h2 class="title-card">Password</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <div v-if="!edit_info" class="form-group col-md-6 padding-left-no">
      <div class="field">
        <label class="form__label"></label>
        <div class="form__field"><span class="passsword-caret"></span></div>
      </div>
    </div>
    <transition name="slide">
      <div class="card-box__content" v-if="openBox">
        <div class="card-box__content-form js-box-content-form">
          <div class="row">
            <div v-if="edit_info" class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="[err ? 'md-invalid' : '', getValidationClass('create','current_password')]">
                  <label class="form__label">Current password</label>
                  <md-input v-model="create.current_password" class="form-control" type="password"></md-input>
                  <span class="md-error" v-if="!$v.create.password.required">This field is required</span>
                  <span class="md-error" v-if="err">Incorrect password</span>
                </md-field>
              </div>
            </div>
          </div>
           <div v-if="edit_info" class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <md-field :class="getValidationClass('create','password')">
                    <label class="form__label">New password</label>
                    <md-input v-model="create.password" class="form-control" type="password" v-on:keyup="validationField('password')"></md-input>
                    <span class="md-error" v-if="!$v.create.password.required">This field is required</span>
                    <span class="md-error" v-else-if="!$v.create.password.minLength">Password must be at least 8 characters</span>
                  </md-field>
                </div>
              </div>
          </div>
          <div v-if="edit_info" class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass('create','password_confirmation')">
                  <label class="form__label">Confirm new password</label>
                  <md-input v-model="create.password_confirmation" class="form-control" type="password" v-on:keyup="validationField('passwordConfirmation')"></md-input>
                  <span
                    class="md-error"
                    v-if="!$v.create.password_confirmation.required"
                  >This field is required</span>
                <span
                  class="md-error"
                  v-else="!$v.create.password_confirmation.sameAsPassword"
                >Passwords don't match.</span>
                </md-field>
              </div>
            </div>
          </div>
         
          <transition name="slide">
            <div v-if="edit_info" class="row">  
              <div class="card-box__content-actions">
                <md-button :class="[!isCompletedForm || deactived_button ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
                <md-button class="md-accent card-box__button-cancel" @click="cancel">Cancel</md-button>
                <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </md-card>
</template>

<script>
import Vue from 'vue';
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: 'password',
  data: () => ({
    create: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    edit_info: false,
    openBox: false,
    update: false,
    deactived_button: false,
    up_to_date: false,
    err: false
  }),
  validations: {
    create: {
      current_password: {
        required
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  computed: {
    isCompletedForm () {
      return this.create.current_password && this.$v.create.password.required && this.$v.create.password_confirmation.sameAsPassword ? true : false
    }
  },
  methods: {
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    validationField (target) {
      if (target === 'password') {
        this.$v.create.password.$touch()
      } else {
        this.$v.create.password_confirmation.$touch()
      }
    },
    editInfo () {
      this.cancel()
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit () {
      this.update = true;
      this.deactived_button = true;
      Vue.http.put(`/users/update_my_password`, this.create ,[{responseType: 'json'}])
        .then(response => {
            this.update = false;
            this.deactived_button = false;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your password change has been successful.', error: false})
            this.up_to_date = true
            this.edit_info = false

           setTimeout(() => {  
            window.location.reload();
          },1000)
        }, err => {
          this.err = true;
          setTimeout(() => {
            this.update = false;
            this.deactived_button = false;
            this.up_to_date = false
          },1000)
      });
    },
    cancel () {
      this.edit_info = !this.edit_info;
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  } 
}
</script>

<style lang="scss" scoped>
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
</style>
<template>
  <div class="organization-banner js-organization-banner">
    <div class="organization-banner__content">
      <div class="organization-banner__content-img">
        <div class="organization-banner__img">
         <img src="../../../../images/towco-img.png" alt="">
        </div>
      </div>
      <div class="organization-banner__info">
        <h2 class="organization-banner__company-name">
          {{name}}
          <span :class="[activeCompany ? 'badge--active' : 'badge--deactived', 'badge']">{{ activeCompany ? 'Active' : 'De-activated' }}</span>
        </h2>
        <ul class="organization-banner__address col-md-4">
          <li class="organization-banner__address-item">
            <img src="../../../../images/pin.svg" alt="">
            <p>Company address</p>
            <span>{{ address }}</span>
          </li>
          <li class="organization-banner__address-item">
            <img src="../../../../images/local-phone.svg" alt="">
            <p>Company phone number</p>
            <span>{{ formatPhoneNumber(contact_phone) }}</span>
          </li>
        </ul>
        <md-switch @click="change" v-model="activeCompany" :class="[activeCompany === 'true' ? 'md-checked' : 'md', 'driver-switch']">{{activeCompany ? 'Active company' : 'De-activated company'}}</md-switch>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'OrganizationBanner',
  props: ['name','address','contact_phone','active', 'id'],
  data: () => ({
    activeCompany: false
  }),
  methods: {
    change () {
      this.activeCompany = !this.activeCompany
    },
    formatPhoneNumber (str) {
      const cleaned = ("" + str).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
      return null;
    },
    changeStatus() {
      Vue.http.put(`/wreckers/${this.id}/update_active`,{active: this.activeCompany},[{responseType: 'json'}])
        .then(response => {
          //console.log(response)
        })
        
    }
  },
  mounted() {
    this.activeCompany = this.active;
  },
  updated () {
    this.changeStatus()
  }
}
</script>
<template>
  <md-card class="card account-user card-wrecker-detail card-box-profile card-box offset-card section js-content-box-bussines service-area" id="service-area-card">
    <h2 class="title-card">Service area</h2>
    <div class="card-box__content" v-if="openBox">
      <div class="card-box__content-form js-box-content-form">
        <div class="row">
          <div :class="[radioOption === 'radius' ? 'col-md-6' : 'col-md-12']">
            <p class="description-card">{{organization.name}} will receive jobs within the following area:</p>
            <div class="field margin-bottom-no">
              <div v-if="radioOption === 'radius'" class="form__field">{{ mileSelected }} miles (from wrecker location)</div>
              <div v-else class="form__field value-none">Entire Ottawa county</div>
            </div>
          </div>
          <div class="col-md-6" v-if="radioOption === 'radius'">
            <div class="service-area__settings-content">
              <div class="service-area__wrapper-map--settings service-area__wrapper-map">
                <div v-if="radioOption === 'radius'" class="map-container service-area__map-preview service-area__map-preview--settings">
                  <gmap-map :center="positionMarker" :zoom="8" :options="optionsMap">
                    <gmap-marker :icon="markerOptions" :position="positionMarker" :draggable="false"></gmap-marker>
                    <gmap-circle :center="positionMarker" :draggable="false" :editable="false" :radius="setMapRadius(mileSelected)" 
                    :options="{
                      strokeColor: '#f06837',
                      strokeOpacity: 0.8,
                      strokeWeight: 3,
                      fillColor: '#f06837',
                      fillOpacity: 0.25
                    }">
                    </gmap-circle>
                  </gmap-map>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </md-card>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'ServiceArea',
  props: ['serviceArea','organization'],
  data: () => ({
    openBox: true,
    update: false,
    openInfo: true,
    deactivedButton: false,
    radioOption: 'radius',
    userEdit: false,
    dialog: {
      showDialog: false
    },
    snackbar: {
      update: false,
      alertMsg: 'Move map to adjust.'
    },
    //map
    positionMarker: {
      lat: 42.9690668, lng: -85.9226895
    },
    positionMarkerRestore: {
      lat: 42.9690668, lng: -85.9226895
    },
    markerOptions: {
      url: 'https://res.cloudinary.com/dpxefvdot/image/upload/v1583352735/pin_1_fisnj2.png'
    },
    centerMap: {
      lat: 42.9690668, lng: -85.9226895
    },
    centerMapPreview: {
      lat: 42.9690668, lng: -85.9226895
    },
    coordinates: null,
    radiusCircle: 40,
    optionsMap: {
      disableDefaultUi: false,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
    mileSelected: 40,
    milesText: '40 miles',
    milesList: [
      {
        miles: 20,
        text: '20 miles'
      },
      {
        miles: 30,
        text: '30 miles'
      },
      {
        miles: 40,
        text: '40 miles'
      }
    ]
  }),
  methods: {
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    },
    setMapRadius (mile) {
      // g-map component expects distances in meters
      return this.milesToMeters(mile)
    },
  },
  created () {
    if(this.serviceArea !== null) {
      this.positionMarker.lat = this.serviceArea.latitude ? this.serviceArea.latitude : 42.9690668;
      this.positionMarker.lng = this.serviceArea.longitude ? this.serviceArea.longitude : -85.9226895;
      this.positionMarkerRestore.lat = this.serviceArea.latitude ? this.serviceArea.latitude : 42.9690668;
      this.positionMarkerRestore.lng = this.serviceArea.longitude ? this.serviceArea.longitude : -85.9226895;
      this.centerMap.lat = this.serviceArea.latitude ? this.serviceArea.latitude : 42.9690668;
      this.centerMap.lng = this.serviceArea.longitude ? this.serviceArea.longitude : -85.9226895;
      this.radiusCircle = this.serviceArea.miles ? this.serviceArea.miles : 40;
      this.mileSelected = this.serviceArea.miles ? this.serviceArea.miles : 40;
      this.radioOption = this.serviceArea.full_area ? 'ottawa' : 'radius';  
    } else {
      this.radioOption = 'ottawa'
    }
  },
  mounted () {
     window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>
<template>
  <div class="motorist-card motorist__details">
    <h2 class="motorist-card__title">Incident details</h2>
    <div class="motorist-card__info">   
      <div class="row">
        <div class="col-md-6">
          <div class="motorist-card__item">
            <p class="motorist-card__subtitle">Location</p>
            <p class="motorist-card__text">{{ data.address }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="motorist-card__item police-remark" v-if="data.details">
            <p class="motorist-card__subtitle">Police remarks</p>
            <p class="motorist-card__text">{{ data.details }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
 name: 'incidentDetails',
 props: ['data']
}
</script>
<template>
  <div>
    <div class="home-screen-container col-lg-9 col-md-10 col-xs-10 col-sm-10 margin-auto">
      <div class="md-layout md-gutter md-alignment-center">
        <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <img src="../../../../images/curbside-sos-dashboard.png" alt="Computer monitor and smart phone with Curbside SOS on screen" />
        </div>
        <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100">
          <h2 class="section-title">Take back <span class="section-title-highlight">control</span> with Curbside SOS</h2>
          <ul class="section-list">
            <li>
              Jobs at your rates. No contracted, low rates.
            </li>
            <li>
              Quote the jobs that make sense to you.
            </li>
            <li>
              Payment is pre-authorized by the motorist before you’re even awarded the job.
            </li>
          </ul>
          <blockButton
            class="md-raised md-accent full-btn btn-home md-small-size-100"
            :fit="true"
            @next="$emit('next')"
            title="Sign up now"
            color="#f06837"
          />
        </div>
      </div>

      <div>
        <h2 class="section-title text-center">Here's how it works</h2>
        <div class="iframe-container">
          <iframe 
            src="https://www.youtube.com/embed/jFosO_gOrY0?si=Com0Q4wOSyGGrrh8" 
            title="Curbside SOS: Motorist and service provider experience" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
          </iframe>
        </div>
      </div>

      <div class="md-layout md-gutter md-alignment-center">
        <div class="md-layout-item md-medium-size-100 md-gutter">
          <h2 class="section-title">Signing up doesn’t cost a penny</h2>
          <ul class="section-list">
            <li>
              Service Provider Fees only apply when <b>you get real jobs and paid your rates</b>.
            </li>
            <li>
              You cover the 3% Processing Fee and a Service Provider Fee of 10% to Curbside SOS. <b>That simple</b>.
            </li>
            <li>
              Quickly check if you comply with <b>insurance requirements</b> using the table below:
            </li>
          </ul>
          <div class="services-table-container">
            <table class="services-table">
              <tr>
                <th>Services Provided</th>
                <th>Commercial Auto & General Liability</th>
                <th>On-hook</th>
                <th>Garage Keepers</th>
              </tr>
              <tr>
                <td>Towing</td>
                <td>$1,000,000</td>
                <td>$100,000</td>
                <td>$100,000</td>
              </tr>
              <tr>
                <td>Roadside Only</td>
                <td>$1,000,000</td>
                <td>Not applicable</td>
                <td>Not applicable</td>
              </tr>
            </table>
          </div>
          <blockButton
            class="md-raised md-accent full-btn btn-home"
            :fit="true"
            @next="$emit('next')"
            title="Sign up now"
            color="#f06837"
          />
        </div>
        <div class="md-layout-item md-medium-size-100 md-small-size-100">
          <img src="../../../../images/curbside-sos-register.png" alt="Person on the phone at a computer" />
        </div>
      </div>
      <div>
        <div class="sign-in-container">
          <a href="/sign-in" style="margin-top: 10px;">
            Already have an account? Sign in
          </a>
        </div>
        <div class="onboarding__legal-text">
          <p>
            By registering with Curbside SOS Inc. you agree to our <a target="_blank" href="https://www.curbsidesos.com/terms/">terms of use</a> and <a target="_blank" href="https://www.curbsidesos.com/privacy-policy/">privacy policy</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blockButton from './block-button'

export default {
  components: {
    blockButton
  }
}
</script>

<style scoped lang="scss">
.sign-in-container {
  display: flex;
  text-align: center;
  justify-content: center;
}

.home-screen-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;
  max-width: 1230px;
}

.btn-home {
  margin: 30px 0;

  button {
    padding: 10px;

    @media screen and (min-width: 600px){
      max-width: fit-content;
    }
  }
}

.section-title {
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 20px;
}

.section-title-highlight {
  color: #f06837;
}

/* Responsively maintains 16:9 aspect ratio */
.iframe-container {
  margin: 0 auto;
  max-width: 896px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.iframe-container::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.iframe-container iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.section-list {
  line-height: 24px;
  margin-bottom: 20px;
}

.section-list li {
  list-style: none;
  font-size: 18px;
  padding-left: 8px;
}

.section-list li::marker {
  color: #f06837;
  content: "\e55f";
  font-family: "Material Icons";
}

.services-table-container {
  box-shadow: 0px 10px 15px 0px rgba(32, 54, 97, 0.10);
  margin-top: 24px;
  overflow-x: auto;
}

.services-table {
  font-size: 18px;
  width: 100%;
}

.services-table td, 
.services-table th {
  padding: 18px 24px;
}

.services-table th {
  background-color:#ccdbe6;
}
</style>

<template>
  <div class="vehicle-details">
    <div class="section-title">
      <h5>Vehicle information</h5>
    </div>

    <!-- Assignment -->
    <div class="vehicles-list" v-if="activeAssignments.length > 0">
      <div class="incident-vehicle row border-bottom mb-2 pb-2" v-for="(assignment, index) in activeAssignments" :key="index">
        <div class="col-7">
          <VehicleDetail :vehicle="assignment.incident_vehicle"></VehicleDetail>
          <div class="assignment-tower-detail">
            <div v-if="assignment.assigned_org">
              <span class="mb-0">ASSIGNED TO</span><br>
              <span v-if="assignment.assigned_org.name" class="mb-0 text-capitalize">
                {{ assignment.assigned_org.name }}
              </span>
              <p v-if="assignment.assigned_org.contact_phone" class="mb-0">
                {{ formatPhoneNumber(assignment.assigned_org.contact_phone) }}
              </p>
            </div>

            <p class="faded-1 mb-0">
              <span v-if="assignment.driver">
                {{ assignment.driver.first }} {{ assignment.driver.last }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // Components
  import VehicleDetail from '../vehicles/vehicle-detail'

  export default {
    props: ['assignments'],

    components: {
      VehicleDetail
    },

    computed: {
      activeAssignments () {
        return this.assignments.filter(assignment => assignment.status !== 'reassigned');
      },
    },

    methods: {
      formatPhoneNumber (number) {
        return this.formattedPhone(number)
      }
    }
  }
</script>

<style scoped lang="scss">
  .assignment-tower-detail {
    margin-top: 20px;
  }
</style>

<template>
  <div>
    <div class="col-md-10 margin-auto">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input type="hidden" name="vehicle[make]" v-model="vehicle.make">
          <md-field :class="getValidationClass('vehicle','make')">
            <label class="form__label">Vehicle make</label>
            <md-input v-model="vehicle.make" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.vehicle.make.required">This field is required</span>
          </md-field>
        </div>

        <div class="col-md-6 col-sm-12">
          <input type="hidden" name="vehicle[model]" v-model="vehicle.model" class="js-model-input">
           <md-field :class="getValidationClass('vehicle','model')">
            <label class="form__label">Vehicle model</label>
            <md-input v-model="vehicle.model" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.vehicle.model.required">This field is required</span>
          </md-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <input type="hidden" name="vehicle[color]" v-model="vehicle.color">
            <md-field :class="getValidationClass('vehicle','color')">
              <label class="form__label">Color</label>
              <md-input v-model="vehicle.color" class="form-control"></md-input>
              <span class="md-error" v-if="!$v.vehicle.color.required">This field is required</span>
            </md-field>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <input type="hidden" name="vehicle[nickname]" v-model="vehicle.nickname">
            <md-field :class="getValidationClass('vehicle','nickname')">
              <label class="form__label">Nickname (optional)</label>
              <md-input v-model="vehicle.nickname" class="form-control"></md-input>
            </md-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-check">
            <label class="form-check-label">
              <input name="vehicle[flatbed]" type="hidden" v-model="vehicle.flatbed">
              <input type="checkbox" name="vehicle[flatbed]" class="form-check-input" v-model="vehicle.flatbed">
              This vehicle is a flatbed
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row" v-if="type_form === 'edit'">
        <div class="form__actions">
          <div class="form__button-delete col-md-6">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
              <defs>
                  <path id="l" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"/>
              </defs>
              <g fill="none" fill-rule="evenodd">
                  <mask id="m" fill="#fff">
                      <use xlink:href="#l"/>
                  </mask>
                  <g fill="#f06837"  mask="url(#m)">
                      <path d="M0 0h24v24H0z"/>
                  </g>
              </g>
            </svg>
            <button class="form__button-link" @click.prevent="activeDialog = true">Delete Vehicle</button>
          </div>
        </div>
      </div>

      <div v-if="type_form === 'edit'" class="footer-form">
        <div class="col-md-10 margin-auto">
          <div class="footer-form__content">
            <md-button :disabled='!isCompleteForm' :class="[isCompleteForm ? 'active-button' : 'disabled-button','btn btn-primary footer-form__submit']" @click="sendForm(data.id)">Update Vehicle</md-button>
            <md-button class="md-primary footer-form__submit footer-form__submit-cancel" :href="path">Cancel</md-button>
            <md-progress-spinner :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
          </div>
        </div>
      </div>
      <div v-else class="footer-form">
        <div class="col-md-10 margin-auto">
          <div class="footer-form__content">
            <md-button :disabled='!isCompleteForm' :class="[isCompleteForm ? 'active-button' : 'disabled-button','btn btn-primary footer-form__submit']" @click="addVehicle($event,'.js-add-vehicle')">Add Vehicle</md-button>
            <md-button class="md-primary footer-form__submit footer-form__submit-cancel" :href="path">Cancel</md-button>
            <md-progress-spinner :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
          </div>
        </div>
      </div>

      <md-dialog-confirm
        :md-active.sync="activeDialog"
        md-content="Are you sure you want to delete this vehicle ?"
        md-confirm-text="Delete"
        md-cancel-text="Cancel"
        @md-cancel="onCancel"
        @md-confirm="deleteVehicle(data.id,$event)"
        />
    </div>
    <md-snackbar :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success', 'snackbar-notify snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="alert" md-persistent>
      <span  class="snackbar-notify__text">
        <i v-if="!error" class="material-icons">check</i>
       {{ alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  required,
  email,
  minLength
} from 'vuelidate/lib/validators'

export default {
  name: 'formVehicle',
  props: ['data', 'btn_text', 'path', 'type_form', 'current_organization'],
  data: () => ({
    carModelsByMake: [],
    vehicle: {
      make: null,
      model: null,
      color: null,
      nickname: null,
      flatbed: false
    },
    boolean: false,
    activeDialog: false,
    update: false,
    error: false,
    position: 'left',
    isInfinity: false,
    duration: 4000,
    alertMsg: '',
    alert: false
  }),
  validations: {
    vehicle: {
      make: {
        required
      },
      model: {
        required
      },
      color: {
        required
      }
    }
  },
  computed : {
    isCompleteForm () {
      return this.vehicle.make && this.vehicle.model && this.vehicle.color
    }
  },
  methods: {
    getModels () {
      const baseURL = 'https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake'

      if (this.vehicle.make) {
        fetch(`${baseURL}/${this.vehicle.make}?format=json`)
        .then(resp => resp.json())
        .then(data => {
          if (data.Results.length > 0) {
            this.carModelsByMake = data.Results.map(item => item['Model_Name'])
          }
        })
      }
    },
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    getQueryModel (query) {
      let model = document.querySelector('.js-model-input');
      model.value = query
    },
    deleteVehicle (id,event) {
      Vue.http.delete(`/vehicles/${id}`,[],[{responseType: 'json'}])
      .then(response => {
        if(response.ok) {
          window.location.href = '/vehicles';
        }
      }, err => {
        this.alert = true
        this.error = true
        this.alertMsg = err.body.msg
      });
    },
    onCancel () {
      this.activeDialog = false
    },
    sendForm (id) {
      let formData = new FormData();
      this.$v.$touch();
      this.update = true
      if (!this.$v.$invalid) {
        formData.append('vehicle[make]', this.vehicle.make)
        formData.append('vehicle[model]', this.vehicle.model)
        formData.append('vehicle[color]', this.vehicle.color)
        if (this.vehicle.nickname !== null) {
          formData.append('vehicle[nickname]', this.vehicle.nickname)
        }
        formData.append('vehicle[flatbed]', !!this.vehicle.flatbed)

        Vue.http.put(`/vehicles/${id}`, formData ,[{responseType: 'json'}])
          .then(response => {
            if(response.ok) {
              this.alert = true
              this.alertMsg = 'Vehicle updated successfully'
              this.update = false
              setTimeout(() => {
                window.location.href = '/vehicles';
              },1000)
            }
          }, err => {
            if (err.status === 422) {
              this.alert = true;
              this.error = true;
              this.alertMsg = err.body.plate[0];
              this.update = false
            }
          });
      }
    },
    addVehicle (e,target) {
      e.preventDefault();
      let formData = new FormData();
      this.$v.$touch();
      this.update = true
      if (!this.$v.$invalid) {
        formData.append('vehicles[][make]', this.vehicle.make)
        formData.append('vehicles[][model]', this.vehicle.model)
        formData.append('vehicles[][color]', this.vehicle.color)
        if (this.vehicle.nickname !== null) {
          formData.append('vehicles[][nickname]', this.vehicle.nickname)
        }
        formData.append('vehicles[][flatbed]', !!this.vehicle.flatbed)
        formData.append('organization', this.current_organization)

        Vue.http.post(`/vehicles`, formData ,[{responseType: 'json'}])
          .then(response => {
            if(response.ok) {
              this.alert = true
              this.alertMsg = 'Vehicle created successfully'
              this.update = false
              setTimeout(() => {
                window.location.href = '/vehicles';
              },1000)
            }
          }, err => {
            if (err.status === 422) {
              this.alert = true;
              this.error = true;
              this.update = false
              this.alertMsg = err.body.msg;
            }
          });
      }
    }
  },
  mounted() {
    this.vehicle.make = this.data.make
    this.vehicle.model = this.data.model ? this.data.model : null
    this.vehicle.nickname = this.data.nickname
    this.vehicle.color = this.data.color
    this.vehicle.flatbed = this.data.flatbed
  }
}
</script>

<template>
  <md-card class="card-wrecker-detail section card-box-profile card-box offset-card js-content-box-storage" id="storage-facilities">
    <h2 class="title-card">Storage facilities</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="open">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <div class="card-box__content" v-if="openBox">
      <p class="description-card">{{ editInfo ? 'You can add new facilities or edit existing ones.' : 'Please tell us the location of your storage facility or facilities.'}}</p>
      <div class="card-box__content-form js-box-content-form">
        <ul class="locations">
          <li v-for="location in tower_info.tower_locations" class="locations__item" v-if="!location._destroy">
            <img v-if="!editInfo" src="../../../../../images/pin.svg" alt="">
            <img v-else src="../../../../../images/pin-blue.svg" alt="">
            <p class="locations__address">{{ location.line_1 }} {{ location.line_2 }}</p>
            <a v-if="editInfo" href="javascript:;" @click="deleteAddress(location)" class="delete-address"><md-icon>clear</md-icon></a>
          </li>
        </ul>
        <div v-if="showLocationField" class="field-address">
          <LocationAutocomplete :newField="newField" v-bind:selection.sync="selection" @update:selection="getSelection" :changeColor="editInfo"/>
        </div>
        <button v-if="editInfo" @click="showLocationField = !showLocationField" class="card-box-profile__btn-add-storage">Add storage facility location</button>
      </div>
      <transition name="slide">
        <div v-if="editInfo" class="row">
          <div class="card-box__content-actions">
            <md-button :disabled='deactivedButton' :class="[deactivedButton ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
            <md-button class="md-accent card-box__button-cancel" @click="cancel(!upToDate ? 'database' : 'browserData')">Cancel</md-button>
            <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
          </div>
        </div>
      </transition>
    </div>
  </md-card>
</template>

<script>
import Vue from 'vue';
import LocationAutocomplete from "../../forms/map-forms/location-autocomplete";
import { resetData } from '../../../mixins/resetData'
import humps from 'humps'

export default {
  name: 'StorageFacilities',
  props: ['tower_data', 'detail','id'],
  components: {
    LocationAutocomplete
  },
  data: () => ({
    tower_locationsDelete: [],
    tower_info: {
      hook_up: '',
      flatbed: '',
      rollover: '',
      clean_up_accident: '',
      clean_up_oil_dry: '',
      mileage_your_storage: '',
      mileage_other_location: '',
      storage_per_day: '',
      impound_fee: '',
      dolly: '',
      flares: '',
      no_preference_fee: '',
      no_preference_charge: '',
      preferences_charge: '',
      release_fee: '',
      emergency_fee: '',
      out_of_gas: '',
      lock_out: '',
      tire_change: '',
      dead_battery: '',
      winch_out: '',
      tower_locations: []
    },
    save_data: null,
    upToDate: false,
    selection: null,
    openBox: false,
    editInfo: false,
    update: false,
    newField: true,
    deactivedButton: true,
    add_address: false,
    showLocationField: false,
  }),
  methods: {
    open () {
      if (!this.upToDate) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    convertValue (number) {
      return number / 100;
    },
    cancel (data) {
      this.editInfo = !this.editInfo
      if ( data === 'database' ) {
        resetData(this.tower_info,this.detail,newData => {
          for ( let value in newData ) {
            if ( !(value === 'tower_locations') ) {
              this.tower_info[value] = this.convertValue(newData[value])
            } else {
              this.tower_info[value] = newData[value]
            }
          }
        })
      }
    },
    getSelection (selection) {
     this.tower_info.tower_locations = [...this.tower_info.tower_locations, selection.addressable]
     this.showLocationField = false
     this.deactivedButton = false
    },
    deleteAddress(item) {
      this.tower_info.tower_locations = this.tower_info.tower_locations.filter(address => address !== item);
      item._destroy = true;
      this.tower_locationsDelete = this.tower_locationsDelete.concat(item)
      this.deactivedButton = false
    },
    validate () {
      if(this.tower_info.tower_locations.length) {
        this.submit()
      } else {
        this.$emit('show:snackbar', {update: true, alertMsg: 'You must add an address', error: true})
      }
    },
    submit () {
      this.update = true;
      this.deactivedButton = true;
     
      let tower_info = this.tower_info
      tower_info.tower_locations = [...this.tower_info.tower_locations, ...this.tower_locationsDelete]
      let data = tower_info
      
      Vue.http.put(`/tower_details/${this.id}`, {tower_detail: data} ,[{responseType: 'json'}])
        .then(response => {
          setTimeout(() => {
            this.update = false;
            this.deactivedButton = false;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
            this.editInfo = false
            this.upToDate = true
            this.save_data = data
            location.reload()
          },1000)
        }, err => {
          setTimeout(() => {
            this.update = false;
            this.deactivedButton = false;
            this.upToDate = false
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.error : 'Something went Wrong', error: true})
          },1000)
      });
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    resetData(this.tower_info,this.detail,newData => {
      for ( let value in newData ) {
        if ( !(value === 'tower_locations') ) {
          this.tower_info[value] = this.convertValue(newData[value])
        } else {
          this.tower_info[value] = newData[value]
        }
      }
    })
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>

<style lang="scss" scoped>
  .hidden-button {
    display: none;
    pointer-events: none;
    margin-top: 0;
    margin-bottom: 0;
  }
</style>
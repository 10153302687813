<template>
 <md-step :id="release_and_impound ? 'fourth' : 'fifth'" :md-label="titleStep" :md-description="titleDescription" :md-editable="false" :md-done.sync="done" :class="[release_and_impound ? 'fourth-step' : 'fifth-step', 'motorist-onboarding-step']">
   
 </md-step>
</template>

<script>
export default {
  name: 'AddPhotos',
  props: ['steps', 'motorist', 'release', 'release_and_impound'],
  data: () => ({
    titleStep: 'Add photos from incident scene',
    titleDescription: '',
    done: false
  }),
  created () {
    console.log(this.release_and_impound)
  },
  mounted () {
    if ( this.motorist ) {
      if (this.steps[this.motorist.completedSteps] !== this.release_and_impound ? 'fourth' : 'fifth' ) {
        this.done = true
        this.$emit("next", this.steps[this.motorist.completedSteps])
      }
    }
    let numberStepperText = document.querySelector(`${this.release_and_impound ? '.fourth-step' : '.fifth-step'} .md-stepper-number`);
    numberStepperText.setAttribute('data-step-number', this.release_and_impound ? 4 : 5)
  }
}
</script>

<template>
  <div class="select-driver">
    <div v-if="drivers && drivers.length > 0" class="mb-4">
      <div class="drivers mb-2 bg-white">
        <md-list class="mb-2 pt-0 pb-0">
          <div 
            class="driver-list-item border-bottom"
            v-for="driver in drivers" :key="driver.id"
          >
            <md-list-item class="select-driver__list-item">
              <DriverWidget 
                :driver="driver" 
                :eta="(driver.eta && driver.eta.duration) ? driver.eta.duration.text : ''" 
              />

              <div class="md-list-action">
                <md-checkbox 
                  v-if="support_multiple_options"
                  :model="selected_drivers" 
                  :value="driver.id" 
                  :disabled="disabledCheckbox(driver.id)"
                  @change="handleDriverSelected">
                </md-checkbox>

                <div v-else class="select-driver__control-wrapper">
                  <span v-if="isSelectedDriver(driver.id)" class="select-driver__currently-assigned">
                    Currently assigned
                  </span>
                  <md-radio 
                    class="md-primary"
                    :model="selected_drivers"
                    :value="driver.id"
                    @change="handleDriverSelected"
                  >
                  </md-radio>
                </div>
              </div>
            </md-list-item>
          </div>
        </md-list>
      </div>

      <!---------------------------------------------------------
        Custom additional content
        ---------------------------------------------------------
        Append custom additional content after the driver list.
        For example a refresh button or a description text.
        --------------------------------------------------------->
      <slot></slot>
    </div>

    <div v-else-if="drivers && drivers.length === 0">
      <p class="caption">There aren't drivers available. Make sure you've invited drivers, and that they have downloaded and completed registration on the Curbside SOS app.</p>
      <md-button class="btn btn-primary" :href="'/drivers'">Resend Invites</md-button>
    </div>
  </div>
</template>

<script>
// Components
import DriverWidget from './driver-widget.vue';

export default {
  props: ['drivers', 'selected_drivers', 'selectable_drivers_count', 'support_multiple_options', 'disable_inactives'],

  components: {
    DriverWidget
  },

  computed: {
    /**
     * Pluck only the ids of the drivers with ETA.
     */
    activeDriverIds () {
      return this.drivers.map(driver => driver.eta.duration.value ? driver.id : undefined).filter(Boolean);
    }
  },

  methods: {
    isActiveDriver (id) {
      return this.activeDriverIds.indexOf(id) > -1;
    },

    isSelectedDriver (id) {
      if (!this.selected_drivers) return ;

      if (this.support_multiple_options) {
        return this.selected_drivers == id;
      } else {
        return this.selected_drivers.indexOf(id) > -1;
      }
    },

    disabledCheckbox (id) {
      const drivers_checked = this.selected_drivers.length == this.selectable_drivers_count;
      const unchecked_driver = !this.selected_drivers.includes(id);
      const driverWithoutEtaShouldDisable = this.disable_inactives && !this.isActiveDriver(id);

      return (drivers_checked && unchecked_driver) || driverWithoutEtaShouldDisable;
    },

    handleDriverSelected (driverId) {
      this.$emit('select-driver', driverId);
    }
  }
}
</script>

<style lang="scss">
  .select-driver {
    &__control-wrapper {
      display: flex;
      align-items: center;
    }

    &__currently-assigned {
      display: none;
      margin-right: 16px;
      color: #637A87;

      @media screen and (min-width: 480px) {
        display: inline;
      }
    }

    &__list-item {
      .md-list-item-content {
        padding: 10px 16px;
      }
    }

    .drivers {
      max-height: 285px;
      overflow-y: scroll;
    }
  }
</style>

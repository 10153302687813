<template>
  <md-card class="card card-wrecker-detail document-card card-box offset-card section" id="documents">
    <h2 class="title-card">Documents</h2>
    <p class="info-documents">We require the following documents:</p>
    <ul class="info-documents">
      <li>Valid Liability Certificates.</li>
      <li>Current copy of the Public Service Commission Letter sent to your agency.</li>
    </ul>
    <ul class="documents">
      <div class="row">
        <li v-for="document in documents" class="documents__item col-md-6">
          <div class="documents__content">
            <div class="documents__content-name">
              <img class="documents__clip" src="../../../../../images/clip.svg" alt="">
              <p class="documents__name">{{ document.name }}</p>
            </div>
            <a :href="document.url" target="_blank" class="documents__link">
            <img src="../../../../../images/eye.svg" alt="">
            </a>
          </div>
        </li>
      </div>  
    </ul>
  </md-card>
</template>

<script>
export default {
  name: 'Documents',
  props: ['documents']
}
</script>
var Pagination = {

  code: '',

  // --------------------
  // Utility
  // --------------------

  // converting initialize data
  Extend: function(data) {
      data = data || {};
      Pagination.size = data.size || 5;
      Pagination.page = data.page || 1;
      Pagination.step = data.step || 3;
      Pagination.querySearch = data.querySearch || ''
  },

  // add pages by number (from [s] to [f])
  Add: function(s, f) {
    if (Pagination.querySearch.length) {
        for (var i = s; i < f; i++) {
            Pagination.code += `<li class="pagination-item js-pagination-item"><a href="?page=${i}&${Pagination.querySearch}" class="pagination-link"> ${i} </a></li>`;
        }
    } else {
        for (var i = s; i < f; i++) {
            Pagination.code += `<li class="pagination-item js-pagination-item"><a href="?page=${i}" class="pagination-link"> ${i} </a></li>`;
        }
    }
  },

  // add last page with separator
  Last: function() {
      Pagination.code += '<li class="more-items"></li>';
  },

  // add first page with separator
  First: function() {
      Pagination.code += '<li class="more-items"></li>';
  },



  // --------------------
  // Handlers
  // --------------------

  // change page
  Click: function() {
      Pagination.page = +this.innerHTML;
      Pagination.Start();
  },

  // previous page
  Prev: function() {
      Pagination.page--;
      if (Pagination.page < 1) {
          Pagination.page = 1;
      }
      Pagination.Start();
  },

  // next page
  Next: function() {
      Pagination.page++;
      if (Pagination.page > Pagination.size) {
          Pagination.page = Pagination.size;
      }
      Pagination.Start();
  },



  // --------------------
  // Script
  // --------------------

  // binding pages
  Bind: function() {
      var a = Pagination.e.getElementsByTagName('a');
      for (var i = 0; i < a.length; i++) {
          if (+a[i].innerHTML === Pagination.page) {
            a[i].parentElement.classList.add('active');
          }
          a[i].addEventListener('click', Pagination.Click, false);
      }
  },

  // write pagination
  Finish: function() {
      Pagination.e.innerHTML = Pagination.code;
      Pagination.code = '';
      Pagination.Bind();
  },

  // find pagination type
  Start: function() {
      if (Pagination.size < Pagination.step * 2 + 2) {
          Pagination.Add(1, Pagination.size + 1);
      }
      else if (Pagination.page < Pagination.step * 2) {
          Pagination.Add(1, Pagination.step * 2 + 2);
          //Pagination.Last();
      }
      else if (Pagination.page > Pagination.size - Pagination.step * 1) {
          //Pagination.First();
          Pagination.Add(Pagination.size - Pagination.step * 1, Pagination.size + 1);
      }
      else {
          //Pagination.First();
          Pagination.Add(Pagination.page - Pagination.step, Pagination.page + Pagination.step + 1);
          //Pagination.Last();
      }
      Pagination.Finish();
  },



  // --------------------
  // Initialization
  // --------------------

  // binding buttons
  Buttons: function(e) {
      var nav = e.getElementsByTagName('a');
      nav[0].addEventListener('click', Pagination.Prev, false);
      nav[1].addEventListener('click', Pagination.Next, false);
  },

  // create skeleton
  Create: function(e) {
      var html = [
          `<a href="${Pagination.querySearch.length ? `?page=${Pagination.page}&${Pagination.querySearch}`: `?page=${Pagination.page - 1}`}" class="pagination-link arrow-pagination arrow-pagination--left ${Pagination.page === 1 || Pagination.page === 0 ? 'disabled-link' : ''}" tabindex="-1">&#171;</a>`, // previous button
          '<ul class="pagination js-pagination pg-blue"></ul>',
          `<a href="${Pagination.page === Pagination.size ? '#' : `${Pagination.querySearch.length ? `?page=${Pagination.page + 1}&${Pagination.querySearch}` : `?page=${Pagination.page + 1}`}`}" class="${Pagination.page === Pagination.size ? 'disabled-link' : ''} pagination-link arrow-pagination arrow-pagination--right">&#187;</a>`  // next button
      ];

      e.innerHTML = html.join('');
      Pagination.e = e.getElementsByTagName('ul')[0];
      Pagination.Buttons(e);
  },

  // init
  Init: function(e, data) {
      Pagination.Extend(data);
      Pagination.Create(e);
      Pagination.Start();
  }
};

export default Pagination;

import humps from 'humps';
import { loadStripe } from '@stripe/stripe-js';
import config from '@motorist/config';

export default {
  data() {
    return {
      stripe: null
    };
  },

  methods: {
    /**
     * Load a stripe instance and inject the stripe script if it hasn't included yet.
     * 
     * @returns {Promise} Promise that resolves with a newly created Stripe object once Stripe.js has loaded.
     */
    loadstripe() {
      return loadStripe(CSOS_RT_CONF.STRIPE_PUBLIC_KEY)
        .then(stripeInstance => {
          this.stripe = stripeInstance;

          return this.stripe;
        });
    },

    /**
     * Get an instance of stripe elements.
     */
    getStripeElementsInstance() {
      const opts = {
        locale: 'en',
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
          },
        ]
      };

      return this.stripe.elements(opts);
    },

    /**
     * Create and mount a stripe element according to the official stripe documentation.
     * 
     * {@link https://stripe.com/docs/js/elements_object/create_element Stripe Element Docs}
     * 
     * @param {String} type - The element type.
     * @param {string|DOMelement} domElement -
     * @param {Object} [options={}] - Options for creating the element.
     * 
     * @returns {Object} A stripe payment element.
     */
    createStripeElement(stripeElements, type, domElement, options = {}) {
      const mergedOptions = {
        ...options
      };

      const element = stripeElements.create(type, mergedOptions);
      element.mount(domElement);

      return element;
    },

    processStripeError(error) {
      if (!error) return config.systemMessages.INTERNAL_ERROR;

      let errorMessage = config.systemMessages.INTERNAL_ERROR;

      if (error.type === 'card_error') {
        errorMessage = error.message;
      } else if (error.type === 'invalid_request_error') {
        if (error.code === 'payment_intent_unexpected_state' && error.paymentIntent.status === 'requires_capture') {
          errorMessage = 'The payment hasn\'t been executed because it is already being processed.';
        } else {
          errorMessage = 'The payment couldn\'t be processed.';
        }
      } else if (error.type === 'validation_error') {
        errorMessage = '';
      }

      return errorMessage;
    },

    /**
     * Gather additional customer data we may have collected in our form.
     * More info on: https://stripe.com/docs/js/payment_intents/confirm_card_payment
     * 
     * @param {String} clientSecret - The client secret of the payment intent.
     * @param {Object} data - The data object to be sent to stripe. 
     */
    submitStripePayment(clientSecret, data) {
      return this.stripe.confirmCardPayment(clientSecret, data)
        .then(result => humps.camelizeKeys(result));
    }
  },
};

<template>
  <div class="drivers-table" v-if="ready">
    <DataTable
      :data="list"
      title="Dispatchers"
      :columns="['First name', 'Last name', 'Email', 'Phone number', 'Status', 'Admin']"
      button-text="New dispatcher"
      :button-path="'/users/new_invitation'"
      type-table="users"
      :org_type="organization.org_type"
      edit-button-path="/users"
    ></DataTable>

    <TableResponsive
      :data="list"
      title="Dispatchers"
      :columns="['First name', 'Last name', 'Email', 'Phone number', 'Status', 'Admin']"
      button-text="New dispatcher"
      :button-path="'/users/new_invitation'"
      type-table="users"
      edit-button-path="/users"
    ></TableResponsive>
  </div>
</template>

<script>
import DataTable from "../data-table";
import TableResponsive from "./table-responsive-users";

const formatPhoneNumber = str => {
  const cleaned = ("" + str).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
  return null;
};
const renderAttrQuantity = (obj, key) => {
  if (obj[key]) {
    const length = obj[key].length;
    return length > 0 ? length : "";
  }
  return "";
};

export default {
  props: ["users", 'organization'],

  components: {
    DataTable,
    TableResponsive
  },

  data: () => {
    return {
      ready: false,
      list: []
    };
  },

  mounted() {
    const mappedUsers = this.users.map((user, idx) => ({
      id: user.id,
      "First name": user.first_name,
      "Last name": user.last_name,
      "Email": user.email,
      "Phone number": formatPhoneNumber(user.phone_number),
      "Admin": user["organization_admin?"] ? "Yes" : "No",
      Status: (user['invitation_accepted?'] || user['profile_completed']) ? user['enable'] ? "Active" : "Inactive" : "Pending invite",
      resend: false || (user['invitation_accepted?'] || user['profile_completed'])
    }));
    this.list = mappedUsers.reverse();
    this.ready = true;
  }
};
</script>

<style scoped lang='scss'>
</style>

<template>
  <div class="assign-equipment">
    <md-list class="mb-4 pt-0 pb-0">
      <md-list-item @click="toggleCheck('no-equipment')"
                    class="equip-list-item border-bottom py-0">
                    <!-- @click="$emit('select-equipment', null)" -->
        <div class="md-list-item-text py-3">
          <p class="mb-0">Not sure</p>
          <p class="caption faded-1 mb-0">No vehicles requiring a flatbed will be assigned.</p>
        </div>
      </md-list-item>
      <md-list-item @click="toggleCheck(vehicle)"
                    class="equip-list-item border-bottom py-0 mt-0"
                    v-for="vehicle in visibleVehicles"
                    :key="vehicle.id">
        <div class="md-list-item-text py-3">
          <p class="mb-0">{{ vehicle.make }} {{ vehicle.model }}</p>
          <p v-if="vehicle.nickname" class="caption faded-1 mb-0">{{ vehicle.nickname }}</p>
          <p v-if="vehicle.flatbed" class="caption faded-1 mb-0">Flatbed</p>
        </div>
      </md-list-item>
    </md-list>
    <div v-if="available_vehicles.length > 3 && !view_all" class="pb-2">
      <md-button class="btn btn-secondary center" @click.prevent="view_all = true">See all</md-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['equipment'],

  data: () => {
    return {
      view_all: false,
    }
  },

  computed: {
    ...mapState('vehicles', {
      available_vehicles: state => state.available
    }),
    visibleVehicles () {
      return this.view_all ?  this.available_vehicles : this.available_vehicles.slice(0,3)
    }
  },

  methods: {
    toggleCheck (vehicle) {
      if (vehicle) {
        this.selected_equipment = vehicle
      } else {
        this.selected_equipment = 'no-equipment'
      }
      setTimeout(() => this.$emit('select-equipment', this.selected_equipment), 800);
    },
  }
}
</script>

<style scoped lang="scss">
</style>

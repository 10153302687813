
/*!
 * Notify v1.0.0
 *
 * Released under the Private license
 *
 * Date: 2021-10-13
 * ------------------------------------------------
 *
 * API Reference
 *
 * Notify.build({settings}): Launch a native notification and start blinking the title.
 *
 *    {
 *      blink: {
 *        alertTitle: 'New alert',
 *        delay: 1000,
 *        showNotificationsCount: true,
 *        stopOnFocus: true
 *      },
 *      notification: {
 *        actions: [],
 *        badge: '',
 *        body: '',
 *        data: null,
 *        icon: '',
 *        image: '',
 *        onlyOnBlurPage: false,
 *        title: null
 *
 *        other native notification settings...
 *        https://developer.mozilla.org/en-US/docs/Web/API/notification
 *      }
 *    };
 *
 */

function Notify() {
  const originalTitle = document.title;
  let unreadNotificationsCount = 0;
  let interval = null;

  // These are the default settings applied if the developer doesn't pass any custom settings.
  const defaultSettings = {
    blink: {
      alertTitle: 'New alert',
      delay: 1000,
      showNotificationsCount: true,
      stopOnFocus: true,
    },
    notification: {
      onlyOnBlurPage: false,
      title: null
    }
  };

  /**
   * Check if browser supports native notifications.
   *
   * @returns {Boolean}
   */
  function supportBrowserNotifications() {
    return ('Notification' in window);
  }

  /**
   * Request to the user permissions to send them notifications.
   *
   * @param {*} successCb A callback when user granted permissions.
   * @param {*} deniedCb A callback when user denied permissions.
   */
  function requestPermission(successCb, deniedCb) {
    if (!supportBrowserNotifications) {
      console.warn('This browser does not support desktop notification.');
    } else if (Notification.permission === 'granted') {
      if (successCb) successCb();
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission()
        .then(function (permission) {
          if (permission === 'granted') {
            if (successCb) successCb();
          } else {
            if (deniedCb) deniedCb();
          }
        });
    }
  }

  /**
   * Stops blinking and reset the notifications counter.
   */
  function markNotificationsAsRead() {
    if (document.hasFocus()) {
      stopBlink(document.title);
      unreadNotificationsCount = 0;
    }
  }

  /**
   * Quickly alternate title between two strings.
   *
   * @param {*} alertTitle The title to alternate.
   */
  function blink(alertTitle) {
    if (document.title === originalTitle) {
      document.title = `(${unreadNotificationsCount}) ${alertTitle}`;
    } else {
      document.title = originalTitle;
    }
  }

  /**
   * Stop the blinking process.
   */
  function stopBlink() {
    if (interval) {
      clearInterval(interval);
      document.title = originalTitle;
    }
  }

  /**
   * Configure and start the blinking process.
   *
   * @param {*} settings
   */
  function configureBlinking(settings) {
    if (document.hasFocus()) {
      return ;
    }

    // If there was a notification in process. We must stop it and start a new one.
    if (interval) {
      stopBlink();
    }

    unreadNotificationsCount += 1;
    interval = setInterval(blink, settings.delay, settings.alertTitle);
    document.addEventListener('click', markNotificationsAsRead, {
      once: true
    });
  }

  /**
   * Build a notification and launch it.
   *
   * @param {*} settings
   * @returns
   */
  function displayNotification(settings) {
    if (!supportBrowserNotifications) {
      console.warn('This browser does not support desktop notification');

      return ;
    }

    // Check if the user has granted permissions.
    if (Notification.permission !== 'granted') {
      requestPermission(() => {
        new Notification(settings.title, settings);
      });

      return ;
    }

    // Not display notification if document is focused and it's required only on blured documents.
    if (settings.onlyOnBlurPage && document.hasFocus()) {
      return ;
    }

    return new Notification(settings.title, settings);
  }

  /**
   * Build a new notification and show it.
   *
   * @param {*} settings
   */
  function build(settings) {
    // Merge default settings with the custom settings...
    const _settings = {
      blink: { ...defaultSettings.blink, ...settings.blink },
      notification: { ...defaultSettings.notification, ...settings.notification }
    };

    if (_settings.notification.title == null) {
      throw new TypeError('The title of the notification must not be empty.');
    }

    // configureBlinking(_settings.blink);
    displayNotification(_settings.notification);
  }

  return {
    build,
    requestPermission,
    stopBlink
  }
}

export default Notify();

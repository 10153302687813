<template>
  <div class="organization-view my-profile">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 margin-auto">
          <h2 class="my-profile__title">Your profile</h2>
          <personal-info
            :userData="user"
            @show:snackbar="showAlert"
          ></personal-info>
          <Password
            @show:snackbar="showAlert"
          ></Password>
        </div>
      </div>
    </div>
    <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
      <span class="snackbar-notify__text">
        <div class="content-icon">
          <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
        </div>
        {{ this.alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>

import PersonalInfo from './personal-info';
import Password from './password';

export default {
  name: 'myProfile',
  props: ['user'],
  data: () => ({
    position: 'center',
    isInfinity: false,
    duration: 4000,
    update: false,
    alertMsg: '',
    errorSnack: false
  }),
  components: {
    PersonalInfo,
    Password
  },
  methods: {
    showAlert (opts) {
      this.update = opts.update
      this.alertMsg = opts.alertMsg
      this.errorSnack = opts.error
    }
  }
}
</script>
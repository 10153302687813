<template>
  <div class="motorist-intro">
    <div class="container">
      <div class="motorist-bar motorist-bar--intro">
        <img class="curbside-icon" src="../../../../images/logo_curbsidesos_dark.png" alt="curbside">
      </div>
      <div class="motorist-intro__content">
        <div class="col-md-10 margin-auto col-sm-12">
          <div class="row">
            <div class="col-md-7 col-sm-12 margin-auto text-center">
              <div class="motorist-intro__image-content">
                <img class="motorist-intro__hero-image" src="../../../../images/tow-truck@2x.png" alt="curbside-towtruck">
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <p class="motorist-intro__title">Tow in progress?</p>
              <p class="motorist-intro__description">We can handle some of the hassle</p>
              <ul class="motorist-intro__menu">
                <li class="motorist-intro__menu-item">
                  <img class="icon-motorist" src="../../../../images/truck-blue.svg" alt="tracking">
                  <p class="desc">Connect with your tow operator</p>
                </li>
                <li class="motorist-intro__menu-item">
                  <img class="icon-motorist" src="../../../../images/tracking.svg" alt="tracking">
                  <p class="desc">Track your tow in real time</p>
                </li>
                <li class="motorist-intro__menu-item">
                  <img class="icon-motorist" src="../../../../images/body-shops.svg" alt="tracking">
                  <p class="desc">Review options for vehicle repair</p>
                </li>
                <li class="motorist-intro__menu-item">
                  <img class="icon-motorist" src="../../../../images/photos.svg" alt="tracking">
                  <p class="desc">Document your incident with photos</p>
                </li>
              </ul>
              <md-button class="md-raised md-accent motorist-intro__button" style="background-color:#f06837" @click="go">Let's go</md-button>
              <a class="motorist-intro__link" href="/motorists/sign-in">Returning customer?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MotoristIntro',
  methods: {
    go () {
      this.$emit('show:motorist-search',true)
    }
  }
}
</script>
/**
 * Exclude the keys from the object if they are not in the keys array.
 *
 * @param {Object} obj
 * @param {Array} keys - An array with the keys to be excluded.
 *
 * @returns Object
 */
export function excludeKeysFromObject(obj, keys) {
  const filteredObj = {};

  Object.keys(obj).forEach(key => {
    if (!keys.includes(key)) {
      filteredObj[key] = obj[key];
    }
  });

  return filteredObj;
};

/**
 * Convert an array of objects to an object.
 * The key param is a value present in each object and
 * It'll be used in the the key property of the returned object.
 *
 * @param {objects} array - An array of objects.
 */
export function objectsArrayToObject(objects, key) {
  return objects.reduce((mappedObjects, currentObject) => {
    mappedObjects[currentObject[key]] = currentObject;

    return mappedObjects;
  }, {});
}

/**
 * Convert a hour string like '06:00' to format date Date object
 *
 * @param {*} stringDate
 * @returns
 */
export function createDateFromStringDate(stringDate) {
  const hours = parseInt(stringDate?.split(':')[0], 10)
  const minutes = parseInt(stringDate?.split(':')[1], 10)
  const currentDate = new Date()
  currentDate.setHours(hours)
  currentDate.setMinutes(minutes)

  return currentDate
}

/**
 * Convert a hour string like '06:00' to minutes
 * 
 * @param {*} time 
 * @returns number
 */
export function parseTimeToMinutes(time) {
  const targetHour = parseInt(time?.split(':')[0], 10)
  const targetMinute = parseInt(time?.split(':')[1], 10)
  const targetTimeInMinutes = targetHour * 60 + targetMinute

  return targetTimeInMinutes
}

/**
 * Calculates the number of minutes since midnight given an HH24:MM format (aka '15:17' is valid)
 * 
 * @param {*} hoursMinutes a string representation of the hours and minutes in the day, assuming a 0-24 hour format
 * @returns the number of minutes since midnight
 */
export function calculateMinutesSinceMidnight(hoursMinutes) {
  const values = hoursMinutes.split(":");
  const hours = parseInt(values[0])
  const minutes = parseInt(values[1])
  const dateToMinutes = hours * 60 + minutes

  return dateToMinutes
}

export function getNextOpenDay(data, currentDay, currentHoursAndMinutes) {
  const reducedOperationDays = data.reduce((acc, cur) => {
    const { day_of_week, start_hour, business_status } = cur.attributes
    acc.push({ dayOfWeek: day_of_week, startHour: start_hour, businessStatus: business_status })

    return acc
  }, [])

  if (!reducedOperationDays.length) {
    return { towerIsOpen: true }
  }

  let nextDay;
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const currentDayIndex = daysOfWeek.indexOf(String(currentDay).toLowerCase())
  const todaysObjectOpen = reducedOperationDays.find(item => item.dayOfWeek === daysOfWeek[currentDayIndex]) 
  const currentTimeInMinutes = calculateMinutesSinceMidnight(currentHoursAndMinutes)
  const startHourInMinutes = parseTimeToMinutes(todaysObjectOpen?.startHour)

  // In case today is open but is not the time yet, it will mention it
  if ((todaysObjectOpen?.businessStatus === 'open' || todaysObjectOpen === 'always_open') && currentTimeInMinutes < startHourInMinutes) {
    return {
      ...todaysObjectOpen,
    }
  }

  // It will look for the next open day and time according to the days of the week
  for (let i = 0; i < daysOfWeek.length; i++) {
    nextDay = ((currentDayIndex + 1) + i) % 7

    const nextDayObject = reducedOperationDays.find(item => item.dayOfWeek === daysOfWeek[nextDay]);

    if (nextDayObject && (nextDayObject.businessStatus === 'open' || nextDayObject.businessStatus === 'always_open')) {
      return {
        ...nextDayObject,
        startHour: nextDayObject.startHour || '00:00'
      };
    }
  }
  
  return null
  
}

/**
 * Returns the time zone name based on the provided time zone string and type.
 *
 * @param {string} targetTimeZone - The time zone string (e.g., "America/Chicago").
 * @param {"short" | "long"} type - The type of time zone name to return.
 * @returns {string | undefined} - The time zone name based on the type requested.
 *   For "short," it returns an abbreviation like "CST."
 *   For "long," it returns the full name like "Central Standard Time."
 */
export function getTimeZoneName(targetTimeZone, nameType = "short") {
  if (!targetTimeZone) {
    return;
  }

  const timeZoneName = new Intl.DateTimeFormat('en-US', {
    timeZone: targetTimeZone,
    timeZoneName: nameType
  }).formatToParts(new Date())
      .find(part => part.type === 'timeZoneName').value;

  return timeZoneName;
}

<template>
  <div class="create-quote-request-form">
    <!-- Create Quuote Request: Form -->
    <form autocomplete="off" id="formCreateQuote">
      <div class="row">
        <div class="col-md-6">
          <JobLocation v-bind:location.sync="location"></JobLocation>
          <JobVehicles v-bind:vehicles.sync="vehicles" :selected_zone="location ? location.zone_id : null"></JobVehicles>
        </div>
        <div class="col-md-6">
          <JobDetails v-bind:details.sync="details" :organization_id="current_organization.id"></JobDetails>
          <RequestDetails v-bind:request.sync="request"></RequestDetails>
        </div>
      </div>
      <div class="footerBar position-fixed py-3">
        <fieldset class="row">
          <div class="col-md-10 offset-md-1">
            <md-button class="btn btn-primary" tabindex="8" @click.prevent="confirm_modal_active = true" :disabled="$v.$invalid">
              Preview
            </md-button>
            <md-button class="btn btn-secondary" tabindex="9" @click="cancelOrder">Cancel Order</md-button>
            <md-snackbar :md-position="'center'" :md-duration="4000" :md-active.sync="showSnackbar">
              <span>{{ snackbarMsg }}</span>
            </md-snackbar>
          </div>
        </fieldset>
      </div>
    </form>

    <!-- Create Quote Request: Confirmation Modal -->
    <md-dialog :md-active.sync="confirm_modal_active">
      <md-dialog-title><h3>Preview order</h3></md-dialog-title>
      <md-dialog-content class="pb-0">
        <div class="row">
          <div class="col-md-6">
            <!-- Location -->
            <div class="confirm-location mb-5 pb-2">
              <div class="section-title">
                <h5>Location</h5>
              </div>
              <div class="location" v-if="location">
                <p class="mb-0">{{ location.address_primary }}</p>
                <p v-if="location.local_description" class="mb-0 faded-1">{{ location.local_description }}</p>
                <p class="mb-0 faded-1">{{ selected_zone.name }} — {{ selected_zone.code }}</p>
              </div>
            </div>

            <!-- Vehicle Information -->
            <div class="confirm-vehicles">
              <div class="section-title">
                <h5>Vehicle information</h5>
              </div>
              <div class="vehicles-list" v-if="vehicles.length > 0">
                <div class="incident-vehicle border-bottom mb-2 pb-2" v-for="(vehicle, index) in vehicles" :key="index">
                  <VehicleDetail :vehicle="vehicle"></VehicleDetail>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <!-- Incident Details -->
            <div class="job-details mb-5 pb-2" v-if="details">
              <div class="section-title">
                <h5>Incident details</h5>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <p class="faded-1 mb-0">Department</p>
                  <p>{{ departmentString }}</p>
                </div>
                <div class="col-md-6">
                  <p class="faded-1 mb-0">Incident number</p>
                  <p>{{ details.number }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <p class="faded-1 mb-0">Incident type</p>
                  <p>{{ details.type }}</p>
                </div>
                <div class="col-md-6">
                  <p class="faded-1 mb-0">Officer reporting</p>
                  <p>{{ details.officer }}</p>
                </div>
              </div>
            </div>

            <!-- Additional Remarks -->
            <div class="request-details" v-if="request && request.comments && request.comments.trim() !== ''">
              <div class="section-title">
                <h5 class="mb-0">Additional remarks</h5>
                <p class="caption faded-1">Situational details to pass onto the wrecker.</p>
              </div>
              <p>
                {{ request.comments }}
              </p>
              <div>
                <p class="emergency" v-if="request.is_emergency">This is an emergency</p>
              </div>
            </div>
          </div>
        </div>
      </md-dialog-content>

      <div class="footerBar px-3 py-2">
        <md-button class="btn btn-primary" @click="submitForm()">Confirm and submit</md-button>
        <md-button class="btn btn-secondary" @click="confirm_modal_active = false">Dismiss</md-button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import JobDetails from './job-details'
import JobLocation from './job-location'
import JobVehicles from './job-vehicles'
import RequestDetails from './request-details'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import errorClass from '../../../../../javascripts/error_class'

// Read-only Confirm Submission Sub-Components
import VehicleDetail from '../../vehicles/vehicle-detail'


export default {
  components: {
    JobLocation,
    JobDetails,
    JobVehicles,
    RequestDetails,
    VehicleDetail
  },

  props: [],

  provide() {
    return {
      $v: this.$v
    }
  },

  data () {
    return {
      amount: 0,
      location: null,
      vehicles: [],
      request: {
        comments: null,
        is_emergency: false
      },
      details: {
        officer: null,
        department_id: null,
        number: null,
        type: null,
      },

      uiState: "submit not clicked",
      errors: true,
      empty: true,

      showSnackbar: false,
      snackbarMsg: "The form above has errors.",
      confirm_modal_active: false,
    }
  },

  validations () {
    if (['81ad9519-4f27-4a35-bc48-7ea315135b4c'].includes(this.current_organization.id)) {
      return {
        details: {
          officer: {required},
          department_id: {required},
          number: {
            required,
            minLength: minLength(12),
            maxLength: maxLength(13),
          },
          type: {required},
        },
        location: {
          address_primary: {required},
          zone_id: {required},
          latLng: {
            lat: {required},
            lng: {required}
          }
        },
        vehicles: {
          required,
          minLength: 1,
          $each: {
            make: {required},
            model: {required}
          }
        }
      }
    } else {
      return {
        details: {
          officer: {required},
          department_id: {required},
          number: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(13),
          },
          type: {required},
        },
        location: {
          address_primary: {required},
          zone_id: {required},
          latLng: {
            lat: {required},
            lng: {required}
          }
        },
        vehicles: {
          required,
          minLength: 1,
          $each: {
            make: {required},
            model: {required}
          }
        }
      }
    }
  },

  methods: {
    ...mapActions("quote_requests", [
      "createQuoteRequest"
    ]),

    async submitJobAssignmentForm() {
      const payload = {
        user_id: this.current_user.id,
        organization_id: this.current_organization.id,
        address: `${this.location.address_primary}`,
        intersection: this.location.local_description,
        officer: this.details.officer,
        requesting_department_id: this.details.department_id,
        incident_number: this.details.number,
        incident_type: this.details.type,
        details: this.request.comments,
        emergency: this.request.is_emergency,
        latitude: this.location.latLng.lat,
        longitude: this.location.latLng.lng,
        vehicles: this.vehicles,
        zone_id: this.location.zone_id
      }
      await this.createQuoteRequest(payload)
    },

    submitForm() {
      this.uiState = "submit clicked";
      this.errors = this.$v.details.$anyError;
      this.formTouched = !this.$v.details.$anyDirty;
      if (this.errors === false && this.vehicles.length && this.location.latLng !== undefined) {
        this.uiState = "form submitted";
        this.$emit('viewState', 'create-quote-success-view');
        this.submitJobAssignmentForm();
      } else {
        // SHOW ERRORS
        this.$v.$touch();
        this.showSnackbar = true;
        if (!this.vehicles.length) {
          this.snackbarMsg = "Add at least one vehicle above."
        } else if (this.location.latLng == undefined) {
          this.snackbarMsg = "No location provided."
        }

      }
    },

    sleep (n) {
      return new Promise(resolve => setTimeout(resolve, n))
    },
    cancelOrder () {
      this.$emit('cancelOrder')
    },

    fixTabindex() {
      let els = document.querySelectorAll('div[tabindex]')
      let i = 0
      for (i = 0; i < els.length; i++) {
        els[i].removeAttribute('tabindex')
      }
    }

  },

  computed: {
    ...mapState("users", {
      current_user: state => state.current,
    }),
    ...mapGetters("organizations", {
      towers: "towersAlphabetical",
      departments: "policeDepartmentsAlphabetical",
    }),
    ...mapState("organizations", {
      current_organization: state => state.current
    }),
    allTowers () {
      return this.towers.map(x => x.id)
    },
    selected_zone () {
      if (this.location.zone_id) {
        const zone_index = this.current_organization.zones.findIndex((item) => {
          return item.id === this.location.zone_id
        })
        return this.current_organization.zones[zone_index]
      } else {
        return null
      }
    },
    departmentString () {
      if (this.details.department_id) {
        const selected_department = this.departments.find(department => department.id === this.details.department_id)
        return selected_department.prefix + " - " + selected_department.name
      } else {
        return ""
      }
    }
  },

  created () {
    this.selected_towers = this.allTowers;
    this.getValidationClass = errorClass.getValidationClass;
  },

  mounted(){
    this.fixTabindex()
  },

}
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="job-details">
    <div class="section-title">
      <h5>Incident details</h5>
    </div>

    <div class="row">
      <div class="col-md-6">
        <md-field :class="getValidationClass('department_id')">
          <label>Department</label>
          <md-select md-dense
                    v-model="details.department_id"
                    @blur="$v.details.department_id.$touch()"
                    tabindex="4">
            <md-option v-for="department in departments"
                       :value="department.id"
                       :key="department.id">
                       {{ department.prefix }} - {{ department.name }}
            </md-option>
          </md-select>
          <span class="md-error">This field is required.</span>
        </md-field>
      </div>
      <div class="col-md-6">
        <!-- Incident Number: Ottawa County -->
        <md-field v-if="['81ad9519-4f27-4a35-bc48-7ea315135b4c'].includes(organization_id)" :class="getValidationClass('number')">
          <label>Incident number</label>
          <md-input maxlength="12"
                    v-model="details.number"
                    @input="$v.details.number.$touch()"
                    @blur="$v.details.number.$touch()"
                    @focus="setCursor"
                    v-on:keyup="parseNumber"
                    tabindex="5">
          </md-input>
          <span class="md-helper-text">"XX-XXXX-XXXX"</span>
          <div v-if="$v.details.number.$dirty || $v.uiState == 'submit clicked'" class="text-danger">
            <span v-if="!$v.details.number.required" class="md-error">This field is required.</span>
            <span v-if="!$v.details.number.minLength" class="md-error">12-character maximum</span>
          </div>
        </md-field>
        <!-- Incident Number: Generic Format -->
        <md-field v-else>
          <label>Incident number</label>
          <md-input maxlength="12"
                    v-model="details.number"
                    @input="$v.details.number.$touch()"
                    @blur="$v.details.number.$touch()"
                    @focus="setCursor"
                    tabindex="5">
          </md-input>
          <div v-if="$v.details.number.$dirty || $v.uiState == 'submit clicked'" class="text-danger">
            <span v-if="!$v.details.number.required" class="md-error">This field is required.</span>
            <span v-if="!$v.details.number.minLength" class="md-error">12-character maximum</span>
          </div>
        </md-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <md-field :class="getValidationClass('type')">
          <label>Incident type</label>
          <md-select md-dense
                     v-model="details.type"
                     @blur="$v.details.type.$touch()"
                     tabindex="6">
            <md-option v-for="option in options"
                       :value="option"
                       :key="option">
                       {{option}}
            </md-option>
          </md-select>
          <span class="md-error">This field is required.</span>
        </md-field>
      </div>
      <div class="col-md-6">
        <md-field :class="getValidationClass('officer')">
          <label>Officer reporting</label>
          <md-input v-model="details.officer"
                    @input="$v.details.officer.$touch()"
                    @blur="$v.details.officer.$touch()"
                    tabindex="7">
          </md-input>
          <div v-if="$v.details.officer.$dirty || $v.uiState == 'submit clicked'" class="text-danger">
            <span v-if="!$v.details.officer.required" class="md-error">This field is required.</span>
          </div>
        </md-field>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import errorClass from '../../../../../javascripts/error_class'


export default {
  props: ['details', 'organization_id'],

  inject: ['$v'],

  data () {
    return {
      options: ['Abandoned Vehicle', 'Accident', 'Arrest', 'Lockout', 'Motorist Assist', 'Recovered Stolen', 'Traffic Violation', 'Other/Misc'],
      code: ''
    }
  },

  computed: {
    ...mapState('organizations', {
      departments: state => state.police_departments,
    }),
  },

  methods: {
    numberPrefix () {
      const d = new Date()
      const year = d.getFullYear().toString().substr(-2)
      const month = (d.getMonth() + 1).toString()
      const day = d.getDate().toString()
      return `${year}${this.pad(month)}${this.pad(day)}`
    },
    pad (s) {
      if (s.length === 1) {
        s = 0 + s
      }
      return s
    },
    setCursor: function(event){
      event.target.setSelectionRange(8, 8);
    },
    parseNumber () {
      if ( this.details.number !== '' ) {
        let val = this.details.number.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/)
        this.details.number = `${val[1]}-${val[2]}-${val[3]}`
      }
    }
  },
  created () {
    this.getValidationClass = errorClass.getValidationClass;

    // Special logic for Ottawa County PD Dispatch, Incident Number
    if (['81ad9519-4f27-4a35-bc48-7ea315135b4c'].includes(this.organization_id)) {
      this.details.number = this.numberPrefix()
      this.parseNumber()
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div class="quote-request-list">
    <div class="request-list">
      <transition-group name="sent-qr-list" v-on:before-leave="beforeLeave">
        <SentQuoteRequestDetail @cancel:order="cancelOrder" v-for="request in activeSentRequests" :request="request" :key="request.id" @select-request="$emit('select-request', $event)"></SentQuoteRequestDetail>
      </transition-group>
    </div>
    <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
      <span class="snackbar-notify__text">
        <div class="content-icon" v-if="!errorSnack">
          <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
        </div>
        {{ this.alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// Socket
import channelConstructor from '../../socket/channels/quote_requests'

// Components
import SentQuoteRequestDetail from './sent-quote-request-detail'

export default {
  data () {
    return {
      errorSnack: false,
      position: 'left',
      isInfinity: false,
      duration: 4000,
      alertMsg: '',
      update: false
    }
  },

  components: {
    SentQuoteRequestDetail
  },

  computed: {
    ...mapState('quote_requests', {
      requests: state => state.sent
    }),
    ...mapGetters('quote_requests', [
      'activeSentRequests'
    ])
  },

  methods: {
    async subscribe () {
      await this.$store.dispatch('quote_requests/getSentQuoteRequests')
      this.requests.forEach((quote_request) => {
        this.$cable.create({ channelConstructor }, { quote_request })
      })
    },
    beforeLeave () {
      setTimeout(() => {}, 1000)
    },
    cancelOrder (obj) {
      this.alertMsg = obj.alertMsg
      this.errorSnack = obj.error
      this.update = true

      if (obj.cancelOrder) {
        setTimeout(() => {
          this.subscribe();
        }, 1000);
      }
    },
    clearCompleted () {
      const requests = this.activeSentRequests
      const completed = requests.filter(r => r.job.status === 'completed')
      const remove = completed.filter(r => {
        if (r.job.completed_at) {
          const d = new Date(r.job.completed_at)
          const twenty_min_ago = new Date() - 20*60*1000
          return (d < twenty_min_ago)
        } else { return false }
      })
      remove.forEach(r => {
        this.$store.dispatch('quote_requests/removeSentRequest', r.id)
      })

    }
  },

  created () {
    this.subscribe()
    setInterval(this.clearCompleted, 60*1000)
  }
}
</script>

<style scoped lang="scss">
.sent-qr-list-leave-to {
  opacity: 0;
}
.sent-qr-list-leave-active {
  transition: all .4s;
}
.sent-qr-list-move {
  transition: transform .4s;
}
</style>

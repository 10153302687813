<template>
 <md-step id="third" :md-label="titleStep" :md-description="titleDescription" :md-editable="false" :md-done.sync="done" class="third-step motorist-onboarding-step">
   <div class="row">
     <div class="col-md-6 relative">
      <md-autocomplete class="insurance-autocomplete" v-model="value" :md-options="insurances" @md-changed="getInsurances" @md-opened="getInsurances" tabindex="3" md-dense>
        <label>Insurance name</label>
        <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item }}</template>
      </md-autocomplete>
      <!-- <div class="form-group relative">
        <md-field>
          <label class="form__label">Insurance name</label>
          <md-input v-model="value" class="form-control" ref="caseMake" v-on:keyup="changeValue"></md-input>
        </md-field>
        <div class="content-complete-motorist">
          <ul class="carsmakes-list">
            <li class="carsmakes-list-item" v-for="insurance in insurances"><span>{{ insurance }}</span></li>
          </ul>
        </div>
      </div> -->
      <span class="example">e.g. "Geico"</span>
     </div>
   </div>
   <div class="row">
      <md-button :disabled="!deactivedButton" :class="[!deactivedButton ? 'deactive-button' : '','md-raised md-primary motorist-onboarding-button-save']" @click="saveInsurance">Save & continue</md-button>
      <md-progress-spinner class="motorist-onboarding__spinner" v-if="update" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
   </div>
 </md-step>
</template>

<script>
import Vue from 'vue'
import EventBus from '../../../../../mixins/event-bus'

export default {
  name: 'InsuranceInfo',
  props: ['job_assignment_id', 'motorist', 'steps'],
  data: () => ({
    titleStep: 'Enter your insurance info',
    titleDescription: 'We’ll use this to suggest places to get your vehicle repaired',
    done: false,
    value: '',
    update: false,
    insurances: [],
    listInsurance: ["I don't have insurance"]
  }),
  computed: {
    deactivedButton () {
      return this.value !== ''
    }
  },
  methods: {
    saveInsurance () {
      this.update = true
      let data = {
        job_reference: this.job_assignment_id,
        insurance: {
          name: this.value.toLowerCase().includes("i don't have insurance") ? '' : this.value
        }
      }
      Vue.http.post(`/insurances`, data, [{responseType: 'json', Accept: 'application/json'}])
        .then(response => {
            this.insurances = response.body
            setTimeout(() => {
              this.done = true
              this.$emit("next", "fourth");
              this.update = false
              EventBus.$emit('GET_BODY_SHOPS')

            },1000)
          }, err => {
            setTimeout(() => {
              this.done = false
              this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.msg : 'Something went Wrong', error: true})
              this.update = false
            },1000)
        });
    },
    getInsurances () {
      this.insurances = new Promise(resolve => {
        window.setTimeout(() => {
          if (!this.value) {
            resolve(this.listInsurance)
          } else {
            const term = this.value.toLowerCase()
            resolve(this.listInsurance.filter( name => name.toLowerCase().includes(term)))
          }
        }, 500)
      })
    }
  },
  created () {
    Vue.http.get(`/insurances` ,[{responseType: 'json'}])
      .then(response => {
          this.listInsurance = this.listInsurance.concat(response.body)
        }, err => {
          console.log(err)
      })
  },
  mounted () {
    if ( this.motorist ) {
      if (this.steps[this.motorist.completedSteps] !== "third" ) {
        this.done = true
        this.$emit("next", this.steps[this.motorist.completedSteps])
      }
    }
    let numberStepperText = document.querySelector('.third-step .md-stepper-number');
    numberStepperText.setAttribute('data-step-number', 3)
  }
}
</script>
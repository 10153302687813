<template>
  <div class="job-info-detail">
    <div class="container-padded">
      <div class="row">
        <div class="col-md-12 pt-4 pb-4 mb-5">
          <!-- Page Header -->
          <div v-if="isMotoristJob">
            <h4>{{ jobAddressTitle }}</h4>
            <p class="caption" v-if="(features && features.new_tower_details === false)">Requested at {{ formattedTime(request.created_at) }}</p>
          </div>
          
          <div v-else>
            <!-- Page Header: Dispatch Job (New) -->
            <h4>
              {{ jobHeader }}
              <StatusBadge v-if="jobDeclined" :status="'declined'" />
            </h4>

            <p class="caption" v-if="(features && features.new_tower_details === false)">Requested at {{ formattedTime(request.created_at) }}
              <span v-if="request_closed">
                / Expired
              </span>
              <span v-else-if="request.status == 'pending'">
                / Assigns in <TimeLeft :date="request.assigns_at" @update:expired="request_closed = true"/>
              </span>
            </p>
          </div>

          <!-- Job Information -->
          <div class="jumbotron" v-if="status === 'open' || status === 'new' || status === 'pending'">
            <QuoteRequestJobDetail :request="request" :job="request.job"/>
          </div>
          <div class="jumbotron" v-else>
            <AssignmentInfo 
              :request="request" 
              :assignments="assignments"
              :destination="destination"
              @complete="$emit('complete')" 
            />
          </div>

          <!-- Incident Vehicles Information -->
          <div v-if="(features && features.new_tower_details === true)">
            <div class="jumbotron" v-for="(assignment, index) in assignmentsList" :key="assignment.id">
              <!-- Header -->
              <div class="md-layout">
                <div class="md-layout md-alignment-center-left">
                  <h5 class="float-left">
                    Vehicle details {{ index + 1 }} of {{ assignmentsList.length }}
                  </h5>
                </div>
                <div class="assignment-buttons float-right">
                  <!-- "View Invoice" button -->
                  <md-button v-if="true === false"
                    class="md-primary float-right"
                    :disabled="false">
                    <md-icon class="btn-icon">receipt</md-icon>
                    View Invoice
                  </md-button>
                  <!-- "Mark Complete" button -->
                  <md-button 
                    v-if="showCompleteButton(assignment) && !isMotoristJob" 
                    class="md-primary mark-complete-button float-right"
                    :md-ripple="false" 
                    :disabled="isDisabled(assignment)"
                    @click="completeModalShow(assignment)"
                  >
                    <md-icon class="btn-icon">done</md-icon>
                    Mark Completed
                  </md-button>
                </div>
              </div>

              <!-- Body -->
              <div class="rectangle-3 mb-4"></div>
              <div class="row mb-5">
                <!-- Vehicle Information: Details -->
                <div class="col-md-6">
                  <VehicleDetail 
                    class="mt-1 mb-4" 
                    :key="assignment.incident_vehicle.plate" 
                    :vehicle="assignment.incident_vehicle" 
                    source="towco"
                    :showStatusBadge="assignment.status === 'canceled'"
                  />
                  
                  <CancellationNotes 
                    v-if="showCancellationNotes(assignment)" 
                    :note="assignment.cancelation_note ? assignment.cancelation_note.note : ''" 
                    :canceledBy="getCanceledByFullName(assignment)" 
                  />

                  <div v-if="assignment.driver">
                    <h6 class="job-info-detail__job-status-title">
                      {{ (assignment.status === 'completed') ? 'Job completed by' : 'Job assigned to' }}
                    </h6>

                    <DriverItem
                      class="mb-4"
                      :request="request"
                      :assignment="assignment"
                      :markedComplete="isComplete(assignment)"
                      @back="$emit('back')"
                    />

                    <div class="mb-0 pb-0">
                      <div class="row mb-0 pb-0" v-if="assignment.eventArrived.length">
                        <p class="mb-0 pb-0 col-md-6 status-event-label">Arrived</p>
                        <p class="mb-0 pb-0 col-md-6 faded-1">
                          {{ getDate(assignment.eventArrived[0].created_at) }} {{ formattedTime(assignment.eventArrived[0].created_at, true) }}
                        </p>
                      </div>
                      <div class="row" v-if="assignment.eventCompleted.length">
                        <p class="mb-0 pb-0 col-md-6 status-event-label">Marked completed</p>
                        <p class="mb-0 pb-0 col-md-6 faded-1">
                          {{ getDate(assignment.eventCompleted[0].created_at) }} {{ formattedTime(assignment.eventCompleted[0].created_at, true) }}
                        </p>
                      </div>
                      <div class="row" v-if="assignment.destination">
                        <p class="mb-0 pb-0 col-md-6 status-event-label">Drop off</p>
                        <p class="mb-0 pb-0 col-md-6 faded-1">
                          {{ assignment.destination }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Vehicle Information: Map -->
                <div class="col-md-6">
                  <div>
                    <JobInfoMap :job="request.job" :status="status"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Mark Complete -->
    <md-dialog :md-active.sync="complete_confirm_modal">
      <md-dialog-title>
        <h5>Mark as Complete</h5>
      </md-dialog-title>

      <md-dialog-content>
        <p>{{ modalMessage(selected_assignment) }}</p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="btn btn-secondary" @click="completeAssignment(selected_assignment)">
          Continue
        </md-button>
        <md-button class="btn btn-secondary" @click="completeModalCancel">
          Cancel
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex"

// Mixins
import jobServices from '../../mixins/jobServices';

// Components
import AssignmentInfo from './assignment-info'
import CancellationNotes from '../job-assignments/cancellation-notes'
import DriverItem from '../drivers/driver-item'
import JobInfoMap from '../maps/job-info-map'
import QuoteRequestJobDetail from '../forms/submit-quote/quote-request-job-detail'
import TimeLeft from '../utilities/timeleft'
import VehicleDetail from "../vehicles/vehicle-detail"
import StatusBadge from '../utilities/status-badge.vue'

export default {
  props: ['request', 'request_data', 'assignments'],

  components: {
    AssignmentInfo,
    CancellationNotes,
    DriverItem,
    JobInfoMap,
    QuoteRequestJobDetail,
    StatusBadge,
    TimeLeft,
    VehicleDetail,
  },

  mixins: [jobServices],

  data() {
    return {
      assignmentsList: [],
      request_closed: false,
      complete_confirm_modal: false,
      selected_assignment: {},
      completed_assignments: [],
      loading_assignments: []
    }
  },

  computed: {
    ...mapState('features', {
      features: state => state.features
    }),

    /**
     * Get the destination requested by the motorist.
     */
    destination() {
      const vehicles = (this.request_data && this.request_data.job.vehicles) || [];
      const destination = (vehicles.length > 0 && vehicles[0].destination) ? vehicles[0].destination.local_description : null;

      return destination;
    },

    /**
     * Returns the details heading according to the version required.
     * new_tower_details will display the job address and not set the incident type.
     */
    jobHeader() {
      if (!this.features) {
        return '';
      }

      return this.features.new_tower_details ? this.jobAddressTitle : this.request.job.incident_type;
    },

    status () {
      return this.parseStatusTower(this.request)
    },

    isMotoristJob () {
      return (this.request.motorist_id != null )
    },

    jobDeclined () {
      if (!this.assignments) return false;

      return this.assignments.every((assignment) => assignment.status === 'declined');
    },

    /**
     * It is necessary for the jobServices mixin.
     */
    job () {
      return this.request.job;
    }
  },

  created() {
    // Append arrived and completed date in the assignmentList.
    this.assignmentsList = this.request.job.assignments.filter(assignment => assignment.status !== 'reassigned').map(assignment => {
      return {
        ...assignment,
        eventCompleted: assignment.events ? assignment.events.filter(event => event.new_status === 'completed') : [],
        eventArrived: assignment.events ? assignment.events.filter(event => event.new_status === 'arrived') : []
      }
    });
  },

  methods: {
    ...mapActions('quote_requests', [
      'updateReceivedQuoteRequest'
    ]),
    getDate (date) {
      let datetime = new Date(date),
          year = datetime.getFullYear(),
          day = datetime.getDate() <= 9 ? `0${datetime.getDate()}` : datetime.getDate(),
          monthNumber = datetime.getMonth() + 1 === 11 ? datetime.getMonth() : datetime.getMonth() + 1,
          month = monthNumber <= 9 ? `0${monthNumber}` : monthNumber;
      return `${month}/${day}/${year}`;
    },
    completeModalShow (assignment) {
      this.selected_assignment = assignment
      this.complete_confirm_modal = true
    },
    completeModalCancel () {
      this.selected_assignment = {}
      this.complete_confirm_modal = false
    },
    completeAssignment (assignment) {
      this.complete_confirm_modal = false
      this.loading_assignments.push(assignment.id)
      this.$http.put(`/job_assignments/${assignment.id}/complete`)
        .then(response => {
          if (response.status == 200) {
            this.updateReceivedQuoteRequest({ id: this.request.id })
            const i = this.loading_assignments.indexOf(assignment.id)
            this.loading_assignments.splice(i, 1)
            this.completed_assignments.push(assignment.id)
            this.$emit('complete')
          } else {}
        })
    },
    showCompleteButton (assignment) {
      if (this.isComplete(assignment)) {
        return false
      } else {
        switch (assignment.status) {
          case 'notified':
          case 'started':
          case 'arrived':
          case 'departed':
            return true
            break;
          default:
            return false
        }
      }
    },
    isComplete (assignment) {
      const index = this.completed_assignments.indexOf(assignment.id)
      return (index != -1)
    },
    isDisabled (assignment) {
      const index = this.loading_assignments.indexOf(assignment.id)
      return (index != -1)
    },
    getCanceledByFullName(assign) {
      if (!assign || !assign.canceled_by) {
        return '';
      }

      return assign.canceled_by;
    },
    showCancellationNotes(assign) {
      return assign.cancelation_note && assign.status === 'canceled' && assign.cancelation_note.share;
    },
    modalMessage (assignment) {
      const driver = assignment.driver
      const requesting_org = assignment.requesting_org

      switch (assignment.status) {
        case 'notified':
          return `${driver.first} ${driver.last} has not acknowledged this assignment in the Curbside SOS app. Using the mobile app allows ${requesting_org.name} to monitor the incident and determine when roads are cleared. Continuing will inform dispatch the job is completed, and the job details will no longer be available to the operator. Are you sure you want to mark the job as complete?`
          break;
        case 'started':
          return `${driver.first} ${driver.last} has not arrived at the incident location. Continuing will inform dispatch the job is completed, and the job details will no longer be available to the operator. Are you sure you want to mark the job as complete?`
          break;
        case 'arrived':
          return `${driver.first} ${driver.last} has not left the incident location. Continuing will inform dispatch the job is completed, and the job details will no longer be available to the operator. Are you sure you want to mark the job as complete?`
          break;
        case 'departed':
        default:
          return `Are you sure you want to mark this job as complete?`
          break;

      }
    }
  },
}
</script>

<style scoped lang="scss">
  .job-info-detail {
    &__title {
      margin-bottom: 16px;
    }

    &__job-status-title {
      margin-bottom: 15px;
    }

    .mark-complete-button {
      margin-right: 0;

      &.md-button .md-ripple {
        padding: 0;
      }

      &.md-button:not([disabled]):hover:before {
        background-color: transparent;
      }
    }
  }

  .rectangle-3 {
    width: 100%;
    height: 1px;
    opacity: 0.12;
    background-color: rgba(15, 50, 70, 0.87);
  }
  .status-event-label {
    font-weight: bold;
    color: rgba(0, 51, 99, 0.65);
  }
</style>

<template>
  <div class="field">
    <fieldset class="grid grid-cols-2 gap-4" :name="`${name}Fields`">
      <legend class="date-range-label">
        {{ label || name }}
      </legend>

      <div class="row">
        <!-- From date -->
        <div class="col-6">
          <label class="full-width">
            From
            <input
              class="date-range-from"
              type="date"
              :value="fromDate"
              :max="maxDate"
              @input="onSelectedFromDate"
            />
          </label>
        </div>

        <!-- To date -->
        <div class="col-6">
          <label class="full-width">
            To
            <input
              class="date-range-from"
              type="date"
              :value="toDate"
              :min="fromDate"
              :max="maxDate"
              @input="onSelectedToDate"
            />
          </label>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
// import { useVuelidate } from '@vuelidate/core'
// import { requiredIf } from '@vuelidate/validators'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
  },

  /* setup() {
    return { v$: useVuelidate() }
  }, */

  /* validations() {
    return {
      fromDate: { requiredIfToDate: requiredIf(this.toDate) },
      toDate: { requiredIfFromDate: requiredIf(this.fromDate) },
    }
  }, */

  data() {
    return {
      maxDate: new Date().toISOString().split('T')[0],
    }
  },

  computed: {
    /**
     * Get the "from date" from the original concatenated dates.
     */
    fromDate() {
      return this.value?.from ? this.value.from : null
    },

    /**
     * Get the "to date" from the original concatenated dates.
     */
    toDate() {
      return this.value?.to ? this.value.to : null
    },
  },

  methods: {
    disabledDates(date) {
      return date > new Date();
    },

    /**
     * Concat from date and to date in a single string in this format mm/dd/yy - mm/dd/yy.
     *
     * @param {*} selectedDate
     */
    onSelectedFromDate(event) {
      const selectedDate = event.target.value;

      // this.v$.fromDate.$touch()
      this.$emit('change', this.name, {
        from: selectedDate,
        to: this.toDate,
      })
    },

    /**
     * Concat from date and to date in a single string in this format mm/dd/yy - mm/dd/yy.
     *
     * @param {*} selectedDate
     */
    onSelectedToDate(event) {
      const selectedDate = event.target.value;

      // this.v$.toDate.$touch()
      this.$emit('change', this.name, {
        from: this.fromDate,
        to: selectedDate,
      })
    },
  },
}
</script>

<style scoped>
.date-range-label {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.date-range-from {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .42);
  font-family: 'CircularStd', Helvetica, Arial, sans-serif !important;
  font-size: 1rem;
}
</style>

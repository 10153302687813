import Notify from '../../../utils/notify';

export default function channelConstructor (...options) {
  return new DispatchChannel(...options)
}

export class DispatchChannel {

  constructor ({ store }, { dispatcher }) {
    this.channel_name_base = "dispatch_channel_"
    this.store = store
    this.organization_id = dispatcher.id
  }

  // Called on connection
  connected () {
    return () => {
    }
  }

  // Called on disconnection
  disconnected () {
    return () => {}
  }

  // Called when data is received
  received () {
    // DispatchChannel broadcasts new assignments to towers
    // Push new assignment to Vuex store, let Vue do the rest
    return ({ data }) => {
      const current_organization = this.store.state.organizations.current;

      // PDs
      if (current_organization.id == this.organization_id) {
        this.store.dispatch('quote_requests/CABLE_addSentQuoteRequest', { id: data.id })
      } else {
        if ( data.type === 'updated' ) {
          this.store.dispatch('quote_requests/updateReceivedQuoteRequest', { id: data.id })
        } else {
          let index = this.store.state.quote_requests.received.findIndex((request) => request.id === data.id)
          if ( index == -1 ) {
            this.store.dispatch('quote_requests/CABLE_addReceivedQuoteRequest', { id: data.id })
          }
        }
      }

      // Blink title page if the job has not driver assigned five minutes later
      if (data.type === 'inactive_new_job') {
        Notify.build({
          notification: {
            title: 'The assigned tow company has not responded to the job.'
          }
        });
      }
    }
  }

  key () {
    return this.channel_name_base + this.organization_id
  }

  channel () {
    return {
      channel: "DispatchChannel",
      organization_id: this.organization_id
    }
  }

  mixins () {
    return {
      connected: this.connected(),
      disconnected: this.disconnected(),
      received: this.received()
    }
  }
}

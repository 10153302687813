<template>
  <div class="create-account margin-auto">
    <div class="card create-account__content">
      <img class="create-account__logo" src="../../../../images/logo_curbsidesos_dark.png" />
      <h2 class="create-account__title">Change your password</h2>
      <p class="create-account__description" v-if="emailUser">Enter a new password for {{ emailUser }} </p>
      <p class="create-account__description" v-else>Enter a new password for reset password</p>
      <form class="create-account__form">
        <div class="create-account__content-fields col-md-9 margin-auto">
          <div class="form-group">
            <md-field :class="getValidationClass('create', 'password')">
              <label class="form__label">Password</label>
              <md-input v-model="create.password" type="password" class="form-control" ref="emailUser" v-on:keyup="getText('password')"></md-input>
              <span class="md-error" v-if="!$v.create.password.required">This field is required</span>
              <span class="md-error" v-else-if="!$v.create.password.minLength">Password must be at least 8 characters long</span>
            </md-field>
          </div>
          <div class="form-group">
            <md-field :class="getValidationClass('create', 'passwordConfirmation')">
              <label class="form__label">Confirm Password</label>
              <md-input v-model="create.passwordConfirmation" type="password" class="form-control" v-on:keyup="getText('passwordConfirmation')"></md-input>
              <span
                class="md-error"
                v-if="!$v.create.passwordConfirmation.required"
              >This field is required</span>
              <span
                class="md-error"
                v-else="!$v.create.passwordConfirmation.sameAsPassword"
              >Passwords must be identical.</span>
            </md-field>
          </div>
          <div class="form-group">
            <md-button v-if="!isCompletedForm" class="create-account__button btn btn-primary disable-button">Change Password</md-button>
            <md-button v-else class="create-account__button btn btn-primary" @click="sendForm('.js-change-password-form')">Change Password</md-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: 'ChangeForgetPassword',
  data: () => ({
    create: {
      password: '',
      passwordConfirmation: ''
    },
    emailUser: '',
    passwordElement: null,
    passwordConfirmationElement: null
  }),
  validations: {
    create: {
      password: {
        required,
        minLength: minLength(8)
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  computed: {
    isCompletedForm () {
      return this.$v.create.password.required && this.$v.create.passwordConfirmation.required
    }
  },
  methods: {
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    sendForm (target) {
      let form = document.querySelector(target);
      this.$v.$touch()
      if (!this.$v.$invalid) {
        localStorage.removeItem('emailUser')
        form.submit();
      }
    },
    show() {
    	this.display = true;
    },
    getText (target) {
      if (target === 'password') {
        this.$v.create.password.$touch()
        this.passwordElement.value = this.create.password
      } else {
        this.$v.create.passwordConfirmation.$touch()
        this.passwordConfirmationElement.value = this.create.passwordConfirmation
      }
    },
    focus() {
    	this.$refs.emailUser.$el.focus();
    },
    nextTickFocus() {
    	this.show();
      this.$nextTick(this.focus)
    },
  },
  mounted () {
    this.nextTickFocus();
    this.passwordElement = document.querySelector('.js-password-field')
    this.passwordConfirmationElement = document.querySelector('.js-password-confirmation-field')
    if(localStorage.getItem('emailUser')) {
      this.emailUser = localStorage.getItem('emailUser')
    }
  }
}
</script>

<style lang="scss" scoped>
  .disable-button {
    background: #f7f9fa !important;
    pointer-events: none;
    box-shadow: none;
    box-shadow: 0 2px 2px 0 rgba(0,0,0, .14), 0 3px 1px -2px rgba(0,0,0, .2), 0 1px 5px 0 rgba(0,0,0, .12) !important;
    .md-ripple .md-button-content {
      color: rgba(0, 0, 0, 0.38);
    }
  } 

  .span-message {
    position: relative;
    top: 13px;
  }
</style>
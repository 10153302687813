import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  all: [],
  available: [],
}

export default {
  namespaced: true,
  state,
  actions,
  getters, 
  mutations
};

<template>
  <div class='dispatch-map'>
    <Map :locations="locations" :drivers="selected_drivers"></Map>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// Components
import Map from './map'

export default {
  components: {
    Map
  },

  data () {
    return {
      selected_drivers: []
    }
  },

  computed: {
    ...mapState('views', {
      selected_quote_request: state => state.selected_quote_request
    }),
    ...mapGetters('quote_requests', [
      'activeSentRequests'
    ]),
    // We might want to move this? Make more accessible elsewhere:
    selectedQuoteRequest () {
      if (this.selected_quote_request != '') {
        const selected = this.activeSentRequests.filter(request => request.id === this.selected_quote_request)
        return selected[0]
      } else {
        return null
      }
    },
    selectedLocations () {
      return this.formatRequests([this.selectedQuoteRequest])
    },
    locations () {
      if (this.selectedQuoteRequest) {
        return this.selectedLocations
      } else {
        return this.formatRequests(this.activeSentRequests)
      }
    }
  },

  watch: {
    // Watch functions need "function syntax" for Vue reasons
    selectedQuoteRequest: function (a, b) {
      // Wrapping this set in a $nextTick so the map doesn't choke on swapping locations
      this.$nextTick(() => {
        this.selected_drivers = this.getDriverLocations()
      })
    },

  },

  methods: {
    parsedStatus (request) {
      if (request.status === 'completed') {
        return request.job.status
      } else if (request.status === 'assigning' || request.status === 'closed') {
        return 'pending'
      } else {
        return request.status
      }
    },
    formatRequests (requests) {
      return requests.map(x => ({
        id: x.id,
        latitude: x.job.latitude,
        longitude: x.job.longitude,
        type: this.parsedStatus(x)
      }))
    },
    getDriverLocations () {
      // Filter out assignments that do not yet have drivers
      if (this.selectedQuoteRequest) {
        const assignmentsWithDrivers = this.selectedQuoteRequest.job.assignments.filter(assignment => {
          return (this.showDriverStatus(assignment) && assignment.hasOwnProperty("driver"))
        })

        return assignmentsWithDrivers.map(assignment => ({
          id: assignment.driver.id,
          latitude: assignment.driver.latitude,
          longitude: assignment.driver.longitude,
          type: 'driver'
          }))
      } else {
        return []
      }
    },
    showDriverStatus (assignment) {
      switch (assignment.status) {
        case 'started':
        case 'arrived':
          return true
          break;
        default:
          return false
      }
    }
  }

}
</script>

<style scoped lang='scss'>
</style>

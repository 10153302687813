<template>
  <div class="content-view-detail">
    <JobInfoMap :job="infoJob"></JobInfoMap>
    <div class="container-fluid">
      <div class="col-md-9 margin-auto">
        <div class="relative job-detail-title">
          <button class="link-goback link-goback--history" @click="goBack">
            <svg class="job-detail-arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
              <defs>
                <path id="poin33625" d="M18.791 11.005H7.621l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41l-4.88-4.88h11.17c.55 0 1-.45 1-1s-.45-1-1-1z"/>
              </defs>
              <g fill="none" fill-rule="evenodd">
                <mask id="mv538b363ge" fill="#fff">
                  <use xlink:href="#poin33625"/>
                </mask>
                <g fill="#000" fill-opacity=".54" mask="url(#mv538b363ge)">
                  <path d="M0 0h24v24H0z"/>
                </g>
              </g>
            </svg>
          </button>

          <h2 class="job-incident-title">
            {{ infoJob.incidentType }}
            
            <StatusBadge v-if="infoJob.status === 'canceled'" :status="'canceled'" text="canceled" />
            <StatusBadge v-if="jobDeclined" :status="'declined'" />
          </h2>

          <!-- CANCELLATION NOTES: This is only rendered on one time canceled jobs -->
          <CancellationNotes 
            v-if="showCancellationNotes" 
            :canceledBy="canceledBy" 
            :note="infoJob.cancellationNoteNote" 
            :showTitle="false"
          />
        </div>

        <incident-details
          :location="infoJob.address"
          :incidentNumber="infoJob.incidentNumber"
          :incidentType="infoJob.incidentType"
          :officer="infoJob.officer"
          :destination="destination"
          :details="infoJob.details"
          :organization="organization"
          :department="infoJob.quoteRequest.requestingDepartment"
          :isMotorist="infoJob.quoteRequest.isMotorist"
          :motorist="infoJob.quoteRequest.motorist"
          :quote="infoJob.quoteRequest.winningQuote"
          :zone="infoJob.zone"
        ></incident-details>

        <vehicles-and-wreckers
          :vehicles="infoJob.vehicles"
          :assignments="infoJob.assignments"
          :organization="organization"
          :isJobCancel="infoJob.status === 'canceled'"
          :isJobDeclined="jobDeclined"
          :wasWholeCanceled="wasWholeCanceled"
        >
          <PaymentDetails v-if="this.infoJob.quoteRequest.winningQuote" :quote="this.infoJob.quoteRequest.winningQuote" />
        </vehicles-and-wreckers>
      </div>
    </div>
  </div>
</template>

<script>

// Libraries
import humps from 'humps';

// Components
import CancellationNotes from '../job-assignments/cancellation-notes';
import IncidentDetails from './incident-details';
import PaymentDetails from './payment-details';
import VehiclesAndWreckers from './vehicles-and-wreckers';
import JobInfoMap from '../maps/job-info-map';
import StatusBadge from '../utilities/status-badge';

export default {
  name: 'JobDetailView',
  props: ['info_data', 'organization_data'],

  components: {
    CancellationNotes,
    IncidentDetails,
    PaymentDetails,
    VehiclesAndWreckers,
    JobInfoMap,
    StatusBadge
  },

  computed: {
    wasWholeCanceled() {
      return this.infoJob.status === 'canceled' && this.infoJob.cancellationNoteCanceledBy && this.infoJob.cancellationNoteNote !== undefined;
    },

    showCancellationNotes() {
      if (this.wasWholeCanceled && (this.organization.orgType === 'dispatcher' || (this.organization.orgType === 'tower' && this.infoJob.cancellationNoteShare))) {
        return true;
      }

      return false;
    },

    /**
     * Returns the canceled by only when the current organization type is disptacher.
     */
    canceledBy() {
      return (this.organization.orgType === 'dispatcher') ? this.infoJob.cancellationNoteCanceledBy : null;
    },

    /**
     * Get the destination requested by the motorist.
     */
    destination() {
      const vehicles = (this.infoJob.vehicles) || [];
      const destination = (vehicles.length > 0 && vehicles[0].destination) ? vehicles[0].destination.localDescription : null;

      return destination;
    }
  },

  data: () => ({
    infoJob: '',
    organization: '',
    cancelJob: [],
    jobDeclined: false
  }),

  async created () {
    this.infoJob = humps.camelizeKeys(this.info_data);
    this.organization = humps.camelizeKeys(this.organization_data);
    this.cancelJob = this.infoJob.assignments.filter(assign => assign.status === 'canceled').length ? true : false;
    this.jobDeclined = this.infoJob.assignments.every((assignment) => assignment.status === 'declined');
    
    await this.$store.dispatch('organizations/getCurrentOrganization')
  },

  methods: {
    goBack() {
      window.history.back()
    }
  }
}
</script>

<style lang="scss" scoped>
  .job-detail-section-title {
    font-size: 15px;
    text-transform: uppercase;
  }

  .cancelation-note {
    margin-top: 0;
    font-size: 16px;
    color: rgba(15, 50, 70, 0.65);

    &__list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  .link-goback--history {
    appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
  }
</style>

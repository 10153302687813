// Vue
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import TurbolinksAdapter from 'vue-turbolinks'
import Vuelidate from 'vuelidate'
import VueResource from "vue-resource";

// Import top-level Vue components
import DispatchDashboard from './components/dispatch-dashboard'
import MobileQuoteRequest from './components/mobile-quote-request'
import Table from './components/table'
import WithToken from './components/with-token'
import TowerDashboard from './components/tower-dashboard'
import FormWizard from './components/form-wizard'
import MenuApp from './components/menu-app'
import formVehicle from './components/forms/vehicle/form-vehicle'
import formDriver from './components/forms/driver/form-driver'
import formUser from './components/forms/user/form-user'
import formWrecker from './components/forms/wrecker/form-wrecker'
import accountForm from './components/forms/account-form/account-form'
import AppStoreRedirect from './components/app-stores-redirect'
import OrganizationView from './components/organization/organization-view'
import TowcoOrganizationProfile from './components/towco-settings/towco-organization-profile'
import JobDetailView from './components/job-detail/job-detail-view';
import ForgetPassword from './components/password/forget-password';
import ChangeForgetPassword from './components/password/change-forget-password';
import MyProfile from './components/my-profile/profile';
import Motorist from './components/motorist-info/motorist';
import MotoristInfoJob from './components/motorist-info/motorist-info-job/motorist-info-job';
import NotificationSettings from './components/notifications/notifications-settings';
import CustomerMotorist from './components/motorist-info/customer-motorist';

//Motorist v1
import MotoristSearch from './components/motorist-info-v1/motorist-search';
import MotoristInfoJobV1 from './components/motorist-info-v1/motorist-info-job/motorist-info-job';

// Third Party Components and Plugins
import * as VueGoogleMaps from 'vue2-google-maps'
import throttle from 'lodash.throttle'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import { format } from 'timeago.js'
import VueMaterial from 'vue-material'

// Custom Plugins, Mixins, and Settings
import Cable from './socket/cable'
import customMixins from './mixins'
import mapSettings from './config/maps'

// Vue dev tools
Vue.config.devtools = process.env.NODE_ENV === 'development' || location.host.includes('develop.');

// Vuex Store
import { modules } from "./store"

// Initializations
Vue.use(Vuex)
Vue.use(TurbolinksAdapter)
Vue.use(VueGoogleMaps, mapSettings)
Vue.use(VueMaterial)
Vue.use(Vuelidate)
Vue.use(VueResource);

Vue.prototype.$throttle = throttle
Vue.prototype.$timeago = format

// I hate this, but I think it's the best workaround right now
// for using Vuex modules
const store = new Vuex.Store({
  modules
})

// Custom Initializations
Vue.use(new Cable(store))
Vue.mixin(customMixins)

// Load Components
Vue.component('custom-marker', GmapCustomMarker)
Vue.component('menu-app', MenuApp)
Vue.component('form-vehicle', formVehicle)
Vue.component('form-driver', formDriver)
Vue.component('form-user', formUser)
Vue.component('form-wrecker', formWrecker)
Vue.component('account-form', accountForm)

// And let's get rollin'
document.addEventListener("turbolinks:load", function() {
  var tower_dashboard = document.getElementById("vue-tower-dashboard")
  var dispatch_dashboard = document.getElementById("vue-dispatch-dashboard")
  var quote_request = document.getElementById("vue-quote-request")
  var form_wizard = document.getElementById("vue-form-wizard")
  var navbar = document.getElementById("vue-navbar")

  var index_table = document.getElementById("vue-index-table")
  var form_vehicle_driver = document.getElementById("form-vehicle-driver");
  var form_vehicle_wrecker = document.getElementById("form-vehicle-wrecker");
  var account_form = document.getElementById('account-form');
  let table_data = {}
  var store_redirect = document.getElementById("vue-app-stores-redirect")
  var organization_edit = document.getElementById("organization_edit");
  var towco_organization_profile = document.getElementById("towco_organization_profile");
  var job_detail = document.getElementById("job-detail-view") // History - Job Details Page
  var forget_password_view = document.getElementById("forget-password");
  var change_forget_password = document.getElementById("change-forget-password")
  var my_profile = document.getElementById('my_profile');
  var motorist = document.getElementById('motorist');
  var motorist_info_job = document.getElementById('motorist-info-job');
  var notification_settings = document.getElementById('notification_settings');
  var validate_customer_motorist = document.getElementById('validate-customer-motorist');

  let app;

  if (tower_dashboard) {
    app = new Vue({
      el: tower_dashboard,
      store,
      components: {
        TowerDashboard,
        WithToken
      }
    })
  }

  if (dispatch_dashboard) {
    app = new Vue({
      el: dispatch_dashboard,
      store,
      template: "<DispatchDashboard />",
      components: { DispatchDashboard }
    })
  }

  if (quote_request) {
    app = new Vue({
      el: quote_request,
      store,
      template: "<MobileQuoteRequest />",
      components: { MobileQuoteRequest }
    })
  }

  if (form_wizard) {
    app = new Vue({
      el: form_wizard,
      components: { FormWizard }
    })
  }


  if (navbar) {
    const navbar_app = new Vue({
      el: navbar
    })
  }

  if (index_table) {
    table_data = JSON.parse(JSON.stringify(index_table.dataset))
    const table = new Vue({
      el: index_table,
      template: "<Table :table_data='table_data' />",
      components: { Table },
      data: () => ({
        table_data: table_data
      })
    })
  }

  if(form_vehicle_driver) {
    const form = new Vue({
      el: form_vehicle_driver,
      data: () => ({
        nameFile: null
      }),
      methods: {
        getFileName (e) {
          this.nameFile = e.target.files[0].name;
        }
      }
    })
  }

  if(form_vehicle_wrecker) {
    const form = new Vue({
      el: form_vehicle_wrecker,
    })
  }

  if(account_form) {
    const account = new Vue({
      el: account_form
    })
  }

  if (store_redirect) {
    app = new Vue({
      el: store_redirect,
      components: { AppStoreRedirect }
    })
  }

  if (organization_edit) {
    app = new Vue({
      el: organization_edit,
      components: {
        OrganizationView
      }
    })
  }

  if (towco_organization_profile) {
    app = new Vue({
      el: towco_organization_profile,
      components: {
        TowcoOrganizationProfile
      }
    })
  }

  if(job_detail) {
    app = new Vue({
      el: job_detail,
      store,
      components: {
        JobDetailView
      }
    })
  }
  if (forget_password_view) {
    app = new Vue({
      el: forget_password_view,
      components: {
        ForgetPassword
      }
    })
  }

  if (change_forget_password) {
    app = new Vue({
      el: change_forget_password,
      components: {
        ChangeForgetPassword
      }
    })
  }


  if(my_profile) {
    app = new Vue({
      el: my_profile,
      components: {
        MyProfile
      }
    })
  }

  if(motorist) {
    app = new Vue({
      el: motorist,
      components: {
        Motorist,
        MotoristSearch
      }
    })
  }

  if(motorist_info_job) {
    app = new Vue({
      el: motorist_info_job,
      components: {
        MotoristInfoJob,
        MotoristInfoJobV1
      },
      mounted () {
        let content = document.querySelector('.content');
        content.classList.add('motorist-content');
      }
    })
  }

  if (notification_settings) {
    const app = new Vue({
      el: notification_settings,
      components: {
        NotificationSettings
      }
    })
  }

  if ( validate_customer_motorist ) {
    const app = new Vue({
      el: validate_customer_motorist,
      components: {
        CustomerMotorist
      }
    })
  }

  if (app) {
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app.constructor
  }

});

<template>
  <md-card class="card card-notification">
    <p class="title-card">Jobs in progress</p>
    <div class="card-notification__item">
      <div class="row">
        <div :class="[admin ? 'col-md-6' : 'col-md-8']">
        <div>
            <p class="card-notification__title">Job was updated</p>
        </div>
        </div>
        <div :class="[admin ? 'col-md-6' : 'col-md-4']">
          <div class="card-notification__options">
            <md-checkbox v-model="jobWasUpdated.email">Email</md-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="card-notification__item">
      <div class="row">
        <div :class="[admin ? 'col-md-6' : 'col-md-8']">
        <div>
            <p class="card-notification__title">Job was canceled</p>
        </div>
        </div>
        <div :class="[admin ? 'col-md-6' : 'col-md-4']">
          <div class="card-notification__options">
            <md-checkbox v-model="jobWasCanceled.email">Email</md-checkbox>
            <md-checkbox v-model="jobWasCanceled.sms">SMS</md-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="card-notification__item">
      <div class="row">
        <div :class="[admin ? 'col-md-6' : 'col-md-8']">
        <div>
            <p class="card-notification__title">Job has not been marked completed</p>
        </div>
        </div>
        <div :class="[admin ? 'col-md-6' : 'col-md-4']">
          <div class="card-notification__options">
            <md-checkbox v-model="jobHasNotBeenMarkedCompleted.email">Email</md-checkbox>
          </div>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="showDialog" class="notification-dialog">
      <md-dialog-title class="notification-dialog__title">At least one method should be checked</md-dialog-title>
      <md-dialog-content>
        <p class="notification-dialog__description">In order to not miss any tow opportunity that was specifically assigned to you, you must keep at least one method on.</p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onConfirm">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
  </md-card>
</template>

<script>
export default {
  name: 'JobsInProgress',
  props: ['notifications'],
  data: () => ({
    showDialog: false,
    optionsList: ['jobWasUpdated','jobWasCanceled','jobHasNotBeenMarkedCompleted'],
    jobWasUpdated: {
      email: false
    },
    jobWasCanceled: {
      email: true,
      sms: false
    },
    jobHasNotBeenMarkedCompleted: {
      email: false
    },
    admin: false
  }),
  watch: {
    notifications: function(settingVal,oldVal) {
      for (let i=0; i < this.optionsList.length; i++) {
        for (let k=0; k < settingVal.length ; k++) {
          if (settingVal[k].name === this.optionsList[i]) {
            this[this.optionsList[i]][settingVal[k].option] = settingVal[k].value
          }
        }
      }
    }
  },
  methods: {
    onConfirm () {
      this.showDialog = false
    },
    setSettingArray (optionList) {
      let data = [];
      for ( let i=0; i < optionList.length; i++ ) {
        let checkboxList = Object.values(this[optionList[i]]),
            checkboxKeys = Object.keys(this[optionList[i]]),
            numberOptions = checkboxList.length;

          for ( let k=0; k < checkboxList.length; k++ ) {
            data.push({
              name: optionList[i],
              option: checkboxKeys[k],
              value: checkboxList[k]
            })
          }
      }
      return data
    }
  },
  updated () {
    this.$emit('show:settings', {data:this.setSettingArray(this.optionsList), key: 'jobInProgress'})
  }
}
</script>
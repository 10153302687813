<template>
  <div class="history-table">
    <DataTable
      :data="history"
      title="History"
      type-table="history"
      :filters-message="filtersMessage"
      :organization="organization"
      @toggle-filter="toggleFilters"
    />

    <TableResponsiveHistory
      :data="history"
      title="History"
      type-table="history"
      :filters-message="filtersMessage"
      :organization="organization"
      @toggle-filter="toggleFilters"
    />

    <SidebarFilters
      v-if="organization === 'tower'"
      :fields.sync="filterFields"
      :visible.sync="filtersVisible"
      @apply="applyFilters"
      @clear="clearFilters"
    />
    <SidebarFilters
      v-else
      :fields.sync="filterFields911"
      :visible.sync="filtersVisible"
      @apply="applyFilters"
      @clear="clearFilters"
    />

    <div
      v-if="total_pages > 1"
      class="container"
    >
      <div class="content-pagination">
        <Pagination :total_pages="total_pages" :current_page="this.current_page" />
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '../data-table'
import TableResponsiveHistory from '../history/table-responsive-history'
import SidebarFilters from '../../sidebar-filters/sidebar-filters.vue'
import Pagination from '../../pagination';

export default {
  props: ['departments', 'history', 'total_pages', 'organization', 'zones', 'current_page'],
  components: {
    DataTable,
    TableResponsiveHistory,
    SidebarFilters,
    Pagination,
  },

  data() {
    const queryParams = new URLSearchParams(window.location.search);
    const incidentDate = queryParams.get('filter_by[incident_date]');
    const incidentDateArray = incidentDate ? incidentDate.split(',') : [null, null];
    const requestedDate = queryParams.get('filter_by[requested_at]');
    const requestedDateArray = requestedDate ? requestedDate.split(',') : [null, null];
    const departments = this.departments?.map((department) => ({
      label: department.name,
      value: department.id
    }))
    const zones = this.zones?.map((zone) => ({
      label: `${zone.name} - ${zone.code}`,
      value: zone.id
    }))
    departments?.unshift({ label: 'All', value: '' });
    zones?.unshift({ label: 'All', value: '' });

    return {
      filtersVisible: false,
      filterFields: [
        {
          name: 'incident_number',
          label: 'Incident No.',
          type: 'text',
          value: queryParams.get('filter_by[incident_number]'),
        },
        {
          name: 'incident_date',
          label: 'Incident date.',
          type: 'date',
          value: {
            from: incidentDateArray[0],
            to: incidentDateArray[1],
          },
        },
        {
          name: 'source',
          label: 'Source',
          type: 'options',
          options: [
            'All',
            'Department',
            'Motorist',
          ],
          value: queryParams.get('filter_by[source]'),
        },
        {
          name: 'drivers',
          label: 'Driver',
          type: 'text',
          value: queryParams.get('filter_by[drivers]'),
        },
        {
          name: 'make',
          label: 'Make',
          type: 'text',
          value: queryParams.get('filter_by[make]'),
        },
        {
          name: 'model',
          label: 'Model',
          type: 'text',
          value: queryParams.get('filter_by[model]'),
        },
        {
          name: 'location',
          label: 'Location',
          type: 'text',
          value: queryParams.get('filter_by[location]'),
        },
      ],
      filterFields911: [
        {
          name: 'requested_at',
          label: 'Requested at',
          type: 'date',
          value: {
            from: requestedDateArray[0],
            to: requestedDateArray[1],
          },
        },
        {
          name: 'incident_number',
          label: 'Incident No.',
          type: 'text',
          value: queryParams.get('filter_by[incident_number]'),
        },
        {
          name: 'service_provider',
          label: 'Service provider',
          type: 'text',
          value: queryParams.get('filter_by[service_provider]'),
        },
        {
          name: 'make',
          label: 'Make',
          type: 'text',
          value: queryParams.get('filter_by[make]'),
        },
        {
          name: 'model',
          label: 'Model',
          type: 'text',
          value: queryParams.get('filter_by[model]'),
        },
        {
          name: 'plate',
          label: 'Plate',
          type: 'text',
          value: queryParams.get('filter_by[plate]'),
        },
        {
          name: 'location',
          label: 'Location',
          type: 'text',
          value: queryParams.get('filter_by[location]'),
        },
        {
          name: 'zone',
          label: 'Zone',
          type: 'options',
          options: zones,
          value: queryParams.get('filter_by[zone]'),
        },
        {
          name: 'department',
          label: 'Department',
          type: 'options',
          options: departments,
          value: queryParams.get('filter_by[department]'),
        },
        {
          name: 'officer',
          label: 'Officer',
          type: 'text',
          value: queryParams.get('filter_by[officer]'),
        },
      ],
    };
  },
  computed: {
    /**
     * Return a message with the filters applied.
     */
    filtersMessage() {
      const totalFilters = window.location.search.split('filter_by').length - 1;

      return totalFilters > 0 ? `${totalFilters} filter(s) applied` : null;
    },
  },
  created() {
    if (window.navigator.userAgent.includes('Firefox')) {
      this.history = this.history.reverse();
    }
  },
  methods: {
    /**
     * Apply the filters to the current URL and refresh the view.
     * @param {object} filters And object with the filters and their values.
     */
    applyFilters(filters) {
      const queryParams = new URLSearchParams(window.location.search);
      const keys = Object.keys(filters);

      // Iterate over the filters and add them to the query params or remove them if they are empty.
      keys.forEach((key) => {
        let { type, value } = filters[key];

        if (!value || (type === 'options' && value === 'All') || (type === 'date' && (!value.from || !value.to))) {
          queryParams.delete(`filter_by[${key}]`);
          return;
        }
        if (type === 'date') {
          value = `${value.from},${value.to}`;
        }

        queryParams.set(`filter_by[${key}]`, value);
      });

      // Remove page query param to reset the pagination.
      queryParams.delete('page');

      window.location.href = `${window.location.pathname}?${queryParams.toString()}`;
    },

    /**
     * Remove all applied filters from the query params.
     */
    clearFilters() {
      const queryParams = new URLSearchParams(window.location.search);
      const keys = [...queryParams.keys()];

      for (const key of keys) {
        if (key.includes('filter_by')) {
          queryParams.delete(key);
        }
      }

      // Remove page query param to reset the pagination.
      queryParams.delete('page');

      window.location.href = `${window.location.pathname}?${queryParams.toString()}`;
    },

    /**
     * Open and close the filters sidebar.
     */
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible;
    },
  },
};
</script>

<template>
  <md-card class="card account-owner card-wrecker-detail card-box-profile card-box offset-card section password-card">
    <h2 class="title-card">Personal info</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <transition name="slide">
      <div class="card-box__content" v-if="openBox">
        <div class="card-box__content-form js-box-content-form">
          <div class="row">
            <div v-if="!edit_info" class="form-group col-md-6">
              <div class="field">
                <label class="form__label">First name</label>
                <div v-if="user.first_name" class="form__field">{{user.first_name}}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass('user','first_name')">
                  <label class="form__label">First name</label>
                  <md-input v-model="user.first_name" class="form-control" v-on:keyup="detectChange()"></md-input>
                  <span class="md-error" v-if="!$v.user.first_name.required">First name is required</span>
                </md-field>
              </div>
            </div>

            <div v-if="!edit_info" class="form-group col-md-6">
              <div class="field">
                <label class="form__label">Last name</label>
                <div v-if="user.last_name" class="form__field">{{user.last_name}}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <md-field :class="getValidationClass('user','last_name')">
                  <label class="form__label">Last name</label>
                  <md-input v-model="user.last_name" class="form-control" v-on:keyup="detectChange()"></md-input>
                   <span class="md-error" v-if="!$v.user.last_name.required">Last name is required</span>
                </md-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
              <div class="field margin-bottom-no">
                <label class="form__label">Mobile phone number</label>
                <div v-if="user.phone_format" class="form__field">{{ user.phone_format }}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group form-group-number">
                <md-field :class="getValidationClass('user','phone_number')">
                  <label class="form__label">Mobile phone number</label>
                  <md-input v-model="user.phone_format" class="form-control" v-on:keyup="caretPhone(); detectChange();"></md-input>
                  <span class="md-error" v-if="!$v.user.phone_number.required">Mobile phone number is required</span>
                </md-field>
                <span
                  class="validate-number bussiness-number"
                  v-if="!validate_number"
                  >The Mobile Number must be at least 10 characters long</span>
              </div>
            </div>
            
            <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
              <div class="field margin-bottom-no">
                <label class="form__label">Email</label>
                <div v-if="user.email" class="form__field">{{ user.email }}</div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-6 col-sm-12">
              <div class="form-group">
                <div class="field">
                  <label class="form__label">Email</label>
                  <div v-if="user.email" class="form__field">{{user.email}}</div>
                  <div v-else class="form__field value-none">None</div>
                 </div>
                <span>You can’t edit your email.</span>
              </div>
            </div>
          </div>
          <transition name="slide">
            <div v-if="edit_info" class="row">
              <div class="card-box__content-actions">
                <md-button :class="[!isCompletedForm || !deactived_button ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
                <md-button class="md-accent card-box__button-cancel" @click="cancel">Cancel</md-button>
                <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </md-card>
</template>

<script>
import Vue from 'vue';
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: 'PersonalInfo',
  props: ['userData'],
  data: () => ({
    user: {
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_format: '',
      email: ''
    },
    validate_number: false,
    edit_info: false,
    openBox: false,
    update: false,
    deactived_button: false,
    up_to_date: false
  }),
  validations: {
    user: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      phone_number: {
        required,
        minLength: minLength(10)
      },
      email: {
        required,
        email
      }
    }
  },
  computed: {
    isCompletedForm () {
      return this.user.first_name && this.user.last_name && this.user.phone_number ? true : false
    }
  },
  methods: {
    formatPhoneNumber (str) {
      const cleaned = ("" + str).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
      return null;
    },
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    detectChange () {
      this.deactived_button = true;
    },
    editInfo () {
      this.cancel()
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    caretPhone () {
      var x = this.user.phone_format.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.user.phone_format = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

      this.user.phone_number =  this.user.phone_format.replace(/[()]/g, '');
      this.user.phone_number = this.user.phone_number.replace(/-/g, "").replace(" ", "");

      this.validate_number = this.$v.user.phone_number.minLength
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit () {
      this.update = true;
      this.deactived_button = true;
      
      Vue.http.put(`/users/update_my_profile`, this.user ,[{responseType: 'json'}])
        .then(response => {
            this.update = false;
            this.deactived_button = false;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
            this.up_to_date = true
            this.edit_info = false
          setTimeout(() => {  
            window.location.reload();
          },1000)
        }, err => {
          setTimeout(() => {
            this.update = false;
            this.deactived_button = false;
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.error : 'Something went Wrong', error: true})
            this.up_to_date = false
          },1000)
      });
    },
    cancel () {
      this.edit_info = !this.edit_info;
      this.deactived_button = false;
      if(!this.up_to_date) {
        this.user.first_name = this.userData.first_name;
        this.user.last_name = this.userData.last_name;
        this.user.phone_format = this.formatPhoneNumber(this.userData.phone_number);
        this.user.phone_number = this.userData.phone_number;
        this.user.email = this.userData.email
        this.validate_number = this.userData.phone_number !== null ? this.userData.phone_number.length === 10 ? true : false : ''
      }
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    this.user.first_name = this.userData.first_name;
    this.user.last_name = this.userData.last_name;
    this.user.phone_format = this.formatPhoneNumber(this.userData.phone_number);
    this.user.phone_number = this.userData.phone_number;
    this.user.email = this.userData.email
    this.validate_number = this.userData.phone_number !== null ? this.userData.phone_number.length === 10 ? true : false : ''
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  } 
}
</script>

<style lang="scss" scoped>
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
</style>
<template>
	<!-- Specify Location -->
    <div class="location-details">
      <h5>Incident Location</h5>
      <h6>{{ request.job.address }}</h6>
      <p class="faded-1 mb-0 pb-0">Zone: {{ zoneFormatted }}</p>
      <p class="faded-1 mb-0 pb-3">{{ request.job.intersection }}</p>
    </div>
</template>
<script>
export default {
  props: ['request'],

  components: {},

  data: () => {
    return {
    }
  },

  computed: {
    zoneFormatted () {
      if (this.request.job.zone) {
        return this.request.job.zone.name + " - " + this.request.job.zone.code
      }
    }
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>

import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/users{/id}"
const customMethods = {
  current: { method: "GET", url: `/current-user` },
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

export default function channelConstructor (...options) {
  return new TowerChannel(...options)
}

export class TowerChannel {
  /**
   * Constructor.
   * 
   * @param {*} param0 
   * @param {*} param1 
   */
  constructor ({ store }, { tower }) {
    this.channel_name_base = "tower_channel_"
    this.store = store
    this.organization_id = tower.id
  }

  /**
   * This is called on connection.
   * 
   * @returns 
   */
  connected () {
    return () => {}
  }

  /**
   * This is called on disconnection.
   * 
   * @returns 
   */
  disconnected () {
    return () => {}
  }

  /**
   * Receives the data of the recent quote request created assigned to the current tower.
   * 
   * @returns 
   */
  received () {
    return ({ data }) => {
      const dataParsed = JSON.parse(data);
      this.store.dispatch('quote_requests/CABLE_receiveNewQuoteRequest', dataParsed)
    }
  }

  /**
   * Define a identifier key for the channel.
   * 
   * @returns 
   */
  key () {
    return this.channel_name_base + this.organization_id
  }

  channel () {
    return {
      channel: "TowerChannel",
      organization_id: this.organization_id
    }
  }

  mixins () {
    return {
      connected: this.connected(),
      disconnected: this.disconnected(),
      received: this.received()
    }
  }
}

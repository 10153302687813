<template>
  <div class="quote-request-detail">
    <p class="caption ml-3" v-if="request.quotes.length > 0">{{ request.quotes.length }} {{ request.quotes.length > 1 ? 'quotes received' : 'quote received'}}.</p>
    <p class="ccaption ml-3" v-else>Waiting for towers.</p>
  </div>
</template>

<script>
export default {
  props: ['request'],

  components: {},

  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
</style>

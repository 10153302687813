<template>
  <div class="map">
    <GmapMap
      :center="center"
      :zoom="11"
      :options="options"
      style="width: 100%; height: 100%;">

      <!-- Current Organization HQ -->
      <custom-marker
        :marker="center"
        alignment="top">
        <md-icon class="md-size-2x">room</md-icon>
      </custom-marker>

      <!-- Assignment Markers -->
      <custom-marker
        v-for="(location, index) in locations"
        :key="location.id"
        :marker="position(location)"
        alignment="top">

        <MapIcon :type="location.type" />
      </custom-marker>
    </GmapMap>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// Components
import MapIcon from './map-icon'

export default {
  props: ['locations'],

  components: {
    MapIcon
  },

  data () {
    return {
      center: {
        lat: 0,
        lng: 0
      },
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
    }
  },

  methods: {
    async setCenter() {
      await this.$store.dispatch('organizations/getCurrentOrganization')
      this.center = this.buildCoordinates(this.current.latitude, this.current.longitude)
    },
    position (location) {
      return this.buildCoordinates(location.latitude, location.longitude)
    }
  },

  computed: {
    ...mapState('organizations', {
      current: state => state.current
    }),
  },

  created () {
    this.setCenter()
  }
}

</script>

<style>
</style>

<template>
  <md-step id="third" md-label="Invite drivers" :md-editable="false" :md-done.sync="done" class="col-md-8">
    <p>Please add the names, phone numbers and email addresses for all drivers.</p>

    <!-- Drivers List -->
    <md-list class="onboarding__list">
      <md-list-item v-for="driver in drivers" class="under">
        <span class="md-list-item-text">{{driver.first}} {{driver.last}}</span>
        <a href="javascript:;" @click="removeDriver(driver)" class="delete-driver"><md-icon>clear</md-icon></a>
      </md-list-item>
    </md-list>

    <a href="javascript:;" @click="handleAddDriver" class="link"><span class="orange header-table__add">ADD DRIVER</span></a>

    <div style="margin-top: 20px;">
      <p>
        We'll send each driver a link to download the Curbside SOS app and finish their registration.
        You'll see their ETA and be able to assign them to jobs. Once assigned, you'll be able to monitor job progress.
      </p>
    </div>

    <div v-if="isOwner == false">
      <p class="md-caption">
        Changed your mind about adding yourself as a driver?
        <a href="javascript:;" @click="handleResetIsOwner">Click here</a>
      </p>
    </div>

    <!-- Submit Buttons: Save & Continue, Finish Later -->
    <div>
      <md-button
        :disabled="!isCompleteForm"
        class="md-raised md-primary upload-files-button" 
        @click="next"
      >
        Save & continue
      </md-button>

      <md-button
        v-if="enableFinishLater"
        :disabled="!isCompleteForm"
        class="md-raised md-primary"
        @click="finishLater"
      >
        Save & finish later
      </md-button>
    </div>


    <!-- Dialog: Add Driver form -->
    <md-dialog :md-active.sync="showAddDriver" class="dialog-form-wizard" :md-fullscreen="false">
      <div class="dialog-form-wizard__content-title">
        <span @click="closeAddDriver" class="dialog-close-icon">
          <img src="../../../../images/icon-close.svg" alt="">
        </span>
        <p class="dialog-form-wizard__title">{{ driver.owner ? 'Add yourself as a driver' : 'Invite driver'}}</p>
        <p v-if="driver.owner">You'll receive a link to download the Curbside SOS app, where you will finish your driver registration.</p>
        <p v-if="!driver.owner">They will receive a text to download the Curbside SOS app, where they will finish their driver registration.</p>
      </div>
      <md-dialog-content>
        <div class="content-col">
          <form class="md-layout js-form-driver" enctype="multipart/form-data">
            <div class="md-layout-item md-small-size-100">
              <div class="row">
                <div class="col-md-6">
                  <md-field :class="getValidationClass('driver','first')">
                    <label for="first">First Name</label>
                    <md-input name="first" id="first" v-model="driver.first" />
                    <span v-if="!$v.driver.first.required" class="md-error">This field is required</span>
                  </md-field>
                </div>
                <div class="col-md-6">
                  <md-field :class="getValidationClass('driver','last')">
                    <label for="last">Last Name</label>
                    <md-input name="last" id="last" v-model="driver.last" />
                    <span v-if="!$v.driver.last.required" class="md-error">This field is required</span>
                  </md-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <md-field :class="getValidationClass('driver','phone')">
                    <label for="phone">Mobile phone number</label>
                    <md-input name="phone" id="number" v-model="driver.phoneFormat" v-on:keyup="caretPhone()"/>
                    <span class="md-error">This field is required</span>
                  </md-field>
                  <span
                    class="validate-number"
                    v-if="!validateNumber"
                    >The Mobile Number must be at least 10 characters long</span>
                </div>
                <div class="col-md-6">
                  <md-field :class="getValidationClass('driver','email')">
                    <label for="email">Email (optional)</label>
                    <md-input name="email" id="email" v-model="driver.email" />
                    <span class="md-error" v-if="$v.driver.email.$error">
                      This should be a valid email
                    </span>
                  </md-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </md-dialog-content>

      <md-dialog-actions class="dialog-form-wizard__footer">
        <div class="col-md-12">
          <div class="footer-form__content footer-form__content-wizard-driver">
            <md-button class="btn btn-primary footer-form__submit" @click="addDriver">save</md-button>
          </div>
        </div>
      </md-dialog-actions>
    </md-dialog>


    <!-- Dialog: Add yourself as Driver? (Owner Operator) -->
    <md-dialog-confirm
      class="dialogConfirm"
      :md-active.sync="showOwnerOperator"
      md-title="Are you a driver?"
      md-content="We'll grab your name, phone and email previously entered. You'll have the opportunity to update these."
      md-confirm-text="ADD ME"
      md-cancel-text="NO THANKS"
      @md-cancel="onOwnerOperatorCancel"
      @md-confirm="onOwnerOperatorConfirm" />

    <ProgressDialog v-if="loading" :lockScreen="true" />

    <md-snackbar :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success', 'snackbar-notify snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="alert" md-persistent>
      <span class="snackbar-notify__text">
        <i v-if="!error" class="material-icons">check</i>
       {{ alertMsg }}  
      </span>
    </md-snackbar>
    
  </md-step>
</template>

<script>
import ProgressDialog from './progress-dialog'
import { required, email, minLength } from 'vuelidate/lib/validators'
import humps from 'humps'

export default {
  props: ['driversArray','onboardingStep', 'user_data', 'enableFinishLater'],
  data () {
    return {
      done: false,
      showAddDriver: false,
      position: 'left',
      isInfinity: false,
      duration: 4000,
      drivers: [],
      error: false,
      showOwnerOperator: false,
      disabledButton: false,
      isCompleteForm: true,
      isOwner: null,
      ownerPhone: null,
      driver: {
        first: '',
        last: '',
        phone: '',
        phoneFormat: '',
        email: '',
        owner: false
      },
      alert: false,
      alertTitle: '',
      alertMsg: '',
      loading: false,
      validateNumber: true
    }
  },

  components: {
    ProgressDialog
  },

  watch: {
    showAddDriver: function(value) {
      // "Add Driver" dialog has been closed
      if (!value) {
        // Reset Owner Operator values
        if (this.driver.owner) {
          this.isOwner = null
          this.ownerPhone = null
        }

        // Reset "Add Driver" form information
        this.driver =  {
          first: '',
          last: '',
          phone: '',
          email: '',
          phoneFormat: '',
          owner: false
        }
        this.$v.driver.$reset()
      }
    }
  },

  methods: {
    /**
     * Validate, submit the form and redirect to the next step.
     */
    next() {
      if (!this.validateHasDrivers()) return ;

      this.isCompleteForm = false;
      let data = {
        drivers: this.drivers
      }

      if (this.isOwner == true) {
        data.owner_operator = true
        data.owner_phone = this.ownerPhone
      } else {
        data.owner_operator = false
        data.owner_phone = null
      }

      this.submit(data)
        .then((success) => {
          if (success) {
            this.$emit('next', 'fourth');
          }
        });
    },

    /**
     * Validate, submit the form and let finish it more later.
     */
    finishLater() {
      if (!this.validateHasDrivers()) return ;

      this.isCompleteForm = false;
      let data = {
        drivers: this.drivers,
        registration_step: 3
      }

      if (this.isOwner) {
        data.owner_operator = true;
      }

      this.submit(data)
        .then((success) => {
          if (success) {
            this.$emit("next", "thank-you");
          }
        });
    },

    /**
     * Check if the user has already added a driver.
     */
    validateHasDrivers() {
      const hasDrivers = this.drivers.length > 0;

      if (!hasDrivers) {
        this.error = true;
        this.alertMsg = 'You must add a driver';
        this.alert = true;
      }

      return hasDrivers;
    },

    /**
     * Submit the form.
     * 
     * @param requestParams
     */
    submit(requestParams) {
      this.loading = true;
      this.disabledButton = true;

      return this.$http.post('/drivers', requestParams).then(response => {
        this.done = true
        this.alertTitle = 'Success!'
        this.alertMsg = 'Drivers data sent successfully'
        this.alert = true
        this.loading = false
        this.error = false
        this.isCompleteForm = false;

        return true;
      }, response => {
        this.alertTitle = 'Error'
        this.alert = true
        this.loading = false
        this.error = true
        this.disabledButton = false
        this.isCompleteForm = true
        this.driver =  {
          first: '',
          last: '',
          phone: '',
          email: '',
          phoneFormat: '',
          owner: false
        }

        if (response.body.msg) {
          this.alertMsg = response.body.msg
        } else {
          this.alertMsg = 'Something went wrong'
        }

        return false;
      });
    },

    addDriver () {
      this.$v.driver.$touch()

      if (!this.$v.driver.$invalid) {
        this.drivers.push({ ...this.driver }) // Add Driver to Drivers List

        // Save phone number for owner to reference later
        if (this.driver.owner) {
          this.ownerPhone = this.driver.phone
        }

        // Reset Driver Form data
        this.driver =  {
          first: '',
          last: '',
          phone: '',
          email: '',
          phoneFormat: '',
          owner: false
      }
        this.$v.driver.$reset()
        this.showAddDriver = false
      }
    },

    closeAddDriver () {
      this.showAddDriver = false
    },

    caretPhone () {
      var x = this.driver.phoneFormat.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.driver.phoneFormat = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

      this.driver.phone =  this.driver.phoneFormat.replace(/[()]/g, '');
      this.driver.phone = this.driver.phone.replace(/-/g, "").replace(" ", "");
      this.validateNumber = this.$v.driver.phone.minLength
    },

    formatPhoneNumber (str) {
        const cleaned = ("" + str).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
        return null;
    },

    removeDriver (driver) {
      if (driver.owner) {
        this.isOwner = false
        this.ownerPhone = null
      }

      this.drivers = this.drivers.filter(d => d.email !== driver.email )
    },

    getValidationClass(form,fieldName) {
      const field = this.$v[form][fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    handleAddDriver () {
      // Reset Driver Form Data
      this.driver =  {
        first: '',
        last: '',
        phone: '',
        phoneFormat: '',
        owner: false
      }

      // If we have not yet asked if they are an Owner Operator, prompt them with dialog
      if (this.isOwner == null){
        this.showOwnerOperator = true
      } else {
        this.showAddDriver = true
      }
    },

    handleResetIsOwner () {
      this.onOwnerOperatorConfirm()
    },

    onOwnerOperatorCancel () {
      // Save that the owner does not want to be added as a Driver
      this.isOwner = false

      this.driver =  {
        first: '',
        last: '',
        phone: '',
        phoneFormat: '',
        owner: false
      }

      // Open the "add driver" dialog
      this.showAddDriver = true
    },
    onOwnerOperatorConfirm () {
      // Save that the owner does want to be added as a Driver
      this.isOwner = true

      // Extract owner's data from user and set driver fields
      let driver = JSON.parse(localStorage.getItem('user'))

      if ( driver === null ) {
        driver = humps.camelizeKeys(this.user_data)
      }
      
      this.driver.first = driver.firstName
      this.driver.last = driver.lastName
      this.driver.email = driver.email
      this.driver.phone = driver.phoneNumber
      this.driver.phoneFormat = driver.phoneFormat === undefined ? this.formatPhoneNumber(driver.phoneNumber) : driver.phoneFormat 
      this.driver.owner = true // mark this driver as owner so we can associate their User account with their Driver account

      // Open the "add driver" dialog
      this.showAddDriver = true
    }
  },
  created () {
    if(this.onboardingStep)
     {
      if(this.onboardingStep.step === 'fourth' || this.onboardingStep.step === 'fifth' || this.onboardingStep.step === 'sixth') {
        this.done = true;
      }
    }
  },

  validations: {
    driver: {
      first: {
        required
      },
      last: {
        required
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      email: {
        email
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.under {
  border-bottom: solid grey 1px;
}

.delete-driver {
  &:hover{
    text-decoration: none !important;
  }
}

.driver-save-button {
  button {
    margin-left: 0;
  }
} 

.disabled-button-driver {
   pointer-events: none;
}

.link {
  padding-left: 20px;
}

.orange {
  color: #F06837;
}

.dialogConfirm {
  .md-dialog-container {
    max-width: 100px;
  }

  .md-dialog-actions {
    .md-theme-default {
      color: #F06837;
      font-weight: bold;
    }
  }
  .md-dialog-content {
    padding-right: 17px;
    text-align: justify;
  }
}
</style>

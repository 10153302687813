<template>
  <div class="motorist-card motorist__details">
    <h2 class="motorist-card__title">Tow details</h2>
    <div class="motorist-card__info">   
      <div class="row motorist-row-reverse">
        <div class="col-md-6">
          <div class="motorist-card__item motorist-card-tow-detail">
            <p class="motorist-card__subtitle">towed by</p>
            <p class="motorist-card__text">{{ towDetail.name ? towDetail.name : '' }}</p>
            <a v-bind:href="'tel:'+ towDetail.contactPhone" class="motorist-card__link">{{ formatPhoneNumber(towDetail.contactPhone) }}</a>
            <div class="job-detail__assigned">
              <div class="job-detail-grid">
                <ul class="job-detail__assigned-info motorist-job-detail job-detail__assigned-info--dispatcher">
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-title">Driver</p>
                  
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-title">Equipment</p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-title">Arrived</p>
                    <p class="job-detail__assigned-info-description"></p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-title">Marked completed</p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-title">Completed near</p>
                  </li>
                </ul>

                <ul class="job-detail__assigned-info job-detail__assigned-info-answer">
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-description">{{ driver.first }} {{ driver.last }}</p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                      <p v-if ="vehicle !== undefined" class="job-detail__assigned-info-description">{{ vehicle.make }} {{ vehicle.model }}</p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-description">{{ eventArrived ? getDate(eventArrived.createdAt) : ''}} {{ eventArrived ? getHour(eventArrived.createdAt) : '' }}</p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-description">{{ eventCompleted ? getDate(eventCompleted.createdAt) : ''}} {{ eventCompleted ? getHour(eventCompleted.createdAt) : '' }}</p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                     <a target="_blank" v-if="eventCompleted" v-bind:href="'https://www.google.com/maps/search/?api=1&query='+ eventCompleted.latitude +','+ eventCompleted.longitude" class="job-detail__assigned-info-description job-detail__assigned-info-description--link">
                      {{ eventCompleted.nearAddress }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 motorist-map-no-padding-mobile">
          <div class="job-detail__map">
              <GmapMap 
                :center="{lat: routePoints[0].lat, lng: routePoints[0].lng}"
                :options="map.options"
                :zoom="10"
                ref="map"
                style="width:100%;height:100%;"
              >
                <gmap-marker :icon="{url: iconPath(map.status), scaledSize: {width: 50, height: 50}}" :position="{lat: routePoints[routePoints.length-1].lat, lng: routePoints[routePoints.length-1].lng}" :draggable="false">
                </gmap-marker>
                <!-- <gmap-marker v-if="assign.eventCompleted.length" :icon="{url: iconPath('driver')}" :position="{lat: assign.routePoints[0].lat, lng: assign.routePoints[0].lng}" :draggable="false">
                </gmap-marker> -->
                <gmap-polyline 
                  v-bind:path.sync="routePoints" 
                  v-bind:options="{ strokeWeight: 4, strokeColor:'#5699ff'}"
                  >
                </gmap-polyline>
              </GmapMap>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import svgs from '../../../../../images/map';

export default {
  name: 'motoristTowDetail',
  props: ['data'],
  data: () => ({
    towDetail: {},
    driver: {},
    vehicle: {},
    routePoints: [],
    eventCompleted: {},
    eventArrived: {},
    map: {
      options: {
        disableDefaultUi: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }
    }
  }),
  methods: {
    formatPhoneNumber (str) {
      const cleaned = ("" + str).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
      return null;
    },
    iconPath (type) {
      switch (type) {
        case 'driver':
          return svgs.DefaultDriver
          break
        case 'new':
        case 'open':
        case 'pending':
        case 'direct':
          return svgs.PendingPin
          break
        case 'in_progress':
          return svgs.InProgressPin
          break
        case 'unassigned':
        case 'unresolved':
        case 'outbid':
          return svgs.UnassignedPin
          break
        case 'completed':
        default:
          return svgs.DefaultPin
          break
      }
    },
    getHour (date) {
      let datetime = new Date(date),
          hours = datetime.getUTCHours() <= 9 ? `0${datetime.getUTCHours()}` : datetime.getUTCHours(),
          minutes = datetime.getUTCMinutes() <= 9 ? `0${datetime.getUTCMinutes()}` : datetime.getUTCMinutes()
      return `${hours}:${minutes}`
    },
    getDate (date) {
      let datetime = new Date(date),
          year = datetime.getFullYear(),
          day = datetime.getDate() <= 9 ? `0${datetime.getDate()}` : datetime.getDate(),
          monthNumber = datetime.getMonth() + 1, // getMonth treats January as 0
          month = monthNumber <= 9 ? `0${monthNumber}` : monthNumber;
      return `${month}/${day}/${year}`;
    }
  },
  created () {
    this.towDetail = this.data.assignments[0].assignedOrg;
    this.driver = this.data.assignments[0].assignedDriver
    this.vehicle = this.data.assignments[0].vehicle
    this.routePoints = this.data.assignments[0].routePoints ? this.data.assignments[0].routePoints.map(point => (
      {"lat": point.location.latitude, "lng": point.location.longitude}
    )) : [];
    this.eventCompleted = this.data.assignments[0].events ? this.data.assignments[0].events.filter(event => event.newStatus === 'completed')[0] : [];
    this.eventArrived = this.data.assignments[0].events ? this.data.assignments[0].events.filter(event => event.newStatus === 'arrived')[0] : [];
    console.log(this.data)
  }
}
</script>
<template>
  <div class="received-quote-request-detail">
    <div>
      <div class="card" @click="$emit('select-request', request)">
        <div class="card-header">
          <ReceivedJobDetail :request="request" :job="request.job" :status="status" />
        </div>
        <div class="card-body">
          <h6 v-if="request.job.vehicles > 1">{{ vehicleCountString }}</h6>
          <div class="row">
            <div class="col-12" v-if="request.job.assignments.length > 0">
              <ReceivedAssignmentList :status="request.status" :assignments="request.job.assignments" />
            </div>
            <div class="col-12" v-if="request.job.assignments.length == 0">
              <div class="incident-vehicle-list">
                <div class="incident-vehicle-list-detail" v-for="vehicle in request.job.vehicles">
                  <div>{{ vehicle.make }}</div>
                  <div>{{ vehicle.model }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showExpireTimer" style="display: flex; flex-direction: row-reverse;">
            <!-- Timer or Expired -->
            <div v-if="timeLeft > 0">
              <div style="padding-top: 0em; margin-top: 0em; font-size: 2em; font-weight: bold; color: #F06837;">
                <md-icon style="color: #F06837;">schedule</md-icon> {{ formatTime }}
              </div>
              <div class="assign-button">
                ASSIGN
              </div>
            </div>
            <div v-else>
              <div style="padding-top: 0em; margin-top: 0em; font-size: 2em; font-weight: bold; color: #F06837;">
                Expired
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import ReceivedJobDetail from '../jobs/received-job-detail'
import ReceivedAssignmentList from '../job-assignments/received-assignment-list'

export default {
  props: ['request'],

  components: {
    ReceivedJobDetail,
    ReceivedAssignmentList
  },

  data () {
    return {
      now: new Date(),
      timerIntervalId: null
    }
  },

  computed: {
    isMotoristJob () {
      return (this.request.motorist_id != null )
    },
    /**
     * Only show countdown timer if autoquote selected with no assigned operator
     */
    showExpireTimer () {
      const winning_quote = this.request.winning_quote
      const assignment = this.request.job.assignments[0]

      return (winning_quote && winning_quote.source === "auto" && assignment && !assignment.driver)
    },
    status () {
      return this.parseStatusTower(this.request)
    },
    vehicleCountString () {
      let vehicleCount = (this.request.job.vehicles.length > 0) ? this.request.job.vehicles.length : this.request.job.assignments.length
      return `${vehicleCount} Vehicle${(vehicleCount > 0) ? 's' : ''}`
    },
    /**
     * Time duration of the countdown in seconds
     */
    assignWindowEnd() {
      const assignment = this.request.job.assignments[0]
      const assignWindowStart = new Date(assignment.history["assigned_at"])
      return new Date(assignWindowStart.getTime() + 300000) // 5 minutes in ms
    },
    timeLeft() {
      const current_time = this.now.getTime()
      const end_time = this.assignWindowEnd.getTime()

      const seconds = (end_time - current_time) / 1000;

      return seconds;
    },
    formatTime() {
      if (this.timeLeft > 0) {
        const minutes = Math.floor(this.timeLeft / 60)
        const seconds = Math.floor(this.timeLeft - minutes * 60)

        const minutesToString =
          minutes < 10 ? '0' + minutes.toString() : minutes.toString()
        const secondsToString =
          seconds < 10 ? '0' + seconds.toString() : seconds.toString()

        return minutesToString + ':' + secondsToString
      } else {
        return '00:00'
      }
    },
  },

  methods: {
    startAssignTimer() {
      this.timerIntervalId = setInterval(this.updateAssignTimer, 500)
    },
    updateAssignTimer() {
      this.now = new Date()
    },
  },
  created() {
    this.startAssignTimer()
  }
}
</script>

<style scoped lang="scss">
  .assign-button {
    background: #F06837;
    color: white;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    text-align: center;
    padding: .4em 0.1em;
    border-radius: .2em;
  }
</style>

<template>
  <div class="motorist-card motorist-onboarding">
    <h2 class="motorist-card__title">We need a few details from you</h2>
    <div class="motorist-onboarding__steppers col-md-8">
      <md-steppers md-vertical md-linear :md-active-step.sync="activeStep">
        <YourInfo 
          @next="goNext" 
          @show:snackbar="showAlert" 
          :job_assignment_id="job_assignment_id"
          :motorist="motorist_data"
          :steps="steps"
        />
        <VerifyPhone
          @next="goNext"
          @show:snackbar="showAlert" 
          :job_assignment_id="job_assignment_id"
          :motorist_data="motorist_data"
          :steps="steps"
        />
        <InsuranceInfo
         @next="goNext"
         @show:snackbar="showAlert"
         :job_assignment_id="job_assignment_id"
         :motorist="motorist_data"
         :steps="steps"
        />
        <SelectDestination
          :steps="steps"
          :job_assignment_id="job_assignment_id"
          :motorist="motorist_data"
          @show:snackbar="showAlert"
          @next="goNext"
          v-if="!incidentVehicle.release && !incidentVehicle.impound"
        />
        <AddPhotos 
          :steps="steps"
          :motorist="motorist_data"
          :release_and_impound="incidentVehicle.release || incidentVehicle.impound"
          :release="incidentVehicle.release"
          @next="goNext"
        />
      </md-steppers>
    </div>
    <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
      <span class="snackbar-notify__text">
        <div class="content-icon">
          <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
        </div>
        {{ this.alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import YourInfo from './steps/your-info'
import VerifyPhone from './steps/verify-phone'
import InsuranceInfo from './steps/insurance-info'
import SelectDestination from './steps/select-destination'
import AddPhotos from './steps/add-photos'

export default {
  name: 'MotoristOnboarding',
  props: ['job_assignment_id', 'motorist_data', 'job_detail'],
  components: {
    YourInfo,
    VerifyPhone,
    InsuranceInfo,
    SelectDestination,
    AddPhotos
  },
  data: () => ({
    activeStep: 'first',
    errorSnack: false,
    alertMsg: '',
    position: 'left',
    isInfinity: false,
    duration: 4000,
    update: false,
    steps: ["first",'second','third','fourth', 'fifth'],
    dataLocal: '',
    incidentVehicle: ''
  }),
  methods: {
    goNext ( step ) {
      return (this.activeStep = step);
    },
    showAlert ( opts ) {
      this.update = opts.update
      this.alertMsg = opts.alertMsg
      this.errorSnack = opts.error
    }
  },
  created () {
    this.incidentVehicle = this.job_detail.assignments[0].incidentVehicle
  },
  mounted () {
    this.goNext(this.steps[this.motorist_data.completedSteps])
  }
}
</script>
import * as API from '../../../api';

export default {
  getAllVehicles ({ commit }) {
    API.Vehicles.get().then(response => {
      commit('setVehicles', response.body)
    })
  },
  getAvailableVehicles ({ commit }) {
    API.Vehicles.available().then(response => {
      commit('setAvailableVehicles', response.body)
    })
  }
}

<template>
  <div class="sent-quote-request-detail"
      @click="selectQuoteRequest(request.id)"
      :class="{ 'jobHiliteFade': request.status === 'new', 'jobHilite': selected }">
    <md-button class="xClose_actionBar md-icon-button mt-3"
      v-show="selected"
      @click.stop="selectQuoteRequest('')">
      <md-icon>clear</md-icon>
    </md-button>
    <div class="card">
      <div class="card-header">
        <JobDetail :job="request.job" :status="parsedStatus"/>
      </div>
      <div class="card-body" v-if="request.status !== 'unresolved' && request.status !== 'completed'">
        <QuoteRequestDetail :request="request" />
      </div>
      <div class="card-body" v-if="request.job.status === 'in_progress' || request.status === 'unresolved'">
        <JobAssignmentList :updating="etas_updating" :status="request.status" :assignments="request.job.assignments" />
      </div>
      <div v-if="selected" class="actionBar mx-4 pb-1 border-top">
        <SentQuoteRequestActions @cancel:order="cancelOrder" :request="request" :updating="etas_updating" :updated_at="getLastUpdated" @select-request="$emit('select-request', $event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

// Components
import JobAssignmentList from '../job-assignments/job-assignment-list'
import JobDetail from '../jobs/job-detail'
import QuoteRequestDetail from './quote-request-detail'
import SentQuoteRequestActions from './sent-quote-request-actions'

// Mixins
import jobServices from '../../mixins/jobServices';

export default {
  props: ['request'],

  components: {
    JobAssignmentList,
    JobDetail,
    QuoteRequestDetail,
    SentQuoteRequestActions,
  },

  mixins: [jobServices],

  data () {
    return {
      details_modal_active: false,
      actionBar_active: false,
      etas_updating: false,
      eta_updated_at: "",
      time_options: {
        hour12 : true,
        hour:  "numeric",
        minute: "numeric",
        seconds: "numeric"
      },
    }
  },

  computed: {
    ...mapState('views', {
      selected_quote_request: state => state.selected_quote_request
    }),

    assignmentStatuses () {
      return this.request.job.assignments.map(a => a.status)
    },

    assignmentEtaUpdateAts () {
      return this.request.job.assignments.map(a => new Date(a.eta_updated_at))
    },

    mostRecentUpdate () {
      return Math.max(this.assignmentEtaUpdateAts)
    },

    selected () {
      return (this.selected_quote_request === this.request.id)
    },
    
    getLastUpdated () {
      if (this.assignmentStatuses.includes('started')) {
        return new Date(this.assignmentEtaUpdateAts).toLocaleTimeString('en-US', this.time_options)
      } else {
        return new Date(this.request.job.updated_at).toLocaleTimeString('en-US', this.time_options)
      }
    },

    /**
     * It is necessary for the jobServices mixin.
     */
    job () {
      return this.request.job;
    }
  },

  methods: {
    ...mapActions('views', [
      'selectQuoteRequest'
    ]),
    setActionBar () {
      this.actionBar_active = true
      if (this.request.job.status === 'in_progress') {
        this.etas_updating = true
        this.getETAs()
      }
    },
    async getETAs () {
      await this.$store.dispatch('quote_requests/refreshQuoteRequestETA', { id: this.request.id })
      this.eta_updated_at = new Date().toLocaleTimeString('en-US', this.time_options)
      this.etas_updating = false
    },
    cancelOrder (obj) {
      this.$emit('cancel:order', obj)
    },
    refreshETAs () {
      if (this.assignmentStatuses.includes('started')) {
        this.getETAs()
      }
      this.scheduleETAs()
    },
    scheduleETAs () {
      setTimeout(this.refreshETAs, 2*60*1000)
    },
  },

  created () {
    this.eta_updated_at = new Date().toLocaleTimeString('en-US', this.time_options)
    this.scheduleETAs()
  }
}
</script>

<style scoped lang="scss">
.jobHilite {
  background-color: #fff;
}
.jobHiliteFade {
  animation: hiliteFade 3s;
}
@keyframes hiliteFade {
  0% {
    background-color: transparent;
  }
  10% {
    opacity: 1;
    background-color: white;
  }
  50% {
    opacity: 1;
    background-color: white;
  }
  100% {
    opacity: .8;
    background-color: transparent;
  }
}
</style>

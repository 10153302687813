<template>
  <div class="map">
    <GmapMap
      :center="center"
      :zoom="11"
      :options="options"
      ref="map"
      style="width: 100%; height: 100%;">

      <!-- Current Organization HQ -->
      <div>
        <custom-marker
          :key="'hq'"
          :marker="center"
          alignment="top">
          <md-icon class="md-size-2x">room</md-icon>
        </custom-marker>
      </div>

      <!-- Assignment Markers -->
      <div>
        <custom-marker
          v-for="(location, index) in locations"
          v-bind:key="index"
          :marker="position(location)"
          alignment="top">
          <MapIcon :type="location.type" />
        </custom-marker>
      </div>

      <!-- Driver Markers -->
      <div v-if="drivers && drivers.length > 0 && (drivers[0].latitude || drivers[0].longitude)">
        <custom-marker
          v-for="(driver, index) in drivers"
          v-bind:key="index"
          :marker="position(driver)"
          alignment="top">
          <MapIcon :type="'driver'" />
        </custom-marker>
      </div>

    </GmapMap>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// Components
import MapIcon from './map-icon'

// Third Party
import { gmapApi } from 'vue2-google-maps'

export default {
  props: ['locations', 'drivers'],

  components: {
    MapIcon
  },

  data () {
    return {
      center: {
        lat: 0,
        lng: 0
      },
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
    }
  },

  computed: {
    ...mapState('organizations', {
      current: state => state.current
    }),
    google: gmapApi
  },

  methods: {
    async setCenter() {
      await this.$store.dispatch('organizations/getCurrentOrganization')
      this.center = this.buildCoordinates(this.current.latitude, this.current.longitude)
    },
    position (location) {
      return this.buildCoordinates(location.latitude, location.longitude)
    },
    bounds () {
      // Create a new google maps bounding entity to contain list of LatLng objects to bound
      const b = new google.maps.LatLngBounds()
      // Extend bounds with organization location first
      b.extend(this.center)
      // Extend bounds to include location markers (if quote selected, only its incident location will be present)
      this.markersLatLng(this.locations).forEach((markerLatLng) => {
        b.extend(markerLatLng.latLng)
      })
      // Extend bounds to include drivers, if quote is selected and any associated driver has started
      if (this.drivers && this.drivers.length > 0) {
        this.markersLatLng(this.drivers).forEach((markerLatLng) => {
          b.extend(markerLatLng.latLng)
        })
      }
      // Update map bounds with bounding entity
      setTimeout(() => {
        this.$refs.map.fitBounds(b)
      }, 500)
    },
    markersLatLng (markers) {
      return markers.map((marker) => {
        return {
          latLng: {
            lat: marker.latitude,
            lng: marker.longitude
          }
        }
      })
    },
  },

  created () {
    this.setCenter()
  },

  updated () {
    // whenever data is updated, re-bounds the map to ensure all new marker locations are included
    if (this.google && this.locations.length > 0) {
      this.bounds()
    }
  }
}

</script>

<style>
</style>

<template>
  <div class="location-autocomplete">
    <md-autocomplete
      class="location-autocomplete__field"
      :value="value"
      :md-options="autocompleteOptions"
      autocomplete="off"
      autocorrect="off"
      md-dense
      @md-changed="autocompleteSearch"
      @md-selected="handleSelectedSuggestion"
      @md-closed="autocompleteClosed"
    >
      <label><md-icon class="location-autocomplete__md-icon-address">location_on</md-icon> {{ label }}</label>
      <template slot="md-autocomplete-item" slot-scope="{ item }">
        <div>
          <md-icon v-if="item.id === 1">near_me</md-icon> <span>{{ item | toString }}</span>
        </div>
      </template>
    </md-autocomplete>

    <span class="md-helper-text location-autocomplete__helper-text">
      {{ helperText }}
    </span>
  </div>
</template>

<script>
  import googleMapsApi from '../mixins/googleMapsApi';

  let currentLocationOption = {
    id: 1,
    place_id: null,
    name: 'use current location',
    "toLowerCase": () => 'use current location',
    "toString": () => 'Use current location'
  };

  export default {
    name: 'LocationAutocomplete',
    mixins: [googleMapsApi],
    data() {
      return {
        selectedPlace: null,
        autocompleteOptions: this.getDefaultOptions()
      }
    },

    filters: {
      toString: function(value) {
        if (!value) return '';

        return value.toString();
      }
    },

    methods: {
      /**
       * Return the default options for the autocomplete widget.
       */
      getDefaultOptions() {
        if (!this.currentLocation) return ['Search for a location'];
        return [
          currentLocationOption
        ];
      },

      /**
       * Map the google place results to valid options of our md-autocomplete component.
       */
      mapGooglePlacePredictionToAutocompleteOption(place) {
        return {
          id: place.place_id,
          place_id: place.place_id,
          terms: place.terms,
          name: place.description,
          "toLowerCase": () => place.description.toLowerCase(),
          "toString": () => place.description
        };
      },

      /**
       * Geocode the selected place and send a event with the location information.
       * 
       * @param {object} selectedPlace
       */
      async handleSelectedSuggestion(selectedPlace) {
        // Check if user selected "use current location option".
        if (selectedPlace.id === 1 && selectedPlace.place_id === null) {
          this.$emit('useMyLocation');

          return ;
        }
        
        const place = await this.geocode({
          placeId: selectedPlace.place_id
        });

        this.selectedPlace = place;

        if (place) {
          this.$emit('location', place);
        }
      },

      /**
       * Use input string to get autocomplete suggestions, choosing first as if it was selected
       */
      async autocompleteClosed() {
        const searchedPlaces = await new Promise((resolve, reject) => {
          this.initGeocoder()
            .then(() => {
              this.getPredictions({
                input: this.value,
                componentRestrictions: {
                  country: 'us'
                }
              }).then(response => {
                const suggestions = response.map(this.mapGooglePlacePredictionToAutocompleteOption);

                if (suggestions.length > 0) {
                  this.handleSelectedSuggestion(suggestions[0])
                }
              }).catch(() => {
                // resolve(this.getDefaultOptions());
              });
            });
        });
      },
      
      /**
       * Request predictions each time user enter a new letter in the address field.
       */
      autocompleteSearch(value) {
        this.$emit('input', value.toString());
        
        this.autocompleteOptions = new Promise((resolve) => {
          this.initGeocoder()
            .then(() => {
              if (value.length < 3) {
                resolve(this.getDefaultOptions());

                return ;
              }

              this.getPredictions({
                input: value,
                componentRestrictions: {
                  country: 'us'
                }
              }).then(response => {
                const suggestions = response.map(this.mapGooglePlacePredictionToAutocompleteOption);

                // Append a static option at the top and resolve the promise.
                suggestions.unshift(...this.getDefaultOptions());
                resolve(suggestions);
              }).catch(() => {
                resolve(this.getDefaultOptions());
              });
            });
        });
      }
    },

    props: {
      value: {
        type: String,
        required: true
      },
      label: {
        type: String,
        default: 'Enter address or adjust map'
      },
      helperText: {
        type: String,
        default: ''
      },
      currentLocation: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .location-autocomplete {
    &__field.md-field {
      margin-bottom: 0;
    }
    
    &__helper-text {
      font-size: 14px;
    }
  }

  .md-icon.location-autocomplete__md-icon-address {
    margin-top: -10px;
  }

  .md-menu-content {
    z-index: 15;
    background-color: inherit;
  }
</style>

<template>
  <div class="store-container">
    <img src="../../../images/logo_curbsidesos_dark.png" alt="curbside sos" class="logo" />

    <h1 class="not-supported" v-if="notSupportedOS">Your device doesn't support Cursidesos App</h1>
  </div>
</template>

<script>
var OS = {
  ANDROID: "ANDROID",
  IOS: "IOS"
};
function getMobileOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  if (/android/i.test(userAgent)) return OS.ANDROID;
  else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    return OS.IOS;

  return;
}

export default {
  data() {
    return {
      notSupportedOS: false,
      linkApp: ''
    };
  },

  methods: {},

  created() {
    
    var os = getMobileOS();
    if (os === OS.ANDROID) window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.koombea.curbsidesos";
    else if (os === OS.IOS)
      window.location.href = "https://apps.apple.com/co/app/curbside-sos/id1480296390?l=en";

    this.notSupportedOS = true;
    
  }
};
</script>

<style scoped>
.store-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}

.not-supported {
  color: #0f3246;
  font-size: 32px;
  padding-top: 36px;
  width: 320px;
  text-align: center;
}
</style>

<template>
  <md-step id="sixth" md-label="Service fees" :md-editable="false" :md-done.sync="done">
    <p>Please provide your rate for the following services, excluding mileage. We require a $40 minimum for all services offered. If you do not offer the service, leave the value as $0.</p>
    <form class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <div class="row">
          <!-- hook_up -->
          <div class="col-md-6">
            <md-field :class="getValidationClass('towerDetail', 'hookUp')">
              <label for="hook-up">
                <md-icon>attach_money</md-icon>Hook-up (basic tow)
              </label>
              <md-input type="number" v-model="towerDetail.hookUp" name="hook-up" id="hook-up" />
              <span class="md-error">Minimum $40 or $0 if not offered</span>
              <span v-if="$v.towerDetail.hookUp.$invalid" class="invalid-fee display-none"></span>
            </md-field>
          </div>
          <div class="col-md-6">
            <md-field :class="getValidationClass('towerDetail', 'tireChange')">
              <label for="tireChange">
                <md-icon>attach_money</md-icon>Tire change
              </label>
              <md-input
                type="number"
                v-model="towerDetail.tireChange"
                name="tireChange"
                id="tireChange"
              />
              <span class="md-error">Minimum $40 or $0 if not offered</span>
              <span v-if="$v.towerDetail.tireChange.$invalid" class="invalid-fee display-none"></span>
            </md-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <md-field :class="getValidationClass('towerDetail', 'lockOut')">
              <label for="lockOut">
                <md-icon>attach_money</md-icon>Lock out
              </label>
              <md-input type="number" v-model="towerDetail.lockOut" name="lockOut" id="lockOut" />
              <span class="md-error">Minimum $40 or $0 if not offered</span>
              <span v-if="$v.towerDetail.lockOut.$invalid" class="invalid-fee display-none"></span>
            </md-field>
          </div>
          <div class="col-md-6">
            <md-field :class="getValidationClass('towerDetail', 'deadBattery')">
              <label for="deadBattery">
                <md-icon>attach_money</md-icon>Dead battery
              </label>
              <md-input
                type="number"
                v-model="towerDetail.deadBattery"
                name="deadBattery"
                id="deadBattery"
              />
              <span class="md-error">Minimum $40 or $0 if not offered</span>
              <span v-if="$v.towerDetail.deadBattery.$invalid" class="invalid-fee display-none"></span>
            </md-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <md-field :class="getValidationClass('towerDetail', 'outOfGas')">
              <label for="outOfgas">
                <md-icon>attach_money</md-icon>Out of gas
              </label>
              <md-input type="number" v-model="towerDetail.outOfGas" name="outOfGas" id="outOfGas" />
              <span class="md-error">Minimum $40 or $0 if not offered</span>
              <span v-if="$v.towerDetail.outOfGas.$invalid" class="invalid-fee display-none"></span>
            </md-field>
          </div>

          <div class="col-md-6">
            <md-field :class="getValidationClass('towerDetail', 'winchOut')">
              <label for="winchOut">
                <md-icon>attach_money</md-icon>Winch out
              </label>
              <md-input type="number" v-model="towerDetail.winchOut" name="winchOut" id="winchOut" />
              <span class="md-error">Minimum $40 or $0 if not offered</span>
              <span v-if="$v.towerDetail.winchOut.$invalid" class="invalid-fee display-none"></span>
            </md-field>
          </div>
        </div>
        <p class="mt-4">Please also list your mileage fee.</p>
        <div class="row">
          <!-- mileage_other_location -->
          <div class="col-md-6">
            <md-field :class="getValidationClass('towerDetail', 'mileageOtherLocation')">
              <label for="mileage-location">
                <md-icon>attach_money</md-icon>Mileage
              </label>
              <md-input
                type="number"
                v-model="towerDetail.mileageOtherLocation"
                name="mileage-location"
                id="mileage-location"
              />
              <span class="md-error">This field is required</span>
              <span v-if="$v.towerDetail.mileageOtherLocation.$invalid" class="invalid-fee display-none"></span>
            </md-field>
          </div>
        </div>
        <!-- Submit Button -->
        <div>
          <md-button class="md-raised md-primary" @click="validateFees">
            finish
          </md-button>
        </div>
      </div>
    </form>

    <!-- Terms of Service, Privacy Policy Agreement -->
    <md-dialog class="terms-of-service-modal" :md-active.sync="showDialogTerms" :md-fullscreen="false">
      <md-dialog-title class="terms-of-service-modal__title">
        Terms of Service
      </md-dialog-title>
      <md-dialog-content>
        Before we can finish creating your account, we need your confirmation on the following:
        <form class="md-layout" enctype="multipart/form-data" accept-charset="UTF-8" id="formTerms" name="formTerms">
          <div class="terms-of-service-modal__form-controls">
            <md-checkbox v-model="terms.certification">
              I certify our business and employees hold all licenses and insurance required by Curbside SOS to perform services as offered on the Curbside SOS Platform.
            </md-checkbox>
            <md-checkbox v-model="terms.agreement">
              I agree with Curbside SOS
              <a href="https://www.curbsidesos.com/terms/" target="_blank" @click.stop>Terms of Service</a>
              and
              <a href="https://www.curbsidesos.com/privacy-policy/" target="_blank" @click.stop>Privacy Policy</a>.
            </md-checkbox>
          </div>
        </form>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-raised md-primary" :disabled="!enableSubmitTerms" @click="submitTerms">
          Submit & Finish
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <ProgressDialog v-if="loading" :lockScreen="true" />

    <md-snackbar
      :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success', 'snackbar-notify snackbar-notify-driver snackbar-notify--success']"
      :md-position="position"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="alert"
      md-persistent
    >
      <span class="snackbar-notify__text">
        <i class="material-icons">check</i>
        {{ alertMsg }}
      </span>
    </md-snackbar>
  </md-step>
</template>

<script>
import humps from 'humps';
import { required, minValue } from "vuelidate/lib/validators";

// Import components
import ProgressDialog from "./progress-dialog";

const MINIMUM_FEE = 40;

export default {
  name: "ServiceFee",
  props: ['onboardingStep', 'enableFinishLater'],
  components: {
    ProgressDialog
  },

  data() {
    return {
      didMount: false,
      done: false,
      position: "left",
      isInfinity: false,
      duration: 4000,
      error: false,
      newField: true,
      isCompleteForm: true,
      selection: {
        address_primary: "",
        address_secondary: ""
      },
      towerDetail: {
        hookUp: "",
        mileageOtherLocation: "",
        winchOut: "",
        outOfGas: "",
        lockOut: "",
        tireChange: "",
        deadBattery: "",
        // Unused
        flatbed: "",
        rollover: "",
        cleanUpAccident: "",
        cleanUpOilDry: "",
        mileageYourStorage: "",
        storagePerDay: "",
        impoundFee: "",
        dolly: "",
        flares: "",
        noPreferenceCharge: "",
        preferencesCharge: "",
        releaseFee: "",
        towerLocations: [],
      },
      showDialogTerms: false,
      terms: {
        certification: false,
        agreement: false
      },
      alert: false,
      alertTitle: "",
      alertMsg: "",
      loading: false
    };
  },
  computed: {
    enableSubmitTerms () {
      return this.terms.certification && this.terms.agreement
    }
  },

  methods: {
    /**
     * validate fees
     */
    validateFees () {
      this.$v.$touch();
      let invalidFields = [...document.querySelectorAll('.invalid-fee')]
      if (invalidFields.length > 0) {
        let element = invalidFields[0].parentElement,
          input = document.querySelector('input');

          input.scrollIntoView();
      }

      if (invalidFields.length === 0) {
        this.openTermsDialog()
      }
    },

    /**
     * Validate, submit the form and redirect to the next step.
     */
    next() {
      if (!this.isValidForm()) return ;

      this.isCompleteForm = false
      this.submit({
        tower_detail: humps.decamelizeKeys(this.towerDetail)
      }).then((success) => {
        if (success) {
          this.$emit('next')
          window.location.href = '/tower'
        }
      });
    },

    /**
     * Open the Terms of Service Dialog
     */
    openTermsDialog() {
      this.showDialogTerms = true;
    },

    /**
     * Close the Terms of Service Dialog
     */
    closeTermsDialog() {
      this.showDialogTerms = false;
    },

    /**
     * Validate, submit the form and redirect to the next step.
     */
    submitTerms() {
      this.closeTermsDialog();
      
      this.submit({
        tower_detail: humps.decamelizeKeys(this.towerDetail)
      }).then((success) => {
        if (success) {
          this.$emit('next')
          window.location.href = '/tower'
        }
      });
    },

    /**
     * Check if the form is correctly filled up.
     */
    isValidForm() {
      this.$v.$touch();
      let invalidFields = [...document.querySelectorAll('.invalid-fee')]
     
      if (invalidFields.length) {
        let element = invalidFields[0].parentElement,
            input = document.querySelector('input');

        input.scrollIntoView();
      }

      return !this.$v.$invalid;
    },

    /**
     * Submit the form.
     * 
     * @param requestParams
     */
    submit(requestParams) {
      this.loading = true;
      
      return this.$http
        .post("/tower_details", requestParams).then(
          response => {
            this.done = true;
            this.alertTitle = "Success!";
            this.alertMsg = "Service fee sent successfully";
            this.alert = true;
            this.loading = false;
            this.error = false;
            this.isCompleteForm = false;

            return true;
          },
          response => {
            this.alertTitle = "Error";
            this.alertMsg = "Something went wrong, please try again";
            this.alert = true;
            this.loading = false;
            this.error = true;
            this.isCompleteForm = true;

            return false;
          }
        );
    },

    getSelection () {
      if(this.selection.addressable !== null || this.selection.addressable !== undefined) {
        let address = `${this.selection.address_primary} ${this.selection.address_secondary}`;
        this.towerDetail.towerLocations.push(this.selection.addressable);
        this.selection.address_primary = ''
        this.selection.address_secondary = ''
      }
    },

    deleteAddress(item) {
      this.towerDetail.towerLocations = this.towerDetail.towerLocations.filter(address => address !== item);
    },

    getValidationClass(form, fieldName) {
      const field = this.$v[form][fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    }
  },

  mounted () {
    this.$nextTick()
      .then(() => {
        this.didMount = true;
      });
  },

  validations () {
    return {
      towerDetail: {
        // base service fees
        hookUp: {
          required,
          minValue: minValue(this.towerDetail['hookUp'] > 0 ? MINIMUM_FEE : 0)
        },
        outOfGas: {
          required,
          minValue: minValue(this.towerDetail['outOfGas'] > 0 ? MINIMUM_FEE : 0)
        },
        lockOut: {
          required,
          minValue: minValue(this.towerDetail['lockOut'] > 0 ? MINIMUM_FEE : 0)
        },
        tireChange: {
          required,
          minValue: minValue(this.towerDetail['tireChange'] > 0 ? MINIMUM_FEE : 0)
        },
        deadBattery: {
          required,
          minValue: minValue(this.towerDetail['deadBattery'] > 0 ? MINIMUM_FEE : 0)
        },
        winchOut: {
          required,
          minValue: minValue(this.towerDetail['winchOut'] > 0 ? MINIMUM_FEE : 0)
        },
        // milage (used)
        mileageOtherLocation: {
          required
        },
        // unused fees
        mileageYourStorage: {
          required
        },
        storagePerDay: {
          required
        },
        impoundFee: {
          required
        },
        dolly: {
          required
        },
        flares: {
          required
        },
        flatbed: {
          required
        },
        rollover: {
          required
        },
        cleanUpAccident: {
          required
        },
        cleanUpOilDry: {
          required
        },
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-address {
  margin-right: 0;
  margin-left: 0;
}
.field-address {
  margin-top: 20px;
}
.delete-address {
  position: absolute;
  right: 0;
  &:hover {
    text-decoration: none !important;
  }
}
.services-fee-button {
  button {
    margin-left: 0;
  }
}
.icon-address {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.fit {
  display: flex;
  min-width: 90%;
  button {
    width: 100%;
    font-weight: bold;
  }
}
.no-fit {
  width: 230px;
  button {
    width: 100%;
    font-weight: bold;
  }
}

.terms-of-service-modal {
  .md-dialog-container {
    max-width: 480px;
    color: rgba(15, 50, 69, 0.87);
  }
  &__title {
    font-weight: bold;
  }
  &__form-controls {
    padding-top: 8px;
  }
  .md-checkbox {
    margin-bottom: 0;
    .md-checkbox-label {
      display: block;
      height: auto;
      color: rgba(15, 50, 69, 0.87);
    }
    a {
      color: #5baff5;
    }
  }
  .md-dialog-actions {
    padding: 24px;
  }
  .md-button {
    display: block;
    width: 100%;
  }
}
</style>
<template>
  <nav class="menu-wrecker js-menu-wrecker">
    <ul class="menu-wrecker__menu">
      <li class="menu-wrecker__item"><a class="menu-wrecker__link wrecker" href="#account-owner-card">Account Owner</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link" href="#fees-card">Fees</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link" href="#service-area-card">Service area</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link" href="#storage-facilities">Storage facilities</a></li>
      <!-- <li class="menu-wrecker__item"><a class="menu-wrecker__link" href="#documents">Documents</a></li> -->
      <li class="menu-wrecker__item"><a class="menu-wrecker__link" href="#vehicles-card">Vehicles</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link" href="#drivers-card">Drivers</a></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MenuWrecker'
}
</script>
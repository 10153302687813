<template>
  <div>
    <div class="col-md-10 margin-auto">
      <div class="row">
        <div class="form-group col-md-12">
          <input type="hidden" v-model="wrecker.name" />
          <md-field :class="getValidationClass('wrecker', 'name')">
            <label class="form__label">Company name</label>
            <md-input v-model="wrecker.name" class="form-control"></md-input>
          </md-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <input type="hidden" v-model="wrecker.firstName" />
          <md-field :class="getValidationClass('wrecker', 'firstName')">
            <label class="form__label">Owner's first name</label>
            <md-input v-model="wrecker.firstName" class="form-control"></md-input>
          </md-field>
        </div>
        <div class="form-group col-md-6">
          <input type="hidden" v-model="wrecker.lastName" />
          <md-field :class="getValidationClass('wrecker', 'lastName')">
            <label class="form__label">Owner's last name</label>
            <md-input v-model="wrecker.lastName" class="form-control"></md-input>
          </md-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <input type="hidden" v-model="wrecker.email" />
          <md-field :class="getValidationClass('wrecker', 'email')">
            <label class="form__label">Owner's Email</label>
            <md-input v-model="wrecker.email" class="form-control"></md-input>
          </md-field>
        </div>
      </div>
    </div>

    <div class="footer-form">
      <div class="col-md-10 margin-auto">
        <div class="footer-form__content">
          <md-button
            :disabled="!isCompleteForm"
            :class="[isCompleteForm ? 'active-button' : 'disabled-button','btn btn-primary footer-form__submit']"
            @click="submit"
          >{{ type_form === 'edit' ? 'update' : 'Invite Wrecker'}}</md-button>
          <md-button
            href="/wreckers"
            class="md-primary footer-form__submit footer-form__submit-cancel"
          >Cancel</md-button>
          <md-progress-spinner :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </div>
      </div>
    </div>

    <md-snackbar
      :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-driver']"
      :md-position="wrecker.position"
      :md-duration="wrecker.isInfinity ? Infinity : wrecker.duration"
      :md-active.sync="alert"
      md-persistent
    >
      <span class="snackbar-notify__text">
        <i v-if="!error" class="material-icons">check</i>
        {{ alertMsg }}
      </span>
    </md-snackbar>
    <ProgressDialog v-if="loading" />
  </div>
</template>


<script>
import Vue from "vue";
import ProgressDialog from "../../form-wizard/progress-dialog";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    ProgressDialog
  },
  name: "FormDriver",
  props: ["type_form"],
  data: () => ({
    error: false,
    wrecker: {
      firstName: null,
      lastName: null,
      email: null,
      name: null,
      buttonActive: true
    },
    alert: false,
    alertTitle: "",
    alertMsg: "",
    loading: false,
    update: false
  }),
  validations: {
    wrecker: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      name: {
        required
      },
      email: {
        email,
        required
      }
    }
  },
  computed: {
    isCompleteForm() {
      return (
        this.wrecker.firstName &&
        this.wrecker.lastName &&
        this.wrecker.name &&
        this.wrecker.email &&
        this.wrecker.buttonActive &&
        this.$v.wrecker.email.email
      );
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.update = true
      this.wrecker.buttonActive = false
      this.$http
        .post("/organizations/invite", {
          organization: { name: this.wrecker.name },
          user: {
            email: this.wrecker.email,
            first_name: this.wrecker.firstName,
            last_name: this.wrecker.lastName
          }
        })
        .then(
          response => {
            this.alertTitle = "Success!";
            this.alertMsg = "Wrecker invite created";
            this.alert = true;
            this.loading = false;
            this.error = false;
            this.update = false
            window.location.href = '/wreckers'
          },
          response => {
            if(!response.ok) {
              this.alertMsg = response.body.error
            }
            this.alertTitle = "Error";
            //this.alertMsg = "Something went wrong, please try again";
            this.alert = true;
            this.error = true;
            this.loading = false;
            this.update = false
            this.wrecker.buttonActive = true
          }
        );
    },
    getValidationClass(form, fieldName) {
      const field = this.$v[form][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    onCancel() {
      this.drivers.activeDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .spinner-show {
    margin-top: 0 !important;
    position: relative;
    top: 10px;
  }
</style>
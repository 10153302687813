import { getParsedJobStatus } from '../services/jobHelpers';

export default {
  computed: {
    /**
     * Return the job address to use it as a title.
     * 
     * @returns 
     */
    jobAddressTitle() {
      if (!this.job) return ;

      const prefix = this.job.test ? '[TEST] ' : '';

      return `${prefix}${this.job.address}`;
    },

    // If all Assignments are delined, show declined regardless of any other status
    // If any Assignment is declined, show unassigned regardless of any other status
    parsedStatus() {
      return getParsedJobStatus(this.job, this.request);
    }
  },

  methods: {
    /**
     * Return an array with the cancellable assignments of a job.
     * 
     * @param {*} job 
     */
    filterCancelableJobAssignments(job) {
      return job.assignments.filter(jobAssigment => {
        return this.jobAssigmentCanBeCanceled(jobAssigment.status);
      });
    },

    /**
     * Determine if a job can be canceled.
     * 
     * @param {object} job 
     */
    jobCanBeCanceled(job) {
      const cancelableJobAssignments = this.filterCancelableJobAssignments(job);
      const jobStatus = job.status;

      return (cancelableJobAssignments.length > 0 && (jobStatus !== 'completed' || jobStatus !== 'canceled'));
    },

    /**
     * Determine if a job assignment can be canceled.
     * 
     * @param {string} status 
     */
    jobAssigmentCanBeCanceled(status) {
      const notCancellableStatus = ['canceled', 'completed', 'reassigned'];

      if (notCancellableStatus.indexOf(status) > -1) {
        return false;
      }

      return true;
    },

    /**
     * Determines if at least one job assignment has been assigned to a tower.
     * 
     * @param {array} assignments 
     */
    isThereAtLeastOneAssignmentAssigned(assignments) {
      const assignedAssignments = assignments.filter(assignment => assignment.assigned_org);

      return assignedAssignments.length > 0;
    }
  }
}

import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/features"
const customMethods = {
  features: { method: "GET", url: `/features` },
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

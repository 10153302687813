<template>
  <div class="driver-widget">
    <div class="driver-widget__driver-avatar-wrapper">
      <Avatar :type="'driver'" />
    </div>
    <div class="driver-widget__driver-info-wrapper">
      <p class="mb-1">
        <span>{{ driver.first }} {{ driver.last }}</span> 
        <a 
          class="driver-widget__re-assign" 
          v-if="canReassignDriver" 
          href="javascript:;" 
          @click="$emit('reassign')" 
        >
          Re-assign
        </a>
      </p>

      <!-- This will be displayed in the job detail view -->
      <p class="caption faded-1 mb-1" v-if="assignmentStatus === 'notified'">Notified</p>
      <p class="caption faded-1 mb-1" v-if="assignmentStatus === 'started' && eta">
        {{ parseETA(eta) }}
      </p>
      <p class="caption faded-1 mb-1" v-if="assignmentStatus === 'arrived'">Arrived</p>
      <p class="caption faded-1 mb-1" v-if="assignmentStatus === 'departed'">In Transit</p>
      <p class="caption faded-1 mb-1" v-if="assignmentStatus === 'completed'">Completed</p>

      <!-- This will be displayed in the view where displayed only the driver info -->
      <p class="caption faded-1 mb-1" v-if="!assignmentStatus && eta">
        {{ eta }}
      </p>

      <!-- Driver Vehicle -->
      <p class="caption faded-1 mb-0" v-if="driver.make && driver.model">
        {{ driver.make }} {{ driver.model }}
      </p>
    </div>
  </div>
</template>

<script>
  import Avatar from './driver-avatar'

  export default {
    props: {
      driver: {
        type: Object,
        required: true
      },
      assignmentStatus: {
        type: String,
        required: false
      },
      eta: {
        type: String,
        required: false
      },
      canReassignDriver: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    components: {
      Avatar
    }
  }
</script>

<style scoped lang="scss">
  .driver-widget {
    &__re-assign {
      display: inline-block;
      color: #0092ff !important;

      &:hover {
        text-decoration: none;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-left: 4px;
        margin-right: 4px;
        vertical-align: baseline;
        background-color: #9b9b9b;
        border-radius: 50%;
      }
    }

    &__driver-avatar-wrapper, &__driver-info-wrapper {
      display: inline-block;
      vertical-align: top;
    }
  }
</style>

import * as API from '../../../api';

export default {
  async getJobs ({commit}, payload) {
    let response = await API.Jobs.get()
    commit('setJobs', response.body)
  },
  CABLE_updateJob ({ commit }, job) {
    commit('updateJob', job)
  }
}

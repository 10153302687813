export default {
    getValidationClass: function(fieldName) { 
        const field = this.$v.details[fieldName]
        if (field) {
            return {
            'md-invalid': field.$invalid && field.$dirty
            }
        }
    }
}

<template>
  <div class="drivers-table">
    <DataTable
      :data="drivers"
      title="Operators"
      :columns="['first', 'last', 'phone', 'email']"
      button-text="invite operator"
      :button-path="'/drivers/new'"
      type-table="drivers"
      edit-button-path="/drivers"
    >
    </DataTable>

    <TableResponsive
      :data="drivers"
      title="Operators"
      :columns="['make', 'model', 'nickname', 'color']"
      button-text="Invite operator"
      :button-path="'/drivers/new'"
      type-table="drivers"
      edit-button-path="/drivers"
    >
  </TableResponsive>
  </div>
</template>

<script>
import DataTable from '../data-table'
import TableResponsive from '../table-responsive-driver'

export default {
  props: ['drivers'],

  components: {
    DataTable,
    TableResponsive
  },

  data: () => {
    return {}
  },
}
</script>

<style scoped lang='scss'>
</style>

<template>
  <div>
    <md-card class="card vehicles-card section card-box" id="vehicles-card">
      <h2 class="title-card title-card--table">Vehicles</h2>
      <md-table
          v-model="vehicles"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-vehicles"
        >
          <md-table-empty-state
            md-label="No records found"
            md-description="Try a different search term"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" class="table-row item-hover" @click="openDialog(item)">
            <md-table-cell md-label="Make" md-sort-by="first"><p class="text-item-table">{{ item.make }}</p></md-table-cell>
            <md-table-cell md-label="Model" md-sort-by="last"><p class="text-item-table">{{ item.model }}</p></md-table-cell>
            <md-table-cell md-label="Color" md-sort-by="last"><p class="text-item-table">{{ item.color }}</p></md-table-cell>
          </md-table-row>
        </md-table>
    </md-card>
    <md-dialog :md-active.sync="showDialog" class="dialog-form-wizard dialog-organization">
      <md-dialog-title class="title">Vehicle’s details</md-dialog-title>
      <md-dialog-content class="dialog-form-wizard__content">
        <div class="col-md-12">
          <form class="md-layout" enctype="multipart/form-data" accept-charset="UTF-8" id="formVehicle" name="formVehicle">
            <div class="md-layout-item md-small-size-100">
              <div class="row">
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Make</label>
                    <div class="form__field">{{vehicle.make}}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Model</label>
                    <div class="form__field">{{vehicle.model}}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Color</label>
                    <div class="form__field">{{vehicle.color}}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Vin #</label>
                    <div class="form__field">{{vehicle.vin}}</div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="vehicle.registration">
                <div class="col-md-6">
                  <div class="field field-registration">
                    <label class="form__label">Registration</label>
                    <div class="form__field">
                      <span>{{vehicle.registration.name}}</span>
                      <a class="field-icon" target="_blank" :href="vehicle.registration.url">
                        <img src="../../../../../images/eye.svg" alt="">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="dialog-form-wizard__footer">
        <div class="footer-form__content">
          <md-button class="btn btn-primary footer-form__submit" @click="closeDialog">close</md-button>
        </div>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: 'vehicles',
  props: ['vehicles'],
  data: () => ({
    showDialog: false,
    vehicle: {
      make: '',
      model: '',
      color: '',
      vin: '',
      registration: ''
    }
  }),
  methods: {
    openDialog (item) {
      this.showDialog = true
      this.vehicle.make = item.make;
      this.vehicle.model = item.model;
      this.vehicle.color = item.color;
      this.vehicle.vin = item.vin;
      this.vehicle.registration = item.registration_url
    },
    closeDialog () {
      this.showDialog = false
    }
  }
}
</script>
<template>
  <div>
    <div>
      <div class="feedback-popup__content">
        <h2 class="feedback-popup__title">Tell us how we’re doing</h2>
        <p class="feedback-popup__subtitle">What would you like to do?</p>
        <!-- Radios -->
        <div class="feedback-popup__radios">
          <div class="row">
            <div class="feedback-popup__radio">
              <input type="radio" :value="info.feedback" name="gender" id="feedback" @click="radioSelect('feedback')" class="feedback-popup__input">
              <div class="feedback-popup__radio-wrapper">
                <svg class="icon-radio" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                      <path id="a" d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 14H6l-2 2V5c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1z"/>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                      <mask id="b" fill="#fff">
                          <use xlink:href="#a"/>
                      </mask>
                      <g fill="#000" class="icon-radio-path" mask="url(#b)">
                          <path d="M0 0h24v24H0z"/>
                      </g>
                  </g>
                </svg>
                <span>Give feedback</span>
              </div>
            </div>
            <div class="feedback-popup__radio">
              <input type="radio" :value="info.bug" name="gender" id="bug" @click="radioSelect('bug')" class="feedback-popup__input">
              <div class="feedback-popup__radio-wrapper">
                <svg class="icon-radio" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <defs>
                      <path id="fn73535" d="M15.5 8a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-7 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm3.49-6C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-1.9 0-3.63.97-4.65 2.58a.747.747 0 1 0 1.27.79c.74-1.18 2-1.88 3.38-1.88s2.64.7 3.38 1.88c.14.23.39.35.64.35.14 0 .27-.04.4-.11.35-.22.46-.68.24-1.03C15.63 14.96 13.9 14 12 14z"/>
                  </defs>
                  <g fill="none" fill-rule="evenodd">
                      <mask id="mbv837b" fill="#fff">
                          <use xlink:href="#fn73535"/>
                      </mask>
                      <g fill="#000" class="icon-radio-path" mask="url(#mbv837b)">
                          <path d="M0 0h24v24H0z"/>
                      </g>
                      </g>
                </svg>
                <span>Report a bug</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Radios -->

        <!-- Select -->
        <p class="feedback-popup__text">What’s your feedback about?</p>
        <md-field>
          <md-select v-model="info.optionSelect" name="page" id="page" placeholder="Page" class="feedback-select">
            <md-option v-for="item in options" :value="item.option">{{ item.option }}</md-option>
          </md-select>
        </md-field>
        <!-- Select -->

        <div>
          <p class="feedback-popup__text tell-us">Tell us more:</p>
          <textarea class="feedback-popup__textarea" v-model="info.autogrow" maxlength="280" v-on:keyup="countFeedback" placeholder="What happened? What were the steps to reproduce the problem?"></textarea>
          <span class="character-limit">{{ count }} Character limit</span>
        </div>

        <!-- File -->
        <div class="feedback-popup__file">
          <md-field>
            <label>Attach a screenshot (optional)</label>
            <md-file v-model="fileName" @change="getFile"/>
          </md-field>
        </div>
        <!-- File -->

        <md-field :class="getValidationClass('info', 'phoneNumber')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path id="gerw3462" d="M19.222 15.268l-2.54-.29a1.99 1.99 0 0 0-1.64.57l-1.84 1.84a15.045 15.045 0 0 1-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52a2.001 2.001 0 0 0-1.99-1.77h-1.73c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <mask id="3b2463" fill="#fff">
                    <use xlink:href="#gerw3462"/>
                </mask>
                <g fill="#000" fill-opacity=".54" mask="url(#3b2463)">
                    <path d="M0 0h24v24H0z"/>
                </g>
            </g>
          </svg>
          <label class="form__label label-phone">Phone number (optional)</label>
          <md-input v-model="info.phoneFormat" class="form-control" v-on:keyup="caretPhone()"></md-input>
          <span
            class="md-error"
            v-if="!$v.info.phoneNumber.required"
          >The Phone number is required</span>
        </md-field>
        <span class="validate-number" v-if="!$v.info.phoneNumber.minLength">The Mobile Number must be at least 10 characters long</span>
      </div>
    </div>
    <div class="feedback-popup__actions">
      <md-button :class="[!isCompleteForm ? 'deactive-button' : '','md-raised md-primary feedback-popup__button-save']" @click="validate">Send Feedback</md-button>
      <md-button class="md-primary" @click="closeFeedbackPopup">cancel</md-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import UploadInput from '../upload-input'
import { required, email, minLength } from "vuelidate/lib/validators";
import { detect } from 'detect.js';

export default {
  name: 'FeedbackForm',
  props: ['currentPage', 'currentOrganization'],
  components: {
    UploadInput
  },
   data: () => ({
    country: '',
    hasRegistrationAttachment: false,
    fileName: null,
    fileFeedback: null,
    deactivedButton: false,
    count: 280,
    countText: 280,
    options: null,
    browser: '',
    platform: '',
    aplication: '',
    info: {
      autogrow: '',
      bug: false,
      feedback: false,
      optionSelect: 'Dispatch',
      phoneNumber: '',
      phoneFormat: ''
    },
    optionsDispatch: [
      {
        option: 'Dispatch',
        namePage: 'job_assignments'
      },
      {
        option: 'Wreckers',
        namePage: 'organizations'
      },
      {
        option: 'History',
        namePage: 'jobs'
      },
      {
        option: 'Your profile',
        namePage: 'users'
      },
      {
        option: 'Users',
        namePage: 'users'
      }
    ],
    optionsTower: [
      {
        option: 'Dispatch',
        namePage: 'base'
      },
      {
        option: 'Drivers',
        namePage: 'drivers'
      },
      {
        option: 'Vehicles',
        namePage: 'vehicles'
      },
      {
        option: 'Settings',
        namePage: 'organizations'
      },
      {
        option: 'Your profile',
        namePage: 'users'
      },
      {
        option: 'Notification settings',
        namePage: 'notification_settings'
      },
      {
        option: 'Users',
        namePage: 'users'
      },
    ]
  }),
  validations: {
    info: {
      phoneNumber: {
        minLength: minLength(10)
      }
    }
  },
  computed: {
    isCompleteForm () {
      return (this.info.bug || this.info.feedback) && this.info.optionSelect && this.info.autogrow ? true : false
    }
  },
  methods: {
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit()
      }
    },
    submit () {
      const params = new FormData();

      if(this.fileFeedback) {
        params.append('info[file]', this.fileFeedback)
      }
      params.append('info[feedback]', this.info.feedback)
      params.append('info[bug]', this.info.bug)
      params.append('info[feedbackAbout]', this.info.optionSelect)
      params.append('info[tell_us]', this.info.autogrow)
      params.append('info[phone_number]', this.info.phoneNumber)
      params.append('info[phone_format]', this.info.phoneFormat)
      params.append('info[platform]', this.platform)
      params.append('info[browser]', this.browser)
      params.append('info[aplication]', this.aplication)
      params.append('info[session_lasted]', localStorage.getItem('sessionCount'))
      Vue.http.post(`/feedbacks`, params ,[{responseType: 'json'}])
        .then(response => {
          this.$emit('close:feedbackPopup', false)
          this.$emit('show:snackbar', {update: true, alertMsg: 'Feedback has been successfully sent', error: false})
        }, err => {
          //console.log(err)
      });
    },
    getValidationClass(form, fieldName) {
      const field = this.$v[form][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    countFeedback () {
      this.count = this.countText - this.info.autogrow.length
    },
    closeFeedbackPopup () {
      this.$emit('close:feedbackPopup', false)
    },
    caretPhone () {
      var x = this.info.phoneFormat.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.info.phoneFormat = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      this.info.phoneNumber =  this.info.phoneFormat.replace(/[()]/g, '');
      this.info.phoneNumber = this.info.phoneNumber.replace(/-/g, "").replace(" ", "");
    },
    getFile (e) {
      this.fileFeedback = e.target.files[0];
    },
    radioSelect (target) {
      if(target === 'feedback') {
        this.info.feedback = true
        this.info.bug = false
      } else {
        this.info.bug = true
        this.info.feedback = false
      }
    }
  },
  mounted () {
    this.aplication = this.currentOrganization.org_type;
    this.platform = detect.parse(window.navigator.userAgent).os.name;
    this.browser = `${detect.parse(window.navigator.userAgent).browser.family}, ${detect.parse(window.navigator.userAgent).browser.version}`;
    if(this.currentOrganization.org_type === 'tower') {
      this.options = this.optionsTower
    } else {
      this.options = this.optionsDispatch
    }
    console.log(this.currentPage)
    this.options.filter(option => {
      if(this.currentPage === 'users') {
        if(window.location.pathname === '/my_profile') {
          if ( option.option === 'Your profile') {
            this.info.optionSelect = option.option
          }
        } else {
          this.info.optionSelect = option.option
        }
      } else if(option.namePage === this.currentPage) {
        this.info.optionSelect = option.option
      }
    });
  }
}
</script>

<template>
  <div class="create-account margin-auto">
    <div class="card create-account__content">
      <img class="create-account__logo" src="../../../../images/logo_curbsidesos_dark.png" />
      <h2 class="create-account__title">Forgot your password ?</h2>
      <p class="create-account__description">We will send you an email to reset your password.</p>
      <form class="create-account__form">
        <div class="create-account__content-fields col-md-9 margin-auto">
          <div class="form-group">
            <md-field class="create-account__field">
              <label class="form__label">Email</label>
              <md-input v-model="create.email" class="form-control" autocomplete="email" ref="emailUser" v-on:keyup="getText"></md-input>
              <span class="md-error" v-if="!$v.create.email.email">Invalid email</span>
            </md-field>
            <div class="error" v-if="errors.length">
              <p class="error__message">{{ errors[0].includes('Email not found') ? 'We did not find any account with this email address.' : '' }}</p>
            </div>
          </div>
          <div class="form-group">
            <md-button v-if="!isCompletedForm" class="create-account__button btn btn-primary disable-button">Send email</md-button>
            <md-button v-else class="create-account__button btn btn-primary" @click="sendForm('.js-forget-password-form')">send email</md-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: 'ForgetPassword',
  props: ['errors'],
  data: () => ({
    create: {
      email: '',
    }
  }),
  validations: {
    create: {
      email: {
        required,
        email
      }
    }
  },
  computed: {
    isCompletedForm () {
      return this.create.email && this.$v.create.email.email
    }
  },
  methods: {
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    sendForm (target) {
      let form = document.querySelector(target);
      this.$v.$touch()
      if (!this.$v.$invalid) {
        localStorage.setItem('emailUser', this.create.email)
        form.submit();
      }
    },
    show() {
    	this.display = true;
    },
    getText () {
      const emailField = document.querySelector('.js-email-field');
      emailField.value = this.create.email
    },
    focus() {
    	this.$refs.emailUser.$el.focus();
    },
    nextTickFocus() {
    	this.show();
      this.$nextTick(this.focus)
    },
  },
  mounted () {
    this.nextTickFocus();
  }
}
</script>

<style lang="scss" scoped>
  .disable-button {
    background: #f7f9fa !important;
    pointer-events: none;
    box-shadow: none;
    box-shadow: 0 2px 2px 0 rgba(0,0,0, .14), 0 3px 1px -2px rgba(0,0,0, .2), 0 1px 5px 0 rgba(0,0,0, .12) !important;
    .md-ripple .md-button-content {
      color: rgba(0, 0, 0, 0.38);
    }
  } 

  .span-message {
    position: relative;
    top: 13px;
  }
</style>
export function getParsedJobStatus(job, request) {
  const requestStatus = request.status;
  const assignment_statuses = job.assignments.map(assignment => assignment.status);
  let status = job.status;

  if (assignment_statuses.every((assignment_status) => assignment_status === 'declined')) {
    status = 'declined';
  } else if (assignment_statuses.includes('declined')) {
    status = 'unassigned'; 
  } else if (requestStatus === 'closed' || requestStatus === 'assigning') {
    status = 'pending';
  } else if (requestStatus !== 'completed') {
    status = requestStatus;
  }

  return status;
}

<template>
  <div class="sent-quote-request-map border">
    <GmapMap :center="center"
             :zoom="15"
             :options="options"
             ref="map"
             style="width: 100%; height: 100%;" >

             <!-- Job Location -->
             <custom-marker
               :marker="center"
               alignment="top">
               <MapIcon :type="status" />
             </custom-marker>

             <!-- Tower Locations -->
             <custom-marker
               v-for="assignment in startedAssignments"
               :marker="assignment.latLng"
               alignment="top">
               <MapIcon :type="'driver'" />
             </custom-marker>
    </GmapMap>
  </div>
</template>

<script>
// Components
import MapIcon from './map-icon'

// Third Party
import { gmapApi } from 'vue2-google-maps'

export default {
  props: ['job', 'status', 'updating'],

  components: {
    MapIcon
  },

  data () {
    return {
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
    }
  },

  computed: {
    google: gmapApi,
    center () {
      return {
        lat: this.job.latitude,
        lng: this.job.longitude
      }
    },
    startedAssignments () {
      const started = this.job.assignments.filter((assignment) => { return assignment.status === 'started' })
      return started.map((a) => {
        return {
          latLng: {
            lat: a.driver.latitude,
            lng: a.driver.longitude
          }
        }
      })
    },
  },

  methods: {
    bounds () {
      const b = new google.maps.LatLngBounds()
      b.extend(this.center)
      this.startedAssignments.forEach((a) => {
        b.extend(a.latLng)
      })
      setTimeout(() => {
        this.$refs.map.fitBounds(b)
      }, 500)
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.startedAssignments.length) {
        this.bounds()
      }
    })
  }
}
</script>

<style scoped lang="scss">
.sent-quote-request-map {
  height: 35vh;
}
</style>

<template>
  <div class="mobile-quote-request">

  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="">
</style>

var levenshtein = require('fast-levenshtein')

// Methods for Mixins

// Build Coordinates
const buildCoordinates = (lat, lng) => {
  return {
    lat: lat,
    lng: lng
  }
}

// Delay
// returns Promise object
// t, time in milliseconds
const delay = t => new Promise(resolve => setTimeout(resolve, t))

// Distance as the Crow Flies
// two sets of latitude and longitude
const distanceCrowFlies = (lat1, lng1, lat2, lng2) => {
  var R = 6371e3; // metres
  var mToMi = (3.28084 / 5280)
  var φ1 = lat1 * (Math.PI / 180)
  var φ2 = lat2 * (Math.PI / 180)
  var Δφ = (lat2-lat1) * (Math.PI / 180)
  var Δλ = (lng2-lng1) * (Math.PI / 180)

  var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  var d = R * c * mToMi
  return d.toPrecision(2)
}

// Filter By Key
// key
const filterArrayByKey = (array, key) => {
  return vals => {
    if (!Array.isArray(vals)) vals = [vals]
    return array.filter(x => vals.indexOf(x[key]) != -1)
  }
}

// Format Phone
// number string
const formattedPhone = (number) => {
  const code = number.substring(0, 3)
  const first = number.substring(3, 6)
  const last = number.substring(6)

  return `(${code}) ${first}–${last}`
}

// Format Time
// time, in datetime string
const formattedTime = (time,secondFormat) => {
  const d = new Date(time)
  const h = d.getHours()
  const H = formatHour(h)
  const m = d.getMinutes()
  const M = formatMinutes(m)
  const ap = (h < 12) ? `${secondFormat ? ' AM' : 'a.m.'}` : `${secondFormat ? ' PM' : 'p.m.'}`
  return H + ':' + M + '' + ap
}

function formatHour (h) {
  const modH = h % 12
  const str = (modH == 0) ? '12' : modH.toString()
  return str
}

function formatMinutes (m) {
  const str = (m < 10) ? '0' + m.toString() : m.toString()
  return str
}

function levenshteinDistance(a, b) {
  return levenshtein.get(a, b)
}

// Convert meters to miles
function metersToMiles(meters) {
  return meters * 0.000621371192;
}

// Convert miles to meters
function milesToMeters(miles) {
  return miles * 1609.344;
}

// Parse ETA
// time, in seconds
const parseETA = (eta) => {
  if (eta != null && eta !== 0) {
    const mins = Math.round(new Date(eta) / 60);
    if (mins > 60) {
      return '60+ mins';
    } else if (mins < 3) {
      return 'Under 3 mins';
    } else {
      return `${mins} minutes`;
    }
  }
  return 'ETA unavailable'
}

// Tower Status
//
function parseStatusTower (request) {

  var quote_status = null
  var assignment_status = null

  if (request?.job?.assignments?.length > 0) {
    assignment_status = parseAssignmentStatus(request)
  }

  if (request?.quotes?.length > 0) {
    quote_status = parseQuoteStatus(request)
  }

  if (quote_status) {
    return quote_status
  } else if (assignment_status) {
    return assignment_status
  } else {
    switch (request.status) {
      case 'new':
      case 'open':
        return 'new'
        break;
      case 'closed':
      case 'assigning':
        return 'expired'
        break;
      case 'completed':
        return 'incorrect'
        break;
      default:
        return request.status
    }
  }
}

function parseQuoteStatus (request) {
  const quote_statuses = request.quotes.map(quote => quote.status)

  if (quote_statuses.every(compareTo('pending'))) {
    return 'pending'
  } else if (quote_statuses.every(compareTo('declined'))) {
    return 'outbid'
  }
}

function parseAssignmentStatus (request) {
  const assignment_statuses = request.job.assignments.map(assignment => assignment.status)

  if (assignment_statuses.every(compareTo('completed'))) {
    return 'completed'
  } else if (assignment_statuses.some(compareTo('assigned'))) {
    return 'direct'
  } else if (assignment_statuses.every(compareTo('canceled'))) {
    return 'canceled'
  } else if (assignment_statuses.every(compareTo('declined'))) {
    return 'declined'
  } else if (assignment_statuses.every(compareTo('reassigned'))) {
    // For Towers, we treat reassigned JobAssignments as declined
    // This occurs when a new JobAssignment has been created as part of the reassign process
    return 'declined'
  } else {
    return 'in_progress'
  }
}

function compareTo (label) {
  return (status) => status === label
}

function formattedTestLocation (location) {
  let primary, secondary

  switch (location.resultType) {
    case 'intersection':
    case 'street':
      primary = `${location.address.street}`
      secondary = `${location.address.city}, ${location.address.state} ${location.address.postalCode}`
      break
    case 'district':
      primary = `${location.address.district}`
      secondary = `${location.address.state}`
      break
    case 'city':
      primary = `${location.address.city}`
      secondary = `${location.address.state}`
      break
    case 'county':
      primary = `${location.address.county}`
      secondary = `${location.address.state}`
      break
    case 'state':
      primary = `${location.address.state}`
      secondary = `${location.address.countryCode}`
      break
    case 'country':
      primary = `${location.address.countryCode}`
      break
    case 'houseNumber':
      primary = `${location.address.houseNumber} ${location.address.street}`
      secondary = `${location.address.city}, ${location.address.state} ${location.address.postalCode}`
      break
    case 'point':
    default:
      primary = `${location.address.label}`.replace(', United States', '')
      break
  }
  return { primary, secondary }
}

function formattedHereLocation (location) {
  let primary, secondary

  switch (location.resultType) {
    case 'intersection':
      primary = `${location.address.streets[0]} / ${location.address.streets[1]}`
      secondary = `${location.address.city}, ${location.address.stateCode} ${location.address.postalCode}`
      break
    case 'street':
      primary = `${location.address.street}`
      secondary = `${location.address.city}, ${location.address.stateCode} ${location.address.postalCode}`
      break
    case 'district':
      primary = `${location.address.district}`
      secondary = `${location.address.stateCode}`
      break
    case 'city':
      primary = `${location.address.city}`
      secondary = `${location.address.stateCode}`
      break
    case 'county':
      primary = `${location.address.county}`
      secondary = `${location.address.stateCode}`
      break
    case 'state':
      primary = `${location.address.stateCode}`
      secondary = `${location.address.countryName}`
      break
    case 'country':
      primary = `${location.address.countryCode}`
      break
    case 'houseNumber':
      primary = `${location.address.houseNumber} ${location.address.street}`
      secondary = `${location.address.city}, ${location.address.stateCode} ${location.address.postalCode}`
      break
    case 'point':
    default:
      primary = `${location.address.label}`.replace(', United States', '')
      break
  }
  return { primary, secondary }
}

// Export
export const methods = {
  buildCoordinates,
  delay,
  distanceCrowFlies,
  filterArrayByKey,
  formattedHereLocation,
  formattedPhone,
  formattedTestLocation,
  formattedTime,
  levenshteinDistance,
  metersToMiles,
  milesToMeters,
  parseAssignmentStatus,
  parseETA,
  parseStatusTower
}

<template>
  <div class="job-assignment-list ml-3">
    <span class="make-invisible">.</span>
    <JobAssignmentDetail v-for="assignment in sortedAssignments" :status="status" :updating="updating" :assignment="assignment" :key="assignment.id" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import JobAssignmentDetail from './job-assignment-detail'

export default {
  props: ['assignments', 'updating', 'status'],

  components: {
    JobAssignmentDetail
  },

  data () {
    return {
      items: ['Apple', 'Banana', 'Carrot']
    }
  },

  computed: {
    activeAssignments () {
      return this.assignments.filter(assignment => assignment.status !== 'reassigned');
    },
    sortedAssignments () {
      return this.activeAssignments.sort((a, b) => {
        const makeA = a.incident_vehicle.make.toUpperCase()
        const makeB = b.incident_vehicle.make.toUpperCase()
        const modelA = a.incident_vehicle.model.toUpperCase()
        const modelB = b.incident_vehicle.model.toUpperCase()
        if (makeA === makeB) {
          return (modelA < modelB) ? -1 : (modelA > modelB) ? 1 : 0;
        } else {
          return (makeA < makeB) ? -1 : (makeA > makeB) ? 1 : 0;
        }
      })
    }
  },

  methods: {
    parsedStatus (assignment_status) {
      switch (assignment_status) {
        case "assigned":
          return "Notified"
          break;
        case "notified":
          return "Accepted"
          break;
        default:
          return assignment_status.charAt(0).toUpperCase() + assignment_status.slice(1)
      }
    },
    assignmentNote (assignment) {
      if (assignment.assigned_org) {
        return assignment.assigned_org.name
      } else if (this.status === 'unresolved') {
        return 'Not enough quotes received'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.make-invisible {
  visibility: hidden;
  height: 0px;
}
</style>

// Helper Functions

// General Helpers
export const last = xs => xs[xs.length - 1]

// Getters

// Filter by Key
// Filter a list of objects by a matching target property
// usersByStatus: filterByKey('users', 'status')
// getters.usersByStatus('ACTIVE')
export function filterByKey (prop, targetKey) {
  return state => vals => {
    if (!Array.isArray(vals)) vals = [vals]
    return state[prop].filter(x => vals.indexOf(x[targetKey]))
  }
}

// Filter out by key
// Filter a list of objects by excluding a matching target property
// usersExcludeStatus: filterOutKey('users', 'status')
// getters.usersExcludeStatus('INACTIVE')
export function filterOutKey (prop, targetKey) {
  return state => vals => {
    if (!Array.isArray(vals)) vals = [vals]
    return state[prop].filter(x => vals.indexOf(x[targetKey]) == -1)
  }
}

// Sort by Alphabetical
// Sort a list of objects alphabetically by a matching target property
// usersAlphabetical: sortByAlphabetical('users', 'first_name')
// getters.usersAlphabetical
export function sortByAlphabetical (prop, targetKey) {
  return state => state[prop]
    .sort((a, b) => {
      const x = a[targetKey].toUpperCase()
      const y = b[targetKey].toUpperCase()
      if (x < y) { return -1 }
      else if (y < x) { return 1 }
      else { return 0 }
  })
}

// Sort by Datetime
// Sort a list of objects by matching a target date property
// usersByCreatedAt: sortByDate('users', 'created_at')
// getters.usersByCreatedAt
export function sortByDate (prop, targetDateKey) {
  return state => state[prop]
    .sort((a, b) => new Date(b[targetDateKey]) - new Date(a[targetDateKey]))
}

// Mutators

// Set
// Set property on a state
// setUser: set('user')
// commit: 'setUser', { name: 'foo' }
export const set = key => (state, val) => { state[key] = val }

import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/organizations{/id}"
const customMethods = {
  current: { method: "GET", url: `/current-organization` },
  dispatchers: { method: "GET", url: `${path}/dispatchers` },
  manual_assignment: { method: "POST", url: `${path}/manual_assignment` },
  police_departments: { method: "GET", url: `${path}/police-departments` },
  towers: { method: "GET", url: `${path}/towers` },
  update_drivers: { method: "GET", url: `${path}/drivers/get_driver_locations` },
  operation_hours: { method: 'GET', url: `${path}/day_operations`}
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

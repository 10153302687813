<template>
  <md-card class="card account-owner card-wrecker-detail card-box offset-card fees" id="fees-card">
    <h2 class="title-card">Fees</h2>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Hook-up</label>
          <div class="form__field">${{convertValue(detail.hook_up)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Flatbed</label>
          <div class="form__field">${{convertValue(detail.flatbed)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Rollover</label>
          <div class="form__field">${{convertValue(detail.rollover)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Clean-up (accident)</label>
          <div class="form__field">${{convertValue(detail.clean_up_accident)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Clean-up (oil dry)</label>
          <div class="form__field">${{convertValue(detail.clean_up_oil_dry)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Mileage (wrecker storage)</label>
          <div class="form__field">${{convertValue(detail.mileage_your_storage)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Mileage (other location)</label>
          <div class="form__field">${{convertValue(detail.mileage_other_location)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Storage (per day)</label>
          <div class="form__field">${{convertValue(detail.storage_per_day)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Impound fee</label>
          <div class="form__field">${{convertValue(detail.impound_fee)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Dolly</label>
          <div class="form__field">${{convertValue(detail.dolly)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Flares</label>
          <div class="form__field">${{convertValue(detail.flares)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">No Preference charge</label>
          <div class="form__field">${{convertValue(detail.no_preference_charge)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Preference charge</label>
          <div class="form__field">${{convertValue(detail.preferences_charge)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Release fee</label>
          <div class="form__field">${{convertValue(detail.release_fee)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Out of gas</label>
          <div class="form__field">${{convertValue(detail.out_of_gas)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Lockout</label>
          <div class="form__field">${{convertValue(detail.lock_out)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Tire change</label>
          <div class="form__field">${{convertValue(detail.tire_change)}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Dead Battery</label>
          <div class="form__field">${{convertValue(detail.dead_battery)}}</div>
        </div>
      </div>
    </div>
    <div class="row">
       <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Winch Out</label>
          <div class="form__field">${{convertValue(detail.winch_out)}}</div>
        </div>
      </div>
    </div>
  </md-card>
</template>

<script>
export default {
  name: 'Fees',
  props: ['detail'],
  methods: {
    convertValue (number) {
      return number / 100;
    }
  }
}
</script>
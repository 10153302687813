import Vue from 'vue';
import { set } from '../../helpers';

export default {
  setJobs: set('jobs'),
  updateJob (state, job) {
    const job_index = state.jobs.findIndex((item) => item.id == job.id)
    Vue.set(state.jobs, job_index, job)
  }
}

<template>
  <div class="job-details ">
    <div class="section-title">
      <h5>Incident details</h5>
    </div>

    <div class="row">
      <div class="col-md-6">
        <p class="faded-1 mb-0">Department</p>
        <p>{{ job.assignments[0].requesting_department.name }}</p>
      </div>
      <div class="col-md-6">
        <p class="faded-1 mb-0">Incident number</p>
        <p>{{ job.incident_number }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <p class="faded-1 mb-0">Incident type</p>
        <p>{{ job.incident_type }}</p>
      </div>
      <div class="col-md-6">
        <p class="faded-1 mb-0">Officer reporting</p>
        <p>{{ job.officer }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'


export default {
  props: ['job'],

  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
  },
  created () {
  }
}
</script>

<style scoped>
</style>

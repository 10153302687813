<template>
  <div class="payment-details">
    <div class="payment-details__charges-wrapper">
      <span class="job-detail-section-title payment-details__charges-title">Charges</span>

      <ul class="payment-details__charges">
        <li v-for="charge in charges" class="payment-details__charge" :key="charge.name">
          <span class="payment-details__charge-name">
            {{ charge.name }}
            <small v-if="charge.description" class="payment-details__charge-description">
              {{ charge.description }}
            </small>
          </span>

          <span class="payment-details__charge-value">
            {{ formatMoney(charge.value) }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { formatMoney } from '@shopify/theme-currency';

  export default {
    name: 'PaymentDetails',
    props: {
      quote: {
        type: Object,
        required: true
      }
    },

    /************************************
     * Derivated Or Computed Data
     ***********************************/

    computed: {
      charges() {
        return this.quote.charges;
      }
    },

    /************************************
     * Methods & Event Handlers
     ***********************************/

    methods: {
      formatMoney,
    },
  }
</script>

<style lang="scss" scoped>
  .payment-details {
    margin-top: 40px;

    &__charges {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__charges-title {
      margin-bottom: 0.5rem;
    }

    &__charges-wrapper {

    }

    &__charge {
      display: flex;
      align-items: center;
      margin: 24px 0;
      color: rgba(15, 50, 69, 0.87);
      font-size: 16px;
    }

    &__charge-name {
      width: 250px;
    }

    &__charge-description {
      display: block;
      margin-top: 2px;
      color: rgba(11, 57, 81, 0.65);
      font-size: 14px;
    }

    &__charge-value {

    }
  }
</style>

<template>
  <div class="field">
    <md-field>
      <label class="capitalize">
        {{ label || name }}
      </label>
      <md-input
        :value="value"
        @input="$emit('change', name, $event)"
      />
    </md-field>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {}
  },
}
</script>

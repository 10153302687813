<template>
  <div class='tower-map'>
    <Map :locations="pins"></Map>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// Components
import Map from './tower-map-duplicate'

export default {
  components: {
    Map
  },

  computed: {
    ...mapGetters('quote_requests', [
      'activeReceivedRequests'
    ]),
    ...mapGetters('drivers', [
      'activeDrivers'
    ]),
    pins () {
      return this.formattedRequests.concat(this.formattedDrivers)
    },
    formattedRequests () {
      return this.activeReceivedRequests.map(x => ({
        id: x.id,
        latitude: x.job.latitude,
        longitude: x.job.longitude,
        type: this.parseStatusTower(x)
      }))
    },
    formattedDrivers () {
      return this.activeDrivers.map(x => ({
        id: x.id,
        latitude: x.current_location.latitude,
        longitude: x.current_location.longitude,
        type: 'driver'
      }))
    }
  },

  methods: {

  }
}
</script>

<style scoped lang='scss'>
.tower-map {
  width: 100%;
  height: 100%;
}
</style>

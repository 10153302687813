<template>
  <div class="organization-view">
    <div class="js-organization-banner">
      <h2 class="menu-wrecker__title">Settings</h2>
    </div>
    <div class="organization-detail">
      <towco-detail-profile :organization="organization_data" :user_is_admin="user_is_admin" :stored_bank_account="stored_bank_account"></towco-detail-profile>
    </div>
  </div>
</template>

<script>
import TowcoDetailProfile from './towco-detail-profile';

export default {
  name: 'TowcoOrganizationProfile',
  props: ["organization_data", "user_is_admin", "stored_bank_account"],
  components: {
    TowcoDetailProfile
  },
  mounted () {
    window.addEventListener("scroll", event => {
      let banner = document.querySelector('.js-organization-banner'),
          menu = document.querySelector('.js-menu-wrecker'),
          title = document.querySelector('.js-title-settings'),
          bannerHeight = banner.clientHeight;

      if(window.scrollY >= bannerHeight) {
        menu.classList.add('banner-sticky')
        title.classList.add('show-title')
      } else {
        menu.classList.remove('banner-sticky')
        title.classList.remove('show-title')
      }
    });
  }
}
</script>

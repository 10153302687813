import Vue from "vue"
import Vuex from "vuex"

Vue.config.devtools = process.env.NODE_ENV === 'development' || location.host.includes('develop.');

// Modules
import drivers from "./modules/drivers"
import features from "./modules/features"
import job_assignments from "./modules/job-assignments"
import jobs from "./modules/jobs"
import organizations from "./modules/organizations"
import quote_requests from "./modules/quote-requests"
import shifts from "./modules/shifts"
import users from "./modules/users"
import vehicles from "./modules/vehicles"
import views from "./modules/views"

export const modules = {
  drivers,
  features,
  job_assignments,
  jobs,
  organizations,
  quote_requests,
  shifts,
  users,
  vehicles,
  views
}

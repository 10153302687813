<template>
  <div class="return-button">
    <md-button class="md-icon-button" @click="$emit('back')">
      <md-icon>arrow_back</md-icon>
    </md-button>
  </div>
</template>

<script>
export default {
  props: [],

  components: {},

  data: () => {
    return {}
  },

  computed: {},

  methods: {}
}
</script>

<style scoped lang='scss'>
</style>

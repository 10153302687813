<template>
  <div class="field">
    <md-field>
      <md-select
        :id="name"
        :value="value"
        :name="name"
        :placeholder="label"
        @md-selected="$emit('change', name, $event)"
      >
        <md-option
          v-for="option in _options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </md-option>
      </md-select>
    </md-field>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    /**
     * Computed fields prop to add it more internal details.
     */
    _options() {
      return this.options.map(option => {
        if (typeof option === 'string') {
          return {
            label: option,
            value: option,
          };
        }
        
        if (typeof option === 'object') {
          return option;
        }
      });
    },
  }
}
</script>

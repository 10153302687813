<template>
  <div class="table">
    <div v-if="table_data.tableType === 'vehicles'">
      <VehiclesTable :vehicles="JSON.parse(table_data.vehicles)"></VehiclesTable>
    </div>
    <div v-if="table_data.tableType === 'users'">
      <UsersTable :users="JSON.parse(table_data.users)" :organization="JSON.parse(table_data.organization)"></UsersTable>
    </div>
    <div v-if="table_data.tableType === 'drivers'">
      <DriversTable :drivers="JSON.parse(table_data.drivers)"></DriversTable>
    </div>
    <div v-if="table_data.tableType === 'wreckers'">
      <WreckersTable :wreckers="JSON.parse(table_data.wreckers)"></WreckersTable>
    </div>
    <div v-if="table_data.tableType === 'history'">
      <HistoryTable
        v-if="table_data.organization === 'tower'"
        :history="JSON.parse(table_data.jobs)"
        :total_pages="JSON.parse(table_data.total_pages)"
        :current_page="JSON.parse(table_data.current_page)"
        :organization="table_data.organization"
      />
      <HistoryTable
        v-else
        :departments="JSON.parse(table_data.departments)"
        :history="JSON.parse(table_data.jobs)"
        :total_pages="JSON.parse(table_data.total_pages)"
        :current_page="JSON.parse(table_data.current_page)"
        :organization="table_data.organization"
        :zones="JSON.parse(table_data.zones)"
      />
    </div>
  </div>
</template>

<script>
import VehiclesTable from "./tables/vehicles/vehicles-table";
import DriversTable from "./tables/drivers/drivers-table";
import UsersTable from "./tables/users/users-table";
import WreckersTable from "./tables/wreckers/wreckers-table";
import HistoryTable from "./tables/history/history-table";

export default {
  props: ["table_data"],

  components: {
    VehiclesTable,
    DriversTable,
    UsersTable,
    WreckersTable,
    HistoryTable
  },

  data: () => {
    return {};
  }
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="create-account margin-auto">
    <div class="card create-account__content">
      <h2 class="create-account__title">Welcome to Curbside SOS</h2>
      <p class="create-account__description">You're almost ready to start receiving tow requests. Complete your account by creating a password. </p>
      <form class="create-account__form">
        <div class="create-account__content-fields col-md-9 margin-auto">
          <div class="form-group">
            <md-field class="create-account__field">
              <label class="form__label">Email</label>
              <md-input v-model="create.email" class="form-control" disabled></md-input>
            </md-field>
          </div>
          <div class="form-group">
            <md-field class="create-account__field" :class="getValidationClass('create', 'password')">
              <label class="form__label">Password</label>
              <md-input v-model="create.password" class="form-control" type="password" @keyup="changePass('password')" ref="passwordUser"></md-input>
              <span class="md-error" v-if="!$v.create.password.required">This field is required</span>
              <span
                class="md-error"
                v-else-if="!$v.create.password.minLength"
              >Password must be at least 8 characters long</span>
            </md-field>
            <span :class="[!$v.create.password.minLength || !$v.create.password.required ? 'span-message' : '', 'create-account__info-label']">Passwords must be 8 characters with two numbers.</span>
          </div>  
          <div class="form-group">
              <md-field class="create-account__field" :class="getValidationClass('create', 'passwordConfirmation')">
              <label class="form__label">Confirmation password</label>
              <md-input v-model="create.passwordConfirmation" class="form-control" type="password" @keyup="changePass('password-confirmation')"></md-input>
              <span class="md-error" v-if="!$v.create.passwordConfirmation.required">This field is required</span>
            <span class="md-error" v-else-if="!$v.create.passwordConfirmation.sameAsPassword">Passwords don't match.</span>
            </md-field>
          </div>
          <div class="form-group">
            <div class="create-account__terms">
              <md-checkbox v-model="create.terms" class="md-primary create-account__checkbox"></md-checkbox>
              <p>I agree to the <a target="_blank" href="https://www.curbsidesos.com/terms/">Terms of Service</a> and <a target="_blank" href="https://www.curbsidesos.com/privacy-policy/">Privacy Policy</a></p>
            </div>
          </div>
          <div class="form-group">
            <md-button v-if="!isCompletedForm" class="create-account__button btn btn-primary disable-button">Create Account</md-button>
            <md-button v-else class="create-account__button btn btn-primary" @click="sendForm('.js-account-form')">Create Account</md-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: 'accountForm',
  props: ['data', 'organization'],
  data: () => ({
    create: {
      email: null,
      password: null,
      passwordConfirmation: null,
      terms: false
    }
  }),
  validations: {
    create: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(8),
      },
      passwordConfirmation: {
        required,
        minLength: 8,
        sameAsPassword: sameAs("password")
      }
    }
  },
  computed: {
    isCompletedForm () {
      return this.create.email && this.create.password && this.create.passwordConfirmation && this.create.terms
    }
  },
  methods: {
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    sendForm (target) {
      let form = document.querySelector(target);
      this.$v.$touch()
      if (!this.$v.$invalid) {
        form.submit();
        localStorage.setItem('user-login-account-form', true);
      }
    },
    changePass (target) {
      console.log('change')
      let password = document.querySelector('.js-phone-number'),
          password_confirmation = document.querySelector('.js-phone-number-confirmation');
      
      if(target === 'password') {
        password.value = this.create.password
      }

      if(target === 'password-confirmation') {
        password_confirmation.value = this.create.passwordConfirmation
      }
    },
    show() {
    	this.display = true;
    },
    focus() {
    	this.$refs.passwordUser.$el.focus();
    },
    nextTickFocus() {
    	this.show();
      this.$nextTick(this.focus)
    },
  },
  mounted () {
    this.nextTickFocus();
    let password = document.querySelector('.js-phone-number'),
        password_confirmation = document.querySelector('.js-phone-number-confirmation');
    this.create.email = this.data.email
  }
}
</script>

<style lang="scss" scoped>
  .disable-button {
    background: #f7f9fa !important;
    pointer-events: none;
    box-shadow: none;
    box-shadow: 0 2px 2px 0 rgba(0,0,0, .14), 0 3px 1px -2px rgba(0,0,0, .2), 0 1px 5px 0 rgba(0,0,0, .12) !important;
    .md-ripple .md-button-content {
      color: rgba(0, 0, 0, 0.38);
    }
  } 

  .span-message {
    position: relative;
    top: 13px;
  }
</style>
<template>

<div>

  <div class="row">

    <!-- Motorist Job Details -->
    <div v-if="isMotoristJob" class="col-md-6">
      <div class="incident-details">
        <h6>Incident</h6>
        <p class="faded-1 pb-0 mb-0">
          {{ getServicesName(job.incident_type) }}
        </p>
        <p class="faded-1 pb-3">
          ${{ convertValue(motoristIncidentFee) }}
          </p>
      </div>

      <div v-if="destination">
        <h6>Destination notes</h6>
        <p class="faded-1 pb-3">
          {{ destination }}
        </p>
      </div>

      <div v-if="job.details">
        <h6>Motorist Remark</h6>
        <p class="faded-1 pb-3">
          {{ job.details }}
        </p>
      </div>
    </div>

    <!-- Dispatcher Job Details -->
    <div v-if="!isMotoristJob" class="col-md-6">
      <div class="location-details">
        <h6>{{ job.address }}</h6>
        <p class="faded-1 mb-0 pb-0">Zone: {{ zoneFormatted }}</p>
        <p class="faded-1 pb-3">{{ job.intersection }}</p>
      </div>
      <div class="job-details" v-if="job.details">
        <h6>Police Remark</h6>
        <p class="faded-1 pb-3">{{ job.details }}</p>
      </div>
      <div class="incident-details">
        <!-- <h6 v-if="job.assignments[0].requesting_department_id">{{ job.assignments[0].requesting_department_id }}</h6> -->
        <h6 v-if="job.requesting_org">{{ job.requesting_org }}</h6>
        <h6 v-else>Incident</h6>
        <p class="faded-1">
          Incident No. {{ job.incident_number }} <br>
          <span v-if="job.officer">Requested by {{ job.officer }}</span>
        </p>
      </div>
    </div>

    <!-- Job Incident Vehicle(s) -->
    <div class="col-md-6">
      <div class="vehicle-details">
        <h6>Vehicles ({{ job.vehicles.length }})</h6>
        <div v-for="vehicle in job.vehicles">
          <VehicleDetail :vehicle="vehicle" :key="vehicle.plate" source="towco" />
          <hr>
        </div>
      </div>
    </div>

  </div>


</div>

</template>

<script>
import { mapState } from 'vuex'

import VehicleDetail from "../../vehicles/vehicle-detail"
import { getServicesName } from '../../../mixins/servicesList'

export default {
  props: ["job","request"],

  components: {
    VehicleDetail
  },

  data: () => {
    return {}
  },

  computed: {
    ...mapState('organizations', {
      current: state => state.current
    }),
    
    isMotoristJob () {
      return (this.request.motorist_id != null )
    },
    
    /**
     * Get the destination requested by the motorist.
     */
    destination() {
      const vehicles = this.request.job.vehicles || [];
      const destination = (vehicles.length > 0 && vehicles[0].destination) ? vehicles[0].destination.local_description : null;

      return destination;
    },

    motoristIncidentFee () {
      const motoristServiceMap = {
        'Basic tow': 'hook_up',
        'Flat tire': 'tire_change',
        'Lockout': 'lock_out',
        "Won’t start": 'dead_battery',
        "Out of gas": 'out_of_gas',
        'Winch Out': 'winch_out'
      }
      const fee_key = motoristServiceMap[this.job.incident_type];

      return this.current.fees[fee_key];
    },

    zoneFormatted () {
      if (this.request.job.zone) {
        return this.request.job.zone.name + " - " + this.request.job.zone.code
      }
    }
  },

  methods: {
    getServicesName,
    convertValue (number) {
      return number / 100;
    }
  }
}
</script>

<style scoped lang="scss">
</style>

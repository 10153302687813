<template>
  <md-step id="second" md-label="Service Area" :md-editable="false" :md-done.sync="done" class="col-md-12 service-area">
    <p>Please enter the number of miles from your headquarters that you are willing to drive for a job. (Must be between 1 and 60 miles)</p>

    <!-- Form -->
    <form class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <div class="row">
          <div class="col-md-6">
            <md-field :class="getValidationClass('mileSelected')">
              <md-input type="number" v-model="mileSelected" name="mile-selected" id="mile-selected" @keydown="allowOnlyNumbers" />
              <span class="md-error">This field is required</span>
              <span v-if="$v.mileSelected.$invalid" class="invalid display-none"></span>
            </md-field>
            <p class="service-area__select-text">From your business address</p>

          </div>
        </div>
      </div>
    </form>

    <!-- Submit Buttons -->
    <div>
      <md-button
        :disabled="!enableSave"
        class="md-raised md-primary"
        @click="next"
      >
        Save & continue
      </md-button>
      <md-button
        v-if="enableFinishLater"
        :disabled="!enableSave"
        class="md-raised md-primary"
        @click="finishLater"
      >
        Save & finish later
      </md-button>
    </div>

    <!-- Loading Spinner, Snackbar Alerts -->
    <ProgressDialog v-if="loading" :lockScreen="true" />

    <md-snackbar
      :class="[snackbarAlert.error ? 'snackbar-notify--error' : 'snackbar-notify--success', 'snackbar-notify snackbar-notify-driver snackbar-notify--success']"
      :md-position="snackbarAlert.position"
      :md-duration="snackbarAlert.isInfinity ? Infinity : snackbarAlert.duration"
      :md-active.sync="snackbarAlert.alert"
      md-persistent
    >
      <span class="snackbar-notify__text">
        <i class="material-icons">check</i>
        {{ snackbarAlert.alertMsg }}
      </span>
    </md-snackbar>
  </md-step>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import ProgressDialog from './progress-dialog'

export default {
  name: 'ServiceArea',
  props: ['onboardingStep', 'enableFinishLater', 'user_data', 'organization_id'],
  components: {
    ProgressDialog
  },
  data: () => ({
    mileSelected: 20,
    done: false,
    loading: false,
    snackbarAlert: {
      alert: false,
      error: false,
      alertMsg: '',
      position: 'left',
      isInfinity: false,
      duration: 4000,
      update: false
    }
  }),
  validations: {
    mileSelected: {
      required
    }
  },
  computed: {
    enableSave () {
      return !this.loading && this.mileSelected && this.mileSelected >= 1 && this.mileSelected <= 60
    }
  },
  methods: {
    /**
     * Validate, submit the form and redirect to the next step.
     */
    next() {
      if (!this.isValidForm()) return ;

      this.isCompleteForm = false
      this.submit().then((success) => {
        if (success) {
          this.$emit('next', 'third');
        }
      });
    },
    /**
     * Validate, submit the form and let finish it more later.
     */
    finishLater() {
      if (!this.isValidForm()) return ;

      this.submit().then((success) => {
        if (success) {
          this.$emit('next', 'thank-you');
        }
      });
    },
    /**
     * Check if the form is correctly filled up.
     */
    isValidForm() {
      this.$v.$touch();
      let invalidFields = [...document.querySelectorAll('.invalid')]
     
      if (invalidFields.length) {
        let element = invalidFields[0].parentElement,
            input = document.querySelector('input');

        input.scrollIntoView();
      }

      return !this.$v.$invalid;
    },
    /**
     * Submit the form.
     */
    submit() {
      if (!this.organization_id) return ;
      this.loading = true;

      // Update Isodistance Service Area
      return this.$http.put(`/service_areas/${this.organization_id}`, { range: this.mileSelected },[{responseType: 'json'}])
        .then(response => {

          if(response.status === 200) {
            // Success
            this.done = true;
            this.alertTitle = "Success!";
            this.alertMsg = "Service Area updated successfully";
            this.alert = true;
            this.loading = false;
            this.error = false;
            this.isCompleteForm = false;

            return true;
          } else {
            // Error
            this.alertTitle = "Error";
            this.alertMsg = "Something went wrong, please try again";
            this.alert = true;
            this.loading = false;
            this.error = true;
            this.isCompleteForm = true;

            return false;
          }
        }, err => {
          // Error
          console.log(err)
          this.alertTitle = "Error";
          this.alertMsg = "Something went wrong, please try again";
          this.alert = true;
          this.loading = false;
          this.error = true;
          this.isCompleteForm = true;

          return false;
      });
    },
    getValidationClass(form, fieldName) {
      const field = this.$v[form][fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    allowOnlyNumbers(event) {
      if (/^\d*$/.test(event.key) || event.keyCode === 8) {
        return ;
      }

       event.preventDefault();
    }
  },
  created () {
    if(this.onboardingStep) {
      if(this.onboardingStep.step === 'third' || this.onboardingStep.step === 'fourth' || this.onboardingStep.step === 'fifth' || this.onboardingStep.step === 'sixth') {
          this.done = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  current: {},
  dispatchers: [],
  police_departments: [],
  towers: [],
  operationHours: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters, 
  mutations
};

<template>
  <div class="tower-dashboard">
    <transition>
      <!-- Dashboard -->
      <div v-if="view === 'dispatch-view'" class="d-md-flex flex-row-reverse">
        <!-- Dispatch Map -->
        <div class="column column--right d-none d-md-block">
          <TowerMap/>
        </div>
        <!-- Dispatch Feed -->
        <div class="column column--left">
          <!-- Dispatch Feed: Header -->
          <div class="p-2 d-md-flex">
            <h1>Dispatch</h1>
            <!-- <div 
              v-if="features.autoquote && this.current.autoquote_enabled"
              class="autoquote-badge">Autoquote Enabled!</div> -->
          </div>

          <div v-if="loading">
            <div class="row pt-5">
              <md-progress-spinner class="mx-auto" :md-diameter="120" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>

          <!-- Empty States -->
          <div v-else-if="activeReceivedRequests.length == 0">
            <!-- Not Ready: No Drivers Invited -->
            <md-empty-state
              v-if="current.drivers == 0"
              md-icon="assignment_ind"
              md-label="You haven't invited any drivers"
              md-description="Invite drivers to respond to job requests.">
              <md-button class="btn btn-primary" :href="'/drivers/new'">Invite operators</md-button>
            </md-empty-state>
            <!-- Not Ready: No Vehicles added -->
            <md-empty-state
              v-else-if="current.vehicles == 0"
              md-icon="error"
              md-label="You haven't added vehicles"
              md-description="Add vehicles to respond to job requests.">
              <md-button class="btn btn-primary" :href="'/vehicles'">Add vehicles</md-button>
            </md-empty-state>
            <!-- Not Ready: No Drivers Accepted Invite -->
            <md-empty-state
              v-else-if="current.drivers && current.drivers.filter(driver => driver.invitation_accepted) == 0"
              md-icon="assignment_ind"
              md-label="Waiting for drivers to register"
              md-description="You can respond to job requests as soon as a driver registers.">
              <md-button class="btn btn-primary" :href="'/drivers'">Resend Invites</md-button>
            </md-empty-state>
            <!-- Ready for Jobs -->
            <md-empty-state v-else class="test-jobs-empty-state">
              <lottie-player v-if="towerIsOpen"
                src="/static/animations/listening-for-jobs.json"
                background="transparent"
                speed="1"
                direction="1"
                mode="normal"
                loop
                autoplay
              />
              <img v-else src="../../../images/icon-not-listening.png" alt="Not Listening for Works" />

              <h2 class="empty-state-title">
                {{ towerIsOpen ? 'Listening for jobs' : 'Jobs are paused' }}
              </h2>
              <div class="empty-state-operation">
                <div class="empty-state-status">
                  <span
                    class="empty-state-connection"
                    :class="[towerIsOpen ? 'empty-state-connection--open' : 'empty-state-connection--close']"
                  >
                  </span>
                  <p>{{ !towerIsOpen ? 'Closed' : STATUS[towerOperation.businessStatus] }}</p>
                </div>
                <p v-if="towerIsOpen && towerOperation.businessStatus === 'open'">
                  Today {{ format12Hour(towerOperation.openTime) }} - {{ format12Hour(towerOperation.closeTime) }}
                  <span v-if="current && current.sp_time_zone">({{ getOrganizationTimeZoneName(current.sp_time_zone) }})</span>.
                </p>
              </div>
              <p v-if="!towerIsOpen && !availableDay" class="empty-state-description">
                Manage your Hours of Operation and Notification Preferences in <a :style="{ color: '#abb7be', textDecoration: 'underline'}" href="/organizations/my_info">Settings</a>.
              </p>
              <p v-else-if="towerIsOpen" class="empty-state-description">
                New jobs will appear here. Manage your Hours of Operation and Notification Preferences in <a :style="{ color: '#abb7be', textDecoration: 'underline'}" href="/organizations/my_info">Settings</a>.
              </p>
              <p v-else class="empty-state-description">
                Jobs will resume <span :style="{textTransform: 'capitalize'}">{{ availableDay.dayOfWeek }}</span> at {{ format12Hour(availableDay.startHour) }} 
                <span v-if="current && current.sp_time_zone">({{ getOrganizationTimeZoneName(current.sp_time_zone) }}).</span>
                Manage your Hours of Operation and Notification Preferences in <a :style="{ color: '#abb7be', textDecoration: 'underline'}" href="/organizations/my_info">Settings</a>.
              </p>
              <div class="d-md-flex">
                <md-button
                  class="btn btn-secondary compact"
                  style="margin: 0.5em"
                  :href="'/vehicles/new'"
                  >+ Add vehicles</md-button
                >
                <md-button
                  class="btn btn-secondary compact"
                  style="margin: 0.5em"
                  :href="'/drivers/new'"
                  >+ Invite operators</md-button
                >
              </div>
            </md-empty-state>
          </div>

          <div class="feed" v-else-if="activeReceivedRequests.length">
            <ReceivedQuoteRequestList @select-request="viewRequest($event)" />
          </div>
        </div>
      </div>

      <!-- Job Detail View -->
      <div v-else-if="view === 'job-info-view' && selected_request">
        <JobInfo
          :request="selected_request"
          @back="cancelDetailView"
          @quoting-expired="onQuotingWindowExpired"
          @decline="onDeclineJob"
          @decline-direct-assignment="onDeclineDirect"
          @complete="markComplete"
        />
      </div>
    </transition>

    <!-- Snackbar Notifications -->
    <md-snackbar
      :md-position="'center'"
      :md-duration="5000"
      :md-active.sync="showGeneralError"
    >
      <span
        ><i class="md-icon md-theme-dark">error</i> Action has failed. An
        internal error ocurred!</span
      >
    </md-snackbar>

    <md-snackbar
      :md-position="'center'"
      :md-duration="5000"
      :md-active.sync="showSnackQuotingExpired"
    >
      <span
        ><i class="md-icon md-theme-dark">error</i> Quote submission
        failed</span
      >
    </md-snackbar>

    <md-snackbar
      :md-position="'center'"
      :md-duration="2000"
      :md-active.sync="showSnackJobDeclined"
    >
      <span
        ><i class="md-icon md-theme-dark">error</i> You have declined the
        job</span
      >
    </md-snackbar>

    <md-snackbar
      :md-position="'left'"
      :md-duration="2000"
      :md-active.sync="showSnackDirectDeclined"
    >
      <span
        ><i class="md-icon md-theme-dark">error</i> You have declined the
        assignment</span
      >
    </md-snackbar>

    <md-snackbar
      class="snack-success"
      :md-position="'center'"
      :md-duration="2000"
      :md-active.sync="showSnackJobComplete"
    >
      <span
        ><i class="md-icon md-theme-dark">check_circle</i> Assignment marked as
        complete</span
      >
    </md-snackbar>

    <md-snackbar
      class="snack-success"
      :md-position="'center'"
      :md-duration="2000"
      :md-active.sync="showSnackQuoteSent"
    >
      <span
        ><i class="md-icon md-theme-dark">check_circle</i> Quote successfully
        submitted</span
      >
    </md-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { format, intlFormat } from 'date-fns';
import { createDateFromStringDate, getNextOpenDay, getTimeZoneName } from '@/utils/object-helpers'
import iconNotListening from '../../../images/icon-not-listening.svg';

// Sockets
import driverConstructor from '../socket/channels/drivers';
import dispatchConstructor from '../socket/channels/dispatch';
import towerConstructor from '../socket/channels/tower';

// Components
import JobInfo from './job-info/job-info';
import ReceivedQuoteRequestList from './quote-requests/received-quote-request-list';
import TowerMap from './maps/tower-map';

const STATUS = {
  'always_open': 'Open 24 Hours',
  'open': 'Open',
  'closed': 'Closed'
}

export default {
  computed: {
    ...mapState('features', {
      features: (state) => state.features,
    }),

    ...mapState('organizations', {
      current: (state) => state.current,
      dispatchers: (state) => state.dispatchers,
      operationHours: (state) => state.operationHours
    }),

    ...mapState('job_assignments', {
      assignments: (state) => state.received,
    }),

    ...mapState('drivers', {
      drivers: (state) => state.all,
    }),

    ...mapGetters('quote_requests', ['activeReceivedRequests']),
    getTime() {
      const currentDate = new Date()
      const options = { timeZone: this.current.sp_time_zone, localeOptions: { locale: 'en-US', } }
      // If `timeZone` is not defined, it will use the time zone of the
      // environment where the code is running.
      const currentIntlDayTime = intlFormat(
        currentDate, {
          weekday: 'long',
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          timeZone: this.current.sp_time_zone
        }, options).toLowerCase();
      const timeSplit = currentIntlDayTime.split(" ");
      const dayName = timeSplit[0];
      const hourTime = timeSplit[1];
      return { dayName, hourTime };
    },
    availableDay() {      
      // Tries to calculate the next available day
      const data = getNextOpenDay(
        this.operationHours,  // => State observer
        this.getTime.dayName, // => tuesday
        this.getTime.hourTime // => 18:07
      )

      // Sets `data` to `{ towerIsOpen: true }` if the organization does not
      // have custom hours.
      if (data?.towerIsOpen) return true

      // Returns `null` if there are no available days (e.g., the organization
      // is marked as closed every day).
      if (!data) return null 

      // Returns the day of the week and the open/start time for the next
      // available day.
      return { dayOfWeek: data.dayOfWeek, startHour: data.startHour }
    }
  },

  data() {
    return {
      STATUS,
      iconNotListening,
      loading: true,
      selected_request: null,
      showGeneralError: false,
      showSnackJobDeclined: false,
      showSnackDirectDeclined: false,
      showSnackJobComplete: false,
      showSnackQuoteSent: false,
      showSnackQuotingExpired: false,
      view: 'dispatch-view',
      motorist_dispatcher: { id: 'motorist' },
      towerIsOpen: true,
      towerOperation: {
        businessStatus: 'always_open',
        openTime: '00:00',
        closeTime: '24:00',
      },
      worker: null,
    };
  },

  components: {
    JobInfo,
    ReceivedQuoteRequestList,
    TowerMap,
  },

  methods: {
    async subscribe() {
      await this.$store.dispatch('organizations/getCurrentOrganization');
      await this.$store.dispatch('organizations/getDispatchers', {
        id: this.current.id,
      });
      if (this.dispatchers.length === 0) {
        this.dispatchers.push(this.motorist_dispatcher);
      }
      this.dispatchers.forEach((dispatcher) => {
        this.$cable.create(
          { channelConstructor: dispatchConstructor },
          { dispatcher }
        );
      });
      await this.drivers.forEach((driver) => {
        this.$cable.create(
          { channelConstructor: driverConstructor },
          { driver }
        );
      });
      await this.$cable.create(
        { channelConstructor: towerConstructor },
        { tower: this.current }
      );
    },
    async refreshReceivedQuoteRequests() {
      this.loading = true;
      await Promise.all([
        await this.$store.dispatch('quote_requests/getReceivedQuoteRequests'),
        await this.$store.dispatch('drivers/getActiveDrivers'),
      ]);
      this.loading = false;
    },
    viewRequest(request) {
      this.view = 'job-info-view';
      this.selected_request = request;
    },
    cancelDetailView() {
      this.view = 'dispatch-view';
      this.selected_request = null;
    },
    onDeclineJob() {
      this.showSnackJobDeclined = true;
      setTimeout(() => {
        const id = this.selected_request.id;
        this.view = 'dispatch-view';
        this.selected_request = null;
        this.$store.dispatch('quote_requests/dismissReceivedRequest', id);
      }, 500);
    },
    onDeclineDirect() {
      this.showSnackDirectDeclined = true;
      setTimeout(() => {
        const id = this.selected_request.id;
        this.view = 'dispatch-view';
        this.selected_request = null;
      }, 500);
    },
    onQuotingWindowExpired() {
      this.showSnackQuotingExpired = true;
    },
    startQuote() {
      this.view = 'submit-quote-view';
    },
    quoteSubitted() {
      setTimeout(() => {
        this.view = 'dispatch-view';
        this.showSnackQuoteSent = true;
      }, 2500);
    },
    markComplete() {
      this.showSnackJobComplete = true;
    },
    checkOperation(data) {
      this.destroyWorkerForOperationTime()
      if (data?.length) {
        data.map(({ attributes }) => {
          if (attributes.day_of_week === this.getTime.dayName) {
            this.towerOperation.openTime = attributes.start_hour || '00:00'
            this.towerOperation.closeTime = attributes.end_hour || '24:00'
            this.towerOperation.businessStatus = attributes.business_status
            this.loading = false
          }
        })
      }
    },
    registerWorkerForOperationTime() {
      this.loading = true

      // `availableDay` will be true if there are no custom hours. In this case,
      // we assume the organization is open 24 hours. Don't start a worker to
      // monitor time.
      //
      // NOTE: We can't use a truthy check here because `availableDay` can be
      // true OR an object which would evaluate as true.
      if (this.availableDay === true) {
        this.towerIsOpen = true
        this.loading = false
        return
      }

      // `availableDay` can also be null or an object with the next available
      // day and start time, where one or both fields could be missing. Don't
      // start a worker to monitor time.
      if (
        this.availableDay == null ||
        (this.availableDay !== true &&
          (!this.availableDay?.dayOfWeek || !this.availableDay?.startHour))
      ) {
        this.towerOperation.businessStatus = 'closed'
        this.towerIsOpen = false
        this.loading = false
        return
      }

      // In case the business Status captured from operation hours is closed
      if (this.towerOperation.businessStatus === 'closed') {
        this.towerIsOpen = false
        this.loading = false
        return
      }

      // If is open, then listen for changes in the time and the current time against open and end timers
      this.worker = new Worker('/operation-hours.js')
      this.worker.postMessage({ 
        closeTime: this.towerOperation.closeTime,
        openTime: this.towerOperation.openTime,
        timeZone: this.current.sp_time_zone,
      })

      this.worker.onmessage = ({ data }) => { 
        if (data) {
          this.towerIsOpen = data.isOpen
          this.loading = false
        }
      }
    },
    destroyWorkerForOperationTime() {
      if (this.worker) {
        this.worker.terminate()
        this.worker = null
      }
    },
    format12Hour(time) {
      if (time) {
        const convertedTimestamp = createDateFromStringDate(time)
        return format(convertedTimestamp, 'h:mm b')
      }
    },
    getOrganizationTimeZoneName(timeZone) {
      return getTimeZoneName(timeZone, "short");
    }
  },

  watch: {
    operationHours(data) {
      this.checkOperation(data)
      this.registerWorkerForOperationTime()
    }
  },

  async created() {
    await Promise.all([
      await this.$store.dispatch('users/getCurrentUser'),
      await this.$store.dispatch('quote_requests/getReceivedQuoteRequests'),
      await this.$store.dispatch('drivers/getActiveDrivers'),
      await this.$store.dispatch('features/getFeatures'),
      await this.$store.dispatch('organizations/getOperationHours', this.current.id),
    ]);
    await this.subscribe();
  },

  mounted() {
    // this.loading = false
    this.registerWorkerForOperationTime()
  },

  destroyed() {
    this.destroyWorkerForOperationTime()
  }
};
</script>

<style lang="scss">
.test-jobs-empty-state {
  .md-empty-state-icon {
    width: 265px;
    height: auto;
  }

  .md-empty-state-label {
    color: rgba(15, 50, 69, 0.87);
    opacity: 0.5;
  }

  .md-empty-state-description {
    color: rgba(11, 57, 81, 0.65);
  }
}

.tower-dashboard {
  &__list-item {
    .md-list-item-container .md-list-item-content {
      color: rgba(15, 50, 69, 0.87);
    }

    .md-list-item-container:hover {
      background-color: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.08),
          rgba(0, 0, 0, 0.08)
        ),
        #ffffff;
    }
  }
}

.requests-list-header {
  background-color: white;
  // border-bottom: 1px solid lightgray;
  padding: 1em;
  margin: 1em;
  border: 1px solid lightgray;
  border-radius: 0.5em;
}

.autoquote-badge {
  border-radius: 2em;
  background-color: green;
}

.empty-state-title {
  color: #abb7be;
  font-size: 1.75rem;
}

.empty-state-description {
  color: #abb7be;
  padding-bottom: 20px;
  border-bottom: 1px solid #CFD6DA;
}

.empty-state-operation {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  font-size: 14px;
  line-height: 18px;
  color: #abb7be;
}

.empty-state-status {
  display: flex;
  gap: 5px;
}

.empty-state-connection {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-top: 3px;

  &--open {
    background: #4caf50;
  }

  &--close {
    background: #ef585a;
  }
}
</style>

<template>
  <div class="col-md-8 margin-auto">
    <GmapMap
      :center="{lat:10, lng:10}"
      :zoom="7"
      map-type-id="terrain"
      style="display: none;"
    ></GmapMap>
    <h1>Sign up for jobs at your rates</h1>
    <form class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <div>
          <h3 class="onboarding__subtitle">Business info</h3>
        </div>
        <div class="row">
          <div class="col-md-6">
            <md-field :class="getValidationClass('company', 'name')">
              <label for="name">Business name</label>
              <md-input name="name" id="name" v-model="company.name" ref="firstInput"/>
              <span v-if="!$v.company.name.required" class="md-error">This field is required</span>
              <span v-else-if="!$v.company.name.maxLength" class="md-error">This field cannot exceed 100 characters</span>
              <span v-if="$v.company.name.$invalid" class="invalid display-none"></span>
            </md-field>
          </div>
          <div class="col-md-6">
            <md-field :class="getValidationClass('company', 'contactPhone')">
              <label for="contactPhone">Business phone number</label>
              <md-input
                name="contactPhone"
                id="contactPhone"
                type="tel"
                v-model="company.phoneFormat"
                v-on:keyup="caretPhone('company')"
              />
              <span class="md-error">This field is required</span>
              <span v-if="$v.company.contactPhone.$invalid" class="invalid display-none"></span>
            </md-field>
            <span
              class="validate-number"
              v-if="!validateNumberCompany"
              >The company phone number must be at least 10 characters long</span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" v-if="didMount">
            <md-field :class="['location-field-wrapper', getValidationClass('company', 'selection')]">
              <LocationAutocomplete
                label="Business address"
                helperText=""
                :currentLocation="false"
                v-model="company.address"
                @location="getSelection"
                class="location-field"
              />
              <span class="md-error" v-if="!$v.company.selection.required">This field is required</span>
              <span class="md-error" v-else-if="!$v.company.selection.maxLength">This field cannot exceed 128 characters</span>
              <span v-if="$v.company.selection.$invalid" class="invalid display-none location-autocomplete-label"></span>
            </md-field>
          </div>
        </div>

        <div>
          <h3 class="onboarding__subtitle">Contact info</h3>
        </div>

        <div class="row">
          <div class="col-md-6">
            <md-field :class="getValidationClass('user', 'firstName')">
              <label for="firstName">First name</label>
              <md-input name="firstName" id="firstName" v-model="user.firstName" />
              <span class="md-error" v-if="!$v.user.firstName.required">This field is required</span>
              <span class="md-error" v-else-if="!$v.user.firstName.maxLength">This field cannot exceed 128 characters</span>
              <span v-if="$v.user.firstName.$invalid" class="invalid display-none"></span>
            </md-field>
          </div>
          <div class="col-md-6">
            <md-field :class="getValidationClass('user', 'lastName')">
              <label for="lastName">Last name</label>
              <md-input name="lastName" id="lastName" v-model="user.lastName" />
              <span class="md-error" v-if="!$v.user.lastName.required">This field is required</span>
              <span class="md-error" v-else-if="!$v.user.lastName.maxLength">Last name cannot exceed 128 characters</span>
              <span v-if="$v.user.lastName.$invalid" class="invalid display-none"></span>
            </md-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <md-field :class="getValidationClass('user', 'phoneNumber')">
              <label for="phoneNumber">Mobile phone number</label>
              <md-input
                name="phoneNumber"
                id="phoneNumber"
                type="tel"
                v-model="user.phoneFormat"
                v-on:keyup="caretPhone('user')"
              />
              <span class="md-error">This field is required</span>
              <span v-if="$v.user.phoneNumber.$invalid" class="invalid display-none"></span>
            </md-field>
            <span
              class="validate-number"
              v-if="!validateNumberAccount"
              >The Mobile Number must be at least 10 characters long</span>
          </div>
          <div class="col-md-6">
            <md-field :class="getValidationClass('user', 'email')">
              <label for="email">Email address</label>
              <md-input name="email" id="email" type="email" v-model="user.email" />
              <span class="md-error" v-if="!$v.user.email.required">This field is required</span>
              <span class="md-error" v-else-if="!$v.user.email.maxLength">This field cannot exceed 128 characters</span>
              <span
                class="md-error"
                v-else-if="!$v.user.email.email && $v.user.email.dirty"
              >This should be a valid email</span>
            </md-field>
            <span v-if="$v.user.email.$invalid" class="invalid display-none"></span>
          </div>
        </div>

        <div>
          <p
            class="onboarding__description"
          >Password must have a minimum of 8 characters.</p>
        </div>

        <div class="row">
          <div class="col-md-6">
            <md-field :class="getValidationClass('user', 'password')">
              <label for="password">Password</label>
              <md-input name="password" id="password" type="password" v-model="user.password" />
              <span class="md-error" v-if="!$v.user.password.required">This field is required</span>
              <span
                class="md-error"
                v-else-if="!$v.user.password.minLength"
              >Password must be at least 8 characters long</span>
              <span
                class="md-error"
                v-else-if="!$v.user.password.maxLength"
              >Password cannot exceed 128 characters</span>
            </md-field>
            <span v-if="$v.user.password.$invalid" class="invalid display-none"></span>
          </div>
          <div class="col-md-6">
            <md-field :class="getValidationClass('user', 'passwordConfirmation')">
              <label for="passwordConfirmation">Confirm password</label>
              <md-input
                name="passwordConfirmation"
                id="passwordConfirmation"
                type="password"
                v-model="user.passwordConfirmation"
              />
              <span
                class="md-error"
                v-if="!$v.user.passwordConfirmation.required"
              >This field is required</span>
              <span
                class="md-error"
                v-else-if="!$v.user.passwordConfirmation.sameAsPassword"
              >Passwords must be identical.</span>
              <span v-if="$v.user.passwordConfirmation.$invalid" class="invalid display-none"></span>
            </md-field>
          </div>
        </div>

        <div>
          <md-button
            :disabled="!isCompleteForm"
            class="md-raised md-primary"
            @click="next"
          >
           Submit
          </md-button>
        </div>
      </div>
    </form>

    <ProgressDialog v-if="loading" :lockScreen="true" />

    <md-snackbar
      :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-driver']"
      :md-position="position"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="alert"
      md-persistent
    >
      <span class="snackbar-notify__text">
        <i v-if="!error" class="material-icons">check</i>
        {{ alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import humps from 'humps';
import { required, email, sameAs, maxLength, minLength } from "vuelidate/lib/validators";
import { validationMixin } from 'vuelidate';

// Import components
import ProgressDialog from "./progress-dialog";
import LocationAutocomplete from "@motorist/components/global/location-autocomplete";

const googleTimezoneApi = "https://maps.googleapis.com/maps/api/timezone/json";

export default {
  name: "LightRegistration",
  props: ['onboardingStep'],
  mixins: [validationMixin],
  components: {
    ProgressDialog,
    LocationAutocomplete
  },

  data() {
    return {
      didMount: false,
      done: false,
      position: "left",
      isInfinity: false,
      duration: 4000,
      isCompleteForm: true,
      company: {
        id: null,
        name: "",
        contactPhone: "",
        phoneFormat: "",
        address: "",
        state: "",
        zip: "",
        latitude: "",
        longitude: "",
        selection: null,
        location_attributes: null,
        orgType: "tower",
        spTimeZone: ""
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        phoneNumber: "",
        phoneFormat: "",
        invitationToken: null
      },
      alert: false,
      alertTitle: "",
      alertMsg: "",
      loading: false,
      error: false,
      validateNumberCompany: true,
      validateNumberAccount: true
    };
  },

  computed: {
    form_method() {
      return 'post';
    },

    form_endpoint() {
      return '/admin/sign-up';
    }
  },

  methods: {
    /**
     * Validate, submit the form and redirect to the next step.
     */
    next() {
      if (!this.isValidForm()) return ;
      
      if (this.company.address.length) {
        this.isCompleteForm = false;

        // the location component used to complete the address line stores 
        // the entire address under the `address` key
        // before submitting the data to the back end, 
        // cut the extra data stored in `address` which is alredy stored under their respective keys
        // this way the input continues to display the full address on loss of focus
        this.company.address = this.company.address.split(',')[0]

        let requestParams = {
          user: humps.decamelizeKeys(this.user),
          organization: humps.decamelizeKeys(this.company),
          is_light_registration: true
        };

        this.submit(requestParams)
          .then((success) => {
            if (success) {
              this.$emit('next', 'thank-you');
            }
          });
      } else {
        this.error = true
        this.alert = true
        this.alertMsg = "Error";
      }
    },

    /**
     * Check if the form is correctly filled up.
     */
    isValidForm() {
      this.$v.user.$touch();
      this.$v.company.$touch();
      let invalidFields = [ ...document.querySelectorAll('.invalid') ];
    
      if (invalidFields.length) {
        let input = document.querySelector('input');

        if (invalidFields[0].className.includes('location-autocomplete-label')) {
          invalidFields[0].scrollIntoView();
        }

        input.scrollIntoView();
      }

      return !this.$v.user.$invalid && !this.$v.company.$invalid;
    },

    /**
     * Submit the form.
     * 
     * @param requestParams
     */
    submit(requestParams) {
      this.loading = true;
      this.isCompleteForm = false;

      return this.$http[this.form_method](this.form_endpoint, requestParams)
        .then(
          response => {
            // store uuid so we can reference org in later registration steps
            this.$emit("updateTowerId", response.body.organization.id);

            this.done = true;
            this.alertTitle = "Success!";
            this.alertMsg = "You've successfully signed up";
            this.alert = true;
            this.loading = false;
            this.error = false;
            localStorage.setItem("user", JSON.stringify(this.user));

            return true;
          },
          response => {
            if (response.status === 422) {
              const errors = response.body.errors;
              const errorKeys = Object.keys(errors || {});

              if (response.body.user) {
                this.alertMsg = response.body.user[0];
              }


              if (response.body.org) {
                this.alertMsg = response.body.org;
              }

              // Only display the first error returned.
              if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const firstErrorKeyCapitalized = firstErrorKey.charAt(0).toUpperCase() + firstErrorKey.slice(1)

                this.alertMsg = `${firstErrorKeyCapitalized} ${errors[firstErrorKey][0]}`;
              }
            }

            this.alertTitle = "Error";
            this.alert = true;
            this.loading = false;
            this.error = true;
            this.isCompleteForm = true;

            return false;
          }
        );
    },

    async getSelection (location) {
      if (!location) return;
      
      axios.get(
        `${googleTimezoneApi}?location=${location.latitude}%2C${location.longitude}&timestamp=1331161200&key=${CSOS_RT_CONF.GOOGLE_MAPS_API_KEY}`
      ).then(response => {
        this.company.spTimeZone = response.data?.timeZoneId
      }).catch(() => {
        console.log('An internal error has ocurred!')
      });

      /**
       * Location params
       */
      this.company.selection = {
        ...location,
        line_1: location.address,
        zip: location.postalCode
      };

      /**
       * Organization params
       */
      this.company.address = location.address;
      this.company.city = location.city ? location.city : location.neighborhood;
      this.company.state = location.state;
      this.company.zip = location.postalCode;
      this.company.latitude = location.latitude;
      this.company.longitude = location.longitude;
      this.company.location_attributes = location;
    },

    getQuery (query) {
      if (query === '') {
        this.company.selection = null
      }
    },

    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    
    caretPhone(target) {
      if (target === "company") {
        var x = this.company.phoneFormat
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.company.phoneFormat = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        this.company.contactPhone = this.company.phoneFormat.replace(
          /[()]/g,
          ""
        );
        this.company.contactPhone = this.company.contactPhone
          .replace(/-/g, "")
          .replace(" ", "");

          this.validateNumberCompany = this.$v.company.contactPhone.minLength
      }
        if(target === 'user') {
          var x = this.user.phoneFormat.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          this.user.phoneFormat = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
          this.user.phoneNumber =  this.user.phoneFormat.replace(/[()]/g, '');
          this.user.phoneNumber = this.user.phoneNumber.replace(/-/g, "").replace(" ", "");

          this.validateNumberAccount = this.$v.user.phoneNumber.minLength
        }
    },

    show() {
    	this.display = true;
    },

    focus() {
    	this.$refs.firstInput.$el.focus();
    },

    nextTickFocus() {
    	this.show();
      this.$nextTick(this.focus)
    },
  },

  mounted () {
    this.nextTickFocus();    
    this.$nextTick()
      .then(() => {
        this.didMount = true;
      });
  },

  validations: {
    user: {
      firstName: {
        required,
        maxLength: maxLength(128)
      },
      lastName: {
        required,
        maxLength: maxLength(128)
      },
      phoneNumber: {
        required,
        minLength: minLength(10)
      },
      email: {
        required,
        email,
        maxLength: maxLength(128)
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(128)
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs("password")
      }
    },
    company: {
      name: {
        required,
        // Stripe API limits company names to 100 characters
        maxLength: maxLength(100)
      },
      contactPhone: {
        required,
        minLength: minLength(10)
      },
      selection: {
        required,
        maxLength: maxLength(128)
      }
    }
  },
};
</script>

<style scoped>
.location-field-wrapper {
  margin-top: 0;
  padding-top: 0;
}

.location-field {
  max-width: inherit;
  width: inherit;
}
</style>
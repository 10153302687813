<template>
  <div>
     <div class="card-title col-md-10 margin-auto vehicle" v-if="type_form === 'edit'">
      <div class="relative">
        <a href="/users" class="link-goback">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path id="ewiug24628" d="M18.791 11.005H7.621l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41l-4.88-4.88h11.17c.55 0 1-.45 1-1s-.45-1-1-1z"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <mask id="bcf383b537v3" fill="#fff">
                    <use xlink:href="#ewiug24628"/>
                </mask>
                <g fill="#000" fill-opacity=".54" mask="url(#bcf383b537v3)">
                    <path d="M0 0h24v24H0z"/>
                </g>
            </g>
          </svg>
       </a>
        <h2 class="vehicle__title">Edit dispatcher</h2>
      </div>
      <h3 class="vehicle__description">
        Dispatcher's details
          <span v-if="!invitation_accepted" class="badge badge--pending">Pending invite</span>
          <span v-else-if="activeUser" class="badge badge--active">active</span>
          <span v-else class="badge badge--deactived">De-activated</span>
      </h3>
    </div>
    <div class="col-md-10 margin-auto">
      <div class="row">
        <div class="form-group col-md-6">
          <input type="hidden" name="driver[first]" v-model="users.firstName" />
          <md-field :class="getValidationClass('users', 'firstName')">
            <label class="form__label">First name</label>
            <md-input v-model="users.firstName" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.users.firstName.required">The First Name is required</span>
          </md-field>
        </div>
        <div class="form-group col-md-6">
          <input type="hidden" name="driver[last]" v-model="users.lastName" />
          <md-field :class="getValidationClass('users', 'lastName')">
            <label class="form__label">Last name</label>
            <md-input v-model="users.lastName" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.users.lastName.required">The Last Name is required</span>
          </md-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <input type="hidden" name="driver[email]" v-model="users.email" />
          <md-field :class="getValidationClass('users', 'email')">
            <label class="form__label">Email</label>
            <md-input v-model="users.email" class="form-control"></md-input>
            <span class="md-error" v-if="!$v.users.email.email">Invalid email</span>
          </md-field>
        </div>

        <div class="form-group col-md-6">
          <input type="hidden" name="driver[phone]" v-model="users.phoneNumber" />
          <md-field class="getValidationClass('users', 'phoneNumber')">
            <label class="form__label">Phone number (Optional)</label>
            <md-input v-model="users.phoneFormat" class="form-control" v-on:keyup="caretPhone()"></md-input>
          </md-field>
        </div>

        <div class="form-group col-md-6">
          <md-checkbox :disabled="admin_toggle_disabled" v-model="users.admin">This dispatcher is an admin</md-checkbox>
          <div v-if="admin_toggle_disabled" style="font-weight: bold; color: #f06837;">Your company must have at least one admin. Please make sure one of your other dispatchers is selected as an admin before disabling this one.</div>
        </div>
      </div>

      <div v-if="type_form === 'edit'">
        <div class="row col-md-12 col-lg-12 padding-left-no">
          <div class="form__actions form__actions-user col-md-12">
            <div v-if="invitation_accepted" :class="[activeUser ? 'md-checked' : 'md','md-switch md-theme-default md- driver-switch']" @click="changeStatus">
              <div class="md-switch-container">
                <div class="md-switch-thumb">
                  <div class="md-ripple">
                    <input id="toogle-user" type="checkbox" :value="users.admin">
                  </div>
                </div>
              </div>
              <label for="toogle-user" class="md-switch-label pointer-events">{{activeUser ? 'Active dispatcher' : 'De-activated dispatcher'}}</label>
            </div>
            <div
              v-if="!invitation_accepted"
              :class="[this.users.sendingInvite ? 'disabled-button ' : '','form__button-resend-invitation-user']"
            >
              <i class="material-icons">autorenew</i>
              <button
                :class="[this.users.sendingInvite ? 'disabled-button ' : '','form__button-link']"
                @click="resendInvite(data.id, $event)"
              >Resend Invite</button>
              <svg
                :class="[this.users.sendingInvite ? 'spinner-show ' : '', 'spinner']"
                width="65px"
                height="65px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  class="circle"
                  fill="none"
                  stroke-width="6"
                  stroke-linecap="round"
                  cx="33"
                  cy="33"
                  r="30"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <md-dialog-confirm
        :md-active.sync="users.activeDialog"
        md-content="Are you sure you want to delete this dispatcher ?"
        md-confirm-text="Delete"
        md-cancel-text="Cancel"
        @md-cancel="onCancel"
        @md-confirm="deleteDriver(data.id,$event)"
      />
    </div>

    <div class="footer-form">
      <div class="col-md-10 margin-auto">
        <div class="footer-form__content">
          <md-button
            :disabled="!isCompleteForm"
            :class="[isCompleteForm ? 'active-button' : 'disabled-button','btn btn-primary footer-form__submit']"
            @click="validateUser(type_form)"
          >{{ type_form === 'edit' ? 'update' : 'Invite Dispatcher'}}</md-button>
          <md-button
            :href="path"
            class="md-primary footer-form__submit footer-form__submit-cancel"
          >Cancel</md-button>
          <md-progress-spinner :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </div>
      </div>
    </div>

    <md-snackbar
      :class="`snackbar-notify snackbar-notify-driver snackbar-notify--${isError ? 'error' : 'success'}`"
      md-position="left"
      :md-duration="isInfinity ? Infinity : duration"
      :md-active.sync="alert"
      md-persistent
    >
      <span class="snackbar-notify__text">
        <i v-if="!isError" class="material-icons">check</i>
        {{alertMsg}}
      </span>
    </md-snackbar>
  </div>
</template>


<script>
import Vue from "vue";
import ProgressDialog from "../../form-wizard/progress-dialog";
import { required, email, minLength } from "vuelidate/lib/validators";
import { caretPhone, formatPhoneNumber } from '../../../mixins/formatNumber'
import humps from 'humps'

export default {
  name: "formUser",
  components: {
    ProgressDialog
  },
  props: [
    "data",
    "path",
    "type_form",
    "driver_license_front",
    "driver_license_back",
    "expiration_date",
    "invitation_accepted",
    "admin",
    "admin_toggle_disabled"
  ],
  data: () => ({
    isError: false,
    alertMsg: "",
    alert: false,
    loading: false,
    isInfinity: false,
    duration: 4000,
    update: false,
    activeUser: false,
    changeSwitch: false,
    users: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      phoneFormat: "",
      validateNumber: true,
      buttonActive: true,
      sendingInvite: false,
      admin: false
    }
  }),
  validations: {
    users: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        email
      }
    }
  },
  computed: {
    isCompleteForm() {
      return (
        this.users.firstName &&
        this.users.lastName &&
        this.users.email &&
        this.$v.users.email.email &&
        this.users.buttonActive
      );
    }
  },
  methods: {
    done() {
      setTimeout(() => {
        window.location.href = "/users";
      }, 1000);
    },
    validateUser (target) {
      this.$v.$touch()
      this.users.buttonActive = false;
      if (!this.$v.$invalid) {
        target === "edit" ? this.updateUser() : this.submit()
        this.users.buttonActive = true;
      }
    },
    getValidationClass(form, fieldName) {
      const field = this.$v[form][fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    onCancel() {
      this.drivers.activeDialog = false;
    },
    caretPhone () {
      let val = caretPhone(this.users.phoneFormat,this.users.phoneNumber)
      this.users.phoneNumber = val.phoneNumber
      this.users.phoneFormat = val.phoneFormat
      this.users.validateNumber = this.$v.users.phoneNumber.minLength 
    },
    resendInvite(userId,e) {
      e.preventDefault();
      this.users.sendingInvite = true;
      Vue.http.post(`/users/${userId}/resend_invitation`, humps.decamelizeKeys(this.users) ,[{responseType: 'json'}])
        .then(response => {
          this.users.sendingInvite = false
          this.alert = true
          this.alertMsg = `We've resent an invite to the dispatcher`
          this.isError = false
        }, err => {
          this.users.sendingInvite = false
          this.alert = true
          this.alertMsg = `Something went wrong, try again`
          this.isError = true
      });
    },
    submit() {
      this.isCompleteForm = false
      this.update = true
      this.$http
        .post("/users/invite", {
          admin: this.users.admin,
          user: {
            email: this.users.email,
            first_name: this.users.firstName,
            last_name: this.users.lastName,
            phone_number: this.users.phoneNumber
          }
        })
        .then(
          response => {
            this.isError = false;
            this.alertMsg = "Dispatcher invite created";
            this.alert = true;
            this.loading = false;
            this.update = false;
            this.isCompleteForm = true;
            this.done();
          },
          response => {
            if(!response.ok) {
              this.alertMsg = response.body.error
            }
            this.isError = true;
            this.alert = true;
            this.loading = false;
            this.update = false;
            this.isCompleteForm = true;
            this.users.buttonActive = true;
          }
        );
    },
    changeStatus () {
      this.activeUser = !this.activeUser
      Vue.http.put(`/users/${this.data.id}/change_enable`, [{responseType: 'json'}])
        .then(response => {
          //console.log('update')
        }, err => {
            this.isError = true;
            this.alert = true;
            this.alertMsg = "Owner can't be deactivated";
            this.activeUser = true;
        });
    },
    updateUser () {
      //this.isCompleteForm = false
      this.update = true
      Vue.http.put(`/users/${this.data.id}`, humps.decamelizeKeys(this.users) ,[{responseType: 'json'}])
        .then(response => {
          this.alert = true
          this.alertMsg = `Updated`
          this.isError = false
          this.update = false
          setTimeout(() => {
            window.location.href = '/users'
          },1000)
        }, err => {
          const { body } = err
          this.users.sendingInvite = false
          this.alert = true
          this.alertMsg = body[0]
          this.isError = true
          this.update = false
      });
    }
  },
  created () {
    let data = humps.camelizeKeys(this.data)
    this.users.firstName = data.firstName
    this.users.lastName = data.lastName
    this.users.email = data.email
    this.users.phoneNumber = data.phoneNumber
    this.users.phoneFormat = formatPhoneNumber(data.phoneNumber)
    this.activeUser = data.enable
    this.users.admin = this.admin
  }
};
</script>

<template>
  <div class="create-account margin-auto">
    <div class="text-center">
      <img class="curbside-icon" src="../../../../images/logo_curbsidesos_dark.png" alt="curbside">
    </div>
    <div class="card create-account__content">
      <h2 class="create-account__title">Find your incident</h2>
      <p class="create-account__description">Enter your incident number and vehicle make. The incident number should be provide by local law enforcement.</p>
      <form class="create-account__form">
        <div class="create-account__content-fields col-md-9 margin-auto">
          <div class="form-group">
            <md-field class="create-account__field" :class="getValidationClass('caseInfo', 'number')">
              <label class="form__label">Incident number</label>
              <md-input v-model="caseInfo.number" class="form-control" ref="caseNumber" v-on:keyup="parseNumber"></md-input>
              <span class="md-error" v-if="!$v.caseInfo.number.required">This field is required</span>
            </md-field>
            <span class="example">e.g. "20-0601-999"</span>
          </div>
          <div class="form-group relative">
            <md-field class="create-account__field" :class="getValidationClass('caseInfo', 'make')">
              <label class="form__label">Vehicle make</label>
              <md-input v-model="caseInfo.make" class="form-control" ref="caseMake" v-on:keyup="filterMake(caseInfo.make)"></md-input>
              <md-progress-spinner class="make-list-loader" :md-diameter="30" :md-stroke="3" v-if="searching" md-mode="indeterminate"></md-progress-spinner>
              <span class="md-error" v-if="!$v.caseInfo.make.required">This field is required</span>
            </md-field>
            <span class="example">e.g. "Ford"</span>
            <div class="content-complete-motorist js-list-makes">
              <ul class="carsmakes-list" v-if="!searching && makeList.length">
                <li class="carsmakes-list-item" v-for="make in makeList" @click="selectMake(make['Make_Name'])"><span>{{ make['Make_Name'] }}</span></li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <md-button v-if="!isCompletedForm" class="create-account__button motorist-btnSubmit btn btn-primary disable-button">submit</md-button>
            <md-button v-else class="create-account__button motorist-btnSubmit btn btn-primary" @click="sendForm">submit</md-button>
          </div>
        </div>
      </form>
    </div>
    <md-dialog :md-active.sync="showDialog" class="dialog-motorist-info">
      <md-dialog-title class="dialog-motorist-info__title">Select your vehicle</md-dialog-title>
      <md-dialog-content class="dialog-form-wizard__content dialog-driver-organization">
       <p class="dialog-motorist-info__description">There are more than one vehicle with the same make involved in this incident.</p>
        <div class="col-md-12">
          <div class="md-layout-item md-small-size-100">
            <div class="row">
              <ul class="dialog-motorist-info__vehicle-list">
                <li class="dialog-motorist-info__vehicle-item" v-for="data in vehiclesIncident">
                  <span>{{ data.incident_vehicle.make}} {{data.incident_vehicle.model}}, {{data.incident_vehicle.plate}}</span>
                  <md-radio class="radio-vehicle" v-model="radioVehicle" :value="data.id">Boolean</md-radio>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="dialog-form-wizard__footer">
        <div class="footer-form__content">
          <md-button class="btn btn-primary footer-form__submit" @click="redirectPageDetail">confirm</md-button>
        </div>
      </md-dialog-actions>
    </md-dialog>
    <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
      <span class="snackbar-notify__text">
        <div v-if="!errorSnack" class="content-icon">
          <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
        </div>
        {{ alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Vue from 'vue';

export default {
  name: 'accountForm',
  data: () => ({
    caseInfo: {
      number: '',
      make: '',
    },
    carsMakes: [],
    makeList: [],
    searching: false,
    showList: false,
    showDialog: false,
    vehiclesIncident: [],
    radioVehicle: '',
    errorSnack: false,
    alertMsg: '',
    position: 'left',
    duration: 4000,
    isInfinity: false,
    update: false
  }),
  validations: {
    caseInfo: {
      number: {
        required
      },
      make: {
        required,
      },
    }
  },
  computed: {
    isCompletedForm () {
      return this.caseInfo.number && this.caseInfo.make
    }
  },
  methods: {
    async getMakes () {
      this.carsMakes = []
      const baseURL = 'https://vpic.nhtsa.dot.gov/api/vehicles/getallmakes?format=json'
      try {
        let response = await fetch(baseURL),
          makes = await response.json()
      
        if ( makes.Results.length > 1 ) {
          this.carsMakes = makes.Results
        }
      } catch {
        this.carsMakes = []
      }
    },
    filterMake (val) {
      this.makeList = []
      this.searching = true
      if ( this.caseInfo.make !== '' || this.$refs.caseMake.$el.value !== '' ) {
        this.makeList = this.carsMakes.filter(make => make['Make_Name'].toLowerCase().includes(this.$refs.caseMake.$el.value.toLowerCase()))
        setTimeout(() => {
          this.searching = false
        },2000)
      } else {
        this.searching = false
        this.makeList = []
      }
    },
    selectMake (make) {
      this.caseInfo.make = make
      this.searching = false
      this.makeList = []
    },
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    sendForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        Vue.http.get(`/motorists/check_reference?make=${this.caseInfo.make.toLowerCase()}&incident_number=${this.caseInfo.number.toLowerCase()}`,[{responseType: 'json'}])
          .then(response => {
            if (response.ok) {
              if (response.body.redirect && response.body.id.length) {
                window.location.href = `/motorists/job_info/${response.body.id}`
              }
              if(Array.isArray(response.body)) {
                this.showDialog = true;
                this.vehiclesIncident = response.body;
                this.radioVehicle = this.vehiclesIncident[0].id;
              }
            }
          }, err => {
             this.update = true;
             this.errorSnack = true;
             if (err.body.error) {
               this.alertMsg = err.body.error
             } else {
               this.alertMsg = 'Something went wrong';
             }
          });
      }
    },
    redirectPageDetail () {
      this.showDialog = false;
      window.location.href = `/motorists/job_info/${this.radioVehicle}`;
    },
    show() {
    	this.display = true;
    },
    focus() {
    	this.$refs.caseNumber.$el.focus();
    },
    nextTickFocus() {
    	this.show();
      this.$nextTick(this.focus)
    },
    parseNumber (e) {
      if ( !(e.keyCode === 8) ) {
        let val = this.caseInfo.number.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/)
        this.caseInfo.number = `${val[1]}-${val[2]}-${val[3]}`
      }
    }
  },
  mounted () {
    this.nextTickFocus();
    this.getMakes()
    window.addEventListener('click', (e) => {
      if ( !e.target.className.includes('js-list-makes') ) {
        this.makeList = []
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .disable-button {
    background: #f7f9fa !important;
    pointer-events: none;
    box-shadow: none;
    box-shadow: 0 2px 2px 0 rgba(0,0,0, .14), 0 3px 1px -2px rgba(0,0,0, .2), 0 1px 5px 0 rgba(0,0,0, .12) !important;
    .md-ripple .md-button-content {
      color: rgba(0, 0, 0, 0.38);
    }
  } 

  .span-message {
    position: relative;
    top: 13px;
  }

  .curbside-icon {
    width: 130px;
    margin: auto;
  }

  .motorist-btnSubmit {
    margin-top: 26px;
  }
  .motorist-form-content {
    padding-left: 0;
  }
</style>
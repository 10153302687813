<template>
  <div>
    <div class="col-md-10 margin-auto content-table-mobile">
      <div v-if="searchFilter.length" class="search">
        <div class="search__content">
          <div class="search__content-title">
            <h1 class="search__title">{{title}}</h1>
            <button class="search__button-search" @click="openSearch">
            </button>
          </div>
          <div class="search__button-add">
            <a :href="buttonPath" class="header-table__add">{{ buttonText }}</a>
          </div>
        </div>
        <transition name="fade">
          <input placeholder="Search" v-model="search" @input="searchOnTable" :class="[showInput ? 'show-search ' : 'hide-search', 'header-table__search']" />
        </transition>
      </div>
      <div v-if="searched.length" v-for="item in searched" md-card class="box">
        <ul class="box-list card">
          <a :href="editButtonPath+'/'+item['id']+'/edit'" class="box-list__button-edit">
            <i class="material-icons">edit</i>
          </a>
          <li class="box-list__item">
            <p class="box-list__title">First Name</p>
            <p class="box-list__value">{{ item.first }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Last Name</p>
            <p class="box-list__value">{{ item.last }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Phone number</p>
            <p class="box-list__value">{{formatPhoneNumber(item.phone)}}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">On a Job?</p>
            <p class="box-list__value">
              <i v-if="item['on_trip?']" class="material-icons table-list-drivers__check">check</i>
            </p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Online?</p>
          </li>
          <li> 
            <div v-if="item.active">
              <md-button v-if="!item.invitation_accepted " class="table-list-drivers__button" @click="resendInvitation(item)">Resend Invite</md-button>
            </div>
            <p v-if="!item.active" class="margin-bottom-no">Deactivated</p>
          </li>
        </ul>
      </div>
    </div>
     <md-snackbar
        :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success' , 'snackbar-notify snackbar-notify-driver']"
        :md-position="drivers.position"
        :md-duration="drivers.isInfinity ? Infinity : drivers.duration"
        :md-active.sync="drivers.inviteSend"
        md-persistent
      >
        <span class="snackbar-notify__text">
          <i v-if="!error" class="material-icons">check</i>
          {{alertMsg}} 
        </span>
      </md-snackbar>
  </div>
</template>

<script>
import Vue from 'vue';

const toLower = text => {
  return text.toString().toLowerCase()
}

const filterByValue = (items, term) => {
  if (term) {
    return items.filter(item =>
      Object.keys(item).some(key => item[key] && toLower(item[key]).includes(toLower(term)))
    )
  }

  return items
}

export default {
  name: 'TableResponsiveDriver',
  props: ['data', 'title', 'columns', 'button-text', 'button-path', 'edit-button-path', 'type-table'],
  data: () => ({
    search: null,
    searched: [],
    searchFilter: [],
    showInput: false,
    alertMsg: '',
    error: false,
    alert: false,
    drivers: {
      position: "left",
      isInfinity: false,
      duration: 4000,
      inviteSend: false
    }
  }),
  methods: {
    searchOnTable () {
      this.searched = filterByValue(this.data, this.search)
    },
    openSearch () {
      this.showInput = !this.showInput;
    },
    resendInvitation(item) {
      Vue.http
        .post(
          `/organizations/${item.organization_id}/drivers/${item.id}/send_email`,
          [],
          [{ responseType: "json" }]
        )
        .then(
          response => {
            if (response.ok) {
              this.drivers.inviteSend = true;
              this.alertMsg = `We've resent the link to the app store`
            }
          },
          err => {
            this.error = true;
            this.drivers.inviteSend = true;
            this.alertMsg = "Something went wrong, please try again";
          }
        );
    },
    formatPhoneNumber(str) {
        const cleaned = ("" + str).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
        return null;
    }
  },
  created () {
    this.searched = this.data
    this.searchFilter = this.data
  }
}
</script>

<style lang="scss" scoped>
  /* .md-field {
    max-width: 300px;
  } */

  .md-table-head-label {
    text-transform: capitalize;
  }
</style>

import * as ActiveStorage from 'activestorage'
import Turbolinks from 'turbolinks'

import '../images'
import '../javascripts'
import '../stylesheets/application'

// Packs
import '../src/dispatch'

ActiveStorage.start()
Turbolinks.start()

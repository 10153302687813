import Vue from 'vue';

export default {
  setVehicles (state, vehicles) {
    state.all = vehicles
  },
  setAvailableVehicles (state, vehicles) {
    state.available = vehicles
  }
}

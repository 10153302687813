<template>
  <div class="received-assignment-detail">
    <div class="row align-items-start">
      <!-- Assignment: Incident Vehicle -->
      <div class="col">
        <span style="color: #6F7172;">{{ assignment.incident_vehicle.make }} {{ assignment.incident_vehicle.model }}</span>
      </div>
      <!-- Assignment: Status -->
      <div class="col">
        <span v-if="assignment.status !== 'started'">
          <!-- {{ parsedStatus }} -->
          <StatusBadge :status="assignment.status" :hide_badge="true" />
        </span>
        <span v-else-if="hasValidEta">
          <span>{{ parseETA(assignment.eta) }}</span>
        </span>
        <span v-else-if="this.assignment.driver && !hasValidEta">
          Assigned
        </span>
        <span v-else>
          <div class="loading-eta">
            <md-progress-spinner :md-diameter="15" :md-stroke="1" md-mode="indeterminate"></md-progress-spinner>
            <span>calculating ETA</span>
          </div>
        </span>
      </div>
      <!-- Assignment: Operator -->
      <div class="col">
        <span v-if="assignment.driver" style="color: #6F7172;">{{ operatorName }}</span>
      </div>
    </div>
  </div>
</template>

<script>

// Components
import StatusBadge from '../utilities/status-badge'

export default {
  props: ['assignment', 'updating'],
  components: {
    StatusBadge
  },
  data () {
    return {
      now: new Date(),
      timerIntervalId: null
    }
  },
  computed: {
    parsedStatus () {
      return this.assignment.status.charAt(0).toUpperCase() + this.assignment.status.slice(1)
    },
    /**
     * Time duration of the countdown in seconds
     */
    assignWindowEnd() {
      const assignWindowStart = new Date(this.assignment.history["assigned_at"])
      return new Date(assignWindowStart.getTime() + 180000) // 3 minutes in ms
    },
    timeLeft() {
      const current_time = this.now.getTime()
      const end_time = this.assignWindowEnd.getTime()

      const seconds = (end_time - current_time) / 1000;

      return seconds;
    },
    formatTime() {
      if (this.timeLeft > 0) {
        const minutes = Math.floor(this.timeLeft / 60)
        const seconds = Math.floor(this.timeLeft - minutes * 60)

        const minutesToString =
          minutes < 10 ? '0' + minutes.toString() : minutes.toString()
        const secondsToString =
          seconds < 10 ? '0' + seconds.toString() : seconds.toString()

        return minutesToString + ':' + secondsToString
      } else {
        return '00:00'
      }
    },
    hasValidEta () {
      return this.assignment.eta != null && this.assignment.eta !== 0
    },
    operatorName () {
      if (this.assignment.driver.first && this.assignment.driver.first) {
        return `${this.assignment.driver.first} ${this.assignment.driver.last}`
      } else {
        return "Operator Name Missing"
      }
    }
  },
  watch: {
  },
  methods: {
    startAssignTimer() {
      this.timerIntervalId = setInterval(this.updateAssignTimer, 500)
    },
    updateAssignTimer() {
      this.now = new Date()
    },
  },
  created() {
    this.startAssignTimer()
  }
}
</script>

<style scoped lang="scss">
  .received-assignment-detail {
    margin: 0.5em;
  }
  .assign-time-remaining {
    font-weight: bold;
    color: #ff9800;
  }
</style>

<template>
  <md-card class="card account-owner card-wrecker-detail card-box-profile card-box offset-card section js-content-box" id="robocalls">
    <h2 class="title-card">Automated Calls</h2>
    <p class="caption">If your company wants to send automated calls to an additional phone number (such as an office landline), please provide that below and set its schedule.</p>
    <p class="caption faded-1">This number will receive calls whenever there is a new request or an update to the job.</p>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <transition name="slide">
      <div class="card-box__content" v-if="openBox">
        <div class="card-box__content-form js-box-content-form">
          <div class="row">
            <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
              <div class="field margin-bottom-no">
                <label class="form__label">Automated calls phone number</label>
                <div v-if="company.robocall_phone_format" class="form__field">
                  {{ company.robocall_phone_format }}
                </div>
                <div v-else class="form__field value-none">None</div>
              </div>
            </div>
            <div v-else class="col-md-12">
              <div class="form-group">
                <md-field :class="getValidationClass(this,'company','robocall_number')">
                  <label class="form__label">Automated calls phone number</label>
                  <md-input v-model="company.robocall_phone_format" class="form-control" v-on:keyup="caretPhone(); detectChange()"></md-input>
                  <span
                    class="md-error"
                    v-if="!$v.company.robocall_number.minLength"
                  >
                    The Phone Number must be at least 10 characters long
                  </span>
                </md-field>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Robocall Settings: Time Windows -->
            <div class="form-group col-md-6 padding-bottom-no">
              <p class="mb-0">
                <md-checkbox v-model="company.notification_settings.notificationsWeekdays" :disabled="!edit_info" @change="detectChange()" class="mb-0"><p>Weekdays</p></md-checkbox>
                <div class="faded-1">You will receive calls between 6:00 AM and 6:00 PM, Monday through Friday</div>
              </p>
              <p class="mb-0">
                <md-checkbox v-model="company.notification_settings.notificationsWeeknights" :disabled="!edit_info" @change="detectChange()" class="mb-0"><p>Weeknights</p></md-checkbox>
                <div class="faded-1">You will receive calls between 6:00 PM and 6:00 AM, Monday night through Friday morning</div>
              </p>
              <p class="mb-0">
                <md-checkbox v-model="company.notification_settings.notificationsWeekends" :disabled="!edit_info" @change="detectChange()" class="mb-0"><p>Weekends</p></md-checkbox>
                <div class="faded-1">You will receive calls between 6:00 PM Friday and 6:00 AM Monday</div>
              </p>
            </div>
          </div>
          <transition name="slide">
            <div v-if="edit_info" class="row">
              <div class="card-box__content-actions">
                <md-button :class="[deactivedButton ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
                <md-button class="md-accent card-box__button-cancel" @click="cancel(!up_to_date ? 'database' : 'browserData')">Cancel</md-button>
                <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </md-card>
</template>

<script>
import Vue from 'vue';
import LocationAutocomplete from "../../forms/map-forms/location-autocomplete";
import { minLength } from "vuelidate/lib/validators";
import { caretPhone } from '../../../mixins/formatNumber'
import { resetData } from '../../../mixins/resetData'
import { getValidationClass } from '../../../mixins/getValidationClass'
import humps from 'humps'

export default {
  name: 'Robocalls',
  props: ['company_data'],
  components: {
    LocationAutocomplete
  },
  data: () => ({
    company: {
      robocall_number: '',
      notification_settings: {
        notificationsWeekdays: false,
        notificationsWeeknights: false,
        notificationsWeekends: false,
      },
      robocall_phone_format: '',
    },
    data_save: null,
    edit_info: false,
    openBox: false,
    update: false,
    deactivedButton: true,
    up_to_date: false
  }),
  validations: {
    company: {
      robocall_number: {
        minLength: minLength(10)
      }
    }
  },
  methods: {
    getValidationClass,
    editInfo () {
      if ( !this.up_to_date ) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    caretPhone () {
      let phone = caretPhone(this.company.robocall_phone_format, this.company.robocall_number)
      this.company.robocall_phone_format = phone.phoneFormat
      this.company.robocall_number = phone.phoneNumber
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit () {
      this.update = true;
      this.deactivedButton = true;
      let company = {
        robocall_phone: this.company.robocall_number,
        settings: this.company.notification_settings
      }

      Vue.http.put(`/organizations/${this.company_data.id}`, company ,[{responseType: 'json'}])
        .then(response => {
          this.data_save = humps.camelizeKeys(this.company)
          setTimeout(() => {
            this.update = false;
            this.deactivedButton = false;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
            this.up_to_date = true
            this.edit_info = false
          },1000)
        }, err => {
          setTimeout(() => {
            this.update = false;
            this.deactivedButton = false;
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.error : 'Something went Wrong', error: true})
            this.up_to_date = false
          },1000)
      });
    },
    cancel (data) {
      this.edit_info = !this.edit_info;
      this.deactivedButton = true
      if ( data === 'database' ) {
        this.company = resetData(this.company, this.company_data)
        this.company.location_attributes = this.company_data.location
      }
      if ( data === 'browserData') {
        this.company = resetData(this.company,humps.decamelizeKeys(this.data_save,{split: /(?=[A-Z0-9])/ }))
      }
    },
    detectChange() {
      this.deactivedButton = false
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    this.company = resetData(this.company, this.company_data)
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  } 
}
</script>

<style lang="scss" scoped>
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
</style>

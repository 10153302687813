<template>
  <div class="map-location-selection">
    <div class="selection-map">
      <div class="mapPinCntr"></div>
      <GmapMap
        ref="selector"
        :center="center"
        :zoom="14"
        :options="options"
        @center_changed="throttledDrag($event)"
        style="width: 100%; height: 100%;"
      >
      </GmapMap>
    </div>
  </div>
</template>

<script>
import { Here } from '../../../services/here'

export default {
  props: ["initial_center", "external_center", 'initial_reverse_geocoding'],

  data () {
    return {
      here: null,
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
      place: null,
      result: null
    }
  },

  computed: {
    latLng (point) {
      return {
        lat: point.lat(),
        lng: point.lng()
      }
    },
    center () {
      return (this.external_center) ? this.external_center : this.initial_center
    }
  },

  methods: {
    async centerDrag (latLng) {
      let reverseGeocodeAddreess = await this.reverseGeocodeHere(latLng)
      this.result = reverseGeocodeAddreess.data.items[0]
      this.formatPlace(this.result)
      this.$emit("update:place", this.place)
    },

    async reverseGeocodeHere (point) {
      if (!this.here) {
        this.here = new Here()
      }

      return new Promise(
        async (resolve) => {
          let results = await this.here.reverseGeocode(point.lat(), point.lng())
          resolve(results)
        }
      )
    },

    formatPlace (place) {
      const locationStrings = this.formattedTestLocation(place)
      this.place = place
      this.place['address_primary'] = locationStrings.primary
      this.place['address_secondary'] = locationStrings.secondary
      this.place['addressable'] = {
        line_1: locationStrings.primary,
        line_2: locationStrings.secondary,
        district: place.address.district,
        city: place.address.city,
        state: place.address.stateCode,
        zip: place.address.postalCode,
        country: place.address.countryCode,
        location_type: place.resultType,
        latitude: place.position.lat,
        longitude: place.position.lng
      }
    },
  },

  created () {
    // We need make a reverse geocoding to get the real address and set it in the autocomplete.
    if (this.initial_center && this.initial_reverse_geocoding) {
      this.centerDrag({
        lat: () => this.initial_center.lat,
        lng: () => this.initial_center.lng
      });
    }

    this.throttledDrag = this.$throttle(this.centerDrag, 1000)
  }
}
</script>

<style>
  .map-location-selection {
    height: 100%;
  }

  .selection-map {
    width: 100%;
    height: 100%;
  }
</style>

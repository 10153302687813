<template>
  <div class="job-assignment-detail-dispatch">
    <div class="row">
      <div class="col-4">
        {{ assignment.incident_vehicle.make }} {{ assignment.incident_vehicle.model }}
      </div>
      <div class="col-2">
        <span v-if="assignment.status !== 'started'">
          {{ parsedStatus }}
        </span>
        <span v-else-if="hasValidEta">
          <span>{{ parseETA(assignment.eta) }}</span>
        </span>
        <span v-else-if="this.assignment.driver && !hasValidEta">
          {{ operatorName }} assigned
        </span>
        <span v-else>
          <div class="loading-eta">
            <md-progress-spinner :md-diameter="15" :md-stroke="1" md-mode="indeterminate"></md-progress-spinner>
            <span>calculating ETA</span>
          </div>
        </span>
      </div>
      <div class="col-6">
        <span>{{ assignmentNote }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// Socket
export default {
  props: ['assignment', 'updating', 'status'],

  components: {},

  data () {
    return {}
  },

  computed: {
    parsedStatus () {
      switch (this.assignment.status) {
        case "assigned":
          return "Notified"
          break;
        case "notified":
          return "Accepted"
          break;
        default:
          return this.assignment.status.charAt(0).toUpperCase() + this.assignment.status.slice(1)
      }
    },
    assignmentNote () {
      if (this.assignment.assigned_org) {
        return this.assignment.assigned_org.name
      } else if (this.status === 'unresolved') {
        return 'Not enough quotes received'
      } else {
        return ''
      }
    },
    hasValidEta () {
      return this.assignment.eta != null && this.assignment.eta !== 0
    },
    operatorName () {
      if (this.assignment.driver.first && this.assignment.driver.first) {
        return `${this.assignment.driver.first} ${this.assignment.driver.last}`
      } else {
        return "Operator"
      }
    }
  }
}
</script>

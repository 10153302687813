<template>
  <div class="quote-detail">
    <p class="mb-0">{{ quote.driver.first }} {{ quote.driver.last }}</p>
    <p v-if="quote.equipment" class="caption mb-0 faded-1">{{ quote.equipment.make }} {{ quote.equipment.model }}</p>
    <p v-if="quote.vehicle" class="caption mb-0 faded-1">{{ quote.vehicle.make }} {{ quote.vehicle.model }}</p>
    <p v-else class="caption mb-0 faded-1">Any vehicle</p>
  </div>
</template>

<script>
export default {
  props: ['quote'],

  data: () => {
    return {}
  }
}
</script>

<style scoped lang="">
</style>

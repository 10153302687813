var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"organization-view__menu"},[_c('menu-towco-profile',{attrs:{"showBankAccountSection":_vm.showBankAccountSection}})],1),_vm._v(" "),_c('div',{staticClass:"organization-view__content js-content-profile"},[_c('bussiness-info',{attrs:{"company_data":{
          company_name: _vm.organization_data.name,
          company_nickname: _vm.organization_data.nickname,
          phone_number: _vm.organization_data.contact_phone,
          company_address: _vm.organization_data.address,
          location: _vm.organization_data.location !== undefined ? _vm.organization_data.location : '',
          id: _vm.organization_data.id
        }},on:{"show:snackbar":_vm.showAlert}}),_vm._v(" "),_c('account-owner',{attrs:{"data_owner":{
          first_name: _vm.organization_data.owner !== null ? _vm.organization_data.owner.first_name : '',
          last_name: _vm.organization_data.owner !== null ? _vm.organization_data.owner.last_name : '',
          phone_number: _vm.organization_data.owner !== null ? _vm.organization_data.owner.phone_number : '',
          email: _vm.organization_data.owner !== null ? _vm.organization_data.owner.email : '',
          id: _vm.organization_data.id
        }},on:{"show:snackbar":_vm.showAlert}}),_vm._v(" "),_c('hours-of-operations',{attrs:{"data_owner":{
          organization_id: _vm.organization_data.id,
          day_operations: _vm.organization_data.day_operations !== null ? _vm.organization_data.day_operations: '',
          organization_timezone: _vm.organization_data.sp_time_zone
        }},on:{"show:snackbar":_vm.showAlert}}),_vm._v(" "),(_vm.showBankAccountSection)?_c('bank-account',{attrs:{"company_data":{
          id: _vm.organization_data.id
        },"stored_bank_account":_vm.stored_bank_account},on:{"show:snackbar":_vm.showAlert}}):_vm._e(),_vm._v(" "),(_vm.showBankAccountSection)?_c('tax-id',{attrs:{"company_data":{
          id: _vm.organization_data.id
        }},on:{"show:snackbar":_vm.showAlert}}):_vm._e(),_vm._v(" "),_c('robocalls',{attrs:{"company_data":{
          robocall_number: _vm.organization_data.robocall_phone,
          notification_settings: _vm.organization_data.settings,
          id: _vm.organization_data.id
        }},on:{"show:snackbar":_vm.showAlert}}),_vm._v(" "),_c('service-area',{attrs:{"serviceArea":_vm.organization_data.service_area !== null ? _vm.organization_data.service_area : null,"idOrganization":_vm.organization_data.id},on:{"show:snackbar":_vm.showAlert}}),_vm._v(" "),_c('fees',{attrs:{"detail":_vm.organization_data.tower_detail !== null ? _vm.organization_data.tower_detail : [],"id":_vm.organization_data.id},on:{"show:snackbar":_vm.showAlert}}),_vm._v(" "),_c('storage-facilities',{attrs:{"detail":_vm.organization_data.tower_detail !== null ? _vm.organization_data.tower_detail : [],"tower_data":_vm.organization_data.tower_detail !== null ? _vm.organization_data.tower_detail.tower_locations : [],"id":_vm.organization.id},on:{"show:snackbar":_vm.showAlert}})],1)]),_vm._v(" "),_c('md-snackbar',{class:[_vm.errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver'],attrs:{"md-position":_vm.position,"md-duration":_vm.isInfinity ? Infinity : _vm.duration,"md-active":_vm.update,"md-persistent":""},on:{"update:mdActive":function($event){_vm.update=$event},"update:md-active":function($event){_vm.update=$event}}},[_c('span',{staticClass:"snackbar-notify__text"},[_c('div',{staticClass:"content-icon"},[_c('i',{class:[_vm.errorSnack ? 'red' : 'green','material-icons']},[_vm._v("check")])]),_vm._v("\n      "+_vm._s(this.alertMsg)+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
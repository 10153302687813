import Vue from 'vue';
import { set } from '../../helpers';

export default {
  appendSentAssignment (state, assignment) {
    state.sent.push(assignment)
  },
  appendReceivedAssignment (state, assignment) {
    state.received.push(assignment)
  },
  setSentAssignments: set('sent'),
  setReceivedAssignments: set('received'),
  updateSentAssignment (state, assignment) {
    const assignment_index = state.sent.findIndex((item) => item.id == assignment.id)
    Vue.set(state.sent, assignment_index, assignment)
  },
  updateReceivedAssignment (state, assignment) {
    const assignment_index = state.received.findIndex((item) => item.id == assignment.id)
    Vue.set(state.received, assignment_index, assignment)
  }
}

<template>
  <div class="job-info">

    <div v-if="view === 'info'">
      <div class="map">
        <JobInfoMap :job="request.job" :status="status"/>
      </div>
      <div class="details">
        <div class="container-padded">
          <div class="row">

            <div class="col-md-1">
              <BackButton @back="$emit('back')" />
            </div>
            <div class="col-md-10 pt-4 pb-4 mb-5">

              <div class="job-info-detail">

                <div class="container-padded">
                  <div class="row">
                    <div class="col-md-12 pt-4 pb-4 mb-5">
                      <div><span class="view-details-header">View Details</span><StatusBadge :status="parsedStatus" /></div>
                      <div class="jumbotron">

                        <div class="row">
                          <div class="col-md-6">
                            <SentJobInfoLocation :request="request"></SentJobInfoLocation>
                            <SentJobInfoVehicles :assignments="request.job.assignments"></SentJobInfoVehicles>
                          </div>
                          <div class="col-md-6">
                            <SentJobInfoIncident :job="request.job"></SentJobInfoIncident>
                            <SentJobInfoRemarks :job="request.job"></SentJobInfoRemarks>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

// Components
import BackButton from '../utilities/back-button'
import JobInfoMap from '../maps/job-info-map'
import StatusBadge from '../utilities/status-badge'
import SentJobInfoFooter from './sent-job-info-footer'

// Info Sub-Components
import SentJobInfoLocation from './sent-job-info-location'
import SentJobInfoVehicles from './sent-job-info-vehicles'
import SentJobInfoIncident from './sent-job-info-incident'
import SentJobInfoRemarks from './sent-job-info-remarks'

export default {
  props: ['request'],

  components: {
    BackButton,
    JobInfoMap,
    StatusBadge,
    SentJobInfoFooter,
    SentJobInfoLocation,
    SentJobInfoVehicles,
    SentJobInfoIncident,
    SentJobInfoRemarks
  },

  data: () => {
    return {
      view: 'info',
      complete_modal_active: false,
    }
  },

  computed: {
    status () {
      return this.parseStatusTower(this.request)
    },
    vehicles () {
      return request.job.vehicles
    },
    footerState () {
      switch (this.status) {
        case 'new':
        case 'open':
        case 'direct':
          return this.status
          break;
        default:
          return null
      }
    },
    parsedStatus () {
      if (this.request.status === 'closed' || this.request.status === 'assigning') {
        return 'pending'
      } else if (this.request.status === 'completed') {
        if (this.request.job.status === 'in_progress')  {
          const assignment_statuses = this.request.job.assignments.map(assignment => assignment.status)

          if (assignment_statuses.every((assignment_status) => assignment_status === 'declined')) {
            return 'declined'
          } else if (assignment_statuses.includes('declined')) {
            return 'unassigned'
          } else return this.request.job.status
        } else return this.request.job.status
      } else {
        return this.request.status
      }
    },
  },

  methods: {
    footerAction (event) {
      switch (event) {
        case 'assign-driver':
          this.view = 'assign-driver'
          break;
        case 'decline-quote':
          this.$emit('decline')
          break;
        case 'start-quote':
          this.view = 'submit-quote'
          break;
        case 'complete-assignment':
          this.$emit('complete-assignment')
          break;
        default:
          break;
      }
    },
  },

  mounted () {
  }
}
</script>

<style scoped lang="scss">
.view-details-header {
  width: 160px;
  height: 38px;
  font-family: CircularStd;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #0f3246;
}
</style>

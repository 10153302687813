<template>
  <nav class="nav-pagination" aria-label="Page navigation example">
    <div id="pagination" class="pagination-table"></div>
  </nav>
</template>

<script>
import Pagination from './page';

export default {
  name: 'Pagination',
   props: ['total_pages', 'current_page'],
  mounted () {
    let urlParams = new URLSearchParams(window.location.search)

    urlParams.delete('page')

    Pagination.Init(document.getElementById('pagination'), {
          size: this.total_pages, // pages size
          page: this.current_page,  // selected page
          step: 3,   // pages before and after current
          querySearch: urlParams.toString()
    });

    let paginationItems = [...document.querySelectorAll('.js-pagination-item')],
        pageLinks = [...document.querySelectorAll('.js-page-link')];

        /* pageLinks.map((link,i,arr) => {
          let number = link.textContent;
          link.innerHTML = parseInt(number) + 1;
        }) */

    let activeItem = paginationItems.filter(item => item.className.includes('active')),
        pagination = document.querySelector('.js-pagination'),
        pos = activeItem[0].offsetLeft - 50;

    pagination.scrollTo(pos,0)

  }
}
</script>

<template>
  <md-step id="first" :md-label="titleStep" :md-description="titleDescription" :md-editable="false" :md-done.sync="done" class="first-step motorist-onboarding-step">
    <div class="row">
      <div class="col-md-6">
        <md-field :class="getValidationClass('user', 'firstName')">
          <label for="name">First name</label>
          <md-input name="name" id="name" v-model="user.firstName" ref="firstInput" v-on:keyup="detectChange"/>
          <span class="md-error">This field is required</span>
          <span v-if="$v.user.firstName.$invalid" class="invalid display-none"></span>
        </md-field>
      </div>
      <div class="col-md-6">
        <md-field :class="getValidationClass('user', 'lastName')">
          <label for="lastName">Last name</label>
          <md-input name="lastName" id="lastName" v-model="user.lastName" v-on:keyup="detectChange"/>
          <span class="md-error">This field is required</span>
          <span v-if="$v.user.firstName.$invalid" class="invalid display-none"></span>
        </md-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <md-field :class="getValidationClass('user', 'phoneNumber')">
          <label for="phoneNumber">Cell phone number</label>
          <md-input
            name="phoneNumber"
            id="phoneNumber"
            type="tel"
            v-model="user.phoneFormat"
            v-on:keyup="detectChange(); caretPhone('user')"
          />
          <span class="md-error">This field is required</span>
          <span v-if="$v.user.phoneNumber.$invalid" class="invalid display-none"></span>
        </md-field>
        <span
          class="validate-number"
          v-if="!validateNumberAccount"
          >The Mobile Number must be at least 10 characters long</span>
      </div>
    </div>
    <div class="row">
      <md-button :disabled="!deactivedButton" :class="[!deactivedButton ? 'deactive-button' : '','md-raised md-primary motorist-onboarding-button-save']" @click="validate">Save & continue</md-button>
      <md-progress-spinner class="motorist-onboarding__spinner" v-if="update" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
    </div>
  </md-step>
</template>

<script>
import Vue from 'vue';
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { validationMixin } from 'vuelidate';
import EventBus from '../../../../../mixins/event-bus'

export default {
  name: 'YourInfo',
  props: ['job_assignment_id', 'motorist', 'steps'],
  data: () => ({
    titleStep: 'Enter your name & phone number',
    titleDescription: 'We will verify it’s you by sending you a text message with a code.',
    user: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      phoneFormat: ''
    },
    update: false,
    validateNumberAccount: true,
    buttonActive: true,
    done: false
  }),
  computed : {
    deactivedButton () {
      return this.user.firstName && this.user.lastName && this.user.phoneNumber && this.validateNumberAccount && this.buttonActive
    }
  },
  validations: {
    user: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      phoneNumber: {
        required,
        minLength: minLength(10)
      }
    }
  },
  methods: {
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    caretPhone () {
      var x = this.user.phoneFormat.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.user.phoneFormat = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      this.user.phoneNumber =  this.user.phoneFormat.replace(/[()]/g, '');
      this.user.phoneNumber = this.user.phoneNumber.replace(/-/g, "").replace(" ", "");
      this.validateNumberAccount = this.$v.user.phoneNumber.minLength
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    detectChange () {
      this.buttonActive = true
    },
    submit () {
      this.buttonActive = false
      this.update = true
      let data = {
        motorist: {
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          phone_number: this.user.phoneNumber
        },
        job_assignment_id: this.job_assignment_id
      }
      EventBus.$emit('DATA_MOTORIST', this.user)
      Vue.http.post(`/motorists`, data ,[{responseType: 'json'}])
        .then(response => {
          setTimeout(() => {
            this.done = true
            this.update = false
            this.$emit("next", "second");
          },1000)
        }, err => {
          setTimeout(() => {
            this.done = false
            this.update = false
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.error : 'Something went Wrong', error: true})
          },1000)
      });
    }
  },
  mounted () {
    if ( this.motorist ) {
      this.user.firstName = this.motorist.firstName
      this.user.lastName = this.motorist.lastName
      this.user.phoneNumber = this.motorist.phoneNumber
      this.user.phoneFormat = this.motorist.phoneNumber
      this.buttonActive = false
      this.caretPhone()
      if (this.steps[this.motorist.completedSteps] !== "first" ) {
        this.done = true
        this.$emit("next", this.steps[this.motorist.completedSteps])
      }
    }

    let numberStepperText = document.querySelector('.first-step .md-stepper-number');
    numberStepperText.setAttribute('data-step-number', 1)
  }
}
</script>
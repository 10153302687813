<template>
  <md-card class="card-wrecker-detail section card-box offset-card" id="storage-facilities">
    <h2 class="title-card">Storage facilities</h2>
    <ul class="locations">
      <li v-for="location in locations" class="locations__item">
        <img src="../../../../../images/pin.svg" alt="">
        <p class="locations__address">{{ location.line_1 }} {{ location.line_2 }}</p>
      </li>
    </ul>
  </md-card>
</template>

<script>
export default {
  name: 'StorageFacilities',
  props: ['locations']
}
</script>
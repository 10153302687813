<template>
  <div>
    <div class="col-md-10 margin-auto content-table-mobile">
      <div v-if="searchFilter.length" class="search">
        <div class="search__content">
          <div class="search__content-title">
            <h1 class="search__title">{{title}}</h1>
            <button class="search__button-search" @click="openSearch">
            </button>
          </div>
          <div class="search__button-add">
            <a :href="buttonPath" class="header-table__add">{{ buttonText }}</a>
          </div>
        </div>
        <transition name="fade">
          <input placeholder="Search" v-model="search" @input="searchOnTable" :class="[showInput ? 'show-search ' : 'hide-search', 'header-table__search']" />
        </transition>
      </div>
      <div v-if="searched.length" v-for="item in searched" md-card class="box">
        <ul class="box-list card">
          <a :href="editButtonPath+'/'+item['id']+'/edit'" style="color: black;" class="box-list__button-edit">
            <i class="material-icons">edit</i>
          </a>
          <li class="box-list__item">
            <p class="box-list__title">Make</p>
            <p class="box-list__value">{{ item.make }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Model</p>
            <p class="box-lsit__value">{{ item.model }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Nickname</p>
            <p class="box-list__value">{{item.nickname}}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Color</p>
            <p class="box-list__value">{{item.color}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const toLower = text => {
  return text.toString().toLowerCase()
}

const filterByValue = (items, term) => {
  if (term) {
    return items.filter(item =>
      Object.keys(item).some(key => item[key] && toLower(item[key]).includes(toLower(term)))
    )
  }

  return items
}

export default {
  name: 'TableResponsive',
  props: ['data', 'title', 'columns', 'button-text', 'button-path', 'edit-button-path', 'type-table'],
  data: () => ({
    search: null,
    searched: [],
    searchFilter: [],
    searchedEmpty: [],
    showInput: false
  }),
  methods: {
    searchOnTable () {
      this.searched = filterByValue(this.data, this.search)
      this.searchedEmpty = filterByValue(this.data, this.search)
    },
    openSearch () {
      this.showInput = !this.showInput;
    } 
  },
  created () {
    this.searched = this.data
    this.searchFilter = this.data
  }
}
</script>

<style lang="scss" scoped>
  /* .md-field {
    max-width: 300px;
  } */
</style>

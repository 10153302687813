<template>
  <md-autocomplete
    v-model="query"
    :md-options="suggestions"
    autocomplete="off"
    autocorrect="off"
    md-dense
    tabindex="3"
    @md-changed="getSuggestions"
    @md-selected="selectSuggestion"
    @md-closed="getQuery"
  >
    <label><md-icon :class="[changeColor ? 'color-blue' : '']">location_on</md-icon>{{ label || 'Enter a location...'}}</label>
    <template slot="md-autocomplete-item" slot-scope="{ item }">
      {{ item.primary }} {{ item.secondary }}
    </template>
    <span v-if="helper" class="md-helper-text">e.g. M-104 / N Fruitport Rd</span>
  </md-autocomplete>
</template>

<script>
// Third Party Services
import {Here} from '../../../services/here'
import errorClass from '../../../../../javascripts/error_class'

export default {

  props: {
    selection: Object,
    orgLocation: {
      lat: Number,
      lng: Number,
    },
    label: String,
    helper: Boolean,
    newField: Boolean,
    queryData: String,
    changeColor: Boolean
  },

  data () {
    return {
      hereAutocomplete: null,
      query: "",
      here_results: null,
      geocoded_selection: null,
      suggestions: [],
      no_results: []
    }
  },

  computed: {
    parsedQuery () {
      return this.query.replace("/", "&")
    },
    autocompleteOptions () {
      return this.here_results ? this.here_results.suggestions : []
    },
    // Short term fix
    hereParams () {
      if (this.orgLocation) {
        return {
          latitude: this.orgLocation.lat,
          longitude: this.orgLocation.lng
        }
      } else {
        return {
          latitude: 42.938030,
          longitude: -86.083682
        }
      }
    }
  },

  methods: {
    initializeHereAutocomplete () {
      if (!this.hereAutocomplete) {
        this.hereAutocomplete = new Here(this.hereParams)
      }
    },
    getSuggestions () {
      this.initializeHereAutocomplete()
      this.suggestions = new Promise(async (resolve) => {
        if (this.query.length > 0) {
          this.here_results = await this.hereAutocomplete.autocomplete(this.parsedQuery)
          const items = this.here_results.data?.items
          if (items && items.length > 0) {
            const suggestions = items.map(s => {
              return this.formattedSuggestion(s)
            })
            resolve(suggestions)
          } else {
            resolve(this.no_results)
          }
        } else {
          resolve([])
        }
      })
    },
    async selectSuggestion (suggestion) {
      const place = await this.hereAutocomplete.geocode(suggestion.id)
      this.geocoded_selection = place.data
      this.geocoded_selection['address_primary'] = suggestion.primary
      this.geocoded_selection['address_secondary'] = suggestion.secondary
      this.geocoded_selection['addressable'] = {
        line_1: suggestion.primary,
        line_2: suggestion.secondary,
        district: this.geocoded_selection.address.district,
        city: this.geocoded_selection.address.city,
        state: this.geocoded_selection.address.stateCode,
        zip: this.geocoded_selection.address.postalCode,
        country: this.geocoded_selection.address.countryCode,
        location_type: this.geocoded_selection.resultType,
        latitude: this.geocoded_selection.position.lat,
        longitude: this.geocoded_selection.position.lng
      }
      this.$emit('update:selection', this.geocoded_selection)

      this.query = `${suggestion.primary} ${suggestion.secondary}`

      if(this.newField) {
        this.query = ''
      }
    },
    formattedSuggestion (suggestion) {
      let primary, secondary
      switch (suggestion.resultType) {
        case 'intersection':
          primary = `${suggestion.address.streets[0]} / ${suggestion.address.streets[1]}`
          secondary = `${suggestion.address.city}, ${suggestion.address.stateCode} ${suggestion.address.postalCode}`
          break
        case 'street':
          primary = `${suggestion.address.street}`
          secondary = `${suggestion.address.city}, ${suggestion.address.stateCode} ${suggestion.address.postalCode}`
          break
        case 'district':
          primary = `${suggestion.address.district}`
          secondary = `${suggestion.address.stateCode}`
          break
        case 'city':
          primary = `${suggestion.address.city}`
          secondary = `${suggestion.address.stateCode}`
          break
        case 'county':
          primary = `${suggestion.address.county}`
          secondary = `${suggestion.address.stateCode}`
          break
        case 'state':
          primary = `${suggestion.address.stateCode}`
          secondary = `${suggestion.address.countryName}`
          break
        case 'countryCode':
          primary = `${suggestion.address.countryCode}`
          break
        case 'houseNumber':
        default:
          primary = `${suggestion.address.houseNumber} ${suggestion.address.street}`
          secondary = `${suggestion.address.city}, ${suggestion.address.stateCode} ${suggestion.address.postalCode}`
          break
      }

      // This is the format needed for VueMaterial's Autocomplete component --
      // It doesn't natively support objects for some reason, so we have to
      // spoof some of these methods
      return {
        type: suggestion.matchLevel,
        primary: primary,
        secondary: secondary,
        address: suggestion.address,
        id: suggestion.id,
        "toLowerCase": () => primary.toLowerCase(),
        "toString": () => primary,
      }
    },
    getQuery () {
      this.$emit('update:queryinfo', this.query)
    }
  },

  mounted () {
    this.query = this.queryData ? this.queryData : '';
  },

  created () {
    this.initializeHereAutocomplete()
    if (this.selection) {
      this.query = `${this.selection.address_primary}`
    }
    this.getValidationClass = errorClass.getValidationClass;
  }
}
</script>

<style lang="scss" scoped>
  .md-menu-content {
    z-index: 15;
    background-color: inherit;
  }

  .color-blue {
    color: rgba(15, 50, 70, 0.87) !important;
  }
</style>

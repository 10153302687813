<template>
  <md-card class="card account-user card-wrecker-detail card-box-profile card-box offset-card section js-content-box-bussines service-area" id="service-area">
    <h2 class="title-card">Service Area</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <div class="card-box__content" v-if="openBox">
      <div class="card-box__content-form js-box-content-form">
        <div class="row">
          <div class="col-md-6">
            <p class="description-card">Please enter the number of miles from your headquarters that you are will to drive for a job. (Must be between 1 and 60 miles)</p>
            <div v-if="!openInfo" class="field margin-bottom-no">
              <div class="form__field">{{ mileSelected }} miles (from your location)</div>
            </div>
            <div v-else>
              <div class="service-area__miles">
                <md-field>
                  <md-input v-model="mileSelected" type="number" class="form-control" @keydown="allowOnlyNumbers"></md-input>
                </md-field>
                <p class="service-area__select-text">From your location</p>
              </div>
            </div>
          </div>
        </div>
        <transition name="slide">
          <div v-if="openInfo" class="row">
            <div class="card-box__content-actions service-area__content-actions-settings">
              <md-button :disabled="!enableSave" class="md-primary" @click="save()">Save</md-button>
              <md-button class="md-accent card-box__button-cancel" @click="cancel">Cancel</md-button>
              <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </md-card>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'ServiceArea',
  props: ['serviceArea','idOrganization'],
  data: () => ({
    openBox: false,
    update: false,
    openInfo: false,
    userEdit: false,
    dataUserSave: {
      mileSelected: ''
    },
    snackbar: {
      update: false
    },
    mileSelected: 40,
  }),
  computed: {
    enableSave () {
      return !this.update && this.mileSelected && this.mileSelected >= 1 && this.mileSelected <= 60
    }
  },
  methods: {
    editInfo () {
      this.cancel();
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    save () {
      this.update = true; // show loading spinner

      Vue.http.put(`/service_areas/${this.idOrganization}`, { range: this.mileSelected },[{responseType: 'json'}])
        .then(response => {
          this.update = false; // hide loading spinner
          this.openInfo = false; // close edit info box
          this.userEdit = true; // user did edit value

          if(response.status === 200) {
            this.dataUserSave.mileSelected = this.mileSelected;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
          } else {
            this.$emit('show:snackbar', {update: true, alertMsg: "We're sorry,Something went wrong.", error: true})
          }
        }, err => {
          console.log(err)
          this.update = false; // hide loading spinner
          this.openInfo = false; // close edit info box
          this.userEdit = false; // user did not edit value

          this.$emit('show:snackbar', {update: true, alertMsg: "We're sorry, something went wrong.", error: true})
      });
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    cancel () {
      this.openInfo = !this.openInfo
      if(this.userEdit) {
        this.mileSelected = this.dataUserSave.mileSelected;
      } else {
        this.mileSelected = this.serviceArea.range
      }
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    },
    allowOnlyNumbers(event) {
      if (/^\d*$/.test(event.key) || event.keyCode === 8) {
        return ;
      }

       event.preventDefault();
    }
  },
  created () {
    this.mileSelected = this.serviceArea.range
  },
  mounted () {
     window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>

import { render, staticRenderFns } from "./timeago.vue?vue&type=template&id=17866d01&scoped=true&"
import script from "./timeago.vue?vue&type=script&lang=js&"
export * from "./timeago.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17866d01",
  null
  
)

export default component.exports
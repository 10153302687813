<template>
  <div class="build-quote">
    <!-- Service Cost -->
    <div class="driver-select" v-if="isMotoristJob">
      <div class="driver-title">
        <h5>Confirm rate</h5>
        <div class="pb-3">
          <md-list>
            <md-list-item>
              <div class="md-list-item-text">
                <h6 class="pb-2">SERVICE</h6>
                <span class="body service-type">{{ getServicesName(request.job.incident_type) }}</span>
                <span class="caption faded-1">${{convertValue(motoristIncidentFee)}}</span>
              </div>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>

    <!-- Driver -->
    <div class="driver-select">
      <div class="driver-title">
        <h5>Driver</h5>
        <div v-if="selected_driver" class="pb-3">
          <md-list>
            <md-list-item>
              <md-avatar>
                <Avatar :type="'driver'" />
              </md-avatar>
              <div class="md-list-item-text">
                <span class="body">{{ selected_driver.first }} {{ selected_driver.last }}</span>
                <span class="caption faded-1 mt-1">{{ selected_driver.eta.duration.text }}</span>
                <span class="caption faded-1 mt-1" v-if="selected_driver.make && selected_driver.model">
                  {{ selected_driver.make }} {{ selected_driver.model }}
                </span>
              </div>
              <div v-if="!editing.driver" class="md-list-action">
                <md-button class="md-icon-button btnIcon" @click="editDriver">
                  <md-icon class="btnIcon">edit</md-icon>
                </md-button>
              </div>
            </md-list-item>
          </md-list>
        </div>

        <div v-else-if="errors.no_driver" class="pb-3">
          <p class="mb-0">
            <md-icon class="small mr-1">error</md-icon>
            No available drivers
          </p>
        </div>

        <div v-else class="pb-3">
          <p class="mb-0 faded-1">Select a driver...</p>
        </div>
      </div>

      <div v-if="editing.driver" class="note pb-1">
        <p class="caption mb-0">Note: Operators without ETAs could not be located. Make sure all operators are using the Curbside SOS mobile app.</p>
      </div>

      <div v-if="editing.driver" class="driver-list" >
        <SelectDriver 
          :drivers="drivers_with_etas"
          :selected_drivers="selected_driver_id" 
          :selectable_drivers_count="1" 
          :disable_inactives="!allow_select_drivers_without_etas"
          @select-driver="selectDriver" 
        >
          <div v-if="refreshing">
            <p class="caption">Locating operators...</p>
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
          </div>
        </SelectDriver>

        <md-button class="btn btn-secondary center" @click="$emit('refresh')" @disabled="refreshing">
          Refresh Driver Locations
        </md-button>
      </div>
    </div>

    <!-- Select Preference -->
    <div class="preference-select" v-if="request.job.vehicles.length > 1">
      <div class="preference-title">
        <h5>Preferred Vehicle</h5>
        <p class="caption">Note: Vehicle preferences are not guaranteed.</p>
      </div>

      <div v-if="selected_preference" class="pb-3">
        <md-list>
          <md-list-item>
            <div v-if="selected_preference === 'no-preference'" class="md-list-item-text py-3">
              <p class="mb-0">No preference</p>
              <p class="caption faded-1 mb-0">No vehicles requiring a flatbed will be assigned.</p>
            </div>
            <div v-else class="md-list-item-text">
              <VehicleDetail :vehicle="selected_preference" :source="'towco'" />
            </div>
            <div v-if="!editing.preference" class="md-list-action">
              <md-button class="md-icon-button btnIcon" @click="editPreference">
                <md-icon class="btnIcon">edit</md-icon>
              </md-button>
            </div>
          </md-list-item>
        </md-list>
      </div>

      <div v-if="editing.preference" class="vehicle-assignment">
        <AssignPreference
          :equipment="selected_equipment"
          :incident_vehicles="request.job.vehicles"
          @select-preference="selectPreference">
        </AssignPreference>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AssignEquipment from './assign-equipment'
import AssignPreference from './assign-preference'
import Avatar from '../../drivers/driver-avatar'
import SelectDriver from '../../drivers/select-driver'
import VehicleDetail from '../../vehicles/vehicle-detail'

import { getServicesName } from '../../../mixins/servicesList'

export default {
  props: [
    'allow_select_drivers_without_etas', 
    'assignments',
    'completed_quotes', 
    'drivers_with_etas',
    'refreshing',
    'request'
  ],

  components: {
    AssignEquipment,
    AssignPreference,
    Avatar,
    SelectDriver,
    VehicleDetail
  },

  data: () => {
    return {
      selected_driver: null,
      selected_driver_id: null,
      selected_equipment: null,
      selected_preference: null,
      editing: {
        driver: true,
        preference: true
      },
      errors: {
        no_driver: false,
        no_equipment: false
      },
    }
  },

  watch: {
    drivers_with_etas () {
      if (this.drivers_with_etas.length == 0) {
        this.errors.no_driver = true
      } else {
        this.errors.no_driver = false
      }
    }
  },

  computed: {
    ...mapState('organizations', {
      current: state => state.current
    }),

    motoristIncidentFee () {
      const motoristServiceMap = {
        'Basic tow': 'hook_up',
        'Flat tire': 'tire_change',
        'Lockout': 'lock_out',
        "Won’t start": 'dead_battery',
        "Out of gas": 'out_of_gas',
        'Winch Out': 'winch_out'
      }
      const fee_key = motoristServiceMap[this.request.job.incident_type];

      return this.current.fees[fee_key];
    },

    selectedDrivers () {
      if (this.selected_driver) {
        return [this.selected_driver]
      } else {
        return []
      }
    },

    requiresFlatbed () {
      const flatbed_vehicles = this.request.job.vehicles.filter(v => {
        return v.flatbed
      })
      const all_flatbeds = (flatbed_vehicles.length == this.request.job.vehicles.length)

      if (this.selected_equipment == null) {
        return all_flatbeds
      } else {
        return (all_flatbeds && !this.selected_equipment.flatbed)
      }
    },

    readyToSubmit () {
      return (this.selected_driver != null && this.selected_equipment != null && this.selected_preference != null)
    },

    isMotoristJob () {
      return (this.request.motorist_id != null )
    },
  },

  watch: {
    readyToSubmit () {
      this.$emit('update:submittable', this.readyToSubmit)

      if (this.readyToSubmit) {
        const quote = this.buildQuote()
        this.$emit('complete', quote)
      }
    }
  },

  methods: {
    getServicesName,
    convertValue (number) {
      return number / 100;
    },

    selectDriver (id) {
      const driver = this.drivers_with_etas.find(driver => driver.id === id);
      this.selected_driver = driver;
      this.selected_driver_id = driver.id;
      this.editing.driver = false;

      if (driver.vehicleId) {
        this.selected_equipment = {
          id: driver.vehicleId,
          make: driver.make,
          model: driver.model
        };
      } else {
        this.selected_equipment = null;
      }
    },

    selectPreference (preference) {
      this.selected_preference = preference
      this.editing.preference = false
    },

    editDriver () {
      this.editing.driver = true
    },

    editPreference () {
      this.editing.preference = true
    },

    buildQuote () {
      return {
        driver: this.selected_driver,
        equipment: this.selected_equipment,
        preference: this.selected_preference
      }
    }
  },

  created () {
    if (this.request.job.vehicles.length == 1) {
      this.selected_preference = 'no-preference'
    }
  }
}
</script>

<style scoped lang='scss'>
  .service-type {
    font-weight: bold;
  }
</style>

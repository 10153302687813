import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/shifts{/id}"
const customMethods = {
  active: { method: "GET", url: `${path}/active` },
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

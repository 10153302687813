<template>
  <div>
    <label
      class="capitalize"
      :for="name"
    >
      {{ label || name }}
    </label>
    <div class="row">
      <div class="col-md-6">
        <md-field>
          <md-select
            :id="name"
            :value="matchMode"
            :name="name"
            @md-selected="$emit('change', name, value, $event)"
          >
            <md-option
              v-for="option in options"
              :key="option"
              :value="option"
            >
              {{ option }}
            </md-option>
          </md-select>
        </md-field>
      </div>

      <div class="col-md-6">
        <md-field>
          <md-input
            :value="value"
            @input="$emit('change', name, $event)"
          />
        </md-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    matchMode: {
      type: String,
      default: 'contains',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      options: ['contains', 'equals', 'starts with', 'ends with'],
    }
  },
}
</script>

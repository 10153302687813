export default {
  activeDrivers (state) {
    return state.all.filter((driver) => {
      return driver.current_trip
    })
  },

  pingedDriversWithCoordinates() {
    return state.pinged.filter(pinged => pinged.current_location && pinged.current_location.latitude && pinged.current_location.longitude);
  }
}

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="organization-view__menu">
        <menu-wrecker></menu-wrecker>
      </div>
      <div class="organization-view__content">
        <account-owner :owner="organization.owner"></account-owner>
        <fees :detail="organization.tower_detail"></fees>
        <service-area
          :serviceArea="organization.service_area !== null ? organization.service_area: null"
          :organization="organization"
        ></service-area>
        <storage-facilities :locations="organization.tower_detail.tower_locations"></storage-facilities>
        <!-- <documents :documents="organization.tower_detail.documents_url"></documents> -->
        <vehicles :vehicles="organization.vehicles"></vehicles>
        <drivers :drivers="organization.drivers"></drivers>
      </div>
    </div>
  </div>
</template>

<script>
import MenuWrecker from './menu-wrecker';

//details
import AccountOwner from './details/account-owner';
import Fees from './details/fees';
import ServiceArea from './details/service-area';
import StorageFacilities from './details/storage-facilities';
import Documents from './details/documents';
import Vehicles from './details/vehicles';
import Drivers from './details/drivers';

export default {
  name: 'WreckerProfileDetail',
  props: ['organization'],
  components: {
    MenuWrecker,
    AccountOwner,
    Fees,
    ServiceArea,
    StorageFacilities,
    Documents,
    Vehicles,
    Drivers
  },
  mounted () {
    let menuWrapper = document.querySelector('.js-menu-wrecker'),
        mainNavLinks = document.querySelectorAll(".menu-wrecker__link"),
        cardBox = [...document.querySelectorAll(".card-box")],
        lastId,
        cur = [];

    menuWrapper.addEventListener('click', e => {
      e.preventDefault();
      if(e.target.tagName === "A") {
        cardBox.map(box => box.classList.remove('scroll-with-offset'))
        let link = document.querySelector(e.target.hash);
        if(link.className.includes('offset-card')) {
          link.classList.add('scroll-with-offset')
        }
        link.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start' 
        });
      }
    })

    document.addEventListener('mousewheel', e => {
      cardBox.map(box => box.classList.remove('scroll-with-offset'))
    }); 

  }
}
</script>
<template>
  <div class="request-details" v-if="job.details || job.emergency">
    <div class="section-title">
      <h5 class="mb-0">Additional remarks</h5>
      <p class="caption faded-1">Situational details to pass onto the wrecker.</p>
    </div>
    <p v-if="job.details">{{ job.details }}</p>
    <div v-if="job.emergency">
      <p class="emergency">This is an emergency</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['job']
  }
</script>

<style scoped lang='scss'>
  .emergency {
    color: #ef585a !important;
  }
</style>

<template>
  <md-step id="second" :md-label="titleStep" :md-description="titleDescription" :md-editable="false" :md-done.sync="done" class="second-step verify-phone motorist-onboarding-step">
    <div class="row">
      <div class="col-md-6">
         <md-field :class="getValidationClass('user', 'code')">
          <label for="name">Code</label>
          <md-input name="name" id="name" :maxlength="6" class="motorist-code" v-model="user.code" ref="firstInput" v-on:keyup="validateValue"/>
          <span class="md-error">This field is required</span>
          <span v-if="$v.user.code.$invalid" class="invalid display-none"></span>
        </md-field>
      </div>
    </div>
    <div class="row">
      <md-button :class="[deactivedButton ? 'deactive-button' : '','md-raised md-primary motorist-onboarding-button-save']" @click="validate">Submit</md-button>
      <md-button class="verify-phone__button-resend-code" @click="resendCode"><i class="material-icons">autorenew</i><span>Resend code</span></md-button>
      <md-progress-spinner class="motorist-onboarding__spinner" v-if="update" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
    </div>
  </md-step>
</template>

<script>
import Vue from 'vue';
import { required, minLength } from "vuelidate/lib/validators";
import { validationMixin } from 'vuelidate';
import EventBus from '../../../../../mixins/event-bus'

export default {
  name: 'VerifyPhone',
  props: ['job_assignment_id', 'motorist_data','steps'],
  data: () => ({
    titleStep: 'Verify it’s you',
    titleDescription: '',
    user: {
      code: ''
    },
    update: false,
    motorits: '',
    deactivedButton: false,
    done: false
  }),
  validations: {
    user: {
      code : {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    getValidationClass (form, fieldName) {
      const field = this.$v[form][fieldName]
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit () {
      let data = {
        phone_number: this.motorits.phoneNumber,
        code: this.user.code,
        job_reference: this.job_assignment_id
      }
      this.update = true
      Vue.http.post(`/motorists/sign-in`, data ,[{responseType: 'json', Accept: 'application/json'}])
        .then(response => {
          setTimeout(() => {
            this.done = true
            this.update = false
            this.$emit('show:snackbar', {update: true, alertMsg: response.body.msg ? response.body.msg : 'Your changes were successfully saved.', error: false})
            this.$emit("next", "third");
          },1000)
        }, err => {
          setTimeout(() => {
            this.done = false
            this.update = false
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.msg : 'Something went Wrong', error: true})
          },1000)
      });
    },
    resendCode () {
      this.update = true
      Vue.http.post(`/motorists/send_auth_code`, {phone_number: this.motorits.phoneNumber} ,[{responseType: 'json', Accept: 'application/json'}])
        .then(response => {
          setTimeout(() => {
            this.done = true
            this.update = false
            this.$emit("next", "second");
          },1000)
        }, err => {
          setTimeout(() => {
            this.done = false
            this.update = false
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.msg : 'Something went Wrong', error: true})
          },1000)
      });
    },
    formatPhoneNumber (str) {
        const cleaned = ("" + str).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
        return null;
    },
    validateValue () {
      if (!isNaN(this.user.code)) {
        this.user.code = this.user.code
      } else {
        this.user.code = ''
      }
    }
  },
  mounted () {
    if ( this.motorist_data ) {
      this.motorist = this.motorist_data
      this.titleDescription = `We sent you a code to ${ this.formatPhoneNumber(this.motorist.phoneNumber) }.`
      if (this.steps[this.motorist.completedSteps] !== "second" ) {
        this.done = true
        this.$emit("next", this.steps[this.motorist.completedSteps])
      }
    }

    EventBus.$on('DATA_MOTORIST', payload => {
      this.motorits = payload
      this.titleDescription = `We sent you a code to ${ this.formatPhoneNumber(payload.phoneNumber) }.`
    })

    let numberStepperText = document.querySelector('.second-step .md-stepper-number');
    numberStepperText.setAttribute('data-step-number', 2)
  }
}
</script>

<style lang="scss" scoped>
  .md-count {
    display: none !important;
  }
</style>

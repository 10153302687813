<template>
  <div>
    <div v-if="typeTable === 'wreckers'">
      <div class="empty-state" v-if="!searchFilter.length">
        <svg
          class="empty-state__icon"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="100"
          height="100"
          viewBox="0 0 24 24"
        >
          <defs>
            <path
              id="72367"
              d="M19 4h-4.18C14.4 2.84 13.3 2 12 2c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V20z"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <mask id="8376" fill="#fff">
              <use xlink:href="#72367" />
            </mask>
            <g fill="#000" fill-opacity="0.54" mask="url(#8376)">
              <path d="M0 0h24v24H0z" />
            </g>
          </g>
        </svg>
        <h2 class="empty-state__title">Add your first wrecker</h2>
        <md-button
          :href="buttonPath"
          class="md-primary md-raised empty-state__button"
        >{{ buttonText }}</md-button>
      </div>
    </div>

    <div v-if="typeTable === 'drivers'">
      <div class="empty-state" v-if="!searchFilter.length">
        <img src="../../../../images/assignment-user.svg" alt="" class="empty-state__img">
        <h2 class="empty-state__title">No drivers have been invited</h2>
        <p class="empty-state__description">Get started by inviting your first driver.</p>
        <md-button
          :href="buttonPath"
          class="md-primary md-raised empty-state__button"
        >{{ buttonText }}</md-button>
      </div>
    </div>

    <div v-if="typeTable === 'history'">
      <div class="empty-state" v-if="!searchFilter.length">
        <img src="../../../../images/history.svg" alt="" class="empty-state__img">
        <h2 class="empty-state__title">
          {{ hasFiltersApplied() ? 'No records Found.' : 'No job has been completed yet.' }}
        </h2>
        <p class="empty-state__description" v-if="!hasFiltersApplied()">
          You’ll be able to view the list of all jobs here once they are completed.
        </p>
        <md-button class="md-primary md-raised empty-state__button" v-if="hasFiltersApplied()" href="/jobs">
          Back to history
        </md-button>
      </div>
    </div>

    <div v-if="typeTable === 'vehicles'">
      <div class="empty-state" v-if="!searchFilter.length">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="124"
          height="101"
          viewBox="0 0 124 101"
        >
          <defs>
            <path
              id="92774"
              d="M93 79.714c5.71 0 10.333 4.63 10.333 10.334 0 5.71-4.622 10.333-10.333 10.333-5.704 0-10.333-4.622-10.333-10.333 0-5.704 4.629-10.334 10.333-10.334zm-62 0c5.71 0 10.333 4.63 10.333 10.334 0 5.71-4.622 10.333-10.333 10.333-5.704 0-10.333-4.622-10.333-10.333 0-5.704 4.629-10.334 10.333-10.334zM10.333 50.847H31V35.593H10.333v15.254zM59.706 66.1H124v15.253c0 5.614-4.63 10.17-10.333 10.17H108.5c0-8.42-6.944-15.254-15.5-15.254-8.556 0-15.5 6.834-15.5 15.254h-31c0-8.42-6.944-15.254-15.5-15.254-8.556 0-15.5 6.834-15.5 15.254H0V35.593a10.1 10.1 0 0 1 3.031-7.194 10.409 10.409 0 0 1 7.302-2.976h31l5.518 12.217L80.324 0h33.343v10.17h-10.334v5.728c8.908 2.264 15.5 10.237 15.5 19.695 0 11.213-9.265 20.338-20.666 20.338S77.5 46.806 77.5 35.593h10.333c0 5.613 4.63 10.169 10.334 10.169s10.333-4.556 10.333-10.17c0-5.613-4.63-10.169-10.333-10.169H93V10.17h-7.991L51.48 47.884 59.706 66.1z"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <mask id="937475" fill="#fff">
              <use xlink:href="#92774" />
            </mask>
            <g fill="#0F3245" fill-opacity="0.38" mask="url(#937475)">
              <path d="M-15-11h156v122H-15z" />
            </g>
          </g>
        </svg>
        <h2 class="empty-state__title">No tow vehicle</h2>
        <p class="empty-state__description">Add your first tow vehicle below.</p>
        <md-button
          :href="buttonPath"
          class="md-primary md-raised empty-state__button"
        >{{ buttonText }}</md-button>
      </div>
    </div>

    <div class="content-table-history">
      <div
        v-if="searchFilter.length"
        :class="[typeTable === 'history' ? 'col-md-12' : 'col-md-10', 'col-sm-12 margin-auto content-table content-tablet--desktop']"
      >
        <div class="header-table">
          <div class="header-table__flex">
            <h1 class="header-table__title">{{title}}</h1>
            <input
              v-if="typeTable !== 'history'"
              placeholder="Search"
              v-model="search"
              @input="searchOnTable"
              class="header-table__search"
            />
            <div v-if="typeTable === 'history'" class="filters-toggle-wrapper">
              <md-button
                class="md-primary"
                @click="$emit('toggle-filter')"
              >
                <md-icon>filter_list</md-icon>
                Filters
              </md-button>
              <span class="filters-message">{{ filtersMessage }}</span>
            </div>
          </div>

          <div v-if="typeTable !== 'history'">
            <a :href="buttonPath" class="header-table__add">{{ buttonText }}</a>
          </div>
        </div>
        <md-table
          v-if="typeTable === 'vehicles'"
          v-model="searched"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-vehicles"
        >
          <md-table-empty-state
            md-label="No records found"
            md-description="Try a different search term"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" class="table-row">
            <md-table-cell
              v-for="column in columns"
              :md-label="column"
              :md-sort-by="column"
            >{{ item[column] }}</md-table-cell>
            <md-table-cell class="text-right">
              <a
                :href="editButtonPath+'/'+item['id']+'/edit'"
                style="color: black;"
                class="table-edit-button"
              >
                <i class="material-icons">edit</i>
              </a>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table
          v-if="typeTable === 'users'"
          v-model="searched"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-vehicles"
        >
          <md-table-empty-state
            md-label="No records found"
            md-description="Try a different search term"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" :class="[item['Status'] === 'Inactive' ? 'row-disabled' : 'row-enabled', 'table-row']">
            <md-table-cell
              v-for="column in columns"
              :md-label="column"
              :md-sort-by="column"
            >{{ item[column] }}</md-table-cell>
            <md-table-cell>
              <md-button
                v-if="!item['resend']"
                class="table-list-drivers__button"
                @click="resend(item, 'users')"
              >{{ !item['resend'] ? 'Resend Invite' : '' }}</md-button>
            </md-table-cell>
            <md-table-cell class="text-right">
              <a
                :href="editButtonPath+'/'+item['id']+'/edit'"
                style="color: black;"
                class="table-edit-button"
              >
                <i class="material-icons">edit</i>
              </a>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table
          v-if="typeTable === 'drivers'"
          v-model="searched"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-drivers"
        >
          <md-table-empty-state
            md-label="No records found"
            md-description="Try a different search term"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" :class="[!item.active && item.invitation_accepted ? 'row-disabled': 'row-enabled', 'table-row']">
            <!-- Driver: First Name -->
            <md-table-cell md-label="First Name" md-sort-by="first"><p class="text-item-table">{{ item.first }}</p></md-table-cell>
            <!-- Driver: Last Name -->
            <md-table-cell md-label="Last Name" md-sort-by="last"><p class="text-item-table">{{ item.last }}</p></md-table-cell>
            <!-- Driver: Phone Number -->
            <md-table-cell md-label="Phone Number" md-sort-by="phone"><p class="text-item-table">{{ formatPhoneNumber(item.phone) }}</p></md-table-cell>
            <md-table-cell md-label="Status">
              <div v-if="item['quoted?']">
                <span class="badge badge-success">Quote, Pending</span>
              </div>
              <div v-else-if="item['assigned_job?']">
                <span v-if="item['notified?']" class="badge badge-success">Job, Notified</span>
                <span v-if="item['on_trip?']" class="badge badge-success">Job, En Route</span>
              </div>
              <div v-else-if="!item['assigned_job?']">
                <span v-if="!item.invitation_accepted" class="badge badge-warning">Invited</span>
                <p v-else-if="!item.active" class="margin-bottom-no table-list-drivers__deactived">Deactivated</p>
                <!-- <p v-else class="margin-bottom-no table-list-drivers__deactived">Available</p> -->
                <span v-else class="badge badge-default">Available</span>
              </div>
            </md-table-cell>
            <!-- Driver: Invite Status -->
            <md-table-cell md-label="Invite Status">
              <div v-if="item.invitation_accepted">
                Accepted
              </div>
              <div v-if="!item.invitation_accepted">
                <md-button class="table-list-drivers__button" @click="resendInvitation(item)">Resend Invite</md-button>
              </div>
            </md-table-cell>
            <!-- Driver: Edit -->
            <md-table-cell md-label="Edit" v-if="editButtonPath" class="text-right">
              <a
                :href="editButtonPath+'/'+item['id']+'/edit'"
                style="color: black;"
                class="table-edit-button"
              >
                <i class="material-icons">edit</i>
              </a>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <!-- 911 Dispatch: Wreckers Table -->
        <md-table
          v-if="typeTable === 'wreckers'"
          v-model="searched"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-wreckers"
        >
          <md-table-empty-state
            md-label="No records found"
            md-description="Try a different search term"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" class="table-row">
            <md-table-cell md-label="Company" md-sort-by="Company name">
              <p>{{ item['Company name']}}</p>
            </md-table-cell>
            <md-table-cell md-label="Phone number" md-sort-by="Company phone number">
              <p>{{ item['Company phone number'] }}</p>
            </md-table-cell>
            <md-table-cell md-label="# Of drivers" md-sort-by="# of drivers">
              <p>{{ item['# of drivers'] }}</p>
            </md-table-cell>
            <md-table-cell md-label="Status" md-sort-by="active">
              <p v-if="!item['# of vehicles']">Pending Invite</p>
              <p v-else>{{ item['active'] ? 'Active' : 'Inactive' }}</p>
            </md-table-cell>
            <md-table-cell v-if="!item['# of vehicles']" class="text-center">
              <md-button
                class="table-list-drivers__button"
                @click="resend(item, 'organizations')"
              >Resend Invite</md-button>
            </md-table-cell>
            <md-table-cell v-if="item['# of vehicles']" class="text-center">
              <a
                :href="editButtonPath+'/'+item['id']"
                style="color: black;"
                class="table-edit-button"
              >
                <img src="../../../../images/eye.svg" alt="">
              </a>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <!-- 911 Dispatcher: Jobs Table -->
        <md-table
          v-if="typeTable === 'history' && organization === 'dispatcher'"
          v-model="searched"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-drivers"
        >
          <md-table-empty-state
            md-label="No records found"
            md-description="Try a different search term"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" class="table-row" @click="viewDetail(item.id)" style="cursor:pointer;">
            <md-table-cell md-label="Requested at" md-sort-by="created_at">
              {{ getDate(item.created_at) }} at {{ formattedTime(item.created_at) }}
            </md-table-cell>
            <md-table-cell md-label="Incident No." md-sort-by="incident_number">
              {{ item.incident_number }}
            </md-table-cell>
            <md-table-cell md-label="Assignments">
              <div v-for="assignment in currentAssignments(item.assignments)" class="text-item-table text-item-table--list-item">
                {{ getAssignmentAndVehicleName(assignment) }}
              </div>
            </md-table-cell>
            <md-table-cell md-label="Location">
              {{ item.address }}
            </md-table-cell>
            <md-table-cell md-label="Zone">
              {{ getZoneNameCode(item) }}
            </md-table-cell>
            <md-table-cell md-label="Department">
              {{ item.quote_request.requesting_department ? item.quote_request.requesting_department.prefix : 'Department' }}
            </md-table-cell>
            <md-table-cell md-label="Officer">
              {{ item.officer }}
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-table
          v-if="typeTable === 'history' && organization === 'tower'"
          v-model="searched"
          md-sort="plate"
          md-sort-order="asc"
          md-card
          class="table-list-drivers"
        >
          <md-table-empty-state
            md-label="No records found"
            md-description="Try a different search term"
          ></md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" class="table-row" @click="viewDetail(item.idJob)" style="cursor:pointer;">
            <md-table-cell md-label="Incident No." md-sort-by="incident_number">
              {{ item.incident_number }}
            </md-table-cell>
            <md-table-cell md-label="Incident date." md-sort-by="incidentDate">
              {{ item.incidentDate }}
            </md-table-cell>
            <md-table-cell md-label="Order time">
              {{ item.orderTime }}
            </md-table-cell>
            <md-table-cell md-label="Source">
              {{ item.department }}
            </md-table-cell>
            <md-table-cell md-label="Vehicle(s)">
              <span v-for="vehicle in item.vehicles">
                {{ vehicle.incident_vehicle !== undefined ? vehicle.incident_vehicle.make : ''}} {{ vehicle.incident_vehicle !== undefined ? vehicle.incident_vehicle.model : '' }}
              </span>
            </md-table-cell>
            <md-table-cell md-label="Driver(s)">
              <span v-for="driver in item.drivers">
                {{ driver.assigned_driver !== undefined ? driver.assigned_driver.first : '' }} {{ driver.assigned_driver !== undefined  ? driver.assigned_driver.last : ''}}
              </span>
            </md-table-cell>
            <md-table-cell md-label="Location">
              {{ item.address }}
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-snackbar
          :class="[isError ? 'snackbar-notify--error' : 'snackbar-notify--success', 'snackbar-notify snackbar-notify-driver']"
          :md-position="drivers.position"
          :md-duration="drivers.isInfinity ? Infinity : drivers.duration"
          :md-active.sync="drivers.inviteSend"
          md-persistent
        >
          <span class="snackbar-notify__text">
            <i v-if="!isError" class="material-icons">check</i>
            {{alertMsg}}
          </span>
        </md-snackbar>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import uid from 'uid';

const toLower = text => {
  return text.toString().toLowerCase();
};

const filterByValue = (items, term) => {
  if (term) {
    return items.filter(item =>
      Object.keys(item).some(
        key => item[key] && toLower(item[key]).includes(toLower(term))
      )
    );
  }
  return items;
};

export default {
  name: "DataTable",
  props: [
    "data",
    "title",
    "columns",
    "type-table",
    "button-text",
    "button-path",
    "edit-button-path",
    "organization",
    "filters-message"
  ],
  data: () => ({
    isError: true,
    alert: false,
    search: null,
    searched: [],
    searchFilter: [],
    userNotify: false,
    position: "left",
    isInfinity: false,
    duration: 3000,
    alertMsg: null,
    drivers: {
      position: "left",
      isInfinity: false,
      duration: 4000,
      inviteSend: false
    },
  }),
  methods: {
    getAssignmentAndVehicleName(assignment) {
      if (!assignment) return ;

      if (assignment.assigned_org?.name) {
        return `${assignment.incident_vehicle.make} ${assignment.incident_vehicle.model} (${assignment.incident_vehicle.plate}), ${assignment.assigned_org.name}`;
      }

      return `${assignment.incident_vehicle.make} ${assignment.incident_vehicle.model} (${assignment.incident_vehicle.plate})`;
    },
    getZoneNameCode(item) {
      if (!item) return '';

      if (item?.zone?.name && item?.zone?.code) {
        return `${item.zone.name} - ${item.zone.code}`
      } else if (item?.zone?.code) {
        return item.zone.code
      } else if (item?.zone?.name) {
        return item.zone.name
      }
    },
    done() {
      location.reload(true);
    },
    getQuery () {
      if(this.search !== null) {
        window.location.href = `/jobs?page=1&q=${this.search}`
      } else {
        window.location.href = `/jobs?page=1`
      }
    },
    searchOnTable() {
      this.searched = filterByValue(this.data, this.search);
    },
    viewDetail (id) {
      window.location.href = `/jobs/${id}`
    },
    resendInvitation(item) {
      Vue.http
        .post(
          `/organizations/${item.organization_id}/drivers/${item.id}/send_email`,
          [],
          [{ responseType: "json" }]
        )
        .then(
          response => {
            if (response.ok) {
              this.drivers.inviteSend = true;
              this.isError = false
              this.alertMsg = `We've resent the link to the app store`
            }
          },
          err => {
             this.drivers.inviteSend = true;
             this.alertMsg = "Something went wrong, please try again";
             this.isError = true;
          }
        );
    },
    formatPhoneNumber(str) {
        const cleaned = ("" + str).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
        return null;
    },
    resend(item, target) {
      http: Vue.http.post(`/${target}/${item.id}/resend_invitation`).then(
        response => {
          if (response.ok) {
            this.drivers.inviteSend = true;
            this.isError = false
            if(this.typeTable === 'users') {
              this.alertMsg = `We've resent an invite to the user.`;
            }
            if(this.typeTable === 'wreckers') {
              this.alertMsg = `We've sent an invite to the wrecker`;
            }
          }
        },
        err => {
          this.isError = true;
          this.drivers.inviteSend = true;
          this.alert = true;
          this.loading = false;
          if(err.status === 422) {
            this.alertMsg = err.body.msg
          } else {
            this.alertMsg = "Something went wrong, please try again";
          }

        }
      );
    },
    getDate (date) {
      let datetime = new Date(date),
          year = datetime.getFullYear(),
          day = datetime.getDate() <= 9 ? `0${datetime.getDate()}` : datetime.getDate(),
          monthNumber = datetime.getMonth() + 1,
          month = monthNumber <= 9 ? `0${monthNumber}` : monthNumber;
      return `${month}/${day}/${year}`;
    },
    currentAssignments (assignments) {
      return assignments.filter(assignment => assignment.status != "reassigned");
    },
    getData (data, target) {
      let text = '';
      data.map(item => {
        if(target === 'vehicles') {
          text += `${item.incident_vehicle !== undefined ? item.incident_vehicle.make : ''} ${item.incident_vehicle !== undefined ? item.incident_vehicle.model : ''}`
        }

        if(target === 'drivers') {
          text += `${item.assigned_driver !== undefined ? item.assigned_driver.first : ''} ${item.assigned_driver !== undefined ? item.assigned_driver.last : ''}`
        }
      });
      return text
    },
    goBack() {
      window.history.back()
    },
    hasFiltersApplied() {
      return window.location.search.includes('filter_by');
    }
  },
  created () {
    this.searched = this.data
    this.searchFilter = this.data

    // History jobs for tower should be mapped to a different object.
    if(this.typeTable === 'history' && this.organization === 'tower') {
      this.searched = this.data.map(item => {
        return {
          incident_number: item.incident_number,
          incidentDate: this.getDate(item.created_at),
          idJob: item.id,
          id: uid(10),
          orderTime: this.formattedTime(item.created_at),
          department: item.quote_request.requesting_department ? item.quote_request.requesting_department.prefix : 'Motorist',
          vehiclesFilter: this.getData(item.assignments,'vehicles'),
          driversFilter: this.getData(item.assignments, 'drivers'),
          vehicles: item.assignments,
          drivers: item.assignments,
          address: item.address
        }
      });
      this.searchFilter = this.searched;
    }
  }
};
</script>

<style lang="scss" scoped>
.filters-toggle-wrapper {
  display: flex;
  align-items: center;
}

.filters-message {
  font-size: 1rem;
}
</style>

<template>
  <div class="cancel-quote-request-modal">
    <md-dialog-title class="mb-0 reassign-dialog__title" style="border-bottom: 1px solid lightgray;">
      <div>
        <h5>Re-assign wrecker</h5>
        <div style="font-size: 14px">
          Vehicles can be reassigned if the currently assigned wrecker has either not responded (by assigning an operator) or if they have declined the assignment.
        </div>
      </div>
      <div class="text-right">
        <h6 class="mb-0">Incident Location</h6>
        <p class="mb-0">{{ request.job.address }}</p>
        <p>{{ zone.name }} — {{ zone.code }}</p>
      </div>
    </md-dialog-title>

		<md-dialog-content class="pb-0 md-dialog-content md-theme-default">
      <!-- Choose Vehicles -->
      <div class="choose-vehicles" v-if="view === 'choose-vehicles'">
        <div class="row" style="min-height: 300px;">
          <!-- List of declined vehicles -->
          <div class="col-md-12 px-0">
            <md-list class="py-0">
              <md-list-item v-for="assignment in reassignableAssignments" :key="assignment.id" class="border-bottom py-3">
                <div class="md-list-item-text">
                  <VehicleDetail :vehicle="assignment.incident_vehicle"></VehicleDetail>
                  <div style="padding-top: .5em;">
                    <span style="font-weight:  bold; color: green;" v-if="assignment.status == 'assigned'"> (Wrecker notified)</span>
                    <span style="font-weight:  bold; color: red;" v-if="assignment.status == 'declined'"> (Wrecker declined)</span>
                  </div>
                </div>
                <div class="md-list-action">
                  <md-checkbox v-model="selected_assignments" :value="assignment.id"></md-checkbox>
                </div>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>

      <!-- Choose Wrecker -->
      <div class="choose-wrecker" v-if="view === 'choose-wrecker'">
        <!-- Selected Incident Vehicles -->
        <div class="row" style="border-bottom: 1px solid lightgray; padding: 1em;">
          <div class="col-md-6 px-0 overflow-auto">
            <div style="font-style: italic;">Selected Vehicles:</div>
            <div>
              <div v-for="selectedAssignment in selectedAssignmentStatuses">
                  {{ selectedAssignment.incident_vehicle.make }} {{ selectedAssignment.incident_vehicle.model }}
                <span style="font-weight:  bold; color: green;" v-if="selectedAssignment.status == 'assigned'"> (Wrecker notified)</span>
                <span style="font-weight:  bold; color: red;" v-if="selectedAssignment.status == 'declined'"> (Wrecker declined)</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="min-height: 300px;">
          <!-- List of Towers -->
          <div class="col-md-6 px-0 overflow-auto">
              <md-list class="py-0">
                <md-list-item key="rotation_list" class="border-bottom" v-if="features && features.rotation_list == true">
                  <div class="md-list-item-text py-3">
                    <span style="font-weight: bold;">Assign by rotation</span>
                    <div>Provider selected via rotation list will be chosen upon submission.</div>
                    <div class="assign-validation" v-if="this.zoneTowers.length == 0">
                      (Zone must have at least 1 active tower)
                    </div>
                  </div>
                  <div class="md-list-action">
                    <md-radio v-model="use_rotation" :value="true" :disabled="zoneTowers.length == 0"></md-radio>
                  </div>
                </md-list-item>
                <md-list-item class="border-bottom">
                  <div class="md-list-item-text py-3">
                    <span style="font-weight: bold;">Assign directly to towing company</span>
                  </div>
                </md-list-item>
                <md-list-item v-for="wrecker in sortedWreckers" :key="wrecker.id" class="border-bottom">
                  <div class="md-list-item-text py-3">
                    <span>{{ wrecker.name }}</span>
                  </div>
                  <div class="md-list-action">
                    <md-radio v-model="selected_tower" :value="wrecker"></md-radio>
                  </div>
                </md-list-item>
              </md-list>
          </div>

          <!-- Map -->
          <div class="col-md-6 px-0">
            <GmapMap
              :center="point"
              :zoom="14"
              :options="options"
              class="border-left w-100 h-100">
              <custom-marker :marker="point" alignment="top">
                <MapIcon :type="'unassigned'" />
              </custom-marker>
            </GmapMap>
          </div>
        </div>
      </div>

      <!-- Loading -->
      <div class="row" v-if="view === 'loading'">
        <div class="col-md-12 py-5 text-center">
          <md-progress-spinner class="loader" :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
          <p class="mt-4">Assigning to wrecker...</p>
        </div>
      </div>
    </md-dialog-content>

    <md-dialog-actions class="dialog-actions-custom">
      <md-button v-if="view === 'choose-vehicles'" class="btn btn-primary" :disabled="selected_assignments.length == 0 || disable_actions" @click="chooseVehicles">
        Choose Wrecker
      </md-button>
      <md-button v-if="view === 'choose-wrecker' || view === 'loading'"  class="btn btn-primary" :disabled="disableSubmit" @click="sendAssignment">
        Re-Assign
      </md-button>
      <md-button class="btn btn-secondary" :disabled="disable_actions" @click="close">
        Cancel
      </md-button>
    </md-dialog-actions>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// API Interfaces
import { JobAssignments } from '../../api'

// Components
import MapIcon from '../maps/map-icon'
import VehicleDetail from '../vehicles/vehicle-detail.vue'

export default {
  props: ['request'],

  components: {
    MapIcon,
    VehicleDetail
  },

  data: () => {
    return {
      use_rotation: false,
      selected_tower: null,
      selected_assignments: [],
      view: 'choose-vehicles',
      disable_actions: false,
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }
    }
  },

  computed: {
    ...mapState('organizations', {
      wreckers: state => state.towers,
      current_organization: state => state.current
    }),
    ...mapState('organizations', {
      current_organization: state => state.current
    }),
    ...mapState('features', {
      features: state => state.features
    }),
    sortedWreckers () {
      const mappedWreckers = this.wreckers.map(wrecker =>
        ({
          id: wrecker.id,
          name: wrecker.name,
        })
      )
      return mappedWreckers.sort((a, b) => (a.name - b.name))
    },
    point (point) {
      return {
        lat: this.request.job.latitude,
        lng: this.request.job.longitude
      }
    },
    assigned () {
      const assignments = this.request.job.assignments
      return assignments.filter(x => x.status === 'assigned')
    },
    declined () {
      const assignments = this.request.job.assignments
      return assignments.filter(x => x.status === 'declined')
    },
    reassignableAssignments () {
      const assignments = this.request.job.assignments
      return assignments.filter(x => x.status === 'assigned' || x.status === 'declined')
    },
    selectedAssignmentStatuses () {
      const assignments = this.request.job.assignments
      return assignments.filter(x => this.selected_assignments.includes(x.id))
    },
    zone () {
      const job_zone_id = this.request.job.assignments[0].job.zone_id

      return this.current_organization.zones.find(zone => zone.id == job_zone_id)
    },
    zoneTowers () {
      if (this.zone == null) return []
      return this.zone.towers.filter(tower => tower.active);
    },
    disableSubmit () {
      if (this.use_rotation) {
        return false
      } else {
        return this.selected_tower === null
      }
    }
  },

  watch: {
    use_rotation (new_value, old_value) {
      if (new_value) {
        this.selected_tower = null
      }
    },
    selected_tower (new_value, old_value) {
      if (new_value !== null) {
        this.use_rotation = false
      }
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },
    chooseVehicles () {
      this.view = 'choose-wrecker'
    },
    async sendAssignment () {
      this.view = 'loading'
      this.disable_actions = true
      let response = this.submitForm()
      setTimeout(() => {
        this.$emit('close')
      },2500)
    },
    async submitForm () {
      // Fire reassign calls for each individual selected assignment
      this.selected_assignments.forEach(assignment_id => {
        // Build payload
        const assigned_org_id = this.selected_tower ? this.selected_tower.id : null
        const payload = {
          job_assignment_id: assignment_id,
          assigned_org_id: assigned_org_id,
          use_rotation: this.use_rotation
        }

        // Make request with payload
        let response = JobAssignments.reassign({ id: assignment_id}, payload).then(response => {
          console.log(response);
        })
      })
    },
  },

  created () {
    // If there is only one reassignable JobAssignment, select it and immediately go to "Choose Wrecker"
    if (this.reassignableAssignments.length == 1) {
      this.selected_assignments.push(this.reassignableAssignments[0].id)
      this.view = 'choose-wrecker'
    }
  }
}
</script>

<style scoped lang='scss'>
  .reassign-dialog {
    &__title {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      background-color: #f7f9fa;

      @media only screen and (min-width: 769px){
        flex-direction: row;
      }
    }
  }
  .assign-validation {
    padding-top: .5em;
    color: #F06837;
    font-weight: bold;
  }
</style>

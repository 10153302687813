import * as API from '../../../api';
import Notify from '../../../../utils/notify';

export default {
  createQuoteRequest ({ commit }, payload) {
    API.QuoteRequests.save({ quote_request: payload }).then(response => {
      commit('appendSentQuoteRequest', response.body)
    })
  },
  async createQuotes ({ commit }, payload) {
    let api_result;

    await API.QuoteRequests.createQuotes({id: payload.id}, {quotes: payload.quotes}).then(response => {
      commit('updateReceivedQuoteRequest', response.body)
      api_result = "success"
    }).catch(error => {
      console.log(error);
      api_result = "error"
    })

    return api_result
  },
  async getSentQuoteRequests ({ commit }) {
    let response = await API.QuoteRequests.get()
    commit('setSentQuoteRequests', response.body)
  },
  async getReceivedQuoteRequests ({ commit }) {
    let response = await API.QuoteRequests.tower()
    commit('setReceivedQuoteRequests', response.body)
  },
  async updateSentQuoteRequest ({ commit }, payload) {
    let response = await API.QuoteRequests.get({id: payload.id})
    commit('updateSentQuoteRequest', response.body)
  },
  async updateReceivedQuoteRequest ({ commit }, payload) {
    // [Qi] CSOS-1995: This is to add 100ms delay before calling API.QuoteRequests.tower
    // So that it waits for Rails BE to finish whatever it is doing when querying.
    // We only see this in Auto Quote.
    // Increase this number if we see more issues with Auto Quote not getting motorist Quote Request until it expires.
    // This is a short term fix and looking into a long term fix on BE.

    setTimeout(async () => {
      let response = await API.QuoteRequests.tower({id: payload.id})
      commit('updateReceivedQuoteRequest', response.body)
    },100)
  },
  async refreshQuoteRequestETA ({ commit }, payload) {
    let response = await API.QuoteRequests.getETAs({ id: payload.id })
    commit('updateSentQuoteRequest', response.body)
  },
  async addDirectRequest ({ commit }, payload) {
    let response = await API.QuoteRequests.tower({ id: payload.id })
    commit('updateReceivedQuoteRequest', response.body)
  },
  dismissReceivedRequest ({ commit }, id) {
    commit('removeReceivedQuoteRequest', id)
  },
  removeSentRequest ({ commit }, id) {
    commit('removeSentQuoteRequest', id)
  },
  async dismissSentRequest ({ commit }, payload ) {
    return new Promise((resolve, reject) => {
      API.QuoteRequests.dismiss({ id: payload.id }, {})
        .then(commit('removeSentQuoteRequest', payload.id))
        .then(resolve())
    })
  },
  async CABLE_addReceivedQuoteRequest ({ commit }, payload) {
    let response = await API.QuoteRequests.tower({ id: payload.id })
    commit('updateReceivedQuoteRequest', response.body)
  },
  async CABLE_addSentQuoteRequest ({ commit }, payload) {
    let response = await API.QuoteRequests.get({ id: payload.id })
    commit('appendSentQuoteRequest', response.body)
  },
  async CABLE_receiveNewQuoteRequest ({ commit, dispatch }, quoteRequest) {
    let requester = quoteRequest.motorist_id ? 'motorist' : 'police dispatch';

    Notify.build({
      notification: {
        title: `A ${requester} job is now available!`
      }
    });

    commit('updateReceivedQuoteRequest', quoteRequest);
  },
  CABLE_updateQuoteRequestAssignments ({ commit }, { quote_request_id, assignment }) {
    commit('updateReceivedQuoteRequestAssignment', { quote_request_id, assignment })
  },
  CABLE_updateSentQuoteRequest ({ commit, state, getters, rootState }, quote_request) {
    const wasRequestDeclined = getters.wasQuoteRequestDeclined(quote_request);
    const orgtype = rootState.organizations.current.org_type;

    if (wasRequestDeclined && orgtype === 'dispatcher') {
      const towingCompanyName = quote_request.job.assignments[0].assigned_org.name;

      Notify.build({
        notification: {
          title: `${towingCompanyName} has declined a job.`
        }
      });
    }

    commit('updateSentQuoteRequest', quote_request)
  },
}

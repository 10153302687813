<template>
  <div>
    <div class="col-md-10 margin-auto content-table-mobile">
      <div v-if="searchFilter.length" class="search">
        <div class="search__content">
          <div class="search__content-title">
            <h1 class="search__title">
              {{ title }}
            </h1>
            <button v-if="typeTable !== 'history'" class="search__button-search" @click="openSearch"></button>
            <div class="filters-toggle-wrapper" v-if="typeTable === 'history'">
              <md-button
                class="md-primary"
                @click="$emit('toggle-filter')"
              >
                <md-icon>filter_list</md-icon>
                Filters
              </md-button>
              <span class="filters-message">{{ filtersMessage }}</span>
            </div>
          </div>
          <div class="search__button-add" v-if="false">
            <a :href="buttonPath" class="header-table__add">{{ buttonText }}</a>
          </div>
        </div>
        <transition name="fade">
          <input
            placeholder="Search"
            v-model="search"
            v-on:keyup.enter="getQuery"
            :class="[showInput ? 'show-search ' : 'hide-search', 'header-table__search']"
          />
        </transition>
      </div>
      <div v-if="searched.length" v-for="item in searched" md-card class="box">
        <ul class="box-list card" v-if="organization === 'dispatcher'" @click="viewDetail(item.id)">
          <li class="box-list__item">
            <p class="box-list__title">Incident No.</p>
            <p class="box-list__value">{{ item.incident_number }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Order time</p>
            <p class="box-list__value">{{ formattedTime(item.created_at) }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Source</p>
            <p class="box-list__value">{{ item.quote_request.requesting_department ? item.quote_request.requesting_department.prefix : 'Motorist' }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Location</p>
            <p class="box-list__value">{{ item.address }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Type</p>
            <p class="box-list__value">{{ item.incident_type }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">No. of vehicles</p>
            <p class="box-list__value">{{ item.vehicles.length }}</p>
          </li>
        </ul>


        <ul class="box-list card" v-if="organization === 'tower'" @click="viewDetail(item.idJob)">
          <li class="box-list__item">
            <p class="box-list__title">Incident date.</p>
            <p class="box-list__value">{{ item.incidentDate }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Order time</p>
            <p class="box-list__value">{{ item.orderTime }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Source</p>
            <p class="box-list__value">{{ item.department }}</p>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Vehicle(s)</p>
            <div>
              <p v-for="vehicle in item.vehicles" class="text-item-table text-item-table--list-item">{{ vehicle.incident_vehicle !== undefined ? vehicle.incident_vehicle.make : ''}} {{ vehicle.incident_vehicle !== undefined ? vehicle.incident_vehicle.model : '' }}</p>
            </div>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Driver(s)</p>
            <div>
              <p v-for="driver in item.drivers" class="text-item-table text-item-table--list-item">{{ driver.assigned_driver !== undefined ? driver.assigned_driver.first : '' }} {{ driver.assigned_driver !== undefined  ? driver.assigned_driver.last : ''}}</p>
            </div>
          </li>
          <li class="box-list__item">
            <p class="box-list__title">Location</p>
            <p class="box-list__value">{{ item.address }}</p>
          </li>
        </ul>
      </div>
    </div>
    <md-snackbar
        :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success' ,'snackbar-notify snackbar-notify-driver']"
        :md-position="position"
        :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="alert"
        md-persistent
      >
        <span class="snackbar-notify__text">
          <i v-if="!error" class="material-icons">check</i>
          {{alertMsg}}
        </span>
      </md-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import uid from 'uid';

const toLower = text => {
  return text.toString().toLowerCase();
};

const filterByValue = (items, term) => {
  if (term) {
    return items.filter(item =>
      Object.keys(item).some(
        key => item[key] && toLower(item[key]).includes(toLower(term))
      )
    );
  }

  return items;
};

export default {
  name: "TableResponsiveDriver",
  props: [
    "data",
    "title",
    "columns",
    "button-text",
    "button-path",
    "edit-button-path",
    "type-table",
    "organization",
    "filters-message",
  ],
  data: () => ({
    alert: false,
    error: false,
    search: null,
    searched: [],
    searchFilter: [],
    showInput: false,
    alertMsg: '',
    position: 'left',
    isInfinity: false,
    duration: 4000,
    searchedTowco: []
  }),
  methods: {
    searchOnTable() {
      if(this.typeTable === 'history' && this.organization === 'tower') {
        this.searched = filterByValue(this.searchedTowco, this.search);
      } else {
        this.searched = filterByValue(this.data, this.search);
      }
    },
    getQuery () {
      if(this.search !== null) {
        window.location.href = `/jobs?page=1&q=${this.search}`
      } else {
        window.location.href = `/jobs?page=1`
      }
    },
    openSearch() {
      this.showInput = !this.showInput;
    },
    resend(item) {
      http: Vue.http.post(`/organizations/${item.id}/resend_invitation`).then(
        response => {
          if (response.ok) {
            this.error = false
            this.alert = true
            this.alertMsg = `We've sent an invite to the wrecker`;
          }
        },
        err => {
          this.error = true;
          this.alertMsg = "Something went wrong, please try again";
          this.alert = true;
        }
      );
    },
    done() {
      location.reload(true);
    },
    getDate (date) {
      let datetime = new Date(date),
          year = datetime.getFullYear(),
          day = datetime.getDate() <= 9 ? `0${datetime.getDate()}` : datetime.getDate(),
          monthNumber = datetime.getMonth() + 1,
          month = monthNumber <= 9 ? `0${monthNumber}` : monthNumber;
      return `${month}/${day}/${year}`;
    },
    getData (data, target) {
      let text = '';
      data.map(item => {
        if(target === 'vehicles') {
          text += `${item.incident_vehicle !== undefined ? item.incident_vehicle.make : ''} ${item.incident_vehicle !== undefined ? item.incident_vehicle.model : ''}`
        } 

        if(target === 'drivers') {
          text += `${item.assigned_driver !== undefined ? item.assigned_driver.first : ''} ${item.assigned_driver !== undefined ? item.assigned_driver.last : ''}`
        }
      });
      return text
    },
    viewDetail (id) {
      window.location.href = `/jobs/${id}`
    },
  },
  created() {
    this.searched = this.data;
    this.searchFilter = this.data;

     if( this.typeTable === 'history' && this.organization === 'tower') {
      this.searchedTowco = this.data.map(item => {
        return {
          incidentDate: this.getDate(item.created_at),
          idJob: item.id,
          id: uid(10),
          orderTime: this.formattedTime(item.created_at),
          department: item.quote_request.requesting_department !== undefined ? item.quote_request.requesting_department.prefix : 'Motorist',
          vehiclesFilter: this.getData(item.assignments,'vehicles'),
          driversFilter: this.getData(item.assignments, 'drivers'),
          vehicles: item.assignments,
          drivers: item.assignments,
          address: item.address
        }
      });
      this.searched = this.searchedTowco;
      this.searchFilter = this.searchedTowco;
    } 
  }
};
</script>

<style lang="scss" scoped>
/* .md-field {
    max-width: 300px;
  } */

.md-table-head-label {
  text-transform: capitalize;
}
</style>

<template>
  <nav class="menu-wrecker menu-wrecker-towco-profile js-menu-wrecker">
    <ul class="menu-wrecker__menu">
      <li class="menu-wrecker__item menu-wrecker__title-menu js-title-settings"><h2 class="menu-wrecker__title padding-left-no margin-top-no">Settings</h2></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link wrecker js-menu-wrecker-link" href="#bussiness-info">Business Info</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link js-menu-wrecker-link" href="#account-owner">Account Owner</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link js-menu-wrecker-link" href="#hours-of-operations">Hours of Operation</a></li>
      <li class="menu-wrecker__item" v-if="showBankAccountSection"><a class="menu-wrecker__link wrecker js-menu-wrecker-link" href="#bank-account">Bank Account</a></li>
      <li class="menu-wrecker__item" v-if="showBankAccountSection"><a class="menu-wrecker__link wrecker js-menu-wrecker-link" href="#tax-id">Tax ID</a></li>
      <!-- <li class="menu-wrecker__item" v-if="showBankAccountSection"><a class="menu-wrecker__link wrecker js-menu-wrecker-link" href="#stripe-onboarding">Payout Requirements</a></li> -->
      <li class="menu-wrecker__item"><a class="menu-wrecker__link wrecker js-menu-wrecker-link" href="#robocalls">Automated Calls</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link js-menu-wrecker-link" href="#service-area">Service Area</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link js-menu-wrecker-link" href="#fees">Fees</a></li>
      <li class="menu-wrecker__item"><a class="menu-wrecker__link js-menu-wrecker-link" href="#storage-facilities">Storage Facilities</a></li>
      <!-- <li class="menu-wrecker__item"><a class="menu-wrecker__link js-menu-wrecker-link" href="#documents">Documents</a></li> -->
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MenuTowcoProfile',
  props: ['showBankAccountSection'],
}
</script>

<style lang="scss" scoped></style>
import Vue from 'vue';

export default {
  setDrivers (state, drivers) {
    state.all = drivers
  },
  setPingedDrivers (state, drivers) {
    if (drivers) {
      state.pinged = []
      state.pinged = drivers
    }
    else { state.pinged = [] }
  },
  updateDriver (state, driver) {
    const driver_index = state.all.findIndex((item) => item.id == driver.id)
    Vue.set(state.all, driver_index, driver)
  },
}

const services = [
  {
    id: 'basic_tow',
    label: 'Basic tow',
    value: 'Basic tow',
    key: 'hookUp',
    description: 'Do you need a ride? If yes, how many passengers? Do you have your keys with you?',
    secondDescription: 'Transporting a nonfunctioning vehicle. Also includes cost of getting vehicle on tow truck for transport.'
  },
  {
    id: 'flat_tire',
    label: 'Flat tire',
    value: 'Flat tire',
    key: 'tireChange',
    description: 'Specify what side the flat tire is and whether you have a spare.',
    secondDescription: 'Helping to replace a flat tire.',
  },
  {
    id: 'locked_out',
    label: 'Lockout',
    value: 'Lockout',
    key: 'lockOut',
    description: 'What type of key does your car have? (e.g. Remote key-less entry or physical key)',
    secondDescription: 'Accessing a vehicle that is locked and does not have keys accessible.',
  },
  {
    id: 'wont_start',
    label: 'Jump Start',
    value: 'Won’t start',
    key: 'deadBattery',
    description: 'Were you actively driving when your car stopped or were you parked for sometime?',
    secondDescription: 'Helping to restart a car battery that is out of power.',
  },
  {
    id: 'out_gas',
    label: 'Fuel Delivery',
    value: 'Out of gas',
    key: 'outOfGas',
    description: 'Does your car take regular gas or diesel?',
    secondDescription: 'Filling up a motorist gas tank when they’ve run out.',
  },
  {
    id: 'winch_out',
    label: 'Stuck',
    value: 'Winch Out',
    key: 'winchOut',
    description: 'Are you stuck on top of an object? How far away are you from the main road and solid ground? Is there damage to your car?',
    secondDescription: 'Pulling a vehicle from an area that is not easily accessible.',
  },
]

export const getServicesName = (service) => {
  const value = services.filter(item => item.value === service)[0]
  return value ? value.label : service
}
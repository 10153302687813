<template>
  <div class="received-assignment-list">
    <transition-group name="r-assignment-list">
      <ReceivedAssignmentDetail class="r-assignment-list-item" v-for="assignment in sortedAssignments" :assignment="assignment" :key="assignment.id"/>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ReceivedAssignmentDetail from './received-assignment-detail'

export default {
  props: ['assignments', 'status', 'vehicles'],

  data () {
    return {
      filter_options: ['accepted', 'pending', 'declined'],
      selected_filters: ['accepted', 'pending'],
    }
  },

  components: {
    ReceivedAssignmentDetail
  },

  computed: {
    ...mapGetters('job_assignments', {
      sorted: 'receivedAssignmentsByCreatedAt'
    }),
    filteredVehicles () {
      if (assignments.length === 0) {
        return vehicles
      } else {
        return assignments
      }
    },
    filteredAssignments () {
      const assignmentFilter = this.filterArrayByKey(this.sorted, 'status')
      return assignmentFilter(this.selected_filters)
    },
    sortedAssignments () {
      return this.assignments.sort((a, b) => {
        const makeA = a.incident_vehicle.make.toUpperCase()
        const makeB = b.incident_vehicle.make.toUpperCase()
        const modelA = a.incident_vehicle.model.toUpperCase()
        const modelB = b.incident_vehicle.model.toUpperCase()
        if (makeA === makeB) {
          return (modelA < modelB) ? -1 : (modelA > modelB) ? 1 : 0;
        } else {
          return (makeA < makeB) ? -1 : (makeA > makeB) ? 1 : 0;
        }
      })
    }
  },
}
</script>

<style scoped>
.r-assignment-list-item {
  transition: all 0.2s;
}
.r-assignment-list-leave-active {
  position: absolute;
}
.r-assignment-list-enter,
.r-assignment-list-leave-to {
 opacity: 0;
}
</style>

<template>
  <div class="sent-quote-request-actions">
    <md-button class="btnText btn-secondary" @click="$emit('select-request', request)">
      View Details
    </md-button>

    <md-button v-if="status === 'unresolved'" class="btnText btn-secondary" :disabled="true" @click="">
      Resubmit
    </md-button>

    <span v-if="features && features.reassign_wrecker == false">
      <md-button class="btnText btn-secondary" v-if="request.job.status !== 'completed'" :disabled="status !== 'unresolved'" @click="call_modal_active = true">
        Call Wrecker
      </md-button>
    </span>

    <span v-if="!hasDirectAssign && features && features.reassign_wrecker == true">
      <md-button class="btnText btn-secondary" :disabled="status !== 'unresolved'" @click="call_modal_active = true">
        Assign
      </md-button>
    </span>

    <span v-if="hasDirectAssign && features && features.reassign_wrecker == true">
      <md-button class="btnText btn-secondary" :disabled="!canReassign" @click="reassign_modal_active = true">
        Re-Assign
      </md-button>
    </span>

    <span>
      <md-button class="btnText btn-secondary" @click="displayCancelModal" :disabled="!isCancelable">
        Cancel order
      </md-button>
    </span>

    <span>
      <md-button v-if="canArchive" class="btnText btn-secondary" @click="archive_modal_active = true">
        Archive
      </md-button>
    </span>

    <div v-if="request.job.status === 'in_progress'" class="last-updated float-right m-3">
      Last updated at {{ updated_at }}
    </div>

    <div v-if="request.job.status === 'completed'" class="last-updated float-right m-3">
      Completed at {{ updated_at }}
    </div>

    <!-- Details Map Modal -->
    <md-dialog :md-active.sync="details_modal_active">
      <md-dialog-title><h5>Job Details</h5></md-dialog-title>
      <md-dialog-content>
        <SentQuoteRequestMap :job="request.job" :updating="etas_updating"></SentQuoteRequestMap>
      </md-dialog-content>
      <div class="footerBar px-3 py-2">
        <md-button class="btn btn-secondary" @click="details_modal_active = false">Dismiss</md-button>
      </div>
    </md-dialog>

    <!-- Call Wrecker Modal -->
    <md-dialog :md-active.sync="call_modal_active">
      <CallWreckerModal v-if="call_modal_active" :request='request' @close="call_modal_active = false"/>
    </md-dialog>

    <!-- Reassign Wrecker Modal -->
    <md-dialog :md-active.sync="reassign_modal_active" class="reassign-dialog" :md-fullscreen="false">
      <ReassignWreckerModal v-if="reassign_modal_active" :request='request' @close="reassign_modal_active = false"/>
    </md-dialog>

    <!-- Archive Confirm Modal -->
    <md-dialog :md-active.sync="archive_modal_active">
      <md-dialog-title>Are you sure?</md-dialog-title>
      <md-dialog-content>
        <p class="faded-1">
          Archiving this order will remove the job information from the map and dashboard feed. This information will be accessible in the History view.
        </p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="btn btn-secondary" @click="archive_modal_active = false">Nevermind</md-button>
        <md-button class="btn btn-secondary" @click="archiveRequest">Archive</md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Cancel Order Modal -->
    <md-dialog v-if="features && !features.new_cancel_job" :md-active.sync="cancel_modal_active">
      <md-dialog-title>Are you sure?</md-dialog-title>
      <md-dialog-content>
        <p class="faded-1">
          The order has been sent to all registered tow companies. Those responding will be notified the job is no longer available. Are you sure you want to cancel the unassigned orders?
        </p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="btn btn-secondary" @click="cancel_modal_active = false">Nevermind</md-button>
        <md-button class="btn btn-secondary" @click="cancelOrder(request.job.id)">Cancel Order</md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- New Cancel Order Modal with more options condicionar with the feature flag -->
    <md-dialog v-if="features && features.new_cancel_job" :md-active.sync="cancel_modal_active" :md-fullscreen="false">
      <CancelQuoteRequestModal 
        v-if="cancel_modal_active" 
        :request='request' 
        @finish="displayAlertMessage"
        @close="cancel_modal_active = false"
      />
    </md-dialog>

    <!-- Unable to Cancel Order Modal -->
    <md-dialog class="unable-to-cancel-order-modal" :md-active.sync="unable_to_cancel_modal_active" :md-fullscreen="false">
      <md-dialog-title>Unable to cancel order</md-dialog-title>
      <md-dialog-content>
        <p class="faded-1">
          The wrecker driver for this job is on their way to the incident. Please call the wrecker to facilitate any changes to this order.
        </p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="unable_to_cancel_modal_active = false">
          OK
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import jobServices from '../../mixins/jobServices';

// Components
import CallWreckerModal from '../call-wrecker/call-wrecker-modal';
import CancelQuoteRequestModal from '../quote-requests/cancel-quote-request-modal';
import SentQuoteRequestMap from '../maps/sent-quote-request-map';
import ReassignWreckerModal from '../reassign-wrecker/reassign-wrecker-modal';

export default {
  props: ['request', 'etas_updating', 'updated_at'],
  components: {
    CallWreckerModal,
    CancelQuoteRequestModal,
    ReassignWreckerModal,
    SentQuoteRequestMap
  },
  mixins: [jobServices],
  
  data: () => {
    return {
      details_modal_active: false,
      call_modal_active: false,
      reassign_modal_active: false,
      cancel_modal_active: false,
      unable_to_cancel_modal_active: false,
      archive_modal_active: false,
    }
  },

  computed: {
    ...mapState('features', {
      features: state => state.features
    }),
    status () {
      return this.request.status
    },

    canArchive () {
      return ( this.request.job.status !== 'new' )
    },
    hasDirectAssign () {
      const assign_types = this.request.job.assignments.map(assignment => assignment.assign_type)
      return assign_types.includes('direct') || assign_types.includes('rotation')
    },
    canReassign () {
      const assignment_statuses = this.request.job.assignments.map(assignment => assignment.status)
      return assignment_statuses.includes('declined') || assignment_statuses.includes('assigned')
    },

    /**
     * It is necessary for the jobServices mixin.
     */
    job () {
      return this.request.job;
    },

    isCancelable() {
      return this.jobCanBeCanceled(this.request.job)
    }
  },

  methods: {
    displayCancelModal() {
      this.cancel_modal_active = true;
    },

    /**
     * Display success or error messages in the app.
     */
    displayAlertMessage(message, error = false) {
      this.$emit('cancel:order', {
        error,
        alertMsg: message,
        cancelOrder: !error
      });
    },

    archiveRequest () {
      this.$store.dispatch('quote_requests/dismissSentRequest', this.request)
        // Update dismissed jobs on User context
        .then(this.$store.dispatch('users/getCurrentUser'))
        .then(this.$store.dispatch('views/selectQuoteRequest', ''))
    },

    cancelOrder (idJob) {
      Vue.http.put(`/jobs/${idJob}/cancel`,[{responseType: 'json'}])
        .then(() => {
          this.cancel_modal_active = false;
          this.displayAlertMessage('Tow order canceled successfully.');
        }, err => {
          this.cancel_modal_active = false;
          this.displayAlertMessage(err ? err.body.message : 'Something went Wrong');
      });
    }
  }
}
</script>

<style scoped lang="scss">
  .btnText {
    margin-left: 8px !important;
  }

  .reassign-dialog {
    .md-dialog-container {
      width: 50%
    }
  }

  .unable-to-cancel-order-modal {
    .md-dialog-container {
      @media only screen and (min-width: 769px){
        max-width: 620px;
        width: 100%;
      }
    }
  }
</style>

<template>
  <div class="box-detail job-detail">
    <h2 class="job-detail__title job-detail__title-vehicle">Incident Vehicles</h2>
    <div class="container-fluid padding-left-no">
      <!-- Vehicles list -->
      <div class="job-detail__border" v-for="assign in assignmentsList" :key="assign.id">
        <div class="row">
          <!-- Vehicle info -->
          <div class="col-md-6">
            <!-- Incident Vehicle Information -->
            <div class="job-detail__info-vehicles job-detail-info">
              <p class="title-vehicle">
                {{assign.incidentVehicle.year}} {{assign.incidentVehicle.make}} {{assign.incidentVehicle.model}} {{assign.incidentVehicle.color}}{{assign.incidentVehicle.plate ? `, ${assign.incidentVehicle.plate}` : ''}}
                <span v-if="assign.status === 'canceled' && !isJobCancel">Canceled</span>
                <StatusBadge v-if="isJobDeclined == false && assign.status === 'declined'" :status="'declined'" />
              </p>
              <span v-if="assign.incidentVehicle.insurance">{{ assign.incidentVehicle.insurance }} Insurance<br></span>
              <span v-if="assign.incidentVehicle.drivable">Rollover</span><span v-if="assign.incidentVehicle.drivable && assign.incidentVehicle.flatbed">,</span> <span v-if="assign.incidentVehicle.flatbed">Requires a flat bed</span><br v-if="assign.incidentVehicle.drivable || assign.incidentVehicle.flatbed">
              <span v-if="assign.incidentVehicle.release">Release with proof<br></span>
              <span v-if="assign.incidentVehicle.comment">"{{ assign.incidentVehicle.comment }}"<br></span>
              <span v-if="assign.incidentVehicle.requestedDestination">Take to {{ assign.incidentVehicle.requestedDestination.line1 }}</span>
            </div>

            <CancellationNotes 
              v-if="showCancellationNotes(assign)" 
              :note="assign.cancelationNote ? assign.cancelationNote.note : ''" 
              :canceledBy="getCanceledByFullName(assign)" 
            />

            <div class="job-detail__assigned job-detail-info">
              <!-- 911 Dashboard: Current Assignment (Assigned Org, Assign Type) -->
              <div v-if="organization.orgType === 'dispatcher' && assign.assignedOrg">
                <p class="title">Current Assignment</p>
                <a class="job-detail__assigned-name cursor-pointer" :href="assign.assignedOrg ? '/wreckers/'+ assign.assignedOrg.id : ''" >
                  {{ assign.assignedOrg.name }}
                </a>
                <p style="color: gray;" v-if="assign.assignType == 'rotation'">Assigned via Rotation List</p>
                <p v-if="assign.assignType == 'direct'">Assigned via Direct Assignment</p>
                <button class="btn btn-sm btn-secondary" style="color: #F06837;" @click="openModal(assign.incidentVehicleId, 'history')">Assignment History</button>
              </div>

              <!-- 911 Dashboard: Declined By, Declined Note -->
              <div v-if="organization.orgType === 'dispatcher' && assign.status === 'declined'">
                <p class="title mb-0">Declined by</p>
                <p class="faded-1 mb-0">{{ getDeclinedBy(assign) }}</p>
                <p class="faded-1">{{ getDeclinedNotes(assign) }}</p>
              </div>

              <!-- SP Dashboard: Job assigned to, Declined by, Job completed by-->
              <div v-if="organization.orgType === 'tower'">
                <p class="title mb-0">
                  {{ getAssigmentStatusTitle(assign.status) }}
                </p>

                <span>
                  <span v-if="assign.status === 'declined'">
                    <p class="faded-1 mb-0">{{ getDeclinedBy(assign) }}</p>
                    <p class="faded-1">{{ getDeclinedNotes(assign) }}</p>
                  </span>
                  <h4 v-else class="job-detail__assigned-name cursor-pointer" @click="openModal(assign.assignedDriver ? assign.assignedDriver : {}, 'driver')">
                    {{ assign.assignedDriver ? assign.assignedDriver.first : '' }} {{ assign.assignedDriver ? assign.assignedDriver.last : '' }}
                  </h4>
                </span>
              </div>

              <!-- 911 & SP Dashboards: Job Details -->
              <!-- Assigned Driver -->
              <!-- Operator Vehicle -->
              <!-- Arrived timestamp -->
              <!-- Marked completed timestamp -->
              <div 
                class="job-detail-grid" 
                v-if="organization.orgType === 'tower' && ((assign.status !== 'canceled') || (assign.status === 'canceled' && assign.assignedDriver && assign.assignedOrg))"
              >
                <ul class="job-detail__assigned-info job-detail__assigned-info--dispatcher">
                  <li class="job-detail__assigned-info-item" v-if="organization.orgType === 'dispatcher' && assign.assignedDriver">
                    <p class="job-detail__assigned-info-title">Driver</p>
                  </li>
                  <li class="job-detail__assigned-info-item" v-if="assign.vehicle">
                    <p class="job-detail__assigned-info-title">Equipment</p>
                  </li>
                  <li class="job-detail__assigned-info-item" v-if="assign.eventArrived.length">
                    <p class="job-detail__assigned-info-title">Arrived</p>
                  </li>
                  <li class="job-detail__assigned-info-item" v-if="assign.eventCompleted.length">
                    <p class="job-detail__assigned-info-title">Marked completed</p>
                  </li>
                  <li class="job-detail__assigned-info-item" v-if="assign.eventCompleted.length">
                    <p class="job-detail__assigned-info-title">Completed near</p>
                  </li>
                </ul>

                <ul class="job-detail__assigned-info job-detail__assigned-info-answer">
                  <li class="job-detail__assigned-info-item" v-if="organization.orgType === 'dispatcher'">
                    <p class="job-detail__assigned-info-description job-detail__assigned-info-description--blue cursor-pointer" @click="openModal(assign.assignedDriver, 'driver')">
                      {{ assign.assignedDriver ? assign.assignedDriver.first : '' }} {{ assign.assignedDriver ? assign.assignedDriver.last : '' }}
                    </p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p v-if ="assign.vehicle" class="job-detail__assigned-info-description job-detail__assigned-info-description--blue cursor-pointer" @click="openModal(assign.vehicle, 'vehicle')">
                      {{ assign.vehicle.make }} {{ assign.vehicle.model }}
                    </p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p  class="job-detail__assigned-info-description">
                      {{ assign.eventArrived.length ? getDate(assign.eventArrived[0].createdAt) : ''}} {{ assign.eventArrived.length ? formattedTime(assign.eventArrived[0].createdAt) : '' }}
                    </p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <p class="job-detail__assigned-info-description">
                      {{ assign.eventCompleted.length ? getDate(assign.eventCompleted[0].createdAt) : ''}} {{ assign.eventCompleted.length ? formattedTime(assign.eventCompleted[0].createdAt) : '' }}
                    </p>
                  </li>
                  <li class="job-detail__assigned-info-item">
                    <a 
                      class="job-detail__assigned-info-description job-detail__assigned-info-description--link"
                      target="_blank" 
                      v-if="assign.eventCompleted.length" 
                      v-bind:href="'https://www.google.com/maps/search/?api=1&query='+ assign.eventCompleted[0].latitude +','+ assign.eventCompleted[0].longitude" 
                    >
                      {{ assign.eventCompleted[0].nearAddress }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Map -->
          <div class="col-md-6">
            <div class="job-detail__map" v-if="assign.routePoints.length && assign.status !== 'canceled'">
              <GmapMap
                :center="{lat: assign.routePoints[0].lat, lng: assign.routePoints[0].lng}"
                :options="map.options"
                :zoom="10"
                ref="map"
                style="width:100%;height:100%;"
              >
                <gmap-marker :icon="{url: iconPath(map.status), scaledSize: {width: 50, height: 50}}" :position="{lat: assign.routePoints[assign.routePoints.length-1].lat, lng: assign.routePoints[assign.routePoints.length-1].lng}" :draggable="false">
                </gmap-marker>
                <gmap-marker v-if="assign.eventCompleted.length" :icon="{url: iconPath('driver')}" :position="{lat: assign.routePoints[0].lat, lng: assign.routePoints[0].lng}" :draggable="false">
                </gmap-marker>
                <gmap-polyline
                  v-bind:path.sync="assign.routePoints"
                  v-bind:options="{ strokeWeight: 4, strokeColor:'#5699ff'}"
                  >
                </gmap-polyline>
              </GmapMap>
            </div>
          </div>

          <!-- Assingment services -->
          <div class="col-md-12">
            <div class="job-detail__photos" v-if="assign.services.length">
              <p class="job-detail__motorist-photos-title">photos (by operator)</p>
              <div v-for="(services,index) in assign.services" :class="[`job-detail__motorist-photos item-motorist-${index}`]">
                <div class="job-detail__motorist-photos-grid">
                  <img v-for="photos in services.photosUrl" class="motorist-photo" :src="photos.url" :alt="photos.filename" @click="openImage(photos.url)">
                </div>
                <p v-if="services.description" class="job-detail__motorist-photos-description"><span>{{assign.assignedDriver.first}}:</span> {{ services.description }}</p>
                <span class="job-detail__motorist-photos-time"><span>{{getDays(services.createdAt)}}</span> at {{formattedTime(services.createdAt)}}</span>
              </div>
            </div>
          </div>

          <!-- Additional content -->
          <div class="col-md-12">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>

    <!-- Assignment History -->
    <md-dialog :md-active.sync="showDialogHistory" class="dialog-form-wizard dialog-organization job-detail-modal-driver">
      <md-dialog-title class="title">History</md-dialog-title>
      <md-dialog-content class="dialog-form-wizard__content dialog-driver-organization">
        <div class="col-md-12">
          <div v-for="assignmentHistory in historySelected" :key="assignmentHistory.id">
            <div v-for="timestamp in assignmentHistory.timestamps" class="row pb-3">
              <div class="col-sm-4 text-right"><b>{{ formattedTime(timestamp.timestamp, true) }}  {{ getDate(timestamp.timestamp) }}</b></div>
              <div class="col-sm-8 text-left">{{ timestamp.label }}</div>
            </div>
          </div>
        </div>
      </md-dialog-content>
    </md-dialog>


    <!-- Driver Information -->
    <md-dialog :md-active.sync="showDialogDriver" class="dialog-form-wizard dialog-organization job-detail-modal-driver">
      <md-dialog-title class="title">Driver’s details</md-dialog-title>
      <md-dialog-content class="dialog-form-wizard__content dialog-driver-organization">
        <div class="col-md-12">
          <form class="md-layout" enctype="multipart/form-data" accept-charset="UTF-8" id="formVehicle" name="formVehicle">
            <div class="md-layout-item md-small-size-100">
              <div class="row">
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">First name</label>
                    <div class="form__field">{{ this.driverSelected.first }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Last name</label>
                    <div class="form__field">{{ this.driverSelected.last }}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 padding-left-no">
                <p class="dialog-organization__license">Driver's License</p>
                <div class="driver-license__licenses row">
                  <div class="col-md-5">
                    <div class="license">
                      <div class="license__head">
                        <img :src="driverSelected.licenseFrontUrl ? driverSelected.licenseFrontUrl.url : 'https://www.toddbershaddvm.com/wp-content/uploads/sites/257/2018/09/placeholder-img.jpg'" alt="license front">
                      </div>
                      <div class="license__body">
                        <p>Front</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="license license-back">
                      <div class="license__head">
                        <img :src="driverSelected.licenseBackUrl ? driverSelected.licenseBackUrl.url : 'https://www.toddbershaddvm.com/wp-content/uploads/sites/257/2018/09/placeholder-img.jpg'" alt="license back">
                      </div>
                      <div class="license__body">
                        <p>Back</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="dialog-form-wizard__footer">
        <div class="footer-form__content">
          <md-button class="btn btn-primary footer-form__submit" @click="closeDialog">close</md-button>
        </div>
      </md-dialog-actions>
    </md-dialog>
    
    <!-- Vehicle Information  -->
    <md-dialog :md-active.sync="showDialogVehicle" class="dialog-form-wizard dialog-organization job-detail-modal-vehicle">
      <md-dialog-title class="title">Vehicle’s details</md-dialog-title>
      <md-dialog-content class="dialog-form-wizard__content">
        <div class="col-md-12">
          <form class="md-layout" enctype="multipart/form-data" accept-charset="UTF-8" id="formVehicle" name="formVehicle">
            <div class="md-layout-item md-small-size-100">
              <div class="row">
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Make</label>
                    <div class="form__field">{{vehicleSelected.make ? vehicleSelected.make : 'None'}}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Model</label>
                    <div class="form__field">{{vehicleSelected.model ? vehicleSelected.model : 'None'}}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Color</label>
                    <div class="form__field">{{vehicleSelected.color ? vehicleSelected.color : 'None'}}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <label class="form__label">Vin #</label>
                    <div class="form__field">{{vehicleSelected.vin ? vehicleSelected.vin : 'None'}}</div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="vehicleSelected.registration">
                <div class="col-md-6">
                  <div class="field field-registration">
                    <label class="form__label">Registration</label>
                    <div class="form__field">
                      <span>{{vehicleSelected.registration.name}}</span>
                      <a class="field-icon" target="_blank" :href="vehicleSelected.registration.url">
                        <img src="../../../../images/eye.svg" alt="">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="dialog-form-wizard__footer">
        <div class="footer-form__content">
          <md-button class="btn btn-primary footer-form__submit" @click="closeDialog">close</md-button>
        </div>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>

// Libraries
import moment from 'moment'

// Components
import CancellationNotes from '../job-assignments/cancellation-notes';
import Timeago from '../utilities/timeago';
import StatusBadge from '../utilities/status-badge';
import MapIcon from '../maps/map-icon';

// Images
import svgs from '../../../../images/map/'

export default {
  name: 'VehiclesAndWreckers',
  props: ['vehicles', 'assignments', 'organization', 'isJobCancel', 'isJobDeclined', 'wasWholeCanceled'],
  components: {
    CancellationNotes,
    MapIcon,
    Timeago,
    StatusBadge
  },

  computed: {
    assignmentsByIncidentVehicleId() {
      return this.assignments.reduce((accumulator, currAssignment) => {
        const assignments = accumulator[currAssignment.incidentVehicleId] = accumulator[currAssignment.incidentVehicleId] || []
        assignments.push(currAssignment)
        return accumulator
      }, {})
    }
  },

  data: () => ({
    assignmentsList: [],
    incidentVehicleAssignmentHistory: {},
    driverSelected: '',
    vehicleSelected: '',
    historySelected: [],
    showDialogDriver: false,
    showDialogHistory: false,
    showDialogVehicle: false,
    // Todo: find a better place to put this where it can be reused elsewhere– 
    // perhaps something like src/strings/strings-by-event-type.js
    stringsForEventType: {
      "assignedAt": "Assigned job to Service Provider",
      "notifiedAt": "Sent job to operator",
      "startedAt": "Operator marked on my way",
      "canceledAt": "The job was canceled",
      "declinedAt": "Service Provider declined job",
      "arrivedAt": "Operator arrived at scene",
      "departedAt": "Operator departed scene",
      "completedAt": "Job was marked complete",
      "reassignedAt": "Job was reassigned",
    },
    map: {
      options: {
        disableDefaultUi: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
      center: {
        lat: 42.9690668,
        lng: -85.9226895
      },
      coordinates:[],
      status: 'completed'
    },
  }),

  methods: {
    closeDialog () {
      this.showDialogDriver = false
      this.showDialogVehicle = false
      this.showDialogHistory = false
    },
    openModal(data, target) {
      if(target === 'driver') {
        this.showDialogDriver = true;
        this.driverSelected = data;
      }

      if(target === 'vehicle') {
        this.showDialogVehicle = true;
        this.vehicleSelected = data;
      }

      if(target === 'history') {
        this.showDialogHistory= true;
        this.historySelected = this.getHistoryByVehicleId(data); 
      }
    },
    openImage (url) {
      window.open(url,'_blank')
    },
    // History
    IncidentVehicleHistories (orgAssignments) {
      // organize assignments by incident vehicle, where each is an array of history objects
      const vehicleAssignments = this.assignments.reduce((accumulator, currAssignment) => {
        const assignments = accumulator[currAssignment.incidentVehicleId] = accumulator[currAssignment.incidentVehicleId] || []
        assignments.push(currAssignment)
        return accumulator
      }, {})

      // return result
      return orgAssignments;
    },
    getEventLabel (event, assignment) {
      switch (event) {
        case 'assignedAt':
          let assignTypeLabel = assignment.assignType == 'rotation' ? ' via Rotation List' : ' via Direct Assignment'
          return `Assigned job to ${assignment.assignedOrg.name}` + assignTypeLabel
        case 'notifiedAt':
          return `${assignment.assignedOrg.name} assigned job to operator`
        case 'declinedAt':
          return `${assignment.assignedOrg.name} declined the job`
        case 'reassignedAt':
          return `Job was reassigned from ${assignment.assignedOrg.name} to a different Provider`
        default:
          return this.getStringForEvent(event)
      }
    },
    getHistoryByVehicleId (incidentVehicleId) {
      const vehicleAssignments = this.assignmentsByIncidentVehicleId[incidentVehicleId]

      // add timestamps list to all Incident Vehicle JobAssignments
      const vehicleAssignmentsWithTimestamps = vehicleAssignments.map(assignment => {
        // create timestamps array of objects
        const timestamps = Object.keys(assignment.history).map(event => {
          return {
            "label": this.getEventLabel(event, assignment),
            "timestamp": assignment.history[event]
          }
        })
        // sort timestamps array of objects
        const sortedTimestamps = timestamps.sort((x, y) => {
          return new Date(x.timestamp) > new Date(y.timestamp) ? 1 : -1
        })
        // save to timestamps key
        assignment["timestamps"] = sortedTimestamps
        return assignment
      })

      // Optional: If Provider History, filter out assignments belonging to other providers
      // Sort assignments by createdAt timestamp
      return vehicleAssignmentsWithTimestamps.sort((x, y) => {
        return new Date(x.createdAt) > new Date(y.createdAt) ? 1 : -1
      })
    },
    getDays (time) {
      let date = moment(time)
      if (moment().diff(date, 'days') >= 1) {
        return date.fromNow();
      }
      return date.calendar().split(' ')[0];
    },
    getStringForEvent(event) {
      return this.stringsForEventType[event] || event
    },
    iconPath (type) {
      switch (type) {
        case 'driver':
          return svgs.DefaultDriver
          break
        case 'new':
        case 'open':
        case 'pending':
        case 'direct':
          return svgs.PendingPin
          break
        case 'in_progress':
          return svgs.InProgressPin
          break
        case 'unassigned':
        case 'unresolved':
        case 'outbid':
          return svgs.UnassignedPin
          break
        case 'completed':
        default:
          return svgs.DefaultPin
          break
      }
    },
    getHour (date) {
      let datetime = new Date(date),
          hours = datetime.getUTCHours() <= 9 ? `0${datetime.getUTCHours()}` : datetime.getUTCHours(),
          minutes = datetime.getUTCMinutes() <= 9 ? `0${datetime.getUTCMinutes()}` : datetime.getUTCMinutes()
      return `${hours}:${minutes}`
    },
    getDate (date) {
      let datetime = new Date(date),
          year = datetime.getFullYear(),
          day = datetime.getDate() <= 9 ? `0${datetime.getDate()}` : datetime.getDate(),
          monthNumber = datetime.getMonth() + 1, // getMonth treats January as 0
          month = monthNumber <= 9 ? `0${monthNumber}` : monthNumber;
      return `${month}/${day}/${year}`;
    },
    getCanceledByFullName(assign) {
      if (!assign || !assign.canceledByString || this.organization.orgType === 'tower') {
        return '';
      }

      return assign.canceledByString;
    },
    getAssigmentStatusTitle(status) {
      const titles = {
        notified: 'Job assigned to',
        declined: 'Declined by',
        completed: 'Job completed by'
      };

      return titles[status];
    },
    showCancellationNotes(assign) {
      if (assign.cancelationNote && (assign.status === 'canceled' && !this.wasWholeCanceled) && (this.organization.orgType === 'dispatcher' || (this.organization.orgType === 'tower' && assign.cancelationNote.share))) {
        return true;
      }

      return false;
    },

    /**
     * Return the declined by for an assignment.
     */
    getDeclinedBy(assignment) {
      if (!assignment.notes.length) {
        return null;
      }

      const user = assignment.notes[0].user;
      let declinedBy = assignment.declinedByString;

      if (user && user.firstName && user.lastName) {
        declinedBy = user.firstName + ' ' + user.lastName;
      } else if (user && user.email) {
        declinedBy = user.email;
      }

      return declinedBy;
    },

    /**
     * Return the declined notes.
     */
    getDeclinedNotes(assignment) {
      const declinedNotes =  assignment.notes[0];

      return declinedNotes ? declinedNotes.body : '';
    }
  },

  created () {
    // If this organization is a Tower, only show Assignments assigned to that Tower
    let orgAssignments = []
    if (this.organization.orgType === 'tower') {
      orgAssignments = this.assignments.filter(assignment => assignment.assignedOrgId == this.organization.id)
    } else {
      orgAssignments = this.assignments
    }

    // Build assignments list
    this.assignmentsList = orgAssignments.filter(assignment => assignment.status !== 'reassigned').map(assignment => {
      return {
        ...assignment,
        eventCompleted: assignment.events ? assignment.events.filter(event => event.newStatus === 'completed') : [],
        eventArrived: assignment.events ? assignment.events.filter(event => event.newStatus === 'arrived') : [],
        routePoints: assignment.routePoints ? assignment.routePoints = assignment.routePoints.map(point => {
          return {"lat": point.nameValuePairs ? point.nameValuePairs.location.latitude : point.location.latitude, "lng": point.nameValuePairs ? point.nameValuePairs.location.longitude : point.location.longitude}}
        ) : []
      }
    });
  }
}
</script>
<template>
  <md-dialog :md-active.sync="showDialog" 
    :md-backdrop="lockScreen" 
    :md-close-on-esc="!lockScreen" 
    :md-click-outside-to-close="!lockScreen"
    :md-fullscreen="!lockScreen"
  >
    <md-dialog-content class="dialog">
      <md-progress-spinner :class="spinnerClass" :md-stroke="3" md-mode="indeterminate" />
    </md-dialog-content>
  </md-dialog>
</template>

<script>
  export default {
    name: 'ProgressDialog',
    data: () => ({
      showDialog: true
    }),
    props: {
      lockScreen: {
        type: Boolean,
        default: false
      },
      spinnerClass: {
        type: String,
        default: 'md-accent'
      }
    }
  }
</script>

<style scoped>
  .dialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

export default function channelConstructor (...options) {
  return new QuoteRequestsChannel(...options)
}

export class QuoteRequestsChannel {
  constructor ({ store }, { quote_request }) {
    this.channel_name_base = "quote_requests_channel_"
    this.quote_request_id = quote_request.id
    this.store = store;
  }

  // Called on connection
  connected () {
    return () => {}
  }

  // Called on disconnection
  disconnected () {
    return () => {}
  }

  // Called when data is received
  received () {
    // QuoteRequestsChannel broadcasts updated quote_requests to the dispatch dashboard
    // Push new quote_request to Vuex store, let Vue do the rest
    return ({ data }) => {
      this.store.dispatch('quote_requests/CABLE_updateSentQuoteRequest', JSON.parse(data))
    }
  }

  key () {
    return this.channel_name_base + this.quote_request_id
  }

  channel () {
    return {
      channel: "QuoteRequestsChannel",
      id: this.quote_request_id
    }
  }

  mixins () {
    return {
      connected: this.connected(),
      disconnected: this.disconnected(),
      received: this.received()
    }
  }
}

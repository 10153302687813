<template>
  <div class="set-hours">
    <div class="set-hours__row">
      <label class="hours__label" for="startHour">Open</label>
      <vue-timepicker v-model="startTimeModel"
                      auto-scroll
                      format="hh:mm A"
                      manual-input
                      minute-interval="30"
                      name="startHour"
                      @input="event => { startTimeModel=event; getTime() }"
      />
    </div>
    <span class="interval__label">
      to
    </span>
    <div class="set-hours__row">
      <label class="hours__label" for="endHour">Closed</label>
      <vue-timepicker v-model="endTimeModel"
                      auto-scroll
                      format="hh:mm A"
                      manual-input
                      minute-interval="30"
                      name="endHour"
                      @input="event => { endTimeModel = event; getTime() }"
      />
    </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'SelectHours',
  props: ['label', 'id', 'startTime', 'endTime', 'type'],
  components: { VueTimepicker },
  data: () => ({
    startHour: '09:00',
    endHour: '18:00',
    meridiemStart: 'AM',
    meridiemEnd: 'PM',
    hours: [],
    startTimeModel: '09:00 AM',
    endTimeModel: '06:00 PM'
  }),
  created () {
    //initializes the time input fields above by utilizing the get hour method
    if (this.startTime || this.endTime) {
      this.startHour = this.getHour(this.startTime, "start")
      this.endHour = this.getHour(this.endTime, "end")
    }
    this.startTimeModel = this.convertToTwelveHours(this.startHour)
    this.endTimeModel = this.convertToTwelveHours(this.endHour)
    this.getTime()
  },
  methods: {
    //the method for populating the underlying vue object so that the container that calls this can take a date formatted string
    //TODO consider just ripping this out?
    getTime() {
      //take the 'HH:MM AM' format from the input value and convert it to a date string
      this.meridiemStart = this.startTimeModel.split(' ')[1]
      this.meridiemEnd = this.endTimeModel.split(' ')[1]
      this.startHour = this.convertStringHour(this.startTimeModel,'start')
      this.endHour = this.convertStringHour(this.endTimeModel, 'end')
      //populate the underlying vue object so that it can be picked up by the container
      this.$emit('show:date', {
        id: this.id,
        startHour: this.startHour,
        endHour: this.endHour
      });
    },
    //takes in a raw 24 hour time and converts it to 12 hour time AM/PM
    convertToTwelveHours(twentyFourHourTime) {

      const [hr, min] = twentyFourHourTime.split(':');
      var hour = parseInt(hr)

      var suffix = "AM";
      if (hour >= 12) {
        hour = hour-12;
        suffix="PM"
      }
      if (hour === 0) {
        hour =12;
      }

      //Note that if the hour is under 10 we append a 0 so it can be displayed correctly
      return `${hour <= 9 ? `0${hour}` : hour}:${min} ${suffix}`
    },

    //TODO maybe clean these up
    convertTo12HourFormat(hour, meridiem) {
      if (hour === 12 && meridiem === 'AM') {
        hour = 0;
      } else if (hour === 0 && meridiem === 'PM') {
        hour = 12;
      } else if (meridiem === 'AM' && hour >= 12) {
        hour -= 12;
      } else if (meridiem === 'PM' && hour < 12) {
        hour += 12;
      }
      return hour;
    },
    //splits the date string from 'HH:MM AM' format into hours and minutes
    convertStringHour(date, type) {
      const [hr, min] = date.split(' ')[0].split(':');
      let hour = parseInt(hr)

      if (type === 'start') {
        hour = this.convertTo12HourFormat(hour, this.meridiemStart);
      }

      if (type === 'end') {
        hour = this.convertTo12HourFormat(hour, this.meridiemEnd);
      }

      return `${hour <= 9 ? `0${hour}` : hour}:${min}`

    },
    //takes a date string and rips it into a 24 hour hours and minutes string
    getHour(date, meridiem) {
      let newDate = new Date(date)
      let datetime = ''
      let hours = ''
      let minutes = ''

      if (newDate.toString() === 'Invalid Date') {
        datetime = new Date()
        const [hr, min] = date.split(':');
        datetime.setHours(parseInt(hr));
        datetime.setMinutes(parseInt(min));

        hours = datetime.getHours() <= 9 ? `0${datetime.getHours()}` : datetime.getHours(),
        minutes = datetime.getMinutes() <= 9 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
      } else {
        datetime = new Date(date)
        hours = datetime.getUTCHours() <= 9 ? `0${datetime.getUTCHours()}` : datetime.getUTCHours(),
        minutes = datetime.getUTCMinutes() <= 9 ? `0${datetime.getUTCMinutes()}` : datetime.getUTCMinutes()
      }

      if (hours >= 12) {
        if (meridiem === "start") {
          this.meridiemStart = 'PM'
        } else {
          this.meridiemEnd = 'PM'
        }
      }
      return `${hours}:${minutes}`
    }
  }
}
</script>

<style lang="scss" scoped>

.set-hours {
  display: flex;
  align-items: center;

  @media screen and (min-width: 1200px) {
    padding-left: 110px;
    margin-left: 50px;
  }

  &__row {
    display: flex;
    flex: 2;
    flex-direction: column;
    margin: 12px 0;
    max-width: 120px;
  }

  span.interval__label {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.15px;
    margin: 0 16px;

    @media screen and (min-width: 1200px) {
      margin: 0 24px;
    }
  }
}

/* The time picker's default width is 10em; this lets us use a container to set its width */
.vue__time-picker.time-picker,
.vue__time-picker input.display-time {
  width: 100%;
}

/* Sets the time picker to use our colors and fixes a conflict with another `.active` class elsewhere */
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #F06837;
  color: #fff;
  height: inherit;
}

/* Tries to resolve a z-index issue with the Vue Material radio button */
.vue__time-picker-dropdown,
.vue__time-picker .dropdown {
  z-index: 7;
}

</style>

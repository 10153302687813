<template>
  <div class="job-detail-info" v-if="true">
    <p class="title" v-if="showTitle">
      Cancellation notes
    </p>

    <p class="cancellation-notes">
      <span class="cancellation-notes__canceled-by" v-if="canceledBy">
        Canceled by {{ canceledBy }}
      </span>
      <span class="cancellation-notes__text">
        {{ note ? `“${note}”` : 'Any notes' }}
      </span>
    </p>
  </div>
</template>

<script>
  export default {
    props: ['note', 'canceledBy', 'showTitle'],
    props: {
      canceledBy: String,
      note: String,
      showTitle: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss">
  .cancellation-notes {
    margin-top: 0;
    font-size: 16px;
    color: rgba(15, 50, 70, 0.65);

    &__title {
      font-size: 15px;
      color: #003248;
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 4px;
      min-height: 0;
      margin-top: 24px;
    }

    &__canceled-by, &__text {
      display: block;
    }

    &__text {
      display: block;
    }
  }
</style>

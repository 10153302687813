<template>
  <div class="motorist__banner">
     <GmapMap :center="center"
      :zoom="15"
      :options="options"
      ref="map"
      style="width: 100%;" >
      <custom-marker
        :marker="center"
        alignment="top">
        <MapIcon :type="status" />
      </custom-marker>
    </GmapMap>
    <div class="motorist__banner-info">
      <div class="motorist__banner-info-item">
        <span>Incident number</span>
        <p>{{ data.incidentNumber }}</p>  
      </div>
      <div class="motorist__banner-info-item">
        <span>Vehicle make & model</span>
        <p>{{ incidentVehicle.make}} {{ incidentVehicle.model }}</p>
      </div>
      <div class="motorist__banner-info-item">
        <span>License plate</span>
        <p v-if="incidentVehicle.plate">{{ incidentVehicle.plate}}</p>
        <p v-else class="not-available">Not available</p>
      </div>
    </div>
  </div>
</template>

<script>
import MapIcon from '../../maps/map-icon';
// Third Party
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'motoristBanner',
  props: ['data'],
  components: {
    MapIcon
  },
  data: () => ({
    options: {
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
    center: {
      lat: 42.9690668,
      lng: -85.9226895
    },
    status: '',
    incidentVehicle: {}
  }),
  computed: {
    google: gmapApi
  },
  created () {
    this.center.lat = this.data.latitude
    this.center.longitude = this.data.longitude
    this.status = this.data.status 
    this.incidentVehicle = this.data.assignments[0].incidentVehicle;
  }
}
</script>
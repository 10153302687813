<template>
  <div class="motorist__banner">
     <GmapMap :center="center"
      :zoom="15"
      :options="options"
      ref="map"
      style="width: 100%;" >
      <custom-marker
        :marker="center"
        alignment="top">
        <MapIcon :type="status" />
      </custom-marker>
    </GmapMap>
    <div class="motorist__banner-info">
      <p class="motorist__banner-time">{{etaDuration.start}}-{{etaDuration.end}}</p>
      <div class="motorist__banner-data">
        <p class="motorist__banner-text">ETA for <span>{{ this.driver.first }} {{this.driver.last}}</span></p>
        <p class="motorist__banner-text">from <span>{{ this.organization.name }}</span></p>
        <p class="motorist__banner-text">for your <span>{{ this.incidentVehicle.make }} {{this.incidentVehicle.model}}</span></p>
      </div>
      <div class="motorist__banner-call">
        <a v-bind:href="'tel:'+ driver.phone" class="motorist__banner-call-icon">
          <img :src="iconCall" alt="call">
        </a>
        <span>Call</span>
      </div>
    </div>
  </div>
</template>

<script>
import MapIcon from '../../maps/map-icon';
// Third Party
import { gmapApi } from 'vue2-google-maps'
import iconCall from '../../../../../images/call.svg'

export default {
  name: 'motoristBanner',
  props: ['data'],
  components: {
    MapIcon
  },
  data: () => ({
    options: {
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
    center: {
      lat: 42.9690668,
      lng: -85.9226895
    },
    status: '',
    incidentVehicle: {},
    organization: {},
    driver: {},
    etaDuration: {
      start: '',
      end: ''
    },
    iconCall
  }),
  computed: {
    google: gmapApi
  },
  methods: {
    getHour (date) {
      return this.formattedTime(date,true)
    },
  },
  created () {
    this.center.lat = this.data.latitude
    this.center.longitude = this.data.longitude
    this.status = this.data.status 
    this.incidentVehicle = this.data.assignments[0].incidentVehicle;
    this.organization = this.data.assignments[0].assignedOrg;
    this.driver = this.data.assignments[0].assignedDriver;
    
    let date = new Date();
    let timeEtaUpdated = new Date(this.data.assignments[0].etaUpdatedAt)
    this.etaDuration.start = this.getHour(timeEtaUpdated)
    this.etaDuration.end = this.getHour(timeEtaUpdated.getTime() + this.data.assignments[0].eta)
  }
}
</script>
<template>
  <md-step id="fourth" md-label="Add vehicles" :md-editable="false" :md-done.sync="done" class="col-md-8">
    <p>
      Please add at least one vehicle.
      You'll need the make, model, and color of each. You can also add an optional nickname. You can update and add vehicles at any time.
    </p>

    <md-list class="onboarding__list">
      <md-list-item class="under" v-for="(vehicle, index) in vehicles">
        <span class="md-list-item-text">{{vehicle.color}} {{vehicle.make}} {{vehicle.model}} 
        <span v-if="vehicle.nickname">("{{vehicle.nickname}}")</span>
        </span>
        <a href="javascript:;" @click="removeVehicle(index)" class="delete-vehicle"><md-icon>clear</md-icon></a>
      </md-list-item>
    </md-list>

    <a href="javascript:;" @click="showDialog = true" class="link"><span class="orange header-table__add">ADD VEHICLE</span></a>

    <div style="margin-top: 20px;">
    </div>

    <!-- Submit Buttons -->
    <div>
      <md-button
        :disabled="!isCompleteForm"
        class="md-raised md-primary"
        @click="next"
      >
        Save & continue
      </md-button>

      <md-button
        v-if="enableFinishLater"
        :disabled="!isCompleteForm"
        class="md-raised md-primary"
        @click="finishLater"
      >
        Save & finish later
      </md-button>
    </div>

    <md-dialog :md-active.sync="showDialog" class="dialog-form-wizard dialog-organization"  :md-fullscreen="false">
      <div class="dialog-form-wizard__content-title">
        <span @click="closeDialog" class="dialog-close-icon">
          <img src="../../../../images/icon-close.svg" alt="">
        </span>
        <p class="dialog-form-wizard__title">Add vehicle</p>
      </div>
      <md-dialog-content class="dialog-form-wizard__content">
        <div class="col-md-12">
          <form class="md-layout" enctype="multipart/form-data" accept-charset="UTF-8" id="formVehicle" name="formVehicle">
            <div class="md-layout-item md-small-size-100">
              <!-- Make & Model -->
              <div class="row">
                <div class="col-md-6 no-padding">
                  <md-field :class="getValidationClass('make')">
                    <label for="make">Make</label>
                    <md-input name="make" id="make" v-model="vehicle.make"/>
                    <span class="md-error">This field is required</span>
                  </md-field>
                </div>
                <div class="col-md-6 no-padding">
                  <md-field :class="getValidationClass('model')">
                    <label for="make">Model</label>
                    <md-input name="make" id="make" v-model="vehicle.model"/>
                    <span class="md-error">This field is required</span>
                  </md-field>
                </div>
              </div>

              <!-- Color & Nickname (optional) -->
              <div class="row">
                <div class="col-md-6 no-padding">
                  <md-field :class="getValidationClass('color')">
                    <label for="color">Color</label>
                    <md-input name="color" id="color" v-model="vehicle.color"/>
                    <span class="md-error">This field is required</span>
                  </md-field>
                </div>
                <div class="col-md-6 no-padding">
                  <md-field :class="getValidationClass('nickname')">
                    <label for="nickname">Nickname (optional)</label>
                    <md-input name="nickname" id="nickname" v-model="vehicle.nickname"/>
                    <span class="md-error">This field is required</span>
                  </md-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 no-padding">
                  <md-checkbox v-model="vehicle.flatbed">
                    This vehicle is a flatbed
                  </md-checkbox>
                </div>
              </div>
            </div>
          </form>
        </div>
      </md-dialog-content>
      <md-dialog-actions class="dialog-form-wizard__footer">
        <div class="col-md-12">
          <div class="footer-form__content footer-form__content-wizard">
            <md-button class="btn btn-primary footer-form__submit" @click="addVehicle">save</md-button>
            <!-- <md-button class="md-primary" @click="closeDialog">cancel</md-button> -->
          </div>
        </div>
      </md-dialog-actions>
    </md-dialog>

    <ProgressDialog v-if="loading" :lockScreen="true" />

    <md-snackbar :class="[error ? 'snackbar-notify--error' : 'snackbar-notify--success', 'snackbar-notify snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="alert" md-persistent>
      <span  class="snackbar-notify__text">
        <i v-if="!error" class="material-icons">check</i>
       {{ alertMsg }}
      </span>
    </md-snackbar>
  </md-step>
</template>

<script>
import ProgressDialog from './progress-dialog'
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['vehiclesArray', 'onboardingStep', 'enableFinishLater'],
  data () {
    return {
      done: false,
      showDialog: false,
      position: 'left',
      isInfinity: false,
      duration: 4000,
      error: false,
      vehicles: [],
      isCompleteForm: true,
      vehicle: {
        make: null,
        model: null,
        color: null,
        nickname: null,
        flatbed: false
      },
      disabledDates: date => {
        let currentDate = new Date();
        return currentDate > date
      },
      carModelsByMake: [],
      alert: false,
      alertTitle: '',
      alertMsg: '',
      loading: false
    }
  },

  components: {
    ProgressDialog
  },

  validations: {
    vehicle: {
      make: {
        required
      },
      model: {
        required
      },
      color: {
        required
      },
    }
  },

  methods: {
    /**
     * Validate and submit the form
     */
    next() {
      if (!this.validateHasVehicles()) return ;

      this.isCompleteForm = false
      this.submit()
    },
    /**
     * Validate, submit the form and let finish it more later.
     */
    finishLater() {
      if (!this.isValidForm()) return ;

      this.submit({
        tower_detail: humps.decamelizeKeys(this.towerDetail),
        registration_step: 2
      }).then((success) => {
        if (success) {
          this.$emit("next", "thank-you");
        }
      });
    },
    /**
     * Check if the user has already added a driver.
     */
    validateHasVehicles() {
      const hasVehicles = this.vehicles.length > 0

      if (!hasVehicles) {
        this.error = true;
        this.alertMsg = 'You must add a vehicle';
        this.alert = true;
      }

      return hasVehicles;
    },
    /**
     * Submit the form
     */
    submit() {
      let formData = new FormData();

      if(this.vehicles.length) {
        this.vehicles.forEach(element => {
            formData.append('vehicles[][make]', element.make)
            formData.append('vehicles[][model]', element.model)
            formData.append('vehicles[][color]', element.color)
            if (element.nickname !== null) {
              formData.append('vehicles[][nickname]', element.nickname)
            }
            formData.append('vehicles[][flatbed]', element.flatbed)
        });

        this.$http.post('/vehicles', formData).then(response => {
          this.done = true
          this.alertTitle = 'Success!'
          this.alertMsg = 'Vehicles data sent successfully'
          this.loading = false
          this.error = false
          this.isCompleteForm = false
          this.$emit('next', 'sixth');
        }, response => {
          if(response.status === 422) {
            this.alertTitle = 'Error'
            this.alertMsg = response.body.msg
            this.alert = true
            this.loading = false
            this.error = true
            this.isCompleteForm = true
          } else {
            this.alertTitle = 'Error'
            this.alertMsg = 'Something went wrong, please try again'
            this.alert = true
            this.loading = false
            this.error = true
            this.isCompleteForm = true
          }
        })
      } else {
        this.isCompleteForm = true
        this.done = true;
        this.$emit('next', 'sixth');
      }
    },
    /**
     * Get Vehicle Models based on Vehicle Make
     */
    getModels () {
      const baseURL = 'https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake'

      if (this.vehicle.make) {
        fetch(`${baseURL}/${this.vehicle.make}?format=json`)
        .then(resp => resp.json())
        .then(data => {
          if (data.Results.length > 0) {
            this.carModelsByMake = data.Results.map(item => item['Model_Name'])
          }
        })
      }
    },

    closeDialog () {
      this.showDialog = false;
      this.vehicle = {
        make: null,
        model: null,
        color: null,
        nickname: null,
        flatbed: false
      }
    },

    addVehicle () {
      this.$v.vehicle.$touch()
      if (!this.$v.vehicle.$invalid) {
        this.vehicles.push({ ...this.vehicle })
        this.vehicle = {}
        this.$v.vehicle.$reset()
        this.showDialog = false
      }
    },
    removeVehicle (index) {
      this.vehicles.splice(index, 1)
    },

    getValidationClass(fieldName) {
      const field = this.$v.vehicle[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    }
  },

  created () {
    if(this.onboardingStep)
     {
      if(this.onboardingStep.step === 'fifth' || this.onboardingStep.step === 'sixth') {
        this.done = true;
      }
    }
  },

  mounted() {

  }
}
</script>

<style lang="scss" scoped>
  .link {
    padding-left: 20px;
    &:hover {
      text-decoration: none !important;
    }
  }
  .onboarding__list {
    margin-bottom: 12px;
  }
  .vehicle-save-button {
    button {
      margin-left: 0;
    }
  }
  .delete-vehicle {
    &:hover {
      text-decoration: none !important;
    }
  }
</style>

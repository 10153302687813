import { sortByDate } from '../../helpers';
import { methods } from '../../../mixins/methods';
import { getParsedJobStatus } from '../../../services/jobHelpers';

export default {
  sentRequestsByCreatedAt: sortByDate('sent', 'created_at'),
  receivedRequestsByCreatedAt: sortByDate('received', 'created_at'),
  activeSentRequests (state, getters, rootState) {
    const dismissed_jobs = rootState.users.current.dismissed_jobs
    const requests = getters.sentRequestsByCreatedAt
    return requests.filter((request) => {
      if ( dismissed_jobs && dismissed_jobs.indexOf(request.id) !== -1 ) { return false }
      else if (request.job.status !== 'completed') { return true }
      else { return (new Date - new Date(request.updated_at)) < 20*60*1000 }
    })
  },
  activeReceivedRequests (state, getters) {
    const requests = getters.receivedRequestsByCreatedAt
    console.log('requests: ', requests);
    return requests.filter((request) => {
      const status = methods.parseStatusTower(request)
      switch (status) {
        case 'completed':
        case 'expired':
        case 'outbid':
        case 'canceled':
        case 'declined':
          return (new Date - new Date(request.updated_at)) < 15*60*1000
          break;
        case 'incorrect':
        case 'unresolved':
          return false
          break;
        default:
          return true
      }
      return request.job.status !== 'completed'
    })
  },
  findQuoteRequestById: (state) => (id) => {
    if (!id) return ;

    return state.sent.find((item) => (
      item.id === id)
    );
  },

  /**
   * Check if a quote request just declined.
   * 
   * @param {*} state 
   * @param {*} getters 
   * @returns 
   */
  wasQuoteRequestDeclined: (state, getters) => (quoteRequest) => {
    const prevQuoteRequest = getters.findQuoteRequestById(quoteRequest.id);

    if (!prevQuoteRequest) return ;

    const jobStatus = getParsedJobStatus(quoteRequest.job, quoteRequest);
    const prevJobStatus = getParsedJobStatus(prevQuoteRequest.job, prevQuoteRequest);

    return jobStatus === 'declined' && prevJobStatus !== 'declined';
  }
}
import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/drivers{/id}"
const customMethods = {
  active: { method: "GET", url: `${path}/active` },
  assign: { method: "POST", url: `${path}/assign`},
  reassign: { method: "POST", url: `${path}/reassign`},
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

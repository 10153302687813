<template>
  <div class="job-info-footer">
    <div class="footerBar position-fixed py-3">
      <fieldset class="row">
        <div class="col-md-10 offset-md-1">

          <div v-if="state === 'new' || state === 'open'">
            <md-button class="btn btn-success" :disabled="disabled" @click="startQuote">Quote Job</md-button>
            <md-button class="btn btn-danger" :disabled="disabled" @click="declineQuote">Decline</md-button>
          </div>

          <div v-if="state === 'direct'">
            <md-button class="btn btn-primary" @click="assignDriver" :disabled="true">Update</md-button>
          </div>

        </div>
      </fieldset>
    </div>

    <md-dialog-confirm
      :md-active.sync="decline_confirm_modal"
      md-content="Are you sure you want to decline this job?"
      md-confirm-text="Decline Job"
      md-cancel-text="Cancel"
      @md-cancel="decline_confirm_modal = false"
      @md-confirm="declineQuoteConfirm" />

  </div>
</template>

<script>
export default {
  props: ['state', 'disabled'],

  components: {},

  data: () => {
    return {
      complete_confirm_modal: false,
      decline_confirm_modal: false,
    }
  },

  computed: {
    status () {
      return this.parsedStatusTower(this.request)
    },
  },

  methods: {
    assignDriver () {
      this.$emit('action', 'assign-driver')
    },
    completeAssignment () {
      this.$emit('action', 'complete-assignment')
    },
    declineQuote () {
      this.decline_confirm_modal = true
    },
    declineQuoteConfirm () {
      this.$emit('action', 'decline-quote')
    },
    startQuote () {
      this.$emit('action', 'start-quote')
    },
  }
}
</script>

<style scoped lang="scss">
</style>

export const caretPhone = (phoneFormat,phoneNumber) => {
   var x = phoneFormat.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
   phoneFormat = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

   phoneNumber =  phoneFormat.replace(/[()]/g, '');
   phoneNumber = phoneNumber.replace(/-/g, "").replace(" ", "");

   return {
     phoneFormat,
     phoneNumber
   }
}

export const formatPhoneNumber = (str) => {
  const cleaned = ("" + str).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];

  return null;
}
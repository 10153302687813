 <template>
  <div>
    <transition name="fade">
      <MotoristSearch v-if="go"/>
    </transition>
    <transition name="fade">
      <MotoristIntro @show:motorist-search="show" v-if="!go"/>
    </transition>
  </div>
</template>

<script>
import MotoristSearch from './motorist-search'
import MotoristIntro from './motorist-intro'

export default {
  name: 'Motorist',
  props: ['currentOrganization'],
  data: () => ({
    go: false
  }),
  components: {
    MotoristSearch,
    MotoristIntro
  },
  methods: {
    show (val) {
      this.go = val
    }
  },
  created () {
    let navbar = document.getElementById('vue-navbar');
    navbar.remove();
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
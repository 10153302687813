<template>
  <md-card class="card hours-of-operations card-wrecker-detail card-box-profile card-box offset-card section js-content-box-bussines" id="hours-of-operations">
    <h2 class="title-card">Hours of Operation</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <div class="card-box__content" v-if="openBox">
      <div class="card-box__content-form js-box-content-form">
        <p class="info-documents">
          Note <span>Hours of Operation</span> only applies to Curbside SOS motorist requests and assignments. 
          If your company is a provider for a police dispatch center (911), 
          those jobs will be assigned to you 24/7 or per your agreement with the center.
        </p>
        <div v-if="!edit_info">
          <p class="schedule-title">Weekly Schedule</p>
          <p class="schedule-timezone" v-if="data_owner.organization_timezone">
              All times presented are in {{ getOrganizationTimeZoneName("long") }} ({{ getOrganizationTimeZoneName("short") }}).
          </p>
          <div class="schedule">
            <div class="schedule__week">
              <p v-for="(week, index) in data" :key="index">{{ week.dayOfWeek }}</p>
            </div>
            <div class="schedule__status">
              <div v-for="(week, index) in data" :key="index" class="schedule__status">
                <div v-if="week.businessStatus === 'open'">
                  <p>
                    <span>{{ getHour(week.startHour) }}</span> -
                    <span>{{ getHour(week.endHour) }}</span>
                  </p>
                </div>
                <p v-else>{{ options.find(option => option.value === week.businessStatus).label }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="hours-of-operations__content">
          <div class="hours-of-operation__settings settings-hours">
            <div class="settings-hours__header">
              <p>Open 24 hours</p>
              <p>Set business hours</p>
              <p>Closed</p>
            </div>
            <div class="settings-hours__row">
              <div v-for="(week, index) in data" :key="index" class="settings-hours__options">
                <div class="day-line">
                  <span class="week-day">{{ week.dayOfWeek }}</span>
                  <div class="options">
                    <md-radio
                      class="checkout__radio checkout__title"
                      v-for="(option, index) in week.options"
                      :key="index"
                      :value="option.value"
                       v-model="week.selectedOption.value"
                       @change="(value) => getOptionSelected(value, week.id)"
                    >
                      {{ option.label }}
                    </md-radio>
                  </div>
                </div>
                <select-hours
                  v-if="week.editHours"
                  :id="week.id"
                  :startTime="week.startHour"
                  :endTime="week.endHour"
                  @show:date="getDate"
                >
                </select-hours>
                <div class="inline-error-box" v-if="week.editHours && partialOpenDayErrorsArr[index]">{{
                    partialOpenDayErrorsArr[index]
                  }}</div>
              </div>
            </div>
          </div>
        </div>
        
        <transition name="slide">
          <div v-if="edit_info" class="row">
            <div class="card-box__content-actions">
              <md-button :class="[deactived_button ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="submit">Save</md-button>
              <md-button class="md-accent card-box__button-cancel" @click="cancel(!up_to_date ? 'database' : 'browserData')">Cancel</md-button>
              <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </md-card>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex';
import { hoursOperation } from '@/shared/mixins/hours-operations';
import SelectHours from '@/shared/components/towco-settings/select-hours.vue'
import humps from 'humps'
import { getTimeZoneName } from '@/utils/object-helpers'

export default {
  name: 'HoursOfOperation',
  props: ['data_owner'],
  components: {
    SelectHours
  },
  data: () => ({
    data: hoursOperation,
    newOperationsArr: [],
    options: [
      { value: "always_open", label: "Open 24 Hours" },
      { value: "open", label: "Set business hours"},
      { value: "closed", label: "Closed" }
    ],
    selectedOption: {},
    saveData: null,
    validate_number: false,
    edit_info: false,
    openBox: false,
    update: false,
    deactived_button: true,
    up_to_date: false,
    // [Qi] Currently error only support partial open day's error check on FE, rename if this needs to support others
    partialOpenDayErrorsArr: [null, null, null, null, null, null, null]
  }),
  watch: {
    data: {
      handler(newValue) {
        this.activateSaveIfNoError()
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('organizations', {
      current: (state) => state.current,
      operationHours: (state) => state.operationHours
    }),
  },
  methods: {
    editInfo () {
      if ( !this.up_to_date ) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }

      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    getHour(date) {
      let newDate = new Date(date)
      let datetime = ''
      let hours = ''
      let minutes = ''
      let meridiem = ''

      if (newDate.toString() === 'Invalid Date') {
        datetime = new Date()
        const [hr, min] = date.split(':');
        datetime.setHours(parseInt(hr));
        datetime.setMinutes(parseInt(min));

        hours = datetime.getHours(),
        minutes = datetime.getMinutes() <= 9 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
      } else {
        datetime = new Date(date)
        hours = datetime.getUTCHours(),
        minutes = datetime.getUTCMinutes() <= 9 ? `0${datetime.getUTCMinutes()}` : datetime.getUTCMinutes()
        meridiem = ''
      }

      if (hours >= 12) {
        meridiem = 'PM'
        hours = hours - 12
      } else {
        meridiem = 'AM'
      }

      return `${hours === 0 ? '12' : hours}:${minutes} ${meridiem}`

    },
    getDayValue(day) {
      const daysWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      return daysWeek.indexOf(day) + 1;
    },
    getOptionSelected(option, id) {
      this.data = this.data.map(day => {
        if (day.id === id) {
          return {
            ...day,
            editHours: option === 'open'
          }
        }
        return day
      })
    },
    getDate(value) {
      const { id, startHour, endHour } = value
      let dayIndex = this.data.findIndex(day => day.id === id)

      if (this.isStartEndTimeValid(startHour, endHour)) {
        this.data[dayIndex].startHour = startHour
        this.data[dayIndex].endHour = endHour
        this.partialOpenDayErrorsArr[dayIndex] = null
        this.activateSaveIfNoError()
      } else {
        this.data[dayIndex].startHour = startHour
        this.data[dayIndex].endHour = endHour
        this.partialOpenDayErrorsArr[dayIndex] = "The Open time must be earlier than the Closed time."
        this.deactived_button = true
      }
    },
    getOrganizationTimeZoneName(typeName) {
      return getTimeZoneName(this.data_owner.organization_timezone, typeName);
    },
    verifyHoursOperation () {
      let newOperationsArr = []
      Vue.http.get(`/organizations/${this.data_owner.organization_id}/day_operations`, {} ,[{responseType: 'json'}])
        .then(response => {
            this.activateSaveIfNoError()
            this.update = false;
            const { data } = response?.body

            if (data?.length) {
              newOperationsArr = data?.map(day => {
                const { day_of_week, business_status, start_hour, end_hour } = day.attributes
                return {
                  id: day.id,
                  businessStatus: business_status,
                  dayOfWeek: day_of_week,
                  startHour: start_hour,
                  endHour: end_hour
                }
              })
            }

            this.newOperationsArr = newOperationsArr
            this.resetData(this.newOperationsArr)
        }, err => {
          setTimeout(() => {
            console.log(err)
          },1000)
      });
    },
    submit () {
      this.update = true;
      this.deactived_button = true;

      let data = this.data.map(day => {
        const { dayOfWeek, startHour, endHour, selectedOption, id} = day
        const businessStatus = selectedOption.value || 'always_open';
        const startHourValue = selectedOption.value === 'open' ? startHour : null;
        const endHourValue = selectedOption.value === 'open' ? endHour : null;

        const result = {
          day_of_week: dayOfWeek,
          business_status: businessStatus,
          start_hour: startHourValue,
          end_hour: endHourValue,
        }

        if (this.newOperationsArr?.length || this.data_owner.day_operations?.length) {
          result.id = id
        }

        return result
      })


      Vue.http.post(`/organizations/${this.data_owner.organization_id}/day_operations`, {
        organization: {
          day_operations: data
        }
      } ,[{responseType: 'json'}])
        .then(response => {
          setTimeout(() => {
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
            this.up_to_date = true
            this.edit_info = false
            this.data.preference = this.preferences
            this.verifyHoursOperation()
          },1000)
        }, err => {
          setTimeout(() => {
            this.update = false;
            this.activateSaveIfNoError()
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.message : 'Something went Wrong', error: true})
            this.up_to_date = false
          },1000)
      });
    },
    cancel () {
      const { day_operations } = this.data_owner
      this.edit_info = !this.edit_info
      this.deactived_button = true

      if (!this.up_to_date) {
        if (day_operations?.length) {
          this.resetData(day_operations)
        } else {
          this.resetData(hoursOperation)
        }
      }
    },
    detectChange() {
      this.activateSaveIfNoError()
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    },
    resetData(arr) {
      let data = humps.camelizeKeys(arr)

      this.data = data.map(day => {
        return {
          ...day,
          editHours: day.businessStatus === 'open',
          selectedOption: {
            value: day.businessStatus
          },
          options: [
            { value: "always_open", label: "Open 24 hours"},
            {value: "open", label: "Set business hours"},
            {value: "closed", label: "Closed"},
          ],
        }
      })

      this.data = this.data.sort((a,b) => this.getDayValue(a.dayOfWeek) - this.getDayValue(b.dayOfWeek))
    },
    isStartEndTimeValid(startTime, endTime) {
      // Convert strings to Date objects for easy comparison
      const startDate = new Date("2000/01/01 " + startTime);
      const endDate = new Date("2000/01/01 " + endTime);

      // Compare the times
      return startDate < endDate
    },
    activateSaveIfNoError() {
      if(this.everyPartialOpenDayHasNoError()){
        this.deactived_button = false
      }
    },
    everyPartialOpenDayHasNoError() {
      return this.partialOpenDayErrorsArr.every((err, index) => !this.data[index].editHours || err === null)
    }
  },
  created () {
    if (this.data_owner.day_operations?.length) {
      const { day_operations } = this.data_owner
      this.resetData(day_operations)
    } else {
      this.resetData(this.data)
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>

<style scoped lang="scss">
.hours-of-operations {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
  }

  .info-documents {
    font-size: 1rem;
    span {
      font-style: italic;
    }
  }
}

.schedule-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  border-top: 1px solid #637986;
  margin: 16px 0 0; /* Resets margin for paragraph tag */
  padding-top: 12px;
}

.schedule-timezone {
  font-size: 16px;
  line-height: 22px;
  margin: 4px 0 0; /* Resets margin for paragraph tag */
  color: #637986;
  font-style: italic;
}

.schedule {
  display: flex;
  margin-top: 16px; /* Adds top margin so space is the same with or without time zone */

  &__status {
    margin-left: 20px;
    text-align: right;

    @media screen and (min-width: 1200px) {
      margin-left: 40px;
    }

    p {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 450;
      line-height: 22px;
      color: #637986;
    }
  }

  &__week {
    p {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 450;
      line-height: 22px;
      color: #0F3246;
    }

    p::first-letter {
      text-transform: uppercase;
    }
  }
}

.inline-error-box {
  color: red;
  display: flex;
  align-items: center;

  @media screen and (min-width: 1200px) {
    padding-left: 110px;
    margin-left: 50px;
  }
}

.settings-hours {
  &__header {
    display: none;
    padding-left: 110px;

    @media screen and (min-width: 1200px) {
      display: flex;
      margin-top: 24px;
    }

    p {
      width: 160px;
      display: flex;
      justify-content: center;
    }
  }

  &__options {
    margin-top: 20px;

    .day-line {
      display: block;

      @media screen and (min-width: 1200px) {
        display: flex;
      }
    }
  }
}

.week-day {
  display: block;
  width: 100px;
  font-size: 16px;
  font-weight: 450;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin-bottom: 9px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
  &::first-letter {
    text-transform: uppercase;
  }
}

.options {
  display: block;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    display: flex;
  }

  .checkout__radio {
    display: flex;

    @media screen and (min-width: 1200px) {
      width: 160px;
      justify-content: center;
    }

    .md-radio-label {
      display: block;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.15px;
      font-weight: 450;
      color: #0F3245DE;

      @media screen and (min-width: 1200px) {
        display: none;
      }
    }
  }
}

</style>

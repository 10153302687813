<template>
  <md-step id="fourth" :md-label="titleStep" :md-description="titleDescription" :md-editable="false" :md-done.sync="done" class="fourth-step motorist-onboarding-step">
    <div class="row">
      <div class="col-md-6">
        <div class="tow-destination">
          <p class="tow-destination__title">{{ title }}</p>
          <div class="tow-destination__destination">
            <div v-if="yourSelection">
              <p class="tow-destination__name--own-selection">{{ ownSelection.line_1 }} {{ ownSelection.line_2 }}</p>
            </div>
            <div v-if="dontknowSelection">
              <p class="i-dont-know">I don’t know</p>
              <p class="tow-destination__name--own-selection">Your vehicle will likely be taken to the tow company's storage lot, where you'll incur a nightly storage fee.</p>
            </div>
            <div v-if="recommenSelection">
              <p class="tow-destination__name">{{ recommend_shop.name }}</p>
              <p class="tow-destination__distance">{{ recommend_shop.distance ? recommend_shop.distance.toFixed(1) : '' }} {{ recommend_shop.unit === 'mi' ? 'miles away from accident' : '' }}</p>
              <p class="tow-destination__address">{{ recommend_shop.address }}</p>
            </div>
            <button class="edit-button" @click="showDialog = !showDialog">
              <img src="../../../../../../../images/edit-icon.svg" alt="edit icon">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <md-button :disabled="!deactivedButton" :class="[!deactivedButton ? 'deactive-button' : '','md-raised md-primary motorist-onboarding-button-save']" @click="saveDestination">Save & continue</md-button>
      <md-progress-spinner class="motorist-onboarding__spinner" v-if="update" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
   </div>
    <md-dialog :md-active.sync="showDialog" class="dialog-motorist-destination">
      <md-dialog-content class="dialog-motorist-destination__content">
        <img class="close-dialog" src="../../../../../../../images/icon-close.svg" @click="showDialog = false" alt="close icon">
        <h2 class="dialog-motorist-destination__title">Select a destination for your tow</h2>
        <span class="dialog-motorist-destination__near">Results near:</span>
        <md-field>
          <label><md-icon :class="[changeColor ? 'color-blue' : '']">location_on</md-icon> Enter a location</label>
          <md-input v-model="userQuery" v-on:keyup="getUserQuery"></md-input>
        </md-field>
        <ul class="dialog-motorist-destination__destinations" v-if="destinations">
          <li class="destination" v-for="destination in destinations">
            <div class="destination__info">
              <p class="destination__title">
                {{ destination.name }}
                <md-radio class="radio-vehicle" v-model="radioOption" :value="destination">Boolean</md-radio>
              </p>
              <p :class="[destination.nation_verified ? '' : 'destination__distance-margin', 'destination__distance']" v-if="destination.distance">{{ destination.distance.toFixed(1) }} {{ destination.unit === 'mi' ? 'miles away from accident' : '' }}</p>
              <p class="destination__address" v-if="destination.address">{{ destination.address }}</p>
            </div>
          </li>
          <li class="destination">
            <div class="destination__info">
              <p class="destination__title">
                Your own destination
                <md-radio class="radio-vehicle" v-model="radioOption" :value="ownDestinationRadio">Boolean</md-radio>
              </p>
              <transition name="slide">
                <div class="own-destination" v-if="radioOption.show_field">
                  <LocationAutocomplete v-bind:selection.sync="ownSelection" @update:selection="getOwnSelection" />
                  <span class="example-complete">e.g. 375 Pasco Rd, Springfield, MA 01119</span>
                </div>
              </transition>
            </div>
          </li>
          <li class="destination">
            <div class="destination__info">
              <p class="destination__title">
                I don’t know
                <md-radio class="radio-vehicle" v-model="radioOption" :value="dontKnowRadio">Boolean</md-radio>
              </p>
              <p class="destination__dont-know">
                Your vehicle will likely be taken to the tow company's storage lot, where you'll incur a nightly storage fee.
              </p>
            </div>
          </li>
        </ul>
      </md-dialog-content>
      <md-dialog-actions class="dialog-motorist-destination__actions">
         <md-button :disabled="deactivedConfirmButton" :class="[deactivedConfirmButton ? 'deactive-button' : '', 'md-raised md-primary motorist-onboarding-button-save']" @click="confirmDialog">confirm</md-button>
      </md-dialog-actions>
    </md-dialog>
  </md-step>
</template>

<script>
import Vue from 'vue';
import EventBus from '../../../../../mixins/event-bus'
import LocationAutocomplete from "../../../../forms/map-forms/location-autocomplete";

export default {
  name: 'SelectDestination',
  props: ['job_assignment_id', 'steps', 'motorist'],
  data: () => ({
    titleStep: 'Select a destination for your tow',
    titleDescription: 'Use the pencil icon to change where you’d like your vehicle sent.',
    title: 'Recommended',
    deactivedButton: true,
    done: false,
    update: false,
    yourSelection: false,
    ownSelection: {},
    changeColor: false,
    showDialog: false,
    radioOption: {},
    userQuery: '',
    destinations_data: [],
    destinations: [],
    recommend_shop: {},
    dontknowSelection: false,
    recommenSelection: false,
    deactivedConfirmButton: false,
    addressSelected: false,
    ownDestinationRadio: {
      show_field: true
    },
    dontKnowRadio: {
      dont_know: true
    }
  }),
  components: {
    LocationAutocomplete
  },
  methods: {
    getUserQuery () {
      if ( this.userQuery != '' ) {
        this.destinations = this.destinations.filter(destination => destination.address.toLowerCase().includes(this.userQuery) ||destination.name.toLowerCase().includes(this.userQuery) ? destination : null)
      } else {
        this.destinations = this.destinations_data
      }
    },
    getOwnSelection (selection) {
      this.ownSelection = selection.addressable
      this.addressSelected = true
    },
    confirmDialog () {


      if ( this.radioOption.show_field ) { 
        if ( !this.addressSelected ) {
          this.$emit('show:snackbar', {update: true, alertMsg: 'You need to add an address', error: true})
       } else {
         this.title = 'Your selection'
         this.yourSelection = true
         this.dontknowSelection = false
         this.radioOption = {}
         this.recommenSelection = false
         this.showDialog = false
       }
      } else if ( this.radioOption.dont_know ) {
        this.title = 'Your selection'
        this.yourSelection = false
        this.dontknowSelection = true
        this.radioOption = {}
        this.recommenSelection = false
        this.showDialog = false
      } else {
        this.title = 'Recommended'
        this.yourSelection = false
        this.dontknowSelection = false
        this.recommenSelection = true
        this.recommend_shop = this.radioOption
        this.showDialog = false
      }
    },
    saveDestination () {
      this.update = true
      let optionSelect = {
        city: this.radioOption.city,
        country: this.radioOption.country,
        district: "",
        latitude: this.radioOption.latitude,
        line_1:  this.radioOption.address,
        line_2: this.radioOption.state,
        location_type: "",
        longitude: this.radioOption.longitude,
        state: this.radioOption.state_code,
        zip: this.radioOption.postal_code
      }
      let data = {
        job_reference: this.job_assignment_id,
        destination: this.yourSelection ? this.ownSelection : optionSelect
      }

      Vue.http.post(`/destinations`, data, [{responseType: 'json'}])
      .then(response => {
          setTimeout(() => {
            this.done = true
            this.update = false
            localStorage.removeItem('body_shops')
            this.$emit("next", "fifth");
          },1000)
        }, err => {
          setTimeout(() => {
            this.done = false
            this.update = false
            this.$emit('show:snackbar', {update: true, alertMsg: err.body.msg ? err.body.msg : 'Something went Wrong', error: true})
          },1000)
      });
    },
    setBodyShops (response) {
      this.destinations = response
      this.destinations_data = response
      this.recommend_shop = response[0]
      this.radioOption = response[0]
      this.recommenSelection = true
    },
    getBodyShops () {
      Vue.http.get(`/destinations/body_shops_recommended?job_reference=${this.job_assignment_id}`, [{responseType: 'json'}])
        .then(response => {
            localStorage.setItem('body_shops', JSON.stringify(response.body))
            this.setBodyShops(response.body)
          }, err => {
            console.log(err.body.msg)
        })
    }
  },
  mounted () {
    EventBus.$on('GET_BODY_SHOPS', () => {
      this.getBodyShops()
    })

    if ( localStorage.getItem('body_shops') ) {
      this.setBodyShops(JSON.parse(localStorage.getItem('body_shops')))
    } else {
      this.getBodyShops()
    }
    
    if ( this.motorist ) {
      if (this.steps[this.motorist.completedSteps] !== "fourth" ) {
        this.done = true
        this.$emit("next", this.steps[this.motorist.completedSteps])
      }
    }
    let numberStepperText = document.querySelector('.fourth-step');
    numberStepperText.setAttribute('data-step-number', 4)
  }
}
</script>
<style lang="scss" scoped>
  .example-complete {
    color: rgba(15, 50, 70, 0.65);
    font-size: 14px;
    margin-left: 10px;
  }
</style>

import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/vehicles{/id}"
const customMethods = {
  available: { method: "GET", url: `${path}/available`}
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";

const path = "/quote_requests{/id}"
const customMethods = {
  sentQuoteRequests: { method: "GET", url: `${path}/` },
  receivedQuoteRequests: { method: "GET", url: `${path}/` },
  createQuotes: { method: "POST", url: `${path}/create_quotes` },
  getETAs: { method: "GET", url: `${path}/get_etas` },
  tower: { method: "GET", url: `${path}/tower` },
  dismiss: { method: "POST", url: `${path}/dismiss` }
};

Vue.use(VueResource);
export default Vue.resource(path, {}, customMethods);

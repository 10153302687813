import ActionCable from  'actioncable'

export default class Cable {

  constructor (store) {
    this.consumer = ActionCable.createConsumer()
    this.store = store
    this.subscriptions = {}
  }

  /*
  * Register Cable with Vue's globals
  */
  install (Vue) {
    Vue.prototype.$cable = this
  }

  create ({ channelConstructor }, { ...options }) {
    const c = new channelConstructor({ store: this.store }, options)
    this.subscriptions[c.key()] = this.consumer.subscriptions.create(
      c.channel(),
      c.mixins()
    )
  }
}

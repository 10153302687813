<template>
  <md-card class="card account-user card-wrecker-detail card-box-profile card-box offset-card section js-content-box-bussines" id="account-owner">
    <h2 class="title-card">Account owner</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <div class="card-box__content" v-if="openBox">
      <div class="card-box__content-form js-box-content-form">
        <div class="row">
          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">First name</label>
              <div v-if="user.first_name" class="form__field">{{ user.first_name }}</div>
              <div v-else class="form__field value-none">None</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass(this,'user','first_name')">
                <label class="form__label">First name</label>
                <md-input v-model="user.first_name" class="form-control" v-on:keyup="detectChange()"></md-input>
                <span class="md-error" v-if="!$v.user.first_name.required">First name is required</span>
              </md-field>
            </div>
          </div>

          <div v-if="!edit_info" class="form-group col-md-6">
            <div class="field">
              <label class="form__label">Last Name</label>
              <div v-if="user.last_name" class="form__field">{{ user.last_name }}</div>
              <div v-else class="form__field value-none">None</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass(this,'user','last_name')">
                <label class="form__label">Last Name</label>
                <md-input v-model="user.last_name" class="form-control" v-on:keyup="detectChange()"></md-input>
                <span class="md-error" v-if="!$v.user.last_name.required">Last name is required</span>
              </md-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
            <div class="field margin-bottom-no">
              <label class="form__label">Mobile phone number</label>
              <div v-if="user.phone_format" class="form__field">{{ user.phone_format }}</div>
              <div v-else class="form__field value-none">None</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass(this,'user','phone_number')">
                <label class="form__label">Mobile phone number</label>
                <md-input v-model="user.phone_format" class="form-control" v-on:keyup="caretPhone(); detectChange()"></md-input>
                <span class="md-error" v-if="!$v.user.phone_number.required">
                  Phone number is required
                </span>
                <span class="md-error" v-if="!$v.user.phone_number.minLength">
                  The Mobile Number must be at least 10 characters long
                </span>
              </md-field>
            </div>
          </div>

          <div v-if="!edit_info" class="form-group col-md-6 padding-bottom-no">
            <div class="field margin-bottom-no">
              <label class="form__label">Email</label>
              <div v-if="user.email" class="form__field">{{ user.email }}</div>
              <div v-else class="form__field value-none">None</div>
            </div>
          </div>
          <div v-else class="col-md-6 col-sm-12">
            <div class="form-group">
              <md-field :class="getValidationClass(this,'user','email')">
                <label class="form__label">Email</label>
                <md-input v-model="user.email" class="form-control" v-on:keyup="detectChange()"></md-input>
                <span class="md-error" v-if="!$v.user.email.required">Email is required</span>
                <span class="md-error" v-if="!$v.user.email.email">Invalid email</span>
              </md-field>
            </div>
          </div>
        </div>
        <transition name="slide">
          <div v-if="edit_info" class="row">
            <div class="card-box__content-actions">
              <md-button :class="[deactived_button ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
              <md-button class="md-accent card-box__button-cancel" @click="cancel(!up_to_date ? 'database' : 'browserData')">Cancel</md-button>
              <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </md-card>
</template>

<script>
import Vue from 'vue';
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { caretPhone, formatPhoneNumber } from '../../../mixins/formatNumber'
import { getValidationClass } from '../../../mixins/getValidationClass'
import { resetData } from '../../../mixins/resetData'
import humps from 'humps'

export default {
  name: 'Accountuser',
  props: ['data_owner'],
  data: () => ({
    user: {
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_format: '',
      email: '',
    },
    save_data: null,
    validate_number: false,
    edit_info: false,
    openBox: false,
    update: false,
    deactived_button: true,
    up_to_date: false
  }),
  validations: {
    user: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      phone_number: {
        required,
        minLength: minLength(10)
      },
      email: {
        email,
        required
      }
    }
  },
  methods: {
    getValidationClass,
    editInfo () {
      if ( !this.up_to_date ) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }

      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    caretPhone () {
      let phone = caretPhone(this.user.phone_format,this.user.phone_number)
      this.user.phone_format = phone.phoneFormat
      this.user.phone_number = phone.phoneNumber

      this.validate_number = this.$v.user.phone_number.minLength
    },
    validate () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit () {
    this.update = true;
    this.deactived_button = true;
     Vue.http.put(`/organizations/${this.data_owner.id}/update_owner`, {user: this.user} ,[{responseType: 'json'}])
        .then(response => {
          setTimeout(() => {
            this.update = false;
            this.deactived_button = false;
            this.$emit('show:snackbar', {update: true, alertMsg: 'Your changes were successfully saved.', error: false})
            this.up_to_date = true
            this.edit_info = false
            this.save_data = humps.camelizeKeys(this.user)
          },1000)
        }, err => {
          setTimeout(() => {
            this.update = false;
            this.deactived_button = false;
            this.$emit('show:snackbar', {update: true, alertMsg: err ? err.body.error : 'Something went Wrong', error: true})
            this.up_to_date = false
          },1000)
      });
    },
    cancel (data) {
      this.edit_info = !this.edit_info
      this.deactived_button = true
      if ( data === 'database') {
        this.user = resetData(this.user,this.data_owner)
        this.validate_number = this.user.phone_number?.length === 10 ? true : false
      }
      if ( data === 'browserData') {
        this.user = resetData(this.user,humps.decamelizeKeys(this.save_data,{split: /(?=[A-Z0-9])/ }))
      }
    },
    detectChange() {
      this.deactived_button = false
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    this.user = resetData(this.user,this.data_owner)
    this.validate_number = this.user.phone_number?.length === 10 ? true : false
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="organization-view__menu">
        <menu-towco-profile :showBankAccountSection="showBankAccountSection"></menu-towco-profile>
      </div>
      <div class="organization-view__content js-content-profile">
        <bussiness-info
          :company_data="{
            company_name: organization_data.name,
            company_nickname: organization_data.nickname,
            phone_number: organization_data.contact_phone,
            company_address: organization_data.address,
            location: organization_data.location !== undefined ? organization_data.location : '',
            id: organization_data.id
          }"
          @show:snackbar="showAlert"
        ></bussiness-info>
        <account-owner
          :data_owner="{
            first_name: organization_data.owner !== null ? organization_data.owner.first_name : '',
            last_name: organization_data.owner !== null ? organization_data.owner.last_name : '',
            phone_number: organization_data.owner !== null ? organization_data.owner.phone_number : '',
            email: organization_data.owner !== null ? organization_data.owner.email : '',
            id: organization_data.id
          }"
          @show:snackbar="showAlert"
        ></account-owner>
        <hours-of-operations
          :data_owner="{
            organization_id: organization_data.id,
            day_operations: organization_data.day_operations !== null ? organization_data.day_operations: '',
            organization_timezone: organization_data.sp_time_zone
          }"
          @show:snackbar="showAlert"
        ></hours-of-operations>
        <bank-account
          v-if="showBankAccountSection"
          :company_data="{
            id: organization_data.id
          }"
          :stored_bank_account="stored_bank_account"
          @show:snackbar="showAlert"
        ></bank-account>
        <tax-id
          v-if="showBankAccountSection"
          :company_data="{
            id: organization_data.id
          }"
          @show:snackbar="showAlert"
        ></tax-id>
        <!-- <stripe-onboarding
          v-if="showBankAccountSection"
          :company_data="{
            id: organization_data.id
          }"
          @show:snackbar="showAlert"
        ></stripe-onboarding> -->
        <robocalls
          :company_data="{
            robocall_number: organization_data.robocall_phone,
            notification_settings: organization_data.settings,
            id: organization_data.id
          }"
          @show:snackbar="showAlert"
        ></robocalls>
        <service-area
          :serviceArea="organization_data.service_area !== null ? organization_data.service_area : null"
          :idOrganization="organization_data.id"
          @show:snackbar="showAlert"
        >
        </service-area>
        <fees
          :detail="organization_data.tower_detail !== null ? organization_data.tower_detail : []"
          :id="organization_data.id"
          @show:snackbar="showAlert"
        ></fees>
        <storage-facilities
          :detail="organization_data.tower_detail !== null ? organization_data.tower_detail : [] "
          :tower_data="organization_data.tower_detail !== null ? organization_data.tower_detail.tower_locations : []"
          :id="organization.id"
          @show:snackbar="showAlert"
        ></storage-facilities>
        <!-- <documents
          :documents="organization.tower_detail !== null ? organization.tower_detail.documents_url : []"
        ></documents> -->
      </div>
    </div>
    <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
      <span class="snackbar-notify__text">
        <div class="content-icon">
          <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
        </div>
        {{ this.alertMsg }}
      </span>
    </md-snackbar>
  </div>
</template>

<script>
import MenuTowcoProfile from './menu-towco-profile';

//details
import Robocalls from './details/robocalls';
import BussinessInfo from './details/bussines-info';
import AccountOwner from './details/account-owner';
import HoursOfOperations from './details/hours-of-operations';
import BankAccount from './details/bank-account';
import TaxId from './details/tax-id'
// import StripeOnboarding from './details/stripe-onboarding'
import Fees from './details/fees';
import ServiceArea from './details/service-area';
import StorageFacilities from './details/storage-facilities';
import Documents from './details/documents';

export default {
  name: 'TowcoDetailProfile',
  props: ['organization', 'user_is_admin', 'stored_bank_account'],
  data: () => ({
    position: 'center',
    isInfinity: false,
    duration: 4000,
    update: false,
    alertMsg: '',
    errorSnack: false,
    organization_data: ''
  }),
  components: {
    MenuTowcoProfile,
    Robocalls,
    BussinessInfo,
    AccountOwner,
    HoursOfOperations,
    BankAccount,
    TaxId,
    // StripeOnboarding,
    Fees,
    ServiceArea,
    StorageFacilities,
    Documents,
},
  computed: {
    showBankAccountSection () {
      return this.user_is_admin && this.organization_data.account && this.organization_data.account.stripe_id
    }
  },
  methods: {
    showAlert (opts) {
      this.update = opts.update
      this.alertMsg = opts.alertMsg
      this.errorSnack = opts.error
    },
    resize (width,cardBox,buttonsEdit) {
      if(width >= 993) {
        document.addEventListener('mousewheel', e => {
          cardBox.map(box => box.classList.remove('scroll-with-offset'))
          buttonsEdit.map(button => button.classList.remove('move-top'))
        });
      }
    }
  },
  created () {
    this.organization_data = this.organization
  },
  mounted () {
    let menuWrapper = document.querySelector('.js-menu-wrecker'),
        mainNavLinks = [...document.querySelectorAll(".js-menu-wrecker-link")],
        cardBox = [...document.querySelectorAll(".card-box")],
        buttonsEdit = [...document.querySelectorAll('.js-card-options')];

    menuWrapper.addEventListener('click', e => {
      e.preventDefault();
      if(e.target.tagName === "A") {
        cardBox.map(box => box.classList.remove('scroll-with-offset'))
        mainNavLinks.map(link => link.classList.remove('current'))
        buttonsEdit.map(button => button.classList.remove('move-top'))
        let link = document.querySelector(e.target.hash),
            button = link.querySelector('.js-card-options');
        if(link.className.includes('offset-card')) {
          e.target.classList.add('current')
          link.classList.add('scroll-with-offset')
          button.classList.add('move-top');
        }
        link.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    })

    window.addEventListener('resize', () => {
      this.resize(window.innerWidth,cardBox,buttonsEdit);
    })

    this.resize(window.innerWidth,cardBox,buttonsEdit);

  }
}
</script>

<template>
  <div class="job-info-footer">
    <div class="footerBar position-fixed py-3">
      <fieldset class="row">
        <div class="col-md-10 offset-md-1">

          <!-- Motorist Requests: Quote, Decline -->
          <div v-if="state === 'new' || state === 'open'">
            <md-button class="btn btn-success" :disabled="disabled" @click="startQuote">Quote Job</md-button>
            <md-button class="btn btn-danger" :disabled="disabled" @click="declineQuote">Decline</md-button>
          </div>

          <!-- 911 Requests: Assign Operator, Decline -->
          <div v-if="state === 'direct'">
            <md-button class="btn btn-success" @click="assignDriver">{{ assignLabel }}</md-button>
            <md-button v-if="!isMotorist" class="btn btn-danger" @click="declineAssignment">{{ declineLabel }}</md-button>
          </div>

        </div>
      </fieldset>
    </div>

    <!-- Decline Job: Open Bid -->
    <md-dialog-confirm
      :md-active.sync="decline_confirm_modal"
      md-content="Are you sure you want to decline this job?"
      md-confirm-text="Decline Job"
      md-cancel-text="Cancel"
      @md-cancel="decline_confirm_modal = false"
      @md-confirm="declineQuoteConfirm" />

    <!-- Decline Job: Direct Assignment -->
    <md-dialog :md-active.sync="decline_assignment_modal" class="decline-dialog">
      <div class="pb-2 decline-assignment-modal">
        <md-dialog-content class="pb-0">
          <div class="row">
            <div class="col-md-12">
              <h3 class="mb-0 pb-0">Decline assignment</h3>
              <p class="caption faded-1" v-if="request.job.assignments.length > 0">Please provide a reason for decline reason below:</p>
              <h5>Select a reason</h5>
              <div class="drivers bg-white">
                <md-list class="mb-2 pt-0 pb-0">
                  <div class="driver-list-item border-bottom" v-for="(reason, index) in decline_reasons" :key="index">
                    <md-list-item>
                      <div class="md-list-item-text py-3">
                        <span class="body">{{ reason }}</span>
                      </div>
                      <div class="md-list-action">
                        <md-radio v-model="decline.reason" :value="reason"></md-radio>
                      </div>
                    </md-list-item>
                    <div v-if="reason === 'Other' && decline.reason === 'Other'">
                      <div class="text-center">
                        <md-textarea v-model="decline.comment" 
                                     placeholder="Add reason for declining this assignment"
                                     class="pa-2 decline-reason-text"
                                     style="padding: .5em"
                                     maxlength="65">
                        </md-textarea>
                        <p class="service-area__select-text pb-2 text-left">{{ remainingCharacters }} characters remaining</p>
                      </div>
                      
                    </div>
                  </div>
                </md-list>
              </div>

            </div>
          </div>
        </md-dialog-content>
      </div>
      <div class="footerBar px-3 py-2">
        <md-button class="btn btn-primary" :disabled="decline.reason == null || (decline.reason == 'Other' && (decline.comment == null || decline.comment == ''))" @click="submitDeclineAssignment()" >Decline</md-button>
        <md-button class="btn btn-secondary" @click="cancelDeclineAssignment()">Cancel</md-button>
      </div>
    </md-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex'

// API Interfaces
import { JobAssignments } from '../../api'

export default {
  props: ['state', 'disabled', 'request'],

  components: {},

  data: () => {
    return {
      complete_confirm_modal: false,
      decline_confirm_modal: false,
      decline_reasons: ['No appropriate equipments available', 'No operators available', 'No equipments available', 'Other'],
      decline: {
        reason: null,
        comment: null
      },
      decline_assignment_modal: false,
    }
  },

  computed: {
    ...mapState('features', {
      features: state => state.features
    }),

    ...mapState('organizations', {
      current: state => state.current
    }),

    status () {
      return this.parsedStatusTower(this.request)
    },
    isMotorist () {
      return this.request.motorist_id
    },
    assignLabel () {
      if (this.request.job.assignments.length > 1) {
        return "Assign operators"
      } else {
        return "Assign operator"
      }
    },
    declineLabel () {
      if (this.request.job.assignments.length > 1) {
        return "Decline assignments"
      } else {
        return "Decline assignment"
      }
    },
    remainingCharacters () {
      if (this.decline.comment) {
        return (65 - this.decline.comment.length)
      } else {
        return "65"
      }
    }
  },

  methods: {
    assignDriver () {
      this.$emit('action', 'assign-driver')
    },
    completeAssignment () {
      this.$emit('action', 'complete-assignment')
    },
    declineQuote () {
      this.decline_confirm_modal = true
    },
    declineQuoteConfirm () {
      this.$emit('action', 'decline-quote')
    },
    startQuote () {
      this.$emit('action', 'start-quote')
    },
    declineAssignment () {
      this.decline_assignment_modal = true
    },
    declineAssignmentConfirm () {
      this.$emit('action', 'decline-direct')
    },
    submitDeclineAssignment () {
      this.request.job.assignments.forEach(assignment => {
        // Get note text
        const noteText = this.decline.reason == "Other" ? this.decline.comment : this.decline.reason;
        // Build payload
        const payload = {
          job_assignment_id: assignment.id,
          note: noteText
        }
        // Make request with payload
        let response = JobAssignments.decline({ id: assignment.id}, payload).then(response => {
          console.log(response);
        })
      })

      // Trigger switch back to dashboard view, create snackbar notification, remove from list
      this.declineAssignmentConfirm();
    },
    cancelDeclineAssignment () {
      // reset decline modal selection
      this.decline.reason = null
      this.decline.comment = null
      // close decline modal
      this.decline_assignment_modal = false
    }
  }
}
</script>

<style scoped lang="scss">
.decline-assignment-modal {
  background: #f7f9fa; // background: $pale-grey;
  padding: 1em;
}
.decline-assignment-options {
  background: #ffffff; 
}
.decline-assignment-option {
  border-bottom: 1px solid #e3e3e3;
  height: 4em;
}
.decline-reason-text {
  width: 95%;
}
.test-center {
  padding: .75em 0;
}

.decline-dialog {
  .md-dialog-container {
    width: 50%
  }
}

</style>

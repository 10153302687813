<template>
  <md-card class="card card-wrecker-detail document-card card-box section js-content-box-documents" id="documents">
    <h2 class="title-card">Documents</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="open">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <div class="card-box__content" v-if="openBox">
      <div class="card-box__content-form js-box-content-form">
        <p class="info-documents">We require the following documents:</p>
        <ul class="info-documents">
          <li>Valid Liability Certificates.</li>
          <li>Current copy of the Public Service Commission Letter sent to your agency.</li>
        </ul>
        <ul class="documents">
          <div class="row">
            <li v-for="document in documents" class="documents__item col-md-6">
              <div class="documents__content">
                <div class="documents__content-name">
                  <img class="documents__clip" src="../../../../../images/clip.svg" alt="">
                  <p class="documents__name">{{ document.name }}</p>
                </div>
                <a :href="document.url" target="_blank" class="documents__link">
                <img src="../../../../../images/eye.svg" alt="">
                </a>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <transition name="slide">
        <div class="documents">
          <div v-if="edit_info" class="row">
            <div class="card-box__content-actions">
              <md-button :class="[deactived_button ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="validate">Save</md-button>
              <md-button class="md-accent card-box__button-cancel" @click="cancel">Cancel</md-button>
              <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </md-card>
</template>

<script>
import UploadInput from "../../upload-input";

export default {
  name: 'Documents',
  props: ['documents'],
  data: () => ({
    edit_info: false,
    openBox: false,
    deactived_button: false,
    update: false
  }),
  components: {
    UploadInput
  },
  methods: {
    onSelectFile(index, file) {
      this.files.push(file);
    },
    open () {
      this.edit_info = !this.edit_info;
    },
    cancel () {

    },
    validate () {

    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  }
}
</script>
<template>
  <div>
    <div class="motorist-bar motorist-bar--intro">
      <img class="curbside-icon" src="../../../../images/logo_curbsidesos_dark.png" alt="curbside">
    </div>
    <div class="returning-customer">
      <h2 class="returning-customer__title">What’s your phone number</h2>
      <p class="returning-customer__description">{{description}}</p>
      <form class="returning-customer__form">
        <div class="form-group">
          <md-field :class="getValidationClass(this,'user', 'phoneNumber')">
            <label class="form__label">Phone number</label>
            <md-input v-model="user.phoneFormat" type="tel" class="form-control" ref="phoneNumber" v-on:keyup="parseNumber()"></md-input>
          </md-field>
          <span class="example-number">e.g. "(555) 555- 5555"</span>
          <span
            class="validate-number"
            v-if="!user.validate_number"
            >The Mobile Number must be at least 10 characters long</span>
          <div class="form-group actions">
            <md-button v-if="!isCompletedForm" class="create-account__button btn btn-primary disable-button">Send code</md-button>
            <md-button v-else class="create-account__button btn btn-primary" @click="sendCode">Send code</md-button>
          </div>
        </div>
      </form>

      <md-dialog :md-active.sync="showDialog" class="returning-customer-dialog">
        <md-dialog-content>
          <p class="returning-customer-dialog__title">What's the code?</p>
          <p class="returning-customer-dialog__description">We sent it to "{{ user.phoneFormat }}”</p>
          <md-field :class="getValidationClass(this,'validate', 'code')">
            <label for="code">Code</label>
            <md-input name="code" id="code" :maxlength="6" v-model="validate.code" type="text" v-on:keyup="validateValue()"/>
          </md-field>
          <span
            class="validate-number"
            v-if="!validate.validate_code"
            >The code must be at least 6 characters long</span>
        </md-dialog-content>
        <md-dialog-actions>
            <md-button @click="sendCode">Re-send</md-button>
            <md-button @click="validateCode">Submit</md-button>
        </md-dialog-actions>
      </md-dialog>

      <md-snackbar :class="[errorSnack ? 'snackbar-notify--error' : 'snackbar-notify--success','snackbar-notify snackbar-notify-towco snackbar-notify-driver']" :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="update" md-persistent>
        <span class="snackbar-notify__text">
          <div class="content-icon">
            <i :class="[errorSnack ? 'red' : 'green','material-icons']">check</i>
          </div>
          {{ this.alertMsg }}
        </span>
      </md-snackbar>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  required,
  email,
  minLength
} from 'vuelidate/lib/validators'
import { formatPhoneNumber, caretPhone } from '../../mixins/formatNumber'
import { getValidationClass } from '../../mixins/getValidationClass';

export default {
  name: 'customerMotorist',
  data: () => ({
    user: {
      phoneNumber: '',
      phoneFormat: '',
      validate_number: true
    },
    validate: {
      code: '',
      validate_code: true
    },
    showDialog: false,
    errorSnack: false,
    alertMsg: '',
    position: 'left',
    duration: 4000,
    isInfinity: false,
    update: false,
    description: 'We’ll text you a code to verify it’s you.'
  }),
  computed: {
    isCompletedForm () {
      return this.user.phoneNumber && this.user.validate_number;
    }
  },
  validations: {
    user: {
      phoneNumber: {
        required,
        minLength: minLength(10)
      }
    },
    validate: {
      code: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    getValidationClass,
    focus() {
      this.$refs.phoneNumber.$el.focus();
    },
    show() {
      this.display = true;
    },
    nextTickFocus() {
      this.show();
      this.$nextTick(this.focus);
    },
    parseNumber () {
      let val = caretPhone(this.user.phoneFormat,this.user.phoneNumber);
      this.user.phoneNumber = val.phoneNumber;
      this.user.phoneFormat = val.phoneFormat;
      this.user.validate_number = this.$v.user.phoneNumber.minLength;
    },
    sendCode () {
      this.$v.$touch()
      if (!this.$v.user.phoneNumber.$invalid) {
        Vue.http.post(`/motorists/send_auth_code`, { phone_number: this.user.phoneNumber } ,[{responseType: 'json'}])
          .then(response => {
            this.update = true
            this.errorSnack = false
            this.alertMsg = response.body.message
            this.showDialog = true;
          }, err => {
            this.errorSnack = true
            this.update = true
            this.alertMsg = err.body.msg
        })
      }
    },
    validateCode () {
      let motoristForm = document.querySelector('.js-motorist-form');
      let fieldPhoneNumber = motoristForm.querySelector('.js-motorist-phone-number');
      let codeMotorist = motoristForm.querySelector('.js-code-motorist');

      fieldPhoneNumber.value = this.user.phoneNumber
      codeMotorist.value = this.validate.code
      
      if ( !this.$v.validate.code.$invalid ) {
        motoristForm.submit()
      }
    },
    validateValue () {
      if (!isNaN(this.validate.code)) {
        this.validate.code = this.validate.code
      } else {
        this.validate.code = ''
      }

      if ( this.validate.code.length < 6 ) {
        this.validate.validate_code = false
      } else {
        this.validate.validate_code = true
      }
    }
  },
  created () {
    let urlParams = new URLSearchParams(window.location.search);
    let errorCode = urlParams.get('error_code');
    if ( errorCode === '3' ) {
      this.errorSnack = true
      this.update = true
      this.alertMsg = 'Incorrect code'
      setTimeout(() => {
        this.update = false
      },2000)
    }
    if ( errorCode === '1' ) {
      this.description = 'We noticed that you’ve already gone through registration. We’ll text you a code to verify it’s you.'
    }
    let navbar = document.getElementById('vue-navbar');
    navbar.remove();
  },
  mounted () {
    this.nextTickFocus();
  }
}
</script>

<style lang="scss" scoped>
  .validate-number {
    display: block;
    margin-top: 3px;
  }
  .md-count {
    display: none !important;
  }
</style>
<template>
  <div class="organization-view">
    <organization-banner
      :name="organization_data.name"
      :address="organization_data.address"
      :contact_phone="organization_data.contact_phone"
      :active="organization_data.active"
      :id="organization_data.id"
    >
    ></organization-banner>
    <div class="organization-detail">
      <wrecker-detail-profile :organization="organization_data"></wrecker-detail-profile>
    </div>
  </div>
</template>

<script>
import OrganizationBanner from './organization-banner';
import WreckerDetailProfile from './wrecker-profile-details';

export default {
  name: 'OrganizationView',
  props: ['organization_data'],
  components: {
    OrganizationBanner,
    WreckerDetailProfile
  },
  mounted () {
    window.addEventListener("scroll", event => {
      let banner = document.querySelector('.js-organization-banner'),
          menu = document.querySelector('.js-menu-wrecker'),
          bannerHeight = banner.clientHeight;
      
      if(window.scrollY >= bannerHeight) {
        menu.classList.add('banner-sticky')
      } else {
        menu.classList.remove('banner-sticky')
      }
    });
  }
}
</script>
<template>
  <div :class="[fit ? 'fit' : 'no-fit']">
    <md-button :disabled="isCompleteForm !== undefined ? !isCompleteForm : false" class="md-raised md-accent" @click="next" v-bind:style="{ backgroundColor: color }">{{ title }}</md-button>
  </div>
</template>

<script>
export default {
  props: ['title', 'fit', 'color', 'isCompleteForm'],
  methods: {
    next () {
      this.$emit('next')
    }
  }
}
</script>

<style scoped lang="scss">
.fit {
  display: flex;
  min-width: 90%;
  button {
    width: 100%;
    font-weight: bold;
  }
}
.no-fit {
  width: 277px;
  button {
    width: 100%;
    font-weight: bold;
  }
}

.full-btn {
  flex: 1;
}
</style>

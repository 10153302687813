export const hoursOperation = [
  {
    id: 0,
    day_of_week: "monday",
    business_status: "always_open",
    start_hour: "",
    end_hour: "",
    edit_hours: false,
    selectedOption: {},
    options: [
      {
        value: "always_open",
        label: "Open 24 hours"
      },
      {
        value: "open",
        label: "Set business hours"
      },
      {
        value: "closed",
        label: "Closed"
      },
    ],
  },
  {
    id: 1,
    day_of_week: "tuesday",
    selected: "",
    business_status: "always_open",
    selectedOption: {},
    start_hour: "",
    end_hour: "",
    edit_hours: false,
    options: [
      {
        value: "always_open",
      },
      {
        value: "open",
      },
      {
        value: "closed",
      },
    ],
  },
  {
    id: 2,
    day_of_week: "wednesday",
    selectedOption: {},
    selected: "",
    business_status: "always_open",
    start_hour: "",
    edit_hours: false,
    end_hour: "",
    options: [
      {
        value: "always_open",
      },
      {
        value: "open",
      },
      {
        value: "closed",
      },
    ],
  },
  {
    id: 3,
    day_of_week: "thursday",
    selectedOption: {},
    selected: "",
    business_status: "always_open",
    start_hour: "",
    end_hour: "",
    edit_hours: false,
    options: [
      {
        value: "always_open",
      },
      {
        value: "open",
      },
      {
        value: "closed",
      },
    ],
  },
  {
    id: 4,
    day_of_week: "friday",
    selectedOption: {},
    selected: "always_open",
    business_status: "always_open",
    start_hour: "",
    end_hour: "",
    edit_hours: false,
    options: [
      {
        value: "always_open",
      },
      {
        value: "open",
      },
      {
        value: "closed",
      },
    ],
  },
  {
    id: 5,
    day_of_week: "saturday",
    selectedOption: {},
    selected: "always_open",
    business_status: "always_open",
    start_hour: "",
    end_hour: "",
    edit_hours: false,
    options: [
      {
        value: "always_open",
      },
      {
        value: "open",
      },
      {
        value: "closed",
      },
    ],
  },
  {
    id: 6,
    day_of_week: "sunday",
    selectedOption: {},
    selected: "",
    business_status: "always_open",
    start_hour: "",
    end_hour: "",
    edit_hours: false,
    options: [
      {
        value: "always_open",
      },
      {
        value: "open",
      },
      {
        value: "closed",
      },
    ],
  },
];

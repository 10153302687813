import * as API from '../../../api';

export default {
  async getActiveDrivers ({ commit }) {
    API.Drivers.active().then(response => {
      commit('setDrivers', response.body)
    })
  },

  async availableDrivers ({ commit }, jobAssignmentId) {
    return new Promise((resolve, reject) => {
      API.JobAssignments.availableDrivers({ id: jobAssignmentId })
        .then(response => {
          commit('setPingedDrivers', response.body)
          resolve()
        })
    })
  },

  async pingDrivers ({ commit }, organization_id) {
    return new Promise((resolve, reject) => {
      API.Organizations.update_drivers({ id: organization_id })
        .then(response => {
          commit('setPingedDrivers', response.body)
          resolve()
        })
    })
  },
  clearPingedDrivers ({ commit }) {
    commit('setPingedDrivers', null)
  },
  CABLE_updateDriver ({ commit }, driver) {
    commit('updateDriver', driver)
  }
}

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  sent: [],
  received: [],
  quotes: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters, 
  mutations
};

<template>
  <div class="driver-item">
    <DriverWidget 
      :driver="assignment.driver" 
      :assignmentStatus="assignment.status" 
      :canReassignDriver="canReassignDriver" 
      :eta="assignment.eta"
      @reassign="reassign_modal_active = true"
    />

    <!-- Re-Assign Driver Modal -->
    <md-dialog 
      class="re-assign-driver-dialog" 
      :md-active.sync="reassign_modal_active" 
      :md-fullscreen="false" 
      @md-opened="refreshDriverLocations"
    >
      <md-dialog-content>
        <!-- Header -->
        <h3 class="re-assign-driver-dialog__title">Operators</h3>
        <div class="re-assign-driver-dialog__description-wrapper">
          <p class="re-assign-driver-dialog__description">Assign from a list of available operators.</p>
          <md-button 
            class="md-icon-button md-primary re-assign-driver-dialog__refresh-button" 
            :md-ripple="false" 
            :disabled="state.refreshing" 
            @click="refreshDriverLocations" 
          >
            <md-icon>refresh</md-icon>
          </md-button>
        </div>

        <!-- Driver List -->
        <div class="re-assign-driver-dialog__driver-select-list">
          <SelectDriver 
            :drivers="drivers_with_etas"
            :selected_drivers="selected_driver_id" 
            :selectable_drivers_count="1" 
            :support_multiple_options="false"
            :disable_inactives="false"
            @select-driver="selectDriver" 
          />
        </div>

        <div class="locating-operators" v-if="state.refreshing">
          <p class="caption">Locating operators...</p>
          <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>

        <!-- Actions -->
        <div class="re-assign-driver-dialog__actions">
          <md-button 
            class="md-raised md-primary re-assign-driver-dialog__re-assign-button" 
            :disabled="reassignDisabled"
            @click.prevent="submitReassign"
          >
            Re-Assign
          </md-button>
          <md-button class="md-primary" @click="reassign_modal_active = false">
            Cancel
          </md-button>
        </div>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Mixins
import availableDrivers from '../../mixins/available-drivers';

// Components
import Avatar from './driver-avatar'
import SelectDriver from './select-driver.vue';
import DriverWidget from './driver-widget.vue';

// API Interfaces
import { Drivers } from '../../api';

export default {
  props: ['request', 'assignment', 'markedComplete'],

  components: {
    Avatar,
    SelectDriver,
    DriverWidget
  },

  mixins: [availableDrivers],

  data() {
    return {
      reassign_modal_active: false,
      state: {
        loading: false,
        refreshing: false
      },
      drivers_with_etas: null,
      selected_driver: null,
      selected_driver_id: this.assignment.driver ? this.assignment.driver.id : null
    }
  },

  computed: {
    ...mapState('features', {
      features: state => state.features
    }),

    selectedDrivers () {
      return [this.assignment.driver]
    },

    /**
     * Disable "reassign" submit button if no driver selected,
     * if selected driver is same as original driver.
     */
    reassignDisabled () {
      if (this.selected_driver === null) return true;
      
      return (this.selected_driver.id === this.assignment.driver.id)
    },

    canReassignDriver() {
      const type = this.assignment.assign_type;
      const status = this.assignment.status;
      const { reassign_driver_motorist } = this.features;

      // "type" requirements for 911 job assignment methods, feature flag for motorist jobs
      const requester_approved = (reassign_driver_motorist || type === 'direct' || type === 'rotation')

      return (
        !this.markedComplete && 
        requester_approved &&
        (status === 'notified' || status === 'started') && 
        (this.features && this.features.reassign_driver)
      );
    }
  },

  methods: {
    /**
     * Firstly We sort the drivers alfabetically, next by the closest ETA 
     * and finally place the current assigned in the top of the list.
     */
    sortDrivers (drivers) {
      const selectedDriver = this.selectedDrivers ? this.selectedDrivers[0] : null;

      const sortedDrivers = drivers.sort((nextDriver, prevDriver) => (
        nextDriver.attributes.last.localeCompare(prevDriver.attributes.last)
      )).sort((nextDriver, prevDriver) => {
        const nextEta = nextDriver.attributes.duration_in_seconds != null ? nextDriver.attributes.duration_in_seconds : Infinity;
        const prevEta = prevDriver.attributes.duration_in_seconds != null ? prevDriver.attributes.duration_in_seconds : Infinity;

        return nextEta - prevEta;
      }).sort((nextDriver, prevDriver) => {
        if (selectedDriver) {
          return nextDriver.id === selectedDriver.id ? -1 : prevDriver.id === selectedDriver.id ? 1 : 0;
        }

        return 0;
      });

      return sortedDrivers;
    },

    refreshDriverLocations () {
      this.state.refreshing = true;
      this.drivers_with_etas = null;
      
      Promise.all([
        this.getAvailableDrivers(this.assignment.id),
        this.delay(1000)
      ]).then(() => {
        this.state.refreshing = false
      }).catch(() => {
        this.drivers_with_etas = [];
      });
    },

    selectDriver (id) {
      const driver = this.drivers_with_etas.find(driver => driver.id === id);
      this.selected_driver = driver;
      this.selected_driver_id = driver.id;
    },
    
    async submitReassign () {
      // Build the payload with the new selected driver
      const payload = {
        job_assignment_id: this.assignment.id,
        driver_id: this.selected_driver.id
      }

      // / Unassign current driver, assign new driver
      let response = await Drivers.reassign(payload)

      // Submit "back" to Tower Dashboard, refreshing list in the process
      this.delay(2000).then(
        this.$emit('back')
      )
    }
  }
}
</script>

<style scoped lang="scss">
  .driver-item {
    .re-assign {
      display: inline-block;
      color: #0092ff !important;

      &:hover {
        text-decoration: none;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-left: 4px;
        margin-right: 4px;
        vertical-align: baseline;
        background-color: #9b9b9b;
        border-radius: 50%;
      }
    }

    &__driver-avatar-wrapper, &__driver-info-wrapper {
      display: inline-block;
      vertical-align: top;
    }
  }

  .re-assign-driver-dialog {
    .md-dialog-container {
      max-width: 550px;
      width: 100% !important;
      margin: 0 10px;
      background-color: #f7f9fa;
      border-radius: 4px;
    }

    .md-dialog-content {
      padding: 24px 35px;
      overflow: visible;
    }

    &__title {
      margin-bottom: 0;
      color: #0f3246;
    }

    &__description-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
    }

    &__description {
      color: rgba(15, 50, 70, .65);
      margin: 0;
    }

    &__refresh-button.md-button {
      min-width: 24px;
      width: 24px;
      height: 24px;
      font-weight: normal;
    }

    &__refresh-button.md-button .md-ripple {
      padding: 0 8px;
    }

    &__driver-select-list {
      max-height: 300px;
    }

    &__re-assign-button {
      margin-left: 0;
      box-shadow: none;
      border-radius: 4px;

      &:disabled {
        background-color: rgba(15, 50, 70, 0.20);

        .md-button-content {
          color: rgba(0, 0, 0, .38);
        }
      }
    }

    .locating-operators {
      margin-top: 8px;
    }
  }
</style>

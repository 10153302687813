<template>
  <md-autocomplete
    v-model="query"
    :md-options="suggestions"
    @md-changed="getAutocompleteOptions"
    @md-selected="selectSuggestion"
    @md-closed="getQuery"
    ref="locationInput"
    md-dense
    tabindex="3">

    <label><md-icon :class="[changeColor ? 'color-blue' : '']">location_on</md-icon>{{ label || 'Enter a location...'}}</label>
    <template slot="md-autocomplete-item" slot-scope="{ item }">
      {{ item.primary }}, {{ item.secondary }}
    </template>
    <span v-if="helper" class="md-helper-text">e.g. M-104 / N Fruitport Rd</span>
  </md-autocomplete>
</template>

<script>
// Third Party Services
import { Here } from '../../../services/here'
import errorClass from '../../../../../javascripts/error_class'

const levenshtein = require('fast-levenshtein')

export default {
  props: {
    telemetry_environment: String,
    selection: Object,
    external_query: String,
    organization_id: String,
    organization_location: {
      lat: Number,
      lng: Number,
    },
    label: String,
    helper: Boolean,
    newField: Boolean,
    changeColor: Boolean
  },

  data () {
    return {
      query: this.external_query,
      hereAutocomplete: null,
      here_results: null,
      telemetry_results: null,
      geocoded_selection: null,
      reverse_geocoded_selection: null,
      suggestions: [],
      here_suggestions: [],
      telemetry_suggestions: [],
      no_results: []
    }
  },

  computed: {
    parsedQuery () {
      return this.query.replace("/", "&")
    },
    // Short term fix
    hereParams () {
      if (this.organization_location) {
        return {
          latitude: this.organization_location.lat,
          longitude: this.organization_location.lng
        }
      } else {
        return {
          latitude: 42.938030,
          longitude: -86.083682
        }
      }
    },
    telemetryParams () {
      return {
        query: this.query,
        // CSOS-2062 Use server side runtime config
        token: CSOS_RT_CONF.TELEMETRY_TOKEN,
        curbsidesos_uuid: this.organization_id
      }
    },
    telemetryURL () {
      // CSOS-2062 Use server side runtime config
      return CSOS_RT_CONF.TELEMETRY_URL + "/points.json?" + new URLSearchParams(this.telemetryParams).toString()
    },
    mergedAutocompleteSuggestions () {
      return this.telemetry_suggestions.concat(this.here_suggestions)
    }
  },

  watch: {
    external_query () {
      this.query = this.external_query
    }
  },

  methods: {
    initializeHere () {
      if (!this.hereAutocomplete) {
        this.hereAutocomplete = new Here(this.hereParams)
      }
    },
    getAutocompleteOptions () {
      this.suggestions = new Promise((resolve) => {
        Promise.all([this.getHereSuggestions(), this.getTelemetrySuggestions()])
          .then(() => {
            const needle = this.query.trim().toLowerCase();
            let suggestions = this.mergedAutocompleteSuggestions;

            const sortedDistances = suggestions.map((suggestion, index) => {
              let haystack = suggestion.primary ? suggestion.primary : '';
              haystack = (haystack && suggestion.secondary) ? haystack + ', ' + suggestion.secondary : '';
              haystack = haystack.trim().toLowerCase();

              return {
                originalIndex: index,
                value: levenshtein.get(needle, haystack)
              }
            }).sort((a, b) => a.value - b.value);

            const sortedSuggestions = [];

            sortedDistances.forEach((sortedDistance) => {
              sortedSuggestions.push(suggestions[sortedDistance.originalIndex]);
            });

            return resolve(sortedSuggestions);
          });
      });
    },
    getHereSuggestions () {
      return new Promise(async (resolve) => {
        if (this.query.length > 0) {
          this.here_results = await this.hereAutocomplete.autocomplete(this.parsedQuery)
          if (this.here_results.data.items !==undefined &&  this.here_results.data.items.length > 0) {
            this.here_suggestions = this.here_results.data.items.map(s => {
              return this.formattedSuggestion(s)
            })
            resolve()
          } else {
            resolve(this.no_results)
          }
        } else {
          resolve([])
        }
      })
    },
    selectSuggestion (suggestion) {
      switch (suggestion.source) {
        case 'here':
          this.selectHereSuggestion(suggestion)
          break;
        case 'telemetry':
          this.selectTelemetrySuggestion(suggestion)
          break;
      }
    },
    async selectHereSuggestion (suggestion) {
      const place = await this.hereAutocomplete.geocode(suggestion.id)
      this.geocoded_selection = place.data
      this.geocoded_selection['address_primary'] = suggestion.primary
      this.geocoded_selection['address_secondary'] = suggestion.secondary
      this.geocoded_selection['addressable'] = {
        line_1: suggestion.primary,
        line_2: suggestion.secondary,
        district: this.geocoded_selection.address.district,
        city: this.geocoded_selection.address.city,
        state: this.geocoded_selection.address.stateCode,
        zip: this.geocoded_selection.address.postalCode,
        country: this.geocoded_selection.address.countryCode,
        location_type: this.geocoded_selection.resultType,
        latitude: this.geocoded_selection.position.lat,
        longitude: this.geocoded_selection.position.lng
      }
      this.$emit('update:selection', this.geocoded_selection)

      this.query = `${suggestion.primary}, ${suggestion.secondary}`

      if(this.newField) {
        this.query = ''
      }
    },
    formattedSuggestion (suggestion) {
      const formatted_suggestion = this.formattedHereLocation(suggestion)


      //little fun fix to here.com's suggestion things so that we get state in a format we want it.
      if (suggestion.address != undefined) {
        suggestion.address.state = suggestion.address.stateCode
      }

      // This is the format needed for VueMaterial's Autocomplete component --
      // It doesn't natively support objects for some reason, so we have to
      // spoof some of these methods
      return {
        source: 'here',
        type: suggestion.resultType,
        primary: formatted_suggestion.primary,
        secondary: formatted_suggestion.secondary,
        address: suggestion.address,
        id: suggestion.id,
        "toLowerCase": () => formatted_suggestion.primary.toLowerCase(),
        "toString": () => formatted_suggestion.primary,
      }
    },
    getQuery () {
      this.$emit('update:queryinfo', this.query)
    },
    getTelemetrySuggestions () {
      fetch(this.telemetryURL)
        .then(response => response.json())
        .then(data => {
          this.telemetry_results = data
          this.telemetry_suggestions = data.map(s => {
            return this.formattedTelemetrySuggestion(s)
          })
        })
    },
    formattedTelemetrySuggestion (suggestion) {
      return {
        source: 'telemetry',
        primary: suggestion.name,
        secondary: suggestion.properties,
        address: suggestion.name,
        lat: suggestion.lat,
        lon: suggestion.lon,
        "toLowerCase": () => name.toLowerCase(),
        "toString": () => name,
      }
    },
    async selectTelemetrySuggestion (suggestion) {
      const place = await this.hereAutocomplete.reverseGeocode(suggestion.lat, suggestion.lon)
      this.geocoded_selection = place.data.items[0]
      this.geocoded_selection['address_primary'] = suggestion.primary
      this.geocoded_selection['addressable'] = {
        line_1: suggestion.primary,
        line_2: suggestion.secondary,
        district: this.geocoded_selection.address.district,
        city: this.geocoded_selection.address.city,
        state: this.geocoded_selection.address.stateCode,
        zip: this.geocoded_selection.address.postalCode,
        country: this.geocoded_selection.address.countryName,
        location_type: this.geocoded_selection.resultType,
        latitude: this.geocoded_selection.position.lat,
        longitude: this.geocoded_selection.position.lng,
        location_type: 'point'
      }

      this.$emit('update:selection', this.geocoded_selection)
      this.query = `${suggestion.primary}`
      if(this.newField) {
        this.query = ''
      }
    },
  },

  async created () {
    this.initializeHere()
    if (this.selection) {
      this.query = `${this.selection.address_primary}`
    }
    this.getValidationClass = errorClass.getValidationClass;
  }
}
</script>

<style scoped>
  .md-menu-content {
    z-index: 15;
    background-color: inherit;
  }

  .color-blue {
    color: rgba(15, 50, 70, 0.87) !important;
  }
</style>

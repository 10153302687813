import { sortByAlphabetical } from '../../helpers';

export default {
  orgLocation (state) {
    return {
      lat: state.current.latitude,
      lng: state.current.longitude
    }
  },
  dispatchersAlphabetical: sortByAlphabetical('dispatchers', 'name'),
  policeDepartmentsAlphabetical: sortByAlphabetical('police_departments', 'prefix'),
  towersAlphabetical: sortByAlphabetical('towers', 'name'),
  impoundTowers: state => (state.towers.filter(tower => tower.impound_tower === true))
}

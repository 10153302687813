import Vue from 'vue';
import { set } from '../../helpers';

export default {
  setCurrentOrganization: set('current'),
  setDispatchers: set('dispatchers'),
  setPoliceDepartments: set('police_departments'),
  setTowers: set('towers'),
  setOperationHours: set('operationHours')
}

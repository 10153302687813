import { formatPhoneNumber } from './formatNumber';

export const resetData = (inititalObject,finalObject,cb) => {
  let newData = {
    ...inititalObject,
    ...finalObject
  }

  if ( newData.phone_format !== undefined ) {
    newData.phone_format = formatPhoneNumber(newData.phone_number)
  }

  if ( newData.robocall_phone_format !== undefined ) {
    newData.robocall_phone_format = formatPhoneNumber(newData.robocall_number)
  }

  if ( cb ) {
    cb(newData)
  } else {
    return newData
  }
}
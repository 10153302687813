<template>
  <div class="job-detail">
    <div class="job-info">
      <div class="row pl-3">
        <div class="col-9">
          <h5 class="mb-0" v-if="job.address">{{ job.address }}</h5>
          <h5 class="mb-0" v-else>{{ job.intersection }}</h5>
          <p class="faded-1"><span class="emergency" v-if="job.emergency">EMERGENCY, </span>{{ job.vehicles.length }} Vehicles, {{ job.incident_type }}</p>
        </div>
        <div class="col-3 text-right faded-1">
          <Timeago :date="job.created_at" /><br />
          <StatusBadge :status="status" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import StatusBadge from '../utilities/status-badge'
import Timeago from '../utilities/timeago'

export default {
  props: ['job', 'status'],

  components: {
    StatusBadge,
    Timeago
  },
}
</script>

<style scoped lang="scss">
.emergency {
  color: #ef585a !important;
}
</style>

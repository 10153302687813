<template>
  <md-card class="card account-owner card-wrecker-detail card-box-profile card-box offset-card section js-content-box" id="tax-id">
    <h2 class="title-card">Tax ID</h2>
    <div class="card-edit-options js-card-options">
      <button class="card-edit-button" @click="editInfo">
        <img src="../../../../../images/edit-icon.svg" alt="edit icon">
      </button>
      <img @click="slideToggle" :class="[openBox ? 'rotate-arrow' : '','arrow js-arrow']" src="../../../../../images/arrow-down.svg" alt="arrow-down">
    </div>
    <transition name="slide">
      <div class="card-box__content" v-if="openBox">
        <div class="card-box__content-form js-box-content-form">
          <p class="caption">
            If you use your Social Security Number for business tax purposes, you may enter that instead.
          </p>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <!-- Tax ID -->
              <div v-if="!edit_info" class="form-group">
                <div class="field">
                  <label class="form__label"/>
                </div>
              </div>
              <div v-else class="">
                <div class="form-group">
                  <md-field :class="getValidationClass(this,'tax_id')">
                    <label class="form__label">Employer Identification Number (EIN)</label>
                    <md-input id="tax_id"
                              name="tax_id"
                              class="form-control"
                              type="numeric"
                              :value="taxIdFormat"
                              v-imask="mask"
                              @accept="taxIdChange"
                              maxlength="10"
                    />
                  </md-field>
                </div>
              </div>
              <div v-if="!edit_info" class="form-group"/>
              <div v-else class="form-group"/>
            </div>
          </div>
          <transition name="slide">
            <div v-if="edit_info" class="row">
              <div class="card-box__content-actions">
                <md-button :class="[!submitActive ? 'deactive-button' : '','md-raised md-primary card-box__button-save']" @click="submit">Save</md-button>
                <md-button class="md-accent card-box__button-cancel" @click="cancel(!up_to_date ? 'database' : 'browserData')">Cancel</md-button>
                <svg :class="[this.update ? 'spinner-show ' : '', 'spinner spinner--orange']" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </md-card>
</template>

<script>
import IMask from 'imask';
import Vue from 'vue'
import Stripe from '@/shared/mixins/stripe';
import { IMaskDirective } from "vue-imask";
import { resetData } from '../../../mixins/resetData'
import { getValidationClass } from '../../../mixins/getValidationClass'

const mask = {
  mask: '00-0000000',
  unmask: true
}
const masked = IMask.createMask(mask)

export default {
  name: 'TaxId',
  mixins: [Stripe],
  props: ['company_data'],
  components: {},
  directives: {
    imask: IMaskDirective
  },
  data () {
    return {
      tax_id: this.tax_id,
      taxIdFormat: '',
      data_save: null,
      validateNumber: false,
      edit_info: false,
      openBox: false,
      update: false,
      deactivedButton: true,
      up_to_date: false,
      mask: {
        mask: "00-0000000",
        lazy: true
      }
    }
  },
  validations: {
    tax_id: {
      minLength: (10),
      maxLength: (10)
    }
  },
  computed: {
    submitActive () {
      if (this.tax_id) {
        return this.tax_id.length >= 9
      } else {
        return false
      }
    },
  },
  methods: {
    /**
     * Load stripe and get the client secret of the payment intent.
     */
    initialize() {
      this.loadstripe()
        .then((stripe) => {
          // Loading Stripe
        });
    },
    getValidationClass,
    editInfo () {
      if ( !this.up_to_date ) {
        this.cancel('database')
      } else {
        this.cancel('browserData')
      }
      if(window.innerWidth < 993) {
        this.openBox = true;
      }
    },
    submit () {
      this.update = true;
      this.deactivedButton = true;

      let tax_id_params = {
        tax_id: this.taxIdFormat
      }
      Vue.http.post(`/organizations/${this.company_data.id}/tax_id`, tax_id_params)
      .then(response => {
        setTimeout(() => {
          this.update = false;
          this.deactivedButton = true;
          this.$emit('show:snackbar', {update: true, alertMsg: 'Your information was successfully submitted.', error: false});
          this.up_to_date = true;
          this.edit_info = false;
          this.taxIdFormat = '';
          this.tax_id = '';
        }, 1000);
      })
      .catch(error => {
        this.update = false;
        this.deactivedButton = false;
        this.$emit('show:snackbar', {update: true, alertMsg: error.body.message, error: true});
      });
    },
    cancel (data) {
      this.edit_info = !this.edit_info;
      this.deactivedButton = true
      if ( data === 'database' ) {
        this.tax_id = ''
        this.taxIdFormat = ''
      }
      if ( data === 'browserData') {
        this.tax_id = ''
        this.taxIdFormat = ''
      }
    },
    taxIdChange(e) {
      const maskRef = e.detail;

      this.tax_id = maskRef.unmaskedValue;
      this.taxIdFormat = maskRef.value;
    },
    slideToggle () {
      this.openBox = !this.openBox;
    },
    resize (width) {
      if(width > 993) {
        this.openBox = true
      }
    }
  },
  created () {
    this.company = resetData(this.company,this.company_data)
    this.loadstripe()
        .then((stripe) => {
          // console.log('stripe: ', stripe);
        });
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.resize(window.innerWidth);
    })

    this.resize(window.innerWidth);
  },
}
</script>

<style lang="scss" scoped>
  .show {
    opacity: 1;
  }
  .hide {
    opacity: 0;
  }
</style>

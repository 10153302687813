import HTTPClient from '../api/jwt';

export default {
  data() {
    return {
      drivers_with_etas: null
    }
  },

  methods: {
    getAvailableDrivers(assignmentId) {
      return HTTPClient.get(`/job_assignments/${assignmentId}/driver_locations`)
        .then(response => {
          const sortedDrivers = this.sortDrivers(response.data.data);
          this.drivers_with_etas = this.formatDriversWithETAs(sortedDrivers);
        }).catch(error => {
          console.log(error);
        });
    },

    formatDriversWithETAs(drivers) {
      return drivers.map(driver => ({
        id: driver.id,
        first: driver.attributes.first,
        last: driver.attributes.last,
        vehicleId: driver.attributes.preferred_vehicle_id,
        make: driver.attributes.make,
        model: driver.attributes.model,
        eta: {
          status: 'OK',
          duration: {
            value: driver.attributes.duration_in_seconds,
            text: driver.attributes.duration_text
          }
        }
      }));
    }
  }
};

<template>
  <span>
    <span v-if="!hide_badge" class="badge" :class="`badge-${badge}`">
      {{ text ? text : message }}
    </span>
    <span v-else style="color: #6F7172; text-transform: capitalize;">
      {{ text ? text : message }}
    </span>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['status', 'dashboard', 'text', 'hide_badge'],

  computed: {
    ...mapState('organizations', {
      current_organization: state => state.current
    }),

    badge () {
      switch (this.status) {
        case 'completed':
          return 'dark'
        case 'in_progress':
          return 'success'
        case 'new':
        case 'open':
        case 'direct':
        case 'assigned':
          return 'warning'
        case 'outbid':
        case 'unresolved':
        case 'unassigned':
        case 'declined':
          if (this.current_organization.org_type == 'dispatcher') return 'danger'
          if (this.current_organization.org_type == 'tower') return 'default'
          else return 'danger'
        default:
          return 'default'
      }
    },

    message () {
      switch (this.status) {
        case 'assigned':
          return 'Assign Operator'
        case 'in_progress':
          return 'In Progress'
        case 'direct':
          return 'Assigned'
        case 'outbid':
          return 'Declined'
        default:
          return this.status
      }
    }
  }
}
</script>

<style lang="scss">
  .badge-default {
    background-color: rgba(15, 50, 70, .35) !important;
  }
</style>

<template>
  <div class="assign-driver">
    <md-list class="mb-4 pt-0 pb-0">
      <md-list-item class="equip-list-item border-bottom py-0" @click="toggleCheck('no-preference')">
        <div class="md-list-item-text py-3">
          <p class="mb-0">No preference</p>
          <p class="caption faded-1 mb-0">No vehicles requiring a flatbed will be assigned.</p>
        </div>
      </md-list-item>
      <md-list-item
        class="equip-list-item border-bottom py-0 mt-0"
        v-for="vehicle in incident_vehicles"
        :key="vehicle.id"
        @click="toggleCheck(vehicle)">
        <div class="md-list-item-text py-3">
          <VehicleDetail :vehicle="vehicle" :source="'towco'" />
        </div>
      </md-list-item>

    </md-list>
  </div>
</template>

<script>
import VehicleDetail from '../../vehicles/vehicle-detail'
export default {
  props: ['driver', 'equipment', 'incident_vehicles'],

  data: () => {
    return {
      view_all: false,
      selected_vehicle: null,
    }
  },

  components: {
    VehicleDetail
  },

  computed: {},

  methods: {
    toggleCheck (preference) {
      this.selected_preference = preference
      setTimeout(() => this.$emit('select-preference', preference), 500);
    },
  },
}
</script>

<style scoped lang="scss">
</style>

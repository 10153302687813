<template>
  <div>
    <md-app>
      <md-app-toolbar class="title-content">
        <img src="../../../images/logo_curbsidesos_dark.png" alt="curbside sos" class="logo" />
      </md-app-toolbar>

      <md-app-content class="form-wizard-content">
        <Home
          v-if="activeStep === 'home'"
          :onboardingStep="onboardingStep"
          @next="goNext(onboardingStep.step)"
        />

        <RegisterThankYou
          v-else-if="activeStep === 'thank-you'"
        />

        <LightRegistration
          v-else-if="!user_data"
          :onboardingStep="onboardingStep"
          @next="goNext"
          @updateTowerId="updateTowerId"
        />

        <div v-else class="col-md-8 margin-auto">
          <h3 class="stepper-title">Setup</h3>

          <md-steppers md-vertical md-linear :md-active-step.sync="activeStep">
            <BusinessInfo
              :enableFinishLater="enable_finish_later_registration"
              :onboardingStep="onboardingStep"
              :user_data="user_data"
              @next="goNext"
              @updateTowerId="updateTowerId"
            />
            <ServiceArea
              @next="goNext"
              :enableFinishLater="enable_finish_later_registration"
              :onboardingStep="onboardingStep"
              :user_data="user_data"
              :organization_id="tower_id"
            />
            <Driver
              @next="goNext"
              :enableFinishLater="enable_finish_later_registration"
              :onboardingStep="onboardingStep"
              :user_data="user_data"
              :driversArray="drivers"
            />
            <Vehicle
              @next="goNext"
              :enableFinishLater="enable_finish_later_registration"
              :onboardingStep="onboardingStep"
              :vehiclesArray="vehicles"
            />
            <ServiceFee
              @next="goNext"
              :enableFinishLater="enable_finish_later_registration"
              :onboardingStep="onboardingStep"
            />
          </md-steppers>

          <div>
            <p class="onboarding__legal-text">©{{ currentYear }} Curbside SOS Inc.</p>
          </div>
        </div>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
// Import components
import Home from "./form-wizard/home";
import RegisterThankYou from './form-wizard/register-thank-you';

// Step Components
import BankAccount from "./form-wizard/bank-account";
import BusinessInfo from "./form-wizard/business-info";
import Driver from "./form-wizard/driver";
import ServiceArea from "./form-wizard/service-area";
import ServiceFee from "./form-wizard/service-fee";
import Vehicle from "./form-wizard/vehicle";
import LightRegistration from "./form-wizard/light-registration"

export default {
  components: {
    Home,
    RegisterThankYou,
    BankAccount,
    BusinessInfo,
    Driver,
    ServiceArea,
    ServiceFee,
    Vehicle,
    LightRegistration
  },

  props: ["user_data", "drivers", "vehicles", "stored_bank_account", "enable_finish_later_registration"],

  data() {
    return {
      // Step Management
      activeStep: "home",
      onboardingStep: '',
      steps: [
        {
          step: "first",
          done: false,
        },
        {
          step: "second",
          done: false
        },
        {
          step: "third",
          done: false
        },
        {
          step: "fourth",
          done: false
        },
        {
          step: "fifth",
          done: false
        },
        {
          step: "sixth",
          done: false
        }
      ],
      // Form Data
      tower_id: "",
      currentYear: new Date().getFullYear()
    };
  },

  methods: {
    goNext(step) {
      // Set activeStep to update the md-steppers component
      return (this.activeStep = step);
    },

    updateTowerId(tower_id) {
      this.tower_id = tower_id
    }
  },

  created() {
    if (this.user_data) {
      // Set tower_id for setting bank information to correct Stripe Account
      this.tower_id = this.user_data.organizations[0].id ? this.user_data.organizations[0].id : "";

      // step management
      let step = this.steps[this.user_data.onboarding_step];

      if(this.user_data.onboarding_step === 0) {
        this.activeStep = "home"
      } else {
        this.goNext(step.step);
      }

      this.onboardingStep = step;
    }
  }
};
</script>

<style scoped>
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: #3c4858;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepper-title {
  padding-left: 21px;
}

.logo {
  height: 22px;
}
</style>

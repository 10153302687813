import axios from 'axios';
import {API_BASE_URL} from "../../../shared/config/constants";

const INTERNAL_ERROR = 'An internal error has occurred. try again or contact the administrator.';
const NETWORK_ERROR = 'No Internet Connection.';
const UNAUTHORIZED = 'Not authorized to perform this request.';

/**
 * Requests a new valid access token.
 * 
 * @returns 
 */
function refreshToken() {
  return httpClient.post('/refresh')
    .then(response => {
      httpClient.defaults.headers.common["Authorization"] = `Bearer ${response.data.access}`;
    })
}

/**
 * Configure an interceptor to capture trivial errors fired in the app.
 * In this way we avoid doing the same logic in each request.
 */
function catchErrorResponse(httpClientInstance) {
  httpClientInstance.interceptors.response.use(undefined, function (error) {
    if ((!error.response && !error.code) || error.message === 'Network Error') { // Network error
      console.log(NETWORK_ERROR);
    } else if (error.code === 'ECONNABORTED') { // Timeout request
      console.log(INTERNAL_ERROR);
    } else {
      /**
       * Error 400, user not authorized to permorm this request.
       * Typically when the access token is invalid or has not been sent in the headers.
       */
      if (error.response.status === 401) {
        // Operation is not authorized.

        return refreshToken()
          .then(() => {
            const config = error.config;

						return httpClient({
              method: config.method, 
              url: config.url, 
              data: config.data
            });
          }).catch(error => {
            // Maybe the refresh token has expired...
            console.log(error);
          });
      } else if (error.response.status === 404) {
        // Resource not found.
      }
    }

    return Promise.reject(error);
  });
}

const httpClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000
});

catchErrorResponse(httpClient);

export default httpClient;

<template>
  <div class="submit-quote-form">
    <div class="container-padded">
      <div class="row">
        <div class="col-md-8 offset-md-2 pt-4">
          <div class="quotes-header">
            <h4>Quote job</h4>
            <p class="submit-quote-form__address pb-3">
              {{ jobAddressTitle }}<span v-if="request.job.intersection"> / {{ request.job.intersection }}</span>
            </p>
          </div>

          <!-- Submitting -->
          <div v-if="state.loading" class="col-md-12 text-center">
            <div class="text-center">
              <md-progress-spinner class="loader" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
              <p class="mt-4">Locating drivers...</p>
            </div>
          </div>

          <div v-if="state.building" class="quotes-body">
            <BuildQuote
              :request="request"
              :assignments="assignments"
              :completed_quotes="completed_quotes"
              :refreshing="state.refreshing"
              :drivers_with_etas="drivers_with_etas"
              :allow_select_drivers_without_etas="allowSelectDriversWithoutEtas"
              :submittable.sync="ready_to_submit"
              @complete="appendQuote"
              @refresh="refreshDriverLocations" />
          </div>

          <div v-if="state.reviewing" class="quotes-extras">
            <div class="comments">
              <h6>Comment to police dispatch</h6>
              <md-field class="heightSm">
                <md-textarea v-model="comment" placeholder="e.g. Weather will delay arrival"></md-textarea>
              </md-field>

              <h6>Comment to Operators</h6>
              <md-field class="heightSm">
                <md-textarea placeholder="e.g. Tim has the dolly bunks"></md-textarea>
              </md-field>
            </div>
          </div>

          <!-- Submitting -->
          <div v-if="state.submitting" class="col-md-12 text-center cellSuccess">
            <div class="text-center">
              <md-progress-spinner class="loader" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
              <p class="mt-4">Submitting quote...</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="footerBar position-fixed py-3">
      <fieldset class="row">
        <div class="col-md-10 offset-md-1">
          <md-button class="btn btn-primary" :disabled="!ready_to_submit" @click.prevent="submitQuote">Submit Quote</md-button>
          <md-button class="btn btn-secondary" @click="cancelQuote">Cancel</md-button>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import availableDrivers from '../../../mixins/available-drivers';
import jobServices from '../../../mixins/jobServices';

// Components
import BackButton from '../../utilities/back-button'
import BuildQuote from './build-quote'
import QuoteDetail from '../../quotes/quote-detail'
import QuoteRequestJobDetail from './quote-request-job-detail'
import TimeLeft from '../../utilities/timeleft'

export default {
  props: ['request', 'assignments'],

  components: {
    BackButton,
    BuildQuote,
    QuoteDetail,
    QuoteRequestJobDetail,
    TimeLeft
  },

  mixins: [availableDrivers, jobServices],

  data() {
    return {
      state: {
        loading: false,
        refreshing: false,
        building: false,
        summary: false,
        submitting: false
      },
      drivers_with_etas: null,
      allowSelectDriversWithoutEtas: true,
      ready_to_submit: false,
      distance_results: [],
      completed_quotes: [],
      comment: ""
    }
  },

  computed: {
    ...mapState('drivers', {
      drivers: state => state.all
    }),

    ...mapState('organizations', {
      current_organization: state => state.current
    }),

    requestedAt () {
      return new Date(this.request.created_at)
    },
    
    motoristIncidentFee () {
      const motoristServiceMap = {
        'Basic tow': 'hook_up',
        'Flat tire': 'tire_change',
        'Lockout': 'lock_out',
        "Won’t start": 'dead_battery',
        "Out of gas": 'out_of_gas',
        'Winch Out': 'winch_out'
      }
      const fee_key = motoristServiceMap[this.request.job.incident_type];

      return this.current_organization.fees[fee_key];
    },

    /**
     * It is necessary for the jobServices mixin.
     */
    job () {
      return this.request.job;
    }
  },

  methods: {
    setState (current_state, next_state) {
      this.state[current_state] = false
      this.state[next_state] = true
    },

    goBack () {
      if (this.state.equipment) {
        this.current_driver = null
        this.setState('equipment', 'drivers')
      } else if (this.state.preference) {
        this.current_equipment = null
        this.setState('preference', 'equipment')
      }
    },

    refreshDriverLocations () {
      this.state.refreshing = true;

      return Promise.all([
        this.getAvailableDrivers(this.assignments[0].id),
        this.delay(1000)
      ]).then(() => {
        this.state.refreshing = false;
        this.$nextTick(() => {
          this.state.building = true;
        });
      })
    },

    selectEquipment(equipment) {
      this.current_equipment = equipment
      this.setState('equipment', 'preference')
    },

    formattedQuotes() {
      return this.completed_quotes.map(quote => {
        const equipment_id = (quote.equipment === 'no-equipment') ? null : quote.equipment.id
        const preferred_id = (quote.preference === 'no-preference') ? null : quote.preference.id
        const price = (this.request.motorist_id === null ) ? null : this.motoristIncidentFee;

        return {
          driver_id: quote.driver.id,
          eta: quote.driver.eta.duration.value,
          incident_vehicle_id: preferred_id,
          vehicle_id: equipment_id,
          organization_id: this.current_organization.id,
          price: price
        }
      })
    },

    cancelQuote () {
      this.$emit('back')
    },

    async submitQuote () {
      this.setState('building', 'submitting')
      const quotes = this.formattedQuotes()
      const payload = {
        id: this.request.id,
        quotes: quotes,
      }
      
      let response = await this.$store.dispatch('quote_requests/createQuotes', payload)
      if (response == 'error') {
        this.$emit('quoting-expired')
      }
      this.$emit('quote-submitted')
    },

    appendQuote (quote) {
      this.completed_quotes.push(quote)
    },

    deleteQuote (index, id) {
      const driver_index = this.selected_drivers.indexOf(id)
      this.selected_drivers.splice(driver_index, 1)
      this.completed_quotes.splice(index, 1)
    },

    /**
     * Sort the available drivers. This method is invoked by the availableDrivers mixin.
     * Firstly We filter only the drivers with ETA, and finally by the closest ETA.
     */
    sortDrivers (drivers) {
      const sortedDrivers = drivers.filter(driver => (
        (this.allowSelectDriversWithoutEtas || driver.attributes.duration_in_seconds)
        && driver.attributes.preferred_vehicle_id
      )).sort((nextDriver, prevDriver) => {
        const nextEta = nextDriver.attributes.duration_in_seconds != null ? nextDriver.attributes.duration_in_seconds : Infinity;
        const prevEta = prevDriver.attributes.duration_in_seconds != null ? prevDriver.attributes.duration_in_seconds : Infinity;

        return nextEta - prevEta;
      });

      return sortedDrivers;
    }
  },

  created () {
    this.state.loading = true;
    
    this.refreshDriverLocations()
      .then(() => {
        this.state.loading = false;
      });
  },
}
</script>

<style lang="scss" scoped>
  .submit-quote-form {
    &__address {
      color: rgba(11, 57, 81, 0.65);
      font-size: 16px;
    }
  }
</style>

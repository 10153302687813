<template>
  <div class="job-info">
    <div v-if="view === 'info'">
      <div class="map">
        <JobInfoMap :job="request.job" :status="status"/>
      </div>
      <div class="details">
        <div class="container-padded">
          <div class="row">
            <div class="col-md-1">
              <BackButton @back="$emit('back')" />
            </div>
            <div class="col-md-10 pt-4 pb-4 mb-5">
              <JobInfoDetail 
                :request="request" 
                :request_data="requestData"
                :assignments="assignments" 
                @back="$emit('back')" 
                @complete="$emit('complete')" 
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer-actions" v-if="footerState">
        <JobInfoFooter :request="request" :state="footerState" @action="footerAction"/>
      </div>
    </div>

    <div v-if="view === 'submit-quote'">
      <div class="submit-quote">
        <SubmitQuoteForm 
          :request="request" 
          :assignments="assignments"
          @back="view = 'info'" 
          @quote-submitted="$emit('back')" 
          @quoting-expired="$emit('quoting-expired')"
        />
      </div>
    </div>

    <div v-if="view === 'assign-driver'">
      <div class="submit-quote">
        <AssignDriverForm :request="request" @back="$emit('back')" @quote-submitted="$emit('back')" />
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../api/quote-requests';
import AssignDriverForm from '../forms/assign-driver/form'
import BackButton from '../utilities/back-button'
import JobInfoDetail from './job-info-detail'
import JobInfoFooter from './job-info-footer'
import JobInfoMap from '../maps/job-info-map'
import SubmitQuoteForm from '../forms/submit-quote/form'

export default {
  props: ['request'],

  components: {
    AssignDriverForm,
    BackButton,
    JobInfoDetail,
    JobInfoFooter,
    JobInfoMap,
    SubmitQuoteForm
  },

  data: () => {
    return {
      view: 'info',
      complete_modal_active: false,
      requestData: null,
      assignments: []
    }
  },

  computed: {
    status () {
      return this.parseStatusTower(this.request)
    },
    footerState () {
      switch (this.status) {
        case 'new':
        case 'open':
        case 'direct':
          return this.status
          break;
        default:
          return null
      }
    }
  },

  methods: {
    footerAction (event) {
      switch (event) {
        case 'assign-driver':
          this.view = 'assign-driver'
          break;
        case 'decline-quote':
          this.$emit('decline')
          break;
        case 'decline-direct':
          this.$emit('decline-direct-assignment')
          break;
        case 'start-quote':
          this.view = 'submit-quote'
          break;
        case 'complete-assignment':
          this.$emit('complete-assignment')
          break;
        default:
          break;
      }
    },
  },

  created() {
    Api.receivedQuoteRequests({ id: this.request.id })
      .then(response => {
        const data = response.body;

        if (!data || !data.job || !data.job.assignments) return ;

        this.requestData = data;
        this.assignments = data.job.assignments;
      });
  }
}
</script>

<template>
  <md-card class="card account-owner card-wrecker-detail card-box offset-card section" id="account-owner-card">
    <h2 class="title-card">Account Owner</h2>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">First name</label>
          <div class="form__field">{{first_name}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Last Name</label>
          <div class="form__field">{{last_name}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Mobile phone number</label>
          <div class="form__field">{{phone_number}}</div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <div class="field">
          <label class="form__label">Email</label>
          <div class="form__field">{{email}}</div>
        </div>
      </div>
    </div>
  </md-card>
</template>

<script>
export default {
  name: 'AccountOwner',
  props: ['owner'],
  data: () => ({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: ''
  }),
  methods: {
    formatPhoneNumber (str) {
      const cleaned = ("" + str).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
      return null;
    }
  },
  created () {
    this.first_name = this.owner.first_name,
    this.last_name = this.owner.last_name,
    this.phone_number = this.formatPhoneNumber(this.owner.phone_number),
    this.email = this.owner.email
  }
}
</script>
<template>
  <div class="upload-input">
    <input type="file" :id="`file-picker-:${filename}`" direct_upload="true" @change="fileChange" />
    <label :for="`file-picker-:${filename}`">
      <div class="icon" />
      <span>{{ placeholder || 'Add file...' }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    filename: Number,
    placeholder: String,
    onSelect: Function
  },
  methods: {
    fileChange({
      target: {
        files: [file]
      }
    }) {
      this.onSelect(this.filename, file);
    }
  }
};
</script>

<style scoped lang="scss">
.upload-input {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 56px;
  border: 0;
  border-bottom: 2px solid rgba(15, 50, 69, 0.38);
  cursor: pointer;
  input {
    height: 100%;
    width: 100%;
  }
  label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    background-color: white;
    padding: 16px;
    cursor: pointer;
  }
  span {
    font-size: 16px;
    color: gba(238, 239, 240, 0.38);
  }
  .icon {
    height: 24px;
    width: 24px;
    background-image: url("../../../../images/attach-file.svg");
    background-size: contain;
    display: inline-block;
  }
}
</style>

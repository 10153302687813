<template>
  <div class="vehicle-detail">
    <p class="mb-0 text-capitalize">
      {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.color }}<span class="text-uppercase" v-if="vehicle.plate">, {{ vehicle.plate }}</span>
      <StatusBadge v-if="showStatusBadge" :status="'canceled'" text="canceled" />
    </p>

    <p class="faded-1 mb-0" :class="source == 'towco' ? 'caption' : ''">
      <span v-if="vehicle.insurance">{{ vehicle.insurance }} Insurance<br></span>
      <span v-if="vehicle.drivable">Rollover</span><span v-if="vehicle.drivable && vehicle.flatbed">,</span> <span v-if="vehicle.flatbed">Requires a flat bed</span><br v-if="vehicle.drivable || vehicle.flatbed">
      <span v-if="vehicle.release">Release with proof<br></span>
      <span v-if="!vehicle.impound && !vehicle.preferred && source != 'towco'">Assigned by Rotation List</span>
      <span v-if="towers.length > 0 && vehicle.impound">Impound by {{ impoundName }}<br></span>
      <span v-if="towers.length > 0 && vehicle.preferred">Tow by {{ preferredTowerName }}<br></span>
      <span v-if="vehicle.comment">"{{ vehicle.comment }}"<br></span>
      <span v-if="vehicle.requested_destination">Take to {{ vehicle.requested_destination.line_1 }}</span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Import components
import StatusBadge from '../utilities/status-badge';

export default {
  props: {
    vehicle: Object, 
    source: String,
    showStatusBadge: Boolean
  },

  components: {
    StatusBadge
  },

  computed: {
    ...mapState("organizations", {
      towers: state => state.towers
    }),
    preferredTowerName () {
      if (this.towers.length > 0 && this.vehicle.preferred && this.vehicle.preferred_tower_id) {
        const preferred_tower = this.towers.find(tower => tower.id === this.vehicle.preferred_tower_id)
        return preferred_tower.name
      } else {
        return null
      }
    },
    impoundName () {
      if (this.towers.length > 0 && this.vehicle.impound && this.vehicle.impound_tower_id) {
        const impound_tower = this.towers.find(tower => tower.id === this.vehicle.impound_tower_id)
        return impound_tower.name
      } else {
        return null
      }
    }
  },
}
</script>

<style scoped lang='scss'>
</style>

<template>
  <div class="assignment-location mb-5 pb-2">
    <div class="section-title pb-2">
      <h5>Specify location</h5>
      <p v-if="!location" class="caption">Enter an address or intersection.</p>
    </div>
    <div v-if="location" class="row">
      <div class="col-9">
        <p class="mb-0">{{ location.address_primary }}</p>
        <p v-if="location.local_description" class="mb-0 faded-1">{{ location.local_description }}</p>
        <p class="mb-0 faded-1">{{ selected_zone.name }} — {{ selected_zone.code }}</p>
      </div>
      <div class="col-3 text-left">
        <md-button class="md-icon-button btnIcon" @click="editLocation">
          <md-icon>edit</md-icon>
        </md-button>
      </div>
    </div>
    <md-button v-else class="btn btn-secondary compact btnAddLocation"
               @click="launchAddLocation()"
               ref="btnAddLocation"
               tabindex="1">
      <md-icon>add</md-icon>
      Add Location
    </md-button>
    <div class="add-location">
      <md-dialog :md-active.sync="adding">
        <md-dialog-title class="border-bottom bg-white mb-0 pb-3">
          <h5>Add location</h5>
        </md-dialog-title>
        <md-dialog-content class="pb-0">
          <div class="row">
            <div class="col-md-6 border-right">
              <TelemetryAutocomplete
                telemetry_environment="production"
                :organization_id="current_organization.id"
                :organization_location="{ lat: current_organization.latitude, lng: current_organization.longitude }"
                v-bind:selection.sync="autocomplete_selection"
                :external_query="externalQuery"
                @update:selection="setSelection(autocomplete_selection)"
                ref="telemetryEl"/>
              <div class="pt-0 pb-1">
                <md-field class="mt-md-1">
                  <label for="zone-select">Zone</label>
                  <md-select v-model="zone_id" name="zone-select" id="zone-select">
                    <md-option v-for="zone in filtered_zones" :value="zone.id">
                      {{ zone.name }} — {{ zone.code }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="pt-0 pb-3">
                <md-field class="mt-md-1">
                  <label>Local description (optional)</label>
                  <md-input ref="local_description" v-model="local_description" tabindex="4"></md-input>
                  <span class="md-helper-text">e.g. "Walmart parking lot"</span>
                </md-field>
              </div>
              <div class="pt-5">
                <h6>Tips</h6>
                <ul>
                  <li>Use a forward slash for intersections, e.g. “Main St / Smith Ave”</li>
                  <li>Can’t find a location? Drag and zoom the map to a specific point.</li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 p-0">
              <MapLocationSelection :initial_center="orgLocation" :external_center="autocompleteCenter" :place.sync="map_selection" @update:place="setSelection(map_selection)"/>
            </div>
          </div>
        </md-dialog-content>
        <div class="footerBar px-3 py-2">
          <md-button class="btn btn-primary" @click="addLocation" :disabled="!confirmEnabled" tabindex="31">Confirm</md-button>
          <md-button class="btn btn-secondary" @click="cancelAddLocation" tabindex="32">Cancel</md-button>
        </div>
      </md-dialog>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

// Components
import TelemetryAutocomplete from '../map-forms/telemetry-autocomplete'
import MapIcon from '../../maps/map-icon'
import MapLocationSelection from '../map-forms/map-location-selection'
import errorClass from '../../../../../javascripts/error_class'

export default {
  props: ['location'],

  inject: ['$v'],

  components: {
    TelemetryAutocomplete,
    MapLocationSelection
  },

  data () {
    return {
      adding: false,
      autocomplete_selection: null,
      map_selection: null,
      selection: null,
      local_description: null,
      zone_id: null,
      // Temporary Fix: Ingham Zones that should not be displayed in dropdown
      blocked_zone_ids: [
        "61b0b030-091a-43b3-bd66-d816da196e10",
        "f7890450-295d-420a-b380-52332c5aa712",
        "0c6d28b6-dcbd-47f2-b056-a06c8bbacbab",
        "86f42431-b192-4aa0-acd2-d5b59fa09962",
        "b6ddb7d2-ab6b-487b-a63c-c552d8d13c5a",
        "ab52145f-cd8f-43ba-8b55-b83ba08f503d",
        "d525886b-5e38-4431-af78-d4c0445b50c6",
        "e151f8bb-89b7-45d7-affa-7f05d685dc6a",
        "6e899b56-7abe-46b9-b010-16ce61a6e16b",
        "9c5852f8-fc55-4cac-9dba-b1a48dec64d1",
        "0bb31159-118b-4942-b973-cd19caffe408",
        "c960f89f-b9d9-4266-99cb-7c1221560dac",
        "89bf3e02-3900-47bd-8122-13f785a0e087",
        "ebb1ac28-2738-4375-bcbf-3e53acca1fc1",
        "2f9c3ae9-b2e8-4b53-99ec-48778dd02fe3",
        "fd5876e4-6acc-4db3-a68c-ea4d2b6d92c5",
        "50d4cf8f-b55f-43f3-aa5c-11dc13fc88a5",
        "ab02380c-0058-4fdb-8404-7d3e34735cb2",
        "272c71ce-1cfe-4cf7-b66a-c367f32a5b1d",
        "3eb933db-9a4c-4b40-82c3-9fd045f65ad3",
        "ed8576c8-15ea-41e6-b46b-6ec0ec4da9d0",
      ]
    }
  },

  computed: {
    ...mapGetters('organizations', [
      'orgLocation'
    ]),
    ...mapState('organizations', {
      current_organization: state => state.current
    }),
    filtered_zones () {
      return this.current_organization.zones.filter(zone => !this.blocked_zone_ids.includes(zone.id));
    },
    // Change the map center if a selection is made via autocomplete:
    autocompleteCenter () {
      if (this.autocomplete_selection === null) { return null } else {
        return {
          lat: this.autocomplete_selection.position.lat,
          lng: this.autocomplete_selection.position.lng
        }
      }
    },
    // Change the autocomplete query if a selection is made via map:
    externalQuery () {
      if (this.map_selection === null && this.location === null) {
        return ""
      } else if (this.map_selection) {
        return this.map_selection.address_primary
      } else {
        return this.location.address_primary
      }
    },
    confirmEnabled () {
      return (this.autocomplete_selection || this.map_selection) && this.zone_id
    },
    selected_zone () {
      if (this.zone_id) {
        const zone_index = this.current_organization.zones.findIndex((item) => {
          return item.id === this.zone_id
        })
        return this.current_organization.zones[zone_index]
      } else {
        return null
      }
    }
  },

  methods: {
    launchAddLocation () {
      this.adding = true
      setTimeout(this.focusLocationInput, 150)
    },
    focusLocationInput () {
      // Placing focus on autocomplete element requires this weird workaround
      this.$refs.telemetryEl.$refs.locationInput.$el.querySelector('input').focus();
    },
    addLocation () {
      this.map_selection = this.selection
      this.autocomplete_selection = this.selection
      this.emitLocation()
      this.adding = false
    },
    cancelAddLocation () {
      this.adding = false
    },
    emitLocation () {
      const location = {
        address_primary: this.selection.address_primary,
        local_description: this.local_description,
        zone_id: this.zone_id,
        latLng: {
          lat: this.selection.position.lat,
          lng: this.selection.position.lng
        },
      }
      this.$emit('update:location', location)
    },
    editLocation () {
      this.adding = true
    },
    setSelection (s) {
      this.selection = s
    }
  },

  created () {
    this.getValidationClass = errorClass.getValidationClass;
  }
}
</script>

<style scoped lang="scss">
ul {
  padding-inline-start: 20px;
  font-size: 14px;
  line-height: 1.29;
  color: rgba(15, 50, 70, 0.65);
}

.md-dialog-container {
  width: 60% !important;
}
</style>
